import React from "react";
import { FaArrowUp } from "react-icons/fa";
import { useEffect, useState } from "react";

const ToTopBtn = () => {
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    // toggleVisible;
  }, []);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 500) {
      setVisible(true);
    } else if (scrolled <= 500) {
      setVisible(false);
    }
  };
  window.addEventListener("scroll", toggleVisible);
  return (
    <>
      {visible && (
        <div
          onClick={() => {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          }}
          style={{
            position: "fixed",
            //   padding: "1rem 2rem",
            fontSize: "10px",
            bottom: "70px",
            left: "3%",
            backgroundColor: "#003399",
            color: "#fff",
            textAlign: "center",
            width: "30px",
            height: "30px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "50%",
            zIndex: "2000000000",
          }}
        >
          <FaArrowUp />
        </div>
      )}
    </>
  );
};

export default ToTopBtn;
