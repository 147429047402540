import React, { useEffect } from "react";
import "./secondBanner.css";
import secondBanner from "../../assets/images/banner2.jpg";
import { Link } from "react-router-dom";
import { useAnimation, motion } from "framer-motion";

import { useInView } from "react-intersection-observer";
import { Location } from "react-router-dom";

import { useLocation } from "react-router-dom";

const squareVariantsY = {
  visible: { opacity: -1, y: 0, transition: { duration: 2 } },
  hidden: { opacity: 0, y: 100 },
};
const squareVariants = {
  visible: { opacity: 1, transition: { duration: 3 } },
  hidden: { opacity: 0 },
};

const SecondBanner = ({
  banner2,
  headText,
  subText,
  btn,
  linkTo,
  children,
}) => {
  const controls = useAnimation();
  const [ref, inView] = useInView();
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <div
      style={{
        background: `url(${banner2})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        overflow: "hidden",
        width: "100%",
        position: "relative",
        marginTop: "20px",
      }}
    >
      <div className="bannerCoverB">
        <div className="secondBanner-maxGen">
          <motion.div
            ref={ref}
            animate={controls}
            initial="hidden"
            variants={squareVariants}
          >
            <div className="secondBanner-maxGens">{headText}</div>
            <div className="secondBanner-maxGenSs">{subText}</div>
            {children}
            {btn && (
              <Link
                to={linkTo}
                style={{ textDecoration: "none" }}
                className="secondBanner-maxGenSsBtn"
              >
                {btn}
              </Link>
            )}
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default SecondBanner;
