import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useRoutes,
} from "react-router-dom";

import {
  AbsorbentsPageCont,
  AerosolPageCont,
  AllProductPageCont,
  AssemblyPastePageCont,
  BioLubricantsPageCont,
  BrandsPageCont,
  ContactPageCont,
  DegreaserPageCont,
  DetergentPageCont,
  DirectoryPageCont,
  DownloadPageCont,
  EnergyEfficientPageCont,
  FoodIndustryPageCont,
  HomePageCont,
  IndustrialLubricantPageCont,
  LubricantPageCont,
  LubricantSpecialPageCont,
  LubricationSystemsPageCont,
  LubritecPageCont,
  MarineAviationLubPageCont,
  MetalLubricantsPageCont,
  MoreProductsPageCont,
  OrderFormPageCont,
  PresentsPageCont,
  ReleaseAgentsageCont,
  SectorsPageCont,
  ServicesPageCont,
  SuccessStoriesPageCont,
  TransportationLubricantPageCont,
} from "./pages";

const RoutesE = () => {
  let routes = useRoutes([
    { path: "/", element: <HomePageCont /> },

    { path: "/brands", element: <BrandsPageCont /> },
    { path: "/contact", element: <ContactPageCont /> },
    { path: "/directory", element: <DirectoryPageCont /> },
    { path: "/downloads", element: <DownloadPageCont /> },
    { path: "/lubricants", element: <LubricantPageCont /> },
    { path: "/lubrication/systems", element: <LubricationSystemsPageCont /> },
    { path: "/lubritec", element: <LubritecPageCont /> },
    { path: "/more_products", element: <MoreProductsPageCont /> },
    { path: "/presents", element: <PresentsPageCont /> },
    { path: "/sectors", element: <SectorsPageCont /> },
    { path: "/services", element: <ServicesPageCont /> },
    { path: "/success_stories", element: <SuccessStoriesPageCont /> },
    { path: "/lubricant/special", element: <LubricantSpecialPageCont /> },
    { path: "/bio/lubricants", element: <BioLubricantsPageCont /> },
    { path: "/energy/efficient", element: <EnergyEfficientPageCont /> },
    { path: "/industrial/lubricant", element: <IndustrialLubricantPageCont /> },
    { path: "/food/industry", element: <FoodIndustryPageCont /> },
    {
      path: "/transportation/lubricant",
      element: <TransportationLubricantPageCont />,
    },
    { path: "/metal/lubricants", element: <MetalLubricantsPageCont /> },
    {
      path: "/marine/aviation_lubricant",
      element: <MarineAviationLubPageCont />,
    },
    {
      path: "/aerosol",
      element: <AerosolPageCont />,
    },
    {
      path: "/degreaser",
      element: <DegreaserPageCont />,
    },
    {
      path: "/release_agents",
      element: <ReleaseAgentsageCont />,
    },
    {
      path: "/detergent_page",
      element: <DetergentPageCont />,
    },

    {
      path: "/assembly/paste",
      element: <AssemblyPastePageCont />,
    },
    {
      path: "/absorbents",
      element: <AbsorbentsPageCont />,
    },
    {
      path: "/orderform",
      element: <OrderFormPageCont />,
    },
    {
      path: "/all_product",
      element: <AllProductPageCont />,
    },
  ]);
  return routes;
};

const AppWrapper = () => {
  return (
    <Router>
      <RoutesE />
    </Router>
  );
};

export default AppWrapper;
