import React, { useState, useEffect } from "react";
import DistributorsList from "../../components/distributorsList/DistributorsList";
import FirstBanner from "../../components/firstBanner/FirstBanner";
import Header from "../../components/header/Header";
import "./homePage.css";
import banner2 from "../../assets/images/banner2.jpg";
import SecondBanner from "../../components/secondBanner/SecondBanner";
import Footer from "../../components/footer/Footer";
import ImgRightInfo from "../../components/imgRightInfo/ImgRightInfo";
import ImgLeftInfo from "../../components/imgLeftInfo/ImgLeftInfo";
import CarouselSection from "../../components/carouselSection/CarouselSection";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { Location } from "react-router-dom";

import { useLocation } from "react-router-dom";

const squareVariantsY = {
  visible: { opacity: 1, y: 0, transition: { duration: 2 } },
  hidden: { opacity: 0, y: 100 },
};

const HomePage = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);
  return (
    <div>
      <Header />

      <FirstBanner />

      <div className="homePack2_gen">
        <div className="homePack2_genMax">
          <div className="homePack2_head">
            Quality range of industrial lubricants with cutting edge technology
          </div>
          <div className="homePack2_para">
            Uncover the benefits of having a partner specialized in industrial
            lubrication. A team of professionals that deliver to you the
            appropriate products required for the maintenance of your equipment.
            Tailored made programmes for optimisation of your machineries and
            operations efficiency.
          </div>
        </div>
      </div>
      {/* <motion.span
        ref={ref}
        animate={controls}
        initial="hidden"
        variants={squareVariantsY}
      > */}
      <DistributorsList />
      {/* </motion.span> */}
      <SecondBanner
        banner2={banner2}
        headText=" SOLUTIONS IN INDUSTRIAL LUBRICATION ACCORDING TO ITS SECTOR OR
            INDUSTRIAL PROCESS"
        subText="Whatever your sector or type of company, at OPlug we have the
            right products and the experience to implement them in your work
            process."
        btn="Know More"
        linkTo="/sectors"
      />
      <ImgRightInfo />
      <ImgLeftInfo />
      <CarouselSection />
      <Footer />
    </div>
  );
};

export default HomePage;
