import React from "react";
import { ProtectedLayout } from "./protected";
import Absorbents from "./screens/absorbents/Absorbents";
import Aerosol from "./screens/aerosol/Aerosol";
import AllProductPage from "./screens/allProductsPage/AllProductPage";
import AssemblyPaste from "./screens/assemblyPaste/AssemblyPaste";
import BioLubricants from "./screens/bioLubricants/BioLubricants";
import BrandsPage from "./screens/brandsPage/BrandsPage";
import ContactPage from "./screens/contactPage/ContactPage";
import Degreaser from "./screens/degreaser/Degreaser";
import DetergentCleaners from "./screens/detergentCleaners/DetergentCleaners";
import DirectoryPage from "./screens/directoryPage/DirectoryPage";
import DownloadPage from "./screens/downloadsPage/DownloadPage";
import EnergyEfficient from "./screens/energyEfficient/EnergyEfficient";
import FoodIndustry from "./screens/foodIndustry/FoodIndustry";
import HomePage from "./screens/homePage/HomePage";
import IndustrialLubricants from "./screens/industrialLubricants/IndustrialLubricants";
import LubricantPage from "./screens/lubricantPage/LubricantPage";
import LubricantsSpecial from "./screens/lubricantsSpecial/LubricantsSpecial";
import LubricationSystemsPage from "./screens/lubricationSystemsPage/LubricationSystemsPage";
import LubritecPage from "./screens/lubritecPage/LubritecPage";
import MarineAvaitionLub from "./screens/marineAvaitionLub/MarineAvaitionLub";
import MetalLubricants from "./screens/metalLubricants/MetalLubricants";
import MoreProductsPage from "./screens/moreProductsPage/MoreProductsPage";
import OrderForm from "./screens/orderForm/OrderForm";
import PresentsPage from "./screens/presentsPage/PresentsPage";
import ReleaseAgents from "./screens/releaseAgents/ReleaseAgents";
import SectorsPage from "./screens/sectorsPage/SectorsPage";
import ServicesPage from "./screens/servicesPage/ServicesPage";
import SuccessStoriesPage from "./screens/successStoriesPage/SuccessStoriesPage";
import TransportationLubricants from "./screens/transportationLubricant/TransportationLubricants";

export const HomePageCont = () => {
  return <HomePage />;
};

export const BrandsPageCont = () => {
  return <BrandsPage />;
};

export const ContactPageCont = () => {
  return <ContactPage />;
};

export const DirectoryPageCont = () => {
  return <DirectoryPage />;
};

export const DownloadPageCont = () => {
  return <DownloadPage />;
};

export const LubricantPageCont = () => {
  return <LubricantPage />;
};

export const LubricationSystemsPageCont = () => {
  return <LubricationSystemsPage />;
};

export const LubritecPageCont = () => {
  return <LubritecPage />;
};

export const MoreProductsPageCont = () => {
  return <MoreProductsPage />;
};

export const PresentsPageCont = () => {
  return <PresentsPage />;
};

export const SectorsPageCont = () => {
  return <SectorsPage />;
};

export const ServicesPageCont = () => {
  return <ServicesPage />;
};

export const SuccessStoriesPageCont = () => {
  return <SuccessStoriesPage />;
};

export const LubricantSpecialPageCont = () => {
  return <LubricantsSpecial />;
};

export const BioLubricantsPageCont = () => {
  return <BioLubricants />;
};

export const EnergyEfficientPageCont = () => {
  return <EnergyEfficient />;
};

export const IndustrialLubricantPageCont = () => {
  return <IndustrialLubricants />;
};

export const FoodIndustryPageCont = () => {
  return <FoodIndustry />;
};

export const TransportationLubricantPageCont = () => {
  return <TransportationLubricants />;
};

export const MetalLubricantsPageCont = () => {
  return <MetalLubricants />;
};

export const MarineAviationLubPageCont = () => {
  return <MarineAvaitionLub />;
};

export const AerosolPageCont = () => {
  return <Aerosol />;
};

export const DegreaserPageCont = () => {
  return <Degreaser />;
};

export const ReleaseAgentsageCont = () => {
  return <ReleaseAgents />;
};

export const DetergentPageCont = () => {
  return <DetergentCleaners />;
};

export const AssemblyPastePageCont = () => {
  return <AssemblyPaste />;
};

export const AbsorbentsPageCont = () => {
  return <Absorbents />;
};

export const OrderFormPageCont = () => {
  return <OrderForm />;
};

export const AllProductPageCont = () => {
  return <AllProductPage />;
};
