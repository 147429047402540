import React, { useEffect } from "react";
import "./imgRightInfo.css";
import serviceso from "../../assets/images/serviceso.webp";
import leftImage from "../../assets/images/leftImage.jpg";
import { FaChevronRight } from "react-icons/fa";
import { useAnimation, motion } from "framer-motion";

import { useInView } from "react-intersection-observer";
import { Link, Location } from "react-router-dom";

import { useLocation } from "react-router-dom";

const squareVariantsX = {
  visible: { opacity: 1, x: 0, transition: { duration: 2 } },
  hidden: { opacity: 0, x: -100 },
};

const squareVariantsY = {
  visible: { opacity: 1, x: 0, transition: { duration: 2 } },
  hidden: { opacity: -1, x: 100 },
};
const squareVariants = {
  visible: { opacity: 1, transition: { duration: 3 } },
  hidden: { opacity: 0 },
};

const ImgRightInfo = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView();
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);
  return (
    <div className="imgRight_infoGen">
      <div className="imgRight_infoGenMax">
        <motion.div
          ref={ref}
          animate={controls}
          initial="hidden"
          variants={squareVariantsX}
          className="imgRight_infoLeft"
        >
          <div className="imgRight_infoHead">
            EXPERIENCE IN THE APPLICATION OF INDUSTRIAL LUBRICATION PRODUCTS AND
            SERVICES
          </div>
          <div className="imgRight_infoPara">
            A range of tailor-made solutions to optimize the lubrication of your
            industry in order to reduce maintenance and production costs.
            Sharing our experience to improve the performance of your equipment.
          </div>
          <Link
            to="/services"
            style={{ textDecoration: "none" }}
            className="imgRight_infoSubParaBtnCo"
          >
            <div className="imgRight_infoSubParaBtn">OPLUG SERVICES </div>
            <div className="imgRight_infoSubParaBtnIcon">
              <FaChevronRight />
            </div>
          </Link>
        </motion.div>

        <motion.div
          ref={ref}
          animate={controls}
          initial="hidden"
          variants={squareVariantsY}
          className="imgRight_infoRight"
        >
          <div className="imgRight_infoImg">
            <img src={leftImage} alt="serviceso" />
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default ImgRightInfo;
