import React from "react";
import "./servicesOpt.css";

const ServicesOpt = ({ serviceIcon, serviceHead, serviceText }) => {
  return (
    <div className="servicesOpt_gen">
      <div className="servicesOpt_genIcon">{serviceIcon}</div>

      <div className="servicesOpt_genHead">{serviceHead}</div>

      <div className="servicesOpt_subText"> {serviceText}</div>
    </div>
  );
};

export default ServicesOpt;
