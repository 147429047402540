import React from "react";
import "./carouselSection.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import CarouselComp from "../carouselComp/CarouselComp";
import news_one from "../../assets/images/news_one.jpg";
import news_two from "../../assets/images/news_two.webp";
import news_three from "../../assets/images/news_three.webp";
import news_four from "../../assets/images/news_four.webp";
import news_five from "../../assets/images/news_five.webp";
import news_six from "../../assets/images/news_six.webp";
import news_seven from "../../assets/images/news_seven.webp";
import news_eight from "../../assets/images/news_eight.webp";
import news_nine from "../../assets/images/news_nine.webp";
import news_ten from "../../assets/images/news_ten.webp";

const CarouselSection = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 1900, min: 1024 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  return (
    <Carousel
      infinite={true}
      swipeable={false}
      draggable={false}
      responsive={responsive}
      autoPlay={true}
      autoPlaySpeed={2000}
      keyBoardControl={true}
      className="carouselGen_maxWidth"
      // customTransition="all .5"
      // transitionDuration={500}
    >
      <CarouselComp
        caroImg={news_one}
        caroHead="FG to inaugurate Lee Engineering gas facility Q1 2023"
        caroBody="Lee Engineer International Machinery Services Ltd., an equipment manufacturing center, will be inaugurated Government in the first quarter of 2023 to boost oil and gas production..."
      />
      <CarouselComp
        caroImg={news_two}
        caroHead="Access to forex major challenge to marketers – DAPPMAN"
        caroBody="The Petroleum Products and Deposits Traders Association of Nigeria (DAPPMAN) says accessing foreign exchange at the official rate is a serious challenge for traders. Ms. Winifred..."
      />
      <CarouselComp
        caroImg={news_three}
        caroHead="Ministry develops framework on oil, gas pipeline assets concession"
        caroBody="The Ministry of Petroleum Resources says it has developed a framework for the concessioning of oil and gas pipeline assets. This was disclosed by the Permanent..."
      />
      <CarouselComp
        caroImg={news_four}
        caroHead="Fingerprinting Nigerian crude remains solution to oil theft — expert"
        caroBody="An energy consultant, Ademola Adigun, says fingerprinting Nigerian crude supported with international advocacy will permanently address frequent crude oil theft in the country. Adigun said this in..."
      />
      <CarouselComp
        caroImg={news_five}
        caroHead="Shell spends $1m to support flood victims in Niger Delta"
        caroBody="The Shell Petroleum Development Company of Nigeria (SPDC) says it has committed One million dollars to provide relief materials to people impacted by the current flood..."
      />
      <CarouselComp
        caroImg={news_six}
        caroHead="NNPC, partners resolve Addax dispute amicably"
        caroBody="The protracted dispute on Oil Mining Leases (OMLs) 123124, 126137, operated by Addax Petroleum Nigeria Limited, has finally been laid to rest, paving the way for..."
      />
      <CarouselComp
        caroImg={news_seven}
        caroHead="Experts urge transparency in marginal field licensing"
        caroBody="Some experts in the oil and gas industry have advised the Federal Government to ensure proper implementation of the Petroleum Industry Act (PIA), and transparency in..."
      />
      <CarouselComp
        caroImg={news_eight}
        caroHead="Agip distributes relief materials to flood victims in 260 communities in Imo, Rivers, Bayelsa & Delta"
        caroBody="The Nigerian Agip Oil Company (NAOC) and its Joint Venture (JV) partners, on Monday delivered food and essential commodities to some 260 communities impacted by flood..."
      />
      <CarouselComp
        caroImg={news_nine}
        caroHead="Addax workers resume strike, deplete Nigeria’s oil production"
        caroBody="About 324 Nigerian employees of Addax Petroleum Development Nigeria have resumed their suspended strike due to the inability of the Federal Government and management of the..."
      />
      <CarouselComp
        caroImg={news_ten}
        caroHead="NLNG calls for more investments to ensure reliable LPG supply"
        caroBody="The Nigeria LNG Ltd. (NL has called on stakeholders in the domestic Liquified Petroleum Gas (LPG) value chain to invest in the supply of the product..."
      />
    </Carousel>
  );
};

export default CarouselSection;
