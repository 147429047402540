import React from "react";
import FormInput from "../formInput/FormInput";
import "./pageAutoForm.css";

const PageAutoForm = () => {
  return (
    <div className="autoForm_Flex">
      <div className="autoForm_flexOneGen">
        <div className="auto_formHeader">Do you need more info?</div>
        <div className="auto_formParagraph">
          Request your product, send us your questions, ask us for more
          information... We offer you a quick and personalized response to all
          your demands.
        </div>
        <div className="auto_formParaSub">
          If you wish you can also call us:
        </div>
        <div className="auto_formParaSubTel">Tel. +234 916 870 6000</div>
      </div>

      <div className="autoForm_flexTwoGen">
        <div className="auto_genHeading">
          Fill the form to complete your order
        </div>
        <FormInput />
      </div>
    </div>
  );
};

export default PageAutoForm;
