import React, { useState, useMemo, useEffect } from "react";
import Pagination from "../../constant/Pagination";
import ProductComponents from "../productComponents/ProductComponents";
import "./showAllProductComp.css";

import { aerosolLubricants } from "../productsAssets/ProductData";
// import { allProduct } from "../productsAssets/ProductData";
import { allProduct } from "../productsAssets/AllProducts";
import { useLocation } from "react-router-dom";
import SearchTopBanner from "../firstBanner/SearchTopBanner";
import ToTopBtn from "../toTopBtn/ToTopBtn";

let PageSize = 10;

const ShowAllProductComp = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [allProductList, setAllProductList] = useState([]);
  const [error, setError] = useState("");

  // const handleSearch = (value) => {
  //   if (value.length < 0) return setAllProductList(sortedData);

  //   const filteredData = sortedData.filter((item) =>
  //     item.productTitle.toLowerCase().includes(value.toLowerCase())
  //   );

  //   if (filteredData.length) {
  //     setAllProductList(filteredData);
  //     setError("");
  //   } else {
  //     setError("No Contacts Were Found");
  //   }
  // };

  const handleSearch = (value) => {
    if (value.length < 1) return setAllProductList(allProduct);

    const filteredData = allProduct.filter((item) => {
      return Object.values(item)
        .join(" ")
        .toLowerCase()
        .includes(value.toLowerCase());
    });

    if (filteredData.length) {
      setAllProductList(filteredData);
      setError("");
    } else {
      setError("No Contacts Were Found");
    }
  };
  const location = useLocation();

  // const sortedData = allProduct.sort((a, b) =>
  //   a.allProduct.localeCompare(b.allProduct)

  // );

  function getConnectionList() {
    setAllProductList(allProduct);
  }

  useEffect(() => {
    getConnectionList();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return allProductList.slice(firstPageIndex, lastPageIndex);
  }, [currentPage]);

  return (
    <div className="allProductspecial_lubGenFam">
      <SearchTopBanner
        onChange={(e) => {
          handleSearch(e);
        }}
      />
      <div className="allProductspecial_lubMax">
        <div className="allProductspecial_lubHeading">All Products</div>
        <ToTopBtn />
        {allProductList.map((data, index) => {
          return (
            <div>
              <ProductComponents
                productImg={data.productImg}
                productTitle={data.productTitle}
                productPara={data.productPara}
              />
            </div>
          );
        })}
      </div>
      {/* <Pagination
        className="allProductpagination-bar"
        currentPage={currentPage}
        totalCount={allProductList.length}
        pageSize={PageSize}
        onPageChange={(page) => setCurrentPage(page)}
      /> */}
    </div>
  );
};

export default ShowAllProductComp;
