import React, { useState, useEffect } from "react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import { useLocation } from "react-router-dom";
import ShowProductIndusLub from "../../components/showProductIndusLub/ShowProductIndusLub";
import { industryLubricants } from "../../components/productsAssets/ProductData";

const IndustrialLubricants = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <div>
      <Header />

      <ShowProductIndusLub />
      <Footer />
    </div>
  );
};

export default IndustrialLubricants;
