import React from "react";
import FlexBtn from "../flexBtn/FlexBtn";
import "./industrialProductList.css";

const IndustrialProductList = ({
  productHeading,
  productDesc,
  productLink,
  btnTag,
}) => {
  return (
    <div className="productListGenMax">
      <div className="productListHead">{productHeading}</div>
      <div className="productListPara">{productDesc}</div>

      <FlexBtn btnLink={productLink} btnTag={btnTag} />
    </div>
  );
};

export default IndustrialProductList;
