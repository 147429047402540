import React, { useEffect } from "react";
import "./contactCard.css";
import banner4 from "../../assets/images/banner4.jpg";
import { FaPhoneAlt, FaWhatsapp } from "react-icons/fa";
import { FaEnvelope } from "react-icons/fa";

import { useAnimation, motion } from "framer-motion";

import { useInView } from "react-intersection-observer";
import { Location } from "react-router-dom";

const squareVariantsYOne = {
  visible: { opacity: 1, x: 0, transition: { duration: 2 } },
  hidden: { opacity: 0, x: 100 },
};

const ContactCard = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <div
      style={{
        background: `url(${banner4})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        overflow: "hidden",
        width: "100%",
        position: "relative",
        marginTop: "20px",
      }}
    >
      <div className="contactCardCoverB">
        <div className="contactCard-maxGen">
          <motion.div
            ref={ref}
            animate={controls}
            initial="hidden"
            variants={squareVariantsYOne}
            className="contactCard_textContBack"
          >
            <div className="contact_genFlex">
              <div className="contact_genAddFlexOne">
                81, Mobolaji Bank Anthony Way, Ikeja Plaza, Ikeja, Lagos.
              </div>
              <div className="contact_genAddFlexTwo">
                <div className="contact_genFlexTwoCont">
                  <div className="contact_genFlexTwoIcon">
                    <FaWhatsapp />
                  </div>
                  <div className="contact_genFlexTwoText">
                    +234 916 870 6000
                  </div>
                </div>

                <div className="contact_genFlexTwoCont">
                  <div className="contact_genFlexTwoIcon">
                    <FaEnvelope />
                  </div>
                  <div className="contact_genFlexTwoText">info@o-plug.com</div>
                </div>

                <div className="contact_genFlexTwoCont">
                  <div className="contact_genFlexTwoIcon">
                    <FaPhoneAlt />
                  </div>
                  <div className="contact_genFlexTwoText">
                    +234 916 870 6000
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default ContactCard;
