import React, { useState, useMemo, useEffect } from "react";
import Pagination from "../../constant/Pagination";
import ProductComponents from "../productComponents/ProductComponents";
import "./showReleaseLub.css";

import { useLocation } from "react-router-dom";
import { releaseLubricants } from "../productsAssets/ProductData";

let PageSize = 10;

const ShowReleaseLub = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return releaseLubricants.slice(firstPageIndex, lastPageIndex);
  }, [currentPage]);

  return (
    <div className="releasespecial_lubGenFam">
      <div className="releasespecial_lubMax">
        <div className="releasespecial_lubHeading">Degreasers</div>
        {currentTableData.map((data, index) => {
          return (
            <div>
              <ProductComponents
                productImg={data.productImg}
                productTitle={data.productTitle}
                productPara={data.productPara}
              />
            </div>
          );
        })}
      </div>
      <Pagination
        className="releasepagination-bar"
        currentPage={currentPage}
        totalCount={releaseLubricants.length}
        pageSize={PageSize}
        onPageChange={(page) => setCurrentPage(page)}
      />
    </div>
  );
};

export default ShowReleaseLub;
