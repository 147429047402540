import React, { useState } from "react";

import "./leftNav.css";
import { NavLink, useLocation, Link, useNavigate } from "react-router-dom";
import { FaAngleDown } from "react-icons/fa";

const LeftNav = ({ open, openLogoutBtn, closeLogoutBtn, openLogoutPage }) => {
  const location = useLocation();

  const { pathname } = location;
  const navigate = useNavigate();

  const splitLocation = pathname.split("/");

  const [show, setShow] = useState(false);
  const [showSecond, setShowSecond] = useState(false);
  const showDropdown = (e) => {
    setShow(!show);
  };

  const showSecondDropdown = (e) => {
    setShowSecond(!showSecond);
  };
  const inSide = (e) => {
    setShow(true);
  };
  const hideDropdown = (e) => {
    setShow(false);
  };

  const greLink = () => {
    navigate("/gre_page");
    showDropdown();
  };
  const gmatLink = () => {
    navigate("/gmat_page");
    showDropdown();
  };
  const pteLink = () => {
    navigate("/pte_page");
    showDropdown();
  };
  const toeflLink = () => {
    navigate("/toefl_page");
    showDropdown();
  };
  const ieltsLink = () => {
    navigate("/ielts_page");
    showDropdown();
  };

  return (
    <>
      <div
        open={open}
        style={{
          transform: `${open ? "translateX(0)" : "translateX(100%)"}`,
          transition: "transform 0.3s ease-in-out",
        }}
        className="sidemenu-family-cont"
      >
        <div className="top-navbar-rel">
          <div className="cont-main">
            <Link
              to="/"
              className={
                splitLocation[1] === ""
                  ? "leftNavbar-opt-active"
                  : "leftNavbar-opt"
              }
              style={{ textDecoration: "none" }}
            >
              HOME
            </Link>

            <div>
              <div
                // onMouseEnter={inSide}
                // onMouseLeave={hideDropdown}
                onClick={showDropdown}
                // show={show}
                className="leftNavbar-opt"
                style={{ display: "flex", flexDirection: "row" }}
              >
                MANUFACTURER{" "}
                <div
                  className={
                    show === true ? "Faq-titleArrowRota" : "Faq-titleArrow "
                  }
                >
                  <FaAngleDown className="navbar-optMenuoptExamArr" />
                </div>
              </div>
              {show && (
                <div
                //   onMouseEnter={inSide}
                //   onMouseLeave={hideDropdown}
                >
                  <Link
                    to="/lubricant/special"
                    className={
                      splitLocation[1] === "lubricant"
                        ? "leftNavbar-opt-active"
                        : "leftNavbar-optExam"
                    }
                    style={{ textDecoration: "none" }}
                  >
                    {/* <div
                    // onMouseEnter={showDropdown}
                    // onMouseLeave={hideDropdown}
                    // show={show}
                    onClick={() => {
                      greLink();
                    }}
                    className="leftNavbar-opt"
                    style={{ background: "#f7acb0", color: "#000" }}
                  > */}
                    MOBIL LUBRICANTS
                    {/* </div> */}
                  </Link>

                  <Link
                    to="/bio/lubricants"
                    className={
                      splitLocation[1] === "bio"
                        ? "leftNavbar-opt-active"
                        : "leftNavbar-optExam"
                    }
                    style={{ textDecoration: "none" }}
                  >
                    TOTAL LUBRICANTS
                  </Link>
                  <Link
                    to="/energy/efficient"
                    className={
                      splitLocation[1] === "energy"
                        ? "leftNavbar-opt-active"
                        : "leftNavbar-optExam"
                    }
                    style={{ textDecoration: "none" }}
                  >
                    CASTROL LUBRICANTS
                  </Link>

                  <Link
                    to="/industrial/lubricant"
                    className={
                      splitLocation[1] === "industrial"
                        ? "leftNavbar-opt-active"
                        : "leftNavbar-optExam"
                    }
                    style={{ textDecoration: "none" }}
                  >
                    INDUSTRIAL LUBRICANTS
                  </Link>
                  <Link
                    to="/transportation/lubricant"
                    className={
                      splitLocation[1] === "transportation"
                        ? "leftNavbar-opt-active"
                        : "leftNavbar-optExam"
                    }
                    style={{ textDecoration: "none" }}
                  >
                    PETRONAS LUBRICANTS
                  </Link>

                  {/* <Link
                    to="/metal/lubricants"
                    className={
                      splitLocation[1] === "metal"
                        ? "leftNavbar-opt-active"
                        : "leftNavbar-optExam"
                    }
                    style={{ textDecoration: "none" }}
                  >
                    LUBRICANTS FOR METAL
                  </Link> */}

                  {/* <Link
                    to="/marine/aviation_lubricant"
                    className={
                      splitLocation[1] === "marine"
                        ? "leftNavbar-opt-active"
                        : "leftNavbar-optExam"
                    }
                    style={{ textDecoration: "none" }}
                  >
                    MARINE AND AVIATION LUBRICANTS
                  </Link> */}
                </div>
              )}
            </div>

            <Link
              to="/services"
              className={
                splitLocation[1] === "about_us"
                  ? "leftNavbar-opt-active"
                  : "leftNavbar-opt"
              }
              style={{ textDecoration: "none" }}
            >
              SERVICES
            </Link>
            <div>
              {/* <div
                // onMouseEnter={inSide}
                // onMouseLeave={hideDropdown}
                onClick={showSecondDropdown}
                // show={show}
                className="leftNavbar-opt"
                style={{ display: "flex", flexDirection: "row" }}
              >
                MORE PRODUCT
                <div
                  className={
                    showSecond === true
                      ? "Faq-titleArrowRota"
                      : "Faq-titleArrow "
                  }
                >
                  <FaAngleDown className="navbar-optMenuoptExamArr" />
                </div>
              </div> */}
              {showSecond && (
                <div
                //   onMouseEnter={inSide}
                //   onMouseLeave={hideDropdown}
                >
                  <Link
                    to="/aerosol"
                    className={
                      splitLocation[1] === "aerosol"
                        ? "leftNavbar-opt-active"
                        : "leftNavbar-optExam"
                    }
                    style={{ textDecoration: "none" }}
                  >
                    {/* <div
                    // onMouseEnter={showDropdown}
                    // onMouseLeave={hideDropdown}
                    // show={show}
                    onClick={() => {
                      greLink();
                    }}
                    className="leftNavbar-opt"
                    style={{ background: "#f7acb0", color: "#000" }}
                  > */}
                    AEROSOL
                    {/* </div> */}
                  </Link>

                  <Link
                    to="/degreaser"
                    className={
                      splitLocation[1] === "degreaser"
                        ? "leftNavbar-opt-active"
                        : "leftNavbar-optExam"
                    }
                    style={{ textDecoration: "none" }}
                  >
                    DEGREASERS
                  </Link>
                  <Link
                    to="/release_agents"
                    className={
                      splitLocation[1] === "release_agents"
                        ? "leftNavbar-opt-active"
                        : "leftNavbar-optExam"
                    }
                    style={{ textDecoration: "none" }}
                  >
                    RELEASE AGENTS
                  </Link>

                  <Link
                    to="/detergent_page"
                    className={
                      splitLocation[1] === "detergent_page"
                        ? "leftNavbar-opt-active"
                        : "leftNavbar-optExam"
                    }
                    style={{ textDecoration: "none" }}
                  >
                    DETERGENTS, CLEANERS AND SOLVENTS
                  </Link>
                  <Link
                    to="/assembly/paste"
                    className={
                      splitLocation[1] === "assembly"
                        ? "leftNavbar-opt-active"
                        : "leftNavbar-optExam"
                    }
                    style={{ textDecoration: "none" }}
                  >
                    ASSEMBLY PASTE
                  </Link>

                  <Link
                    to="/absorbents"
                    className={
                      splitLocation[1] === "absorbents"
                        ? "leftNavbar-opt-active"
                        : "leftNavbar-optExam"
                    }
                    style={{ textDecoration: "none" }}
                  >
                    ABSORBENT
                  </Link>
                </div>
              )}
            </div>

            <Link
              to="/sectors"
              className={
                splitLocation[1] === "sectors"
                  ? "leftNavbar-opt-active"
                  : "leftNavbar-opt"
              }
              style={{ textDecoration: "none" }}
            >
              SECTORS
            </Link>
            <Link
              to="/success_stories"
              className={
                splitLocation[1] === "success_stories"
                  ? "leftNavbar-opt-active"
                  : "leftNavbar-opt"
              }
              style={{ textDecoration: "none" }}
            >
              CONSULTANCY
            </Link>
            {/* <Link
              to="/training"
              className={
                splitLocation[1] === "training"
                  ? "leftNavbar-opt-active"
                  : "leftNavbar-opt"
              }
              style={{ textDecoration: "none" }}
            >
              TRAINING CENTER
            </Link> */}

            <Link
              to="/contact"
              className={
                splitLocation[1] === "contact"
                  ? "leftNavbar-opt-active"
                  : "leftNavbar-opt"
              }
              style={{ textDecoration: "none", marginBottom: "100px" }}
            >
              CONTACT
            </Link>
            <div
              // to="/contact_page"
              // className={
              //   splitLocation[1] === "contact_page"
              //     ? "leftNavbar-opt-active"
              //     : "leftNavbar-opt"
              // }
              style={{ textDecoration: "none", paddingBottom: "300px" }}
            ></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeftNav;
