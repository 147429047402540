import React from "react";
import "./onHoverMenu.css";
import { NavLink, useLocation, Link, useNavigate } from "react-router-dom";

const Menu = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { pathname } = location;

  const splitLocation = pathname.split("/");
  return (
    <div className="optionMenu_gen">
      <div
        className="optionMenu_genMax"
        // onMouseEnter={openHoverMenu}
        // onMouseLeave={closeHoverMenu}
      >
        <div className="optionMenu_flexCont">
          <Link
            to="/lubricant/special"
            className={
              splitLocation[1] === "lubricant"
                ? "singleOptionMenu_flexContActive"
                : "singleOptionMenu_flexCont"
            }
            style={{ textDecoration: "none" }}
          >
            MOBIL LUBRICANTS{" "}
          </Link>
          <Link
            to="/bio/lubricants"
            className={
              splitLocation[1] === "bio"
                ? "singleOptionMenu_flexContActive"
                : "singleOptionMenu_flexCont"
            }
            style={{ textDecoration: "none" }}
          >
            TOTAL LUBRICANTS
          </Link>
        </div>

        <div className="optionMenu_flexCont">
          <Link
            to="/energy/efficient"
            className={
              splitLocation[1] === "energy"
                ? "singleOptionMenu_flexContActive"
                : "singleOptionMenu_flexCont"
            }
            style={{ textDecoration: "none" }}
          >
            CASTROL LUBRICANTS
          </Link>
          <Link
            to="/industrial/lubricant"
            className={
              splitLocation[1] === "industrial"
                ? "singleOptionMenu_flexContActive"
                : "singleOptionMenu_flexCont"
            }
            style={{ textDecoration: "none" }}
          >
            INDUSTRIAL LUBRICANTS
          </Link>
        </div>

        <div className="optionMenu_flexCont">
          <Link
            to="/food/industry"
            className={
              splitLocation[1] === "food"
                ? "singleOptionMenu_flexContActive"
                : "singleOptionMenu_flexCont"
            }
            style={{ textDecoration: "none" }}
          >
            HYUNDAI LUBRICANTS
          </Link>
          <Link
            to="/transportation/lubricant"
            className={
              splitLocation[1] === "transportation"
                ? "singleOptionMenu_flexContActive"
                : "singleOptionMenu_flexCont"
            }
            style={{ textDecoration: "none" }}
          >
            PETRONAS LUBRICANTS
          </Link>
        </div>

        {/* <div className="optionMenu_flexCont">
          <Link
            to="/metal/lubricants"
            className={
              splitLocation[1] === "metal"
                ? "singleOptionMenu_flexContActive"
                : "singleOptionMenu_flexCont"
            }
            style={{ textDecoration: "none" }}
          >
            LUBRICANTS FOR METAL
          </Link>
          <Link
            to="/marine/aviation_lubricant"
            className={
              splitLocation[1] === "marine"
                ? "singleOptionMenu_flexContActive"
                : "singleOptionMenu_flexCont"
            }
            style={{ textDecoration: "none" }}
          >
            MARINE AND AVIATION LUBRICANTS
          </Link>
        </div> */}
      </div>
    </div>
  );
};

export default Menu;
