import React from "react";
import "./flexBtn.css";
import { FaChevronRight } from "react-icons/fa";
import { Link } from "react-router-dom";

const FlexBtn = ({ btnLink, btnTag }) => {
  return (
    <Link
      to={btnLink}
      style={{ textDecoration: "none" }}
      className="flexBtn_infoSubParaBtnCo"
    >
      <div className="flexBtn_infoSubParaBtn">{btnTag}</div>
      <div className="flexBtn_infoSubParaBtnIcon">
        <FaChevronRight />
      </div>
    </Link>
  );
};

export default FlexBtn;
