import React from "react";
import serviceCardImg from "../../assets/icons/serviceCardImg.webp";
import "./servicesCard.css";

const ServicesCard = ({ serviceCardImg, serviceHead, serviceText }) => {
  return (
    <div className="services_cardGen">
      <div className="services_cardImg">
        <img src={serviceCardImg} alt="service_card" />
      </div>

      <div className="services_genHead">{serviceHead}</div>
      <div className="services_genText">{serviceText}</div>
    </div>
  );
};

export default ServicesCard;
