import React from "react";
import "./orderForm.css";

const OrderForm = () => {
  return (
    <div className="orderFormGen">
      <div className="orderFormGen_max">
        <div>Order Form</div>
      </div>
    </div>
  );
};

export default OrderForm;
