import React, { useState, useMemo, useEffect } from "react";
import Pagination from "../../constant/Pagination";
import ProductComponents from "../productComponents/ProductComponents";
import "./showProductEnergy.css";

import {
  castrolLubricants,
  energyLubricants,
} from "../productsAssets/ProductData";
import { useLocation } from "react-router-dom";

let PageSize = 10;

const ShowProductEnergy = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return castrolLubricants.slice(firstPageIndex, lastPageIndex);
  }, [currentPage]);

  return (
    <div className="prodEnergyspecial_lubGenFam">
      <div className="prodEnergyspecial_lubMax">
        <div className="prodEnergyspecial_lubHeading">Castrol Lubricants</div>
        {currentTableData.map((data, index) => {
          return (
            <div>
              <ProductComponents
                productImg={data.productImg}
                productTitle={data.productTitle}
                productPara={data.productPara}
              />
            </div>
          );
        })}
      </div>
      <Pagination
        className="prodEnergypagination-bar"
        currentPage={currentPage}
        totalCount={castrolLubricants.length}
        pageSize={PageSize}
        onPageChange={(page) => setCurrentPage(page)}
      />
    </div>
  );
};

export default ShowProductEnergy;
