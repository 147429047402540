import React, { useState, useMemo, useEffect } from "react";
import Pagination from "../../constant/Pagination";
import ProductComponents from "../productComponents/ProductComponents";
import "./showProductBody.css";
import {
  lubricantSpecial,
  mobilLubricants,
} from "../productsAssets/ProductData";
import { useLocation } from "react-router-dom";

let PageSize = 10;

const ShowProductBody = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return mobilLubricants.slice(firstPageIndex, lastPageIndex);
  }, [currentPage]);

  return (
    <div className="special_lubGenFam">
      <div className="special_lubMax">
        <div className="special_lubHeading">MOBIL </div>
        {currentTableData.map((data, index) => {
          return (
            <div>
              <ProductComponents
                productImg={data.productImg}
                productTitle={data.productTitle}
                productPara={data.productPara}
              />
            </div>
          );
        })}
      </div>
      <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={mobilLubricants.length}
        pageSize={PageSize}
        onPageChange={(page) => setCurrentPage(page)}
      />
    </div>
  );
};

export default ShowProductBody;
