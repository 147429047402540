import React, { useEffect, useState } from "react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import { useLocation } from "react-router-dom";
import ShowProductBio from "../../components/showProductBio/ShowProductBio";

const BioLubricants = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <div>
      <Header />

      <ShowProductBio />
      <Footer />
    </div>
  );
};

export default BioLubricants;
