import React, { useState } from "react";
import skf from "../../assets/images/skf.webp";
import "./singleDistributor.css";

const SingleDistributor = ({ distLogo, distName, distLogoAlt }) => {
  const [distModal, setDistModal] = useState(false);

  const openDistModal = () => {
    setDistModal(true);
  };
  const closeDistModal = () => {
    setDistModal(false);
  };
  return (
    <div className="singleDist_genFam">
      <div className="singleDist_genImg" onMouseEnter={openDistModal}>
        <div className="sing_imgCont">
          <img
            // style={{
            //   height: "100%",
            //   width: "100%",

            //   objectFit: "cover",
            // }}
            src={distLogo}
            alt="distLogoAlt"
          />
        </div>
      </div>
      {/* {distModal && (
        <div onMouseLeave={closeDistModal} className="singleDistFamCover">
          <div className="hoverSingLink">{distName}</div>
        </div>
      )} */}
    </div>
  );
};

export default SingleDistributor;
