import React, { useState, useEffect } from "react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import { useLocation } from "react-router-dom";
import SecondBanner from "../../components/secondBanner/SecondBanner";
import banner4 from "../../assets/images/banner4.jpg";
import ServicesCard from "../../components/servicesCard/ServicesCard";
import sector_one from "../../assets/images/sector_One.jpg";
import sector_two from "../../assets/images/sector_two.jpg";
import sector_three from "../../assets/images/sector_three.jpg";
import sector_four from "../../assets/images/sector_four.jpg";
import sector_five from "../../assets/images/sector_five.jpg";
import sector_six from "../../assets/images/sector_six.png";
import sector_seven from "../../assets/images/sector_seven.jpg";
import "./sectorsPage.css";
import PageAutoForm from "../../components/pageAutoForm/PageAutoForm";

const SectorsPage = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <div>
      <Header />
      <SecondBanner
        banner2={banner4}
        headText="THE RIGHT LUBRICANT FOR YOUR SECTOR AND INDUSTRIAL PROCESSS"
        subText="Our experience in industrial lubrication in different sectors generates added value through expert knowledge in products and services, helping them to obtain maximum efficiency from their equipment, extend the useful life of their vehicles and reduce downtime."
      />

      <div className="sector_genFam">
        <div className="sector_genMax">
          <div className="sector_FlexGen">
            <ServicesCard
              serviceText="Solutions in oils, industrial greases, aerosols, degreasers, for gears, hydraulic systems, compressors and refrigeration systems. We seek the best performance and maintenance of your equipment."
              serviceHead="INDUSTRIAL MAINTENANCE"
              serviceCardImg={sector_one}
            />
            <ServicesCard
              serviceText="Solutions aimed at increasing food safety in each phase of the production chain, which is why we are committed to marketing products that have NSF registration and specific services for optimal lubrication management, taking into account regulatory requirements in food safety."
              serviceHead="FOOD AND PHARMACEUTICAL INDUSTRY"
              serviceCardImg={sector_two}
            />
            <ServicesCard
              serviceText="Specialized oils and greases for specific applications in the paper, cardboard and plastic industry with the aim of maintaining an optimum level of equipment performance."
              serviceHead="PACKAGING INDUSTRY"
              serviceCardImg={sector_three}
            />
            <ServicesCard
              serviceText="Solutions for the metalworking industry with the highest quality lubricants. We offer a wide range of innovative products, soluble and pure fluids, degreasers, protectors and detergents, together with technical assistance that allow extending the useful life, increasing productivity and reducing production costs; discover them with us."
              serviceHead="MECHANICAL METAL INDUSTRY"
              serviceCardImg={sector_four}
            />
            <ServicesCard
              serviceText="Oplug advises you on the optimal performance of applications for your fleet of heavy vehicles and buses including synthetic motor oils, transmission fluids, for differentials, for wheel bearings."
              serviceHead="TRANSPORT AND PUBLIC WORKS"
              serviceCardImg={sector_five}
            />
            <ServicesCard
              serviceText="Special lubricants for marine environments and for boats and yachts, betting at all times on specific solutions through synthetic and biodegradable lubricants. Lubritec Ecomarine range, Eco-Bio solutions for the Naval industry with European regulations. The program includes a set of lubricants, degreasers, solvents, detergents, liquid waste containment materials. Technical value and sustainability proposals."
              serviceHead="MARITIME INDUSTRY"
              serviceCardImg={sector_six}
            />
            <ServicesCard
              serviceText="High performance and biodegradable solutions, respectful with the environment. Lubrication both for railway vehicles, such as locomotives, trains, trams and applications for their infrastructure: rails, tracks, shock absorbers, traction bars, axles and bogie components."
              serviceHead="RAILWAY INDUSTRY"
              serviceCardImg={sector_seven}
            />
          </div>

          <PageAutoForm />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SectorsPage;
