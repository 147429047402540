import React, { useEffect, useState } from "react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import { useLocation } from "react-router-dom";
import ShowAviation from "../../components/showAviation/ShowAviation";

const MarineAvaitionLub = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <div>
      <Header />
      <ShowAviation />
      <Footer />
    </div>
  );
};

export default MarineAvaitionLub;
