import React, { useState, useEffect } from "react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import { useLocation } from "react-router-dom";
import "./successStoriesPage.css";
import SecondBanner from "../../components/secondBanner/SecondBanner";
import secondBanner from "../../assets/images/secondBanner.jpg";
import CarouselSection from "../../components/carouselSection/CarouselSection";
import PageAutoForm from "../../components/pageAutoForm/PageAutoForm";

const SuccessStoriesPage = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <div>
      <Header />
      <SecondBanner
        banner2={secondBanner}
        headText="CONSULTATION SERVICE"
        subText="We have a formidable team of experts with sharp skills developed over the years in lubricant formulation & production, including sales, marketing strategies and technical application. With testimonia of excellent service to the Marine, Industrial and logistics industry. Application of our blended technical expertise and experience acquired in the oil and gas downstream sector made us the preferred lubrication solutions expert of choice."
      >
        <div className="service_optHead">Our Service Includes</div>
        <div className="service_li">
          <ul>
            <li>
              Robust sales technical support programme geared toward sales
              growth.
            </li>
            <li>Market intelligence support and data analysis.</li>
            <li>Market penetration strategy programme to grow market share.</li>
            <li> Brand onboarding and statutory registration service.</li>
            <li> Admin services and team building.</li>
            <li>Technical training</li>
            <li>
              After sales technical support programmes, proven to be the best
              industrial sales commercial tool.
            </li>
          </ul>
        </div>
        {/* <div className="consul_servicesGenHead">
          <div className="consul_servicesHead">Our Service Includes</div>
          <div className="consul_servicesChildren">
            Robust sales technical support programme geared toward sales growth.
          </div>
          <div className="consul_servicesChildren">
            Robust sales technical support programme geared toward sales growth.
          </div>{" "}
          <div className="consul_servicesChildren">
            Market intelligence support and data analysis.
          </div>{" "}
          <div className="consul_servicesChildren">
            Market penetration strategy programme to grow market share.
          </div>{" "}
          <div className="consul_servicesChildren">
            Brand onboarding and statutory registration service.
          </div>{" "}
          <div className="consul_servicesChildren">
            Admin services and team building.
          </div>{" "}
          <div className="consul_servicesChildren">Technical training</div>{" "}
          <div className="consul_servicesChildren">
            After sales technical support programmes, proven to be the best
            industrial sales commercial tool.
          </div>
        </div> */}
      </SecondBanner>
      <div className="success_storiesGen">
        <div className="success_storiesMaxGen">
          <CarouselSection />
          <PageAutoForm />
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default SuccessStoriesPage;
