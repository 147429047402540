import React, { useState, useMemo, useEffect } from "react";
import Pagination from "../../constant/Pagination";
import ProductComponents from "../productComponents/ProductComponents";
import "./showAerosolComp.css";

import { aerosolLubricants } from "../productsAssets/ProductData";
import { useLocation } from "react-router-dom";

let PageSize = 10;

const ShowAerosolComp = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return aerosolLubricants.slice(firstPageIndex, lastPageIndex);
  }, [currentPage]);

  return (
    <div className="aerosolspecial_lubGenFam">
      <div className="aerosolspecial_lubMax">
        <div className="aerosolspecial_lubHeading">
          Energy Efficient Lubricants
        </div>
        {currentTableData.map((data, index) => {
          return (
            <div>
              <ProductComponents
                productImg={data.productImg}
                productTitle={data.productTitle}
                productPara={data.productPara}
              />
            </div>
          );
        })}
      </div>
      <Pagination
        className="aerosolpagination-bar"
        currentPage={currentPage}
        totalCount={aerosolLubricants.length}
        pageSize={PageSize}
        onPageChange={(page) => setCurrentPage(page)}
      />
    </div>
  );
};

export default ShowAerosolComp;
