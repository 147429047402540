import React, { useState, useEffect } from "react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import { useLocation } from "react-router-dom";
import ContactCard from "../../components/contactCard/ContactCard";
import PageAutoForm from "../../components/pageAutoForm/PageAutoForm";

const ContactPage = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <div>
      <Header />

      <ContactCard />
      <div className="success_storiesGen">
        <div className="success_storiesMaxGen">
          <PageAutoForm />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ContactPage;
