import React, { useState, useEffect } from "react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import { useLocation } from "react-router-dom";
import ShowAllProductComp from "../../components/showAllProductComp/ShowAllProductComp";
import FirstBanner from "../../components/firstBanner/FirstBanner";
import SearchTopBanner from "../../components/firstBanner/SearchTopBanner";

const AllProductPage = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div>
      <Header />
      {/* <SearchTopBanner /> */}
      <ShowAllProductComp />
      <Footer />
    </div>
  );
};

export default AllProductPage;
