import React from "react";
import IndustrialProductList from "../industrialProductList/IndustrialProductList";
import "./showProductIndusLub.css";
import { industryLubricants } from "../productsAssets/ProductData";

const ShowProductIndusLub = () => {
  return (
    <div className="indusLub_Gen">
      <div className="indusLub_Max">
        <div className="indusLub_GenFLex">
          {industryLubricants.map((data, index) => {
            return (
              <IndustrialProductList
                productHeading={data.productTitle}
                productDesc={data.productPara}
                productLink={data.productLink}
                btnTag={data.btnTag}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ShowProductIndusLub;
