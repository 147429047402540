import React, { useState, useEffect } from "react";
import "./productComponents.css";
import krytox from "../../assets/images/krytox.webp";
import { Link } from "react-router-dom";
import ModalForm from "../../screens/modalForm/ModalForm";

const ProductComponents = ({ productImg, productTitle, productPara }) => {
  const [showForm, setShowForm] = useState(false);

  const handleShowForm = () => {
    setShowForm(true);
  };

  const handleCloseForm = () => {
    setShowForm(false);
  };

  const handleChildClick = (event) => {
    event.stopPropagation();
  };

  return (
    <div>
      <div
        // to="/orderform"
        style={{ textDecoration: "none" }}
        className="productCompGen"
        onClick={handleShowForm}
      >
        <div className="productCompMax_Gen">
          <div className="productComp_Img">
            <img src={productImg} />
          </div>
          <div className="productComp_TextGen">
            <div className="productComp_Title">{productTitle}</div>
            <div className="productComp_Para">{productPara}</div>
          </div>
        </div>
      </div>
      {showForm && (
        <ModalForm
          handleCloseForm={handleCloseForm}
          handleChildClick={handleChildClick}
          productTitle={productTitle}
        />
      )}
    </div>
  );
};

export default ProductComponents;
