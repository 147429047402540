import React, { useEffect, useState } from "react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import { useLocation } from "react-router-dom";
import ShowReleaseLub from "../../components/showReleaseLub/ShowReleaseLub";

const ReleaseAgents = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <div>
      <Header />
      <ShowReleaseLub />
      <Footer />
    </div>
  );
};

export default ReleaseAgents;
