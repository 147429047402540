import React from "react";
import "./footer.css";
import oplugLogoP from "../../assets/images/oplugLogoP.png";
import {
  FaFacebookSquare,
  FaYoutubeSquare,
  FaLinkedin,
  FaPhoneSquare,
  FaCircle,
  FaWhatsapp,
  FaEnvelope,
  FaInstagram,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer_extWidth">
      <div className="footer_GenFlex">
        <div className="footerSecGen1">
          <div className="footerSecGen1_logo">
            <img src={oplugLogoP} alt="oplug_logo" />
          </div>
          <div className="footerSecGen1_heading">
            {" "}
            OPlug limited is a lubrication solutions company registered March
            2021, a major player in the Nigeria oil and gas downstream sector
            and distributor of top global lubricant brands Like TotalEnergies,
            Mobil, Petronas, Hyundai, Castrol, Eterna... we have created a niche
            for ourselves as a robust sales and marketing professional partner
            with strong and effective distribution channels. Positioned to meet
            up with all modern lubricant needs and services posted at us.Our
            lubricant portfolio includes automotive lubricants, industrial
            lubricants, marine lubricants, and specialty Oils.We offer lubricant
            business developmental support service, through consultation
            arrangement. Our clientele includes Lubritex Limited, Aksoy Nigeria
            Limited & Danal consulting Limited.Our team of professionals has a
            wealth of experience and expertise required to add value businesses.
          </div>

          <div className="footerSecGen1_bottomLinks">
            <div className="footerSecGen1_eachbottomLink">Legal notice |</div>
            <div className="footerSecGen1_eachbottomLink">
              Privacy Policy Cookies Policy |
            </div>
            <div className="footerSecGen1_eachbottomLink">
              General Conditions Environmental Policy
            </div>
          </div>

          <div className="footerSecGen1_socialBottom">
            <div className="footerSecGenSocialIcon">
              <FaFacebookSquare />
            </div>
            <div className="footerSecGenSocialIcon">
              <FaYoutubeSquare />
            </div>
            <div className="footerSecGenSocialIcon">
              <FaLinkedin />
            </div>

            <div className="footerSecGenSocialIcon">
              <a
                href="https://www.instagram.com/opluglimited/"
                target="_blank"
                style={{ textDecoration: "none", color: "#fff" }}
              >
                <FaInstagram />
              </a>
            </div>
          </div>
        </div>
        <div className="footerSecGen2">
          <Link
            to="/"
            style={{ textDecoration: "none" }}
            className="footerSecGen2_option"
          >
            <div className="footerSecGen2_bullet">
              <FaCircle />
            </div>

            <div className="footerSecGen2_text">Home</div>
          </Link>

          <Link
            to="/services"
            style={{ textDecoration: "none" }}
            className="footerSecGen2_option"
          >
            <div className="footerSecGen2_bullet">
              <FaCircle />
            </div>
            <div className="footerSecGen2_text">Services</div>
          </Link>

          <Link
            to="/sectors"
            style={{ textDecoration: "none" }}
            className="footerSecGen2_option"
          >
            <div className="footerSecGen2_bullet">
              <FaCircle />
            </div>
            <div className="footerSecGen2_text">Sectors</div>
          </Link>

          <Link
            to="/success_stories"
            style={{ textDecoration: "none" }}
            className="footerSecGen2_option"
          >
            <div className="footerSecGen2_bullet">
              <FaCircle />
            </div>
            <div className="footerSecGen2_text">Success Stories</div>
          </Link>

          <Link
            to="/contact"
            style={{ textDecoration: "none" }}
            className="footerSecGen2_option"
          >
            <div className="footerSecGen2_bullet">
              <FaCircle />
            </div>
            <div className="footerSecGen2_text">Contact</div>
          </Link>
        </div>
        <div className="footerSecGen3">
          <div className="footerSecGen3_offHead">Lagos, Nigeria Office</div>
          <div className="footerSecGen3_offPara">
            81, Mobolaji Bank Anthony Way, Ikeja Plaza, Ikeja, Lagos.
          </div>

          <div>
            <a
              href="tel:+2349168706000"
              style={{ textDecoration: "none" }}
              className="footerSecGen3_contGen"
              target="_blank"
            >
              <div className="footerSecGen3_contIcon">
                <FaPhoneSquare />
              </div>
              <div className="footerSecGen3_contD">+234 916 870 6000</div>
            </a>
            <a
              href="https://wa.me/2349168706000/"
              target="_blank"
              className="footerSecGen3_contGen"
            >
              <div className="footerSecGen3_contIcon">
                <FaWhatsapp />
              </div>
              <div className="footerSecGen3_contD">+234 916 870 6000</div>
            </a>

            <a
              href="mailto: info@o-plug.com"
              className="footerSecGen3_contGen"
              target="_blank"
            >
              <div className="footerSecGen3_contIcon">
                <FaEnvelope />
              </div>
              <div className="footerSecGen3_contD">info@o-plug.com</div>
            </a>
          </div>
        </div>
      </div>

      <div className="footer_right">
        © Copyright 2022-2023 Oil Plug, Inc. All Rights Reserved.
      </div>
    </div>
  );
};

export default Footer;
