import React, { useState, useEffect } from "react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import { useLocation } from "react-router-dom";
import ShowAerosolComp from "../../components/showAerosolComp/ShowAerosolComp";

const Aerosol = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <div>
      <Header />
      <ShowAerosolComp />
      <Footer />
    </div>
  );
};

export default Aerosol;
