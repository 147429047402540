import React from "react";
import "./carouselComp.css";
import caro1 from "../../assets/images/caro1.webp";

const CarouselComp = ({ caroHead, caroImg, caroBody }) => {
  return (
    <div className="carouselComp_SecGen">
      <div className="carouselComp_SecImg">
        <img src={caroImg} alt="caro_jpg" />
      </div>

      <div className="carouselComp_heading">{caroHead}</div>
      <div className="carouselComp_para">{caroBody}</div>
    </div>
  );
};

export default CarouselComp;
