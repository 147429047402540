import React, { useState, useEffect } from "react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import banner2 from "../../assets/images/banner2.jpg";
import servicesGen from "../../assets/icons/servicesGen.jpg";

import { useLocation } from "react-router-dom";
import SecondBanner from "../../components/secondBanner/SecondBanner";
import ServicesOpt from "../../components/servicesOpt/ServicesOpt";
import "./servicesPage.css";
import {
  SiAcclaim,
  SiAdguard,
  SiGnuprivacyguard,
  SiPlex,
} from "react-icons/si";
import ServicesCard from "../../components/servicesCard/ServicesCard";
import serviceCardImg from "../../assets/icons/serviceCardImg.webp";
import serviceTrain from "../../assets/icons/serviceTrain.webp";
import servicesSignal from "../../assets/icons/servicesSignal.webp";
import servicespre from "../../assets/icons/servicespre.webp";
import serviceSupervision from "../../assets/icons/serviceSupervision.webp";
import PageAutoForm from "../../components/pageAutoForm/PageAutoForm";

import { useAnimation, motion } from "framer-motion";

import { useInView } from "react-intersection-observer";
import { Location } from "react-router-dom";

const squareVariantsYOne = {
  visible: { opacity: 1, x: 0, transition: { duration: 2 } },
  hidden: { opacity: 0, x: -100 },
};
const squareVariantsYTwo = {
  visible: { opacity: 1, y: 0, transition: { duration: 2 } },
  hidden: { opacity: 0, y: 100 },
};
const squareVariantsYT = {
  visible: { opacity: 1, y: 0, transition: { duration: 2 } },
  hidden: { opacity: 0, y: 100 },
};
const squareVariantsYThree = {
  visible: { opacity: 1, x: 0, transition: { duration: 2 } },
  hidden: { opacity: 0, x: 100 },
};

const ServicesPage = () => {
  const location = useLocation();
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <div>
      <Header />

      <SecondBanner
        banner2={banner2}
        headText="Industrial Lubrication Services"
        subText="From the training of your operators to the possibility of completely outsourcing maintenance tasks. Over the years, we have created a set of industrial lubricant services to respond to the many challenges our customers have posed to us.
Today, they are a way of contributing to the improvement of the industrial lubrication of your plant, with all kinds of advantages."
      />

      <div className=" services_genFam">
        <div className="services_genMax">
          <div className="services_CompMax">
            <motion.div
              ref={ref}
              animate={controls}
              initial="hidden"
              variants={squareVariantsYOne}
            >
              <ServicesOpt
                serviceIcon={<SiAdguard />}
                serviceHead="Warranty"
                serviceText=" It has the approvals of the main system manufacturers"
              />
            </motion.div>
            <motion.div
              ref={ref}
              animate={controls}
              initial="hidden"
              variants={squareVariantsYTwo}
            >
              <ServicesOpt
                serviceIcon={<SiPlex />}
                serviceHead="Speed"
                serviceText="Our experience allows us to guarantee an agile implementation."
              />
            </motion.div>
            <motion.div
              ref={ref}
              animate={controls}
              initial="hidden"
              variants={squareVariantsYT}
            >
              <ServicesOpt
                serviceIcon={<SiGnuprivacyguard />}
                serviceHead="Confidentiality"
                serviceText="We guarantee the professional secrecy of all the information you share."
              />
            </motion.div>
            <motion.div
              ref={ref}
              animate={controls}
              initial="hidden"
              variants={squareVariantsYThree}
            >
              <ServicesOpt
                serviceIcon={<SiAcclaim />}
                serviceHead="Tracing"
                serviceText="Once the service has been implemented, we remain at your disposal to ensure its proper functioning."
              />
            </motion.div>
          </div>

          <div className="services_cardComp">
            <ServicesCard
              serviceText="Training and training for the application of lubricants with the aim of
        knowing the best industrial lubrication practices. Personalized courses
        adapted to the production process."
              serviceHead="INDUSTRIAL LUBRICATION TRAINING"
              serviceCardImg={serviceTrain}
            />
            <ServicesCard
              serviceText="It is often difficult to identify greasing points on equipment. To facilitate lubrication tasks, we have developed several solutions to signal and identify these points, avoiding potential errors."
              serviceHead="IDENTIFICATION AND SIGNALING OF POINTS"
              serviceCardImg={servicesSignal}
            />

            <ServicesCard
              serviceText="Equipment predictive maintenance program through oil analysis. Knowing the state of the lubricants we can anticipate mechanical failures and thus seek solutions and reduce unforeseen stops."
              serviceHead="PREDICTIVE MAINTENANCE"
              serviceCardImg={servicespre}
            />
            <ServicesCard
              serviceText="The correct lubrication begins with a correct storage of lubricants. Identified, with their application systems, means of retention and proper cleanliness and order. Lubrite advises you on the planning, design, supply and installation of storage systems."
              serviceHead="WAREHOUSE OPTIMIZATION"
              serviceCardImg={serviceCardImg}
            />
            <ServicesCard
              serviceText="Training and training for the application of lubricants with the aim of
        knowing the best industrial lubrication practices. Personalized courses
        adapted to the production process."
              serviceHead="INDUSTRIAL LUBRICATION PLAN"
              serviceCardImg={serviceCardImg}
            />
            <ServicesCard
              serviceText="We ensure the correct and effective application of the lubrication plan based on the monitoring and verification of the tasks, detecting possible incidents and maintaining the continuous improvement plan."
              serviceHead="SUPERVISION SERVICE"
              serviceCardImg={serviceSupervision}
            />
          </div>

          <PageAutoForm />
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default ServicesPage;
