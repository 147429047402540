import aeroshellFluid from "../../assets/icons/aeroshellFluid.webp";

import aeroshellFluid71 from "../../assets/icons/aeroshellFluid71.webp";

import aeroshellGrease22 from "../../assets/icons/aeroshellGrease22.webp";
import aeroshellGrease33 from "../../assets/icons/aeroshellGrease33.webp";
import aeroshellGrease64 from "../../assets/icons/aeroshellGrease64.webp";
import aeroshellW100plus from "../../assets/icons/aeroshellW100plus.webp";
import aeroshellGrease581 from "../../assets/icons/aeroshellGrease581.webp";
import cassidaAirline from "../../assets/icons/cassidaAirline.webp";
import cassidaAlt from "../../assets/icons/cassidaAlt.webp";
import cassidaDry from "../../assets/icons/cassidaDry.webp";
import cassidaGrease from "../../assets/icons/cassidaGrease.webp";
import cassidaOil from "../../assets/icons/cassidaOil.webp";
import cassidaSpray from "../../assets/icons/cassidaSpray.webp";
import cepsaAlt from "../../assets/icons/cepsaAlt.jpg";
import dieselPowerUnit from "../../assets/icons/dieselPowerUnit.webp";

import deurexPure from "../../assets/icons/deurexPure.webp";
import ibiotecNeolube2000 from "../../assets/icons/ibiotecNeolube2000.webp";
import ibiotecMetal1100 from "../../assets/icons/ibiotecMetal1100.webp";
import exxonMobil from "../../assets/icons/exxonMobil.webp";
import absorbent30_80 from "../../assets/icons/absorbent30_80.webp";

import fuchAlt from "../../assets/icons/fuchAlt.webp";
import fuchAnticorit from "../../assets/icons/fuchAnticorit.webp";
import ibiotecAirline from "../../assets/icons/ibiotecAirline.webp";
import ibiotecImpact from "../../assets/icons/ibiotecImpact.webp";
import ibiotecNeolube from "../../assets/icons/ibiotecNeolube.webp";
import ibiotecNeolube20 from "../../assets/icons/ibiotecNeolube20.webp";
import ibiotecNeolube160 from "../../assets/icons/ibiotecNeolube160.webp";
import ibiotecNeolube220 from "../../assets/icons/ibiotecNeolube220.webp";
import ibiotecNeolube350 from "../../assets/icons/ibiotecNeolube350.webp";
import ibiotecNeolube500 from "../../assets/icons/ibiotecNeolube500.webp";
import ibiotecNeolube2272 from "../../assets/icons/ibiotecNeolube2272.webp";
import ibiotecNeolubeAlt from "../../assets/icons/ibiotecNeolubeAlt.webp";
import ibiotecDrum from "../../assets/icons/ibiotecDrum.webp";
import ibiotecDecap1000 from "../../assets/icons/ibiotecDecap1000.webp";

import ibiotecDecap5000 from "../../assets/icons/ibiotecDecap5000.webp";
import ibiotecDemo750 from "../../assets/icons/ibiotecDemo750.webp";

import ibiotecDemo780 from "../../assets/icons/ibiotecDemo780.webp";

import ibiotecDemo850 from "../../assets/icons/ibiotecDemo850.webp";
import ibiotecDP2 from "../../assets/icons/ibiotecDP2.webp";
import ibiotecDP6 from "../../assets/icons/ibiotecDP6.jpg";
import ibiotecProtect100 from "../../assets/icons/ibiotecProtect100.webp";

import ibiotecAero10 from "../../assets/icons/ibiotecAero10.webp";
import krytox from "../../assets/icons/krytox.webp";
import lubcon from "../../assets/icons/lubcon.webp";
import lubriscoFood from "../../assets/icons/lubriscoFood.webp";
import motulStagAlt from "../../assets/icons/motulStagAlt.webp";
import panolinDrum from "../../assets/icons/panolinDrum.webp";
import panolinKeg from "../../assets/icons/panolinKeg.webp";
import petronas from "../../assets/icons/petronas.webp";
import petronasAkros10w30 from "../../assets/icons/petronasAkros10w30.webp";
import petronasAkros20w30 from "../../assets/icons/petronasAkros20w30.webp";
import petronasAlt from "../../assets/icons/petronasAlt.webp";
import petronasAltBig from "../../assets/icons/petronasAltBig.webp";
import petronasGl80w90 from "../../assets/icons/petronasGl80w90.webp";
import petronasMach15w40 from "../../assets/icons/petronasMach15w40.webp";
import petronasMach20w50 from "../../assets/icons/petronasMach20w50.webp";
import petronasSyntium5w20 from "../../assets/icons/petronasSyntium5w20.webp";
import petronasSyntium5w30 from "../../assets/icons/petronasSyntium5w30.webp";
import petronasSyntium5w40 from "../../assets/icons/petronasSyntium5w40.webp";
import petronasTutela from "../../assets/icons/petronasTutela.webp";
import petronasTutelaFv from "../../assets/icons/petronasTutelaFv.webp";
import petronasTutelaGie from "../../assets/icons/petronasTutelaGie.webp";
import petronasTutelaPs from "../../assets/icons/petronasTutelaPs.webp";
import petronasTutelaZC90 from "../../assets/icons/petronasTutelaZC90.webp";
import petronasUrania10w40 from "../../assets/icons/petronasUrania10w40.webp";
import petronasUrania15w40 from "../../assets/icons/petronasUrania15w40.webp";
import petronasUranium500_10w40 from "../../assets/icons/petronasUranium500_10w40.png";
import rivoltaMTX from "../../assets/icons/rivoltaMTX.webp";
import rivoltaBFC from "../../assets/icons/rivoltaBFC.webp";
import petronas6_1 from "../../assets/icons/petronas6_1.webp";

import quaker from "../../assets/icons/quaker.webp";
import shellAdvance10w40SmMa from "../../assets/icons/shellAdvance10w40SmMa.webp";
import shellAdvance15w50 from "../../assets/icons/shellAdvance15w50.webp";
import shellAdvance15w50smMa from "../../assets/icons/shellAdvance15w50smMa.webp";
import shellAdvanceRacingM30 from "../../assets/icons/shellAdvanceRacingM30.webp";
import rivoltaSBC from "../../assets/icons/rivoltaSBC.webp";
import rivoltaslx from "../../assets/icons/rivoltaslx.webp";
import rivoltabrx from "../../assets/icons/rivoltabrx.webp";

import ibiotecHV1 from "../../assets/icons/ibiotecHV1.webp";
import ibiotecNeutra200 from "../../assets/icons/ibiotecNeutra200.webp";
import ibiotecStripper from "../../assets/icons/ibiotecStripper.jpg";
import thermorensinox from "../../assets/icons/thermorensinox.webp";
import thermorensnaval from "../../assets/icons/thermorensnaval.webp";
import thermorensoxide from "../../assets/icons/thermorensoxide.webp";
import rivoltabrx611 from "../../assets/icons/rivoltabrx611.webp";
import rivoltabwr from "../../assets/icons/rivoltabwr.webp";
import rivoltaslx500 from "../../assets/icons/rivoltaslx500.webp";
import rivoltaslxrapid from "../../assets/icons/rivoltaslxrapid.webp";

import rivoltaslxSuper from "../../assets/icons/rivoltaslxSuper.webp";
import rivoltaslxTop from "../../assets/icons/rivoltaslxTop.webp";
import rivoltabwk from "../../assets/icons/rivoltabwk.webp";
import ibiotecAlt from "../../assets/icons/ibiotecAlt.webp";

import shellAdvanceUltra2t from "../../assets/icons/shellAdvanceUltra2t.webp";
import ibiotecNeutraVGAL from "../../assets/icons/ibiotecNeutraVGAL.webp";
import shellAdvancevsx2 from "../../assets/icons/shellAdvancevsx2.webp";
import shellAlt from "../../assets/icons/shellAlt.webp";
import shellAtf134 from "../../assets/icons/shellAtf134.webp";
import shellAtf134Fe from "../../assets/icons/shellAtf134Fe.webp";
import shellGas from "../../assets/icons/shellGas.webp";
import shellHelix10w40 from "../../assets/icons/shellHelix10w40.webp";
import shellHelix10w60 from "../../assets/icons/shellHelix10w60.webp";
import shellHelix20w50 from "../../assets/icons/shellHelix20w50.webp";
import shellHelixHx710w40 from "../../assets/icons/shellHelixHx710w40.webp";
import shellHelixUltra0w40 from "../../assets/icons/shellHelixUltra0w40.webp";
import shellHelixUltra5w40 from "../../assets/icons/shellHelixUltra5w40.webp";
import shellNautilus from "../../assets/icons/shellNautilus.webp";
import shellOmala from "../../assets/icons/shellOmala.webp";
import shellRimula10w from "../../assets/icons/shellRimula10w.webp";
import shellRimula15w40 from "../../assets/icons/shellRimula15w40.webp";
import shellRimulaR5 from "../../assets/icons/shellRimulaR5.webp";
import shellRimulaR5Le10 from "../../assets/icons/shellRimulaR5Le10.webp";
import shellRimulaR5Lm10 from "../../assets/icons/shellRimulaR5Lm10.webp";
import shellRimula5W_30B from "../../assets/icons/shellRimula5W_30B.webp";
import shellSpiraxg80w from "../../assets/icons/shellSpiraxg80w.gif";
import shellSpiraxS1 from "../../assets/icons/shellSpiraxS1.webp";
import shellSpiraxS2 from "../../assets/icons/shellSpiraxS2.webp";
import shellSpiraxS3 from "../../assets/icons/shellSpiraxS3.webp";
import shellSpiraxS5Atf from "../../assets/icons/shellSpiraxS5Atf.webp";
import shellSpiraxS5dct from "../../assets/icons/shellSpiraxS5dct.webp";

import shellSpiraxS6AtfA from "../../assets/icons/shellSpiraxS6AtfA.webp";
import shellSpiraxS6AtfX from "../../assets/icons/shellSpiraxS6AtfX.webp";
import shellSpiraxS6AXME from "../../assets/icons/shellSpiraxS6AXME.webp";
import shellSpiraxS6GXME from "../../assets/icons/shellSpiraxS6GXME.webp";
import mmccAlt from "../../assets/icons/mmccAlt.webp";
import totalAlt from "../../assets/icons/totalAlt.webp";
import mobilJetOil254 from "../../assets/icons/mobilJetOil254.jpeg";
import delvacshc_5w40 from "../../assets/icons/delvacshc_5w40.png";
import delvac15w40drum from "../../assets/icons/delvac15w40drum.jpeg";
import mobildelvacpail from "../../assets/icons/mobildelvacpail.jpeg";
import delvac4x4 from "../../assets/icons/delvac4x4.jpg";
import delvacsuper from "../../assets/icons/delvacsuper.webp";
import delvac_15w40pail from "../../assets/icons/delvac_15w40pail.jpeg";
import delvac1340 from "../../assets/icons/delvac1340.jpeg";
import mobiltrans30 from "../../assets/icons/mobiltrans30.png";
import mobillubedrum from "../../assets/icons/mobillubedrum.jpeg";
import mobillube80w90 from "../../assets/icons/mobillube80w90.jpeg";
import mobillube4l80w90 from "../../assets/icons/mobillube4l80w90.jpeg";
import mobillube1404l from "../../assets/icons/mobillube1404l.png";
import mobilhydraulic10w from "../../assets/icons/mobilhydraulic10w.jpeg";
import mobil424drum from "../../assets/icons/mobil424drum.jpeg";
import mobiltacpail from "../../assets/icons/mobiltacpail.jpeg";
import mobilpail from "../../assets/icons/mobilpail.webp";
import mobilshc626 from "../../assets/icons/mobilshc626.jpeg";
import mobilshc629 from "../../assets/icons/mobilshc629.jpeg";
import mobilshc632 from "../../assets/icons/mobilshc632.webp";
import mobilshc634 from "../../assets/icons/mobilshc634.jpeg";
import mobilshc639 from "../../assets/icons/mobilshc639.jpeg";
import mobilgear600 from "../../assets/icons/mobilgear600.webp";
import m_polyrex103 from "../../assets/icons/m_polyrex103.jpeg";
import mobil_rarus from "../../assets/icons/mobil_rarus.jpeg";
import mobilrarus1026 from "../../assets/icons/mobilrarus1026.jpeg";
import mobilshc626pail from "../../assets/icons/mobilshc626pail.jpeg";
import mobilesp1 from "../../assets/icons/mobilesp1.jpeg";
import mobil1esp1 from "../../assets/icons/mobil1esp1.jpeg";
import mobil1fs from "../../assets/icons/mobil1fs.webp";
import mobil14l from "../../assets/icons/mobil14l.webp";
import mobil3000 from "../../assets/icons/mobil3000.jpeg";
import mobil30004l from "../../assets/icons/mobil30004låç.webp";
import mobil20001l from "../../assets/icons/mobil20001l.webp";
import mobil20004l from "../../assets/icons/mobil20004l.jpeg";
import mobil10001l from "../../assets/icons/mobil10001l.jpeg";
import mobil10004l from "../../assets/icons/mobil10004l.jpeg";
import mobilsupermoto4t from "../../assets/icons/mobilsupermoto4t.jpeg";
import mobil20w501l from "../../assets/icons/mobil20w501l.png";
import mobilspecial20w504l from "../../assets/icons/mobilspecial20w504l.jpeg";
import mobilhd404l from "../../assets/icons/mobilhd404l.jpeg";
import mobilatf3201l from "../../assets/icons/mobilatf3201l.avif";
import mobilatf220 from "../../assets/icons/mobilatf220.png";
import gtx20w501l from "../../assets/icons/gtx20w501l.jpeg";
import gtx20w504l from "../../assets/icons/gtx20w504l.jpeg";
import gtx25w504l from "../../assets/icons/gtx25w504l.jpeg";
import gtx15w40 from "../../assets/icons/gtx15w40.jpeg";
import castrolsae40 from "../../assets/icons/castrolsae40.jpeg";
import castroldrum from "../../assets/icons/castroldrum.jpeg";
import edge5w401l from "../../assets/icons/edge5w401l.jpeg";
import edge5w405l from "../../assets/icons/edge5w405l.jpeg";
import edge5w404l from "../../assets/icons/edge5w404l.webp";
import edge10w605l from "../../assets/icons/edge10w605l.jpeg";
import edgeec5 from "../../assets/icons/edgeec5.jpg";
import gtx5w40710 from "../../assets/icons/gtx5w40710.webp";

import magnatec10w40 from "../../assets/icons/magnatec10w40.jpeg";
import castrol5w301l from "../../assets/icons/castrol5w301l.webp";
import castrol5w305L from "../../assets/icons/castrol5w305L.webp";
import castrolmagnatecstopstart4l from "../../assets/icons/castrolmagnatecstopstart4l.webp";
import magnateca51l from "../../assets/icons/magnateca51l.jpg";
import magnatec10wa3b4 from "../../assets/icons/magnatec10wa3b4.webp";
import magnateca3a44l from "../../assets/icons/magnateca3a44l.jpeg";
import magnateca3a45l from "../../assets/icons/magnateca3a45l.jpeg";
import superoutboard from "../../assets/icons/superoutboard.jpeg";
import castrolpower1racing from "../../assets/icons/castrolpower1racing.jpeg";
import powerracing2t from "../../assets/icons/powerracing2t.jpg";
import actevo4t10w401l from "../../assets/icons/actevo4t10w401l.jpeg";

import radicoolnf1l from "../../assets/icons/radicoolnf1l.jpeg";
import radicoolsfpremix from "../../assets/icons/radicoolsfpremix.jpeg";

import radicoolsfpremix1l from "../../assets/icons/radicoolsfpremix1l.jpeg";

import castrolatfdex5l from "../../assets/icons/castrolatfdex5l.webp";

import transmaxdex05l from "../../assets/icons/transmaxdex05l.jpeg";
import transmaxdex1l from "../../assets/icons/transmaxdex1l.webp";

import brakefluidcastrol from "../../assets/icons/brakefluidcastrol.jpeg";
import viscogenklspray from "../../assets/icons/viscogenklspray.webp";

import twotoil from "../../assets/icons/twotoil.jpg";
import powermaxoil from "../../assets/icons/powermaxoil.jpeg";

import multimax4l from "../../assets/icons/multimax4l.jpeg";

import multimax1l20 from "../../assets/icons/multimax1l20.jpeg";
import multimax4lsuoper from "../../assets/icons/multimax4lsuoper.jpeg";
import optimax25l from "../../assets/icons/optimax25l.jpeg";

import optimax5l from "../../assets/icons/optimax5l.jpeg";

import supertc from "../../assets/icons/supertc.jpeg";
import castrolep140 from "../../assets/icons/castrolep140.jpeg";

import castrolep90 from "../../assets/icons/castrolep90.jpeg";
import ep25l from "../../assets/icons/ep25l.jpeg";
import castrol25l from "../../assets/icons/castrol25l.webp";
import ep2grease from "../../assets/icons/ep2grease.png";
import hydrexaw32 from "../../assets/icons/hydrexaw32.jpeg";
import mouldingoil1l from "../../assets/icons/mouldingoil1l.jpeg";
import atf1L from "../../assets/icons/atf1L.png";
import petronas20w50 from "../../assets/icons/petronas20w50.webp";
import hyundaixteerg50020w50 from "../../assets/icons/hyundaixteerg50020w50.jpeg";
import hyundaixteer15w40 from "../../assets/icons/hyundaixteer15w40.jpeg";
import hyundai1l from "../../assets/icons/hyundai1l.jpeg";
import hyudaixteer_3000 from "../../assets/icons/hyudaixteer_3000.jpeg";
import totalq0w204l from "../../assets/icons/totalq0w204l.png";
import totalhtcineo from "../../assets/icons/totalhtcineo.png";
import total4x44l from "../../assets/icons/total4x44l.jpg";
import total90005w from "../../assets/icons/total90005w.jpg";
import total7000s10w40 from "../../assets/icons/total7000s10w40.png";
import total700015w50 from "../../assets/icons/total700015w50.jpeg";
import total500020w from "../../assets/icons/total500020w.jpeg";

import total500044 from "../../assets/icons/total500044.jpeg";
import total3000205l from "../../assets/icons/total3000205l.webp";
import total50005ww from "../../assets/icons/total50005ww.jpeg";
import rubia7400aaa from "../../assets/icons/rubia7400aaa.png";
import totalrubiafleet22 from "../../assets/icons/totalrubiafleet22.webp";
import rubiafleethd200 from "../../assets/icons/rubiafleethd200.png";
import rubiahd200sae from "../../assets/icons/rubiahd200sae.png";
import rubias40 from "../../assets/icons/rubias40.jpeg";
import rubiafleethd100 from "../../assets/icons/rubiafleethd100.png";

export default {
  aeroshellFluid,
  aeroshellFluid71,
  aeroshellGrease22,
  aeroshellGrease33,
  aeroshellGrease64,
  aeroshellW100plus,
  aeroshellGrease581,
  absorbent30_80,
  cassidaAirline,
  cassidaAlt,
  cassidaDry,
  cassidaGrease,
  cassidaOil,
  cassidaSpray,
  cepsaAlt,
  deurexPure,
  exxonMobil,
  fuchAlt,
  fuchAnticorit,
  ibiotecAirline,
  ibiotecImpact,
  ibiotecNeolube,
  ibiotecNeolube20,
  ibiotecNeolube160,
  ibiotecNeolube220,
  ibiotecNeolube350,
  ibiotecNeolube500,
  ibiotecNeolube2272,
  ibiotecNeolubeAlt,
  ibiotecAero10,
  ibiotecDrum,
  ibiotecDecap1000,
  ibiotecDecap5000,
  ibiotecDemo750,
  ibiotecDemo780,
  ibiotecDemo850,
  ibiotecDP2,
  ibiotecDP6,
  ibiotecProtect100,
  ibiotecNeutraVGAL,
  ibiotecHV1,
  ibiotecNeutra200,
  ibiotecStripper,
  ibiotecAlt,
  ibiotecNeolube2000,
  ibiotecMetal1100,
  petronas6_1,
  krytox,
  lubcon,
  lubriscoFood,
  motulStagAlt,
  panolinDrum,
  panolinKeg,
  petronas,
  petronasAkros10w30,
  petronasAkros20w30,
  petronasAlt,
  petronasAltBig,
  petronasGl80w90,
  petronasMach15w40,
  petronasMach20w50,
  petronasSyntium5w20,
  petronasSyntium5w30,
  petronasSyntium5w40,
  petronasTutela,
  petronasTutelaFv,
  petronasTutelaGie,
  petronasTutelaPs,
  petronasTutelaZC90,
  petronasUrania10w40,
  petronasUrania15w40,
  petronasUranium500_10w40,
  quaker,
  shellAdvance10w40SmMa,
  shellAdvance15w50,
  shellAdvance15w50smMa,
  shellAdvanceRacingM30,
  shellAdvanceUltra2t,
  shellAdvancevsx2,
  shellAlt,
  shellAtf134,
  shellAtf134Fe,
  shellGas,
  shellHelix10w40,
  shellHelix10w60,
  shellHelix20w50,
  shellHelixHx710w40,
  shellHelixUltra0w40,
  shellHelixUltra5w40,
  shellNautilus,
  shellOmala,
  shellRimula10w,
  shellRimula15w40,
  shellRimulaR5,
  shellRimulaR5Le10,
  shellRimulaR5Lm10,
  shellRimula5W_30B,
  shellSpiraxg80w,
  shellSpiraxS1,
  shellSpiraxS2,
  shellSpiraxS3,
  shellSpiraxS5Atf,
  shellSpiraxS5dct,
  shellSpiraxS6AtfA,
  shellSpiraxS6AtfX,
  shellSpiraxS6AXME,
  shellSpiraxS6GXME,
  totalAlt,
  rivoltaBFC,
  rivoltaMTX,
  rivoltaSBC,
  rivoltaslx,
  rivoltabrx,
  rivoltabrx611,
  rivoltabwr,
  rivoltaslx500,
  rivoltaslxrapid,
  rivoltaslxSuper,
  rivoltaslxTop,
  rivoltabwk,
  mmccAlt,
  thermorensinox,
  thermorensnaval,
  thermorensoxide,
  dieselPowerUnit,
  mobilJetOil254,
  delvacshc_5w40,
  delvac15w40drum,
  mobildelvacpail,
  delvac4x4,
  delvacsuper,
  delvac_15w40pail,
  delvac1340,
  mobiltrans30,
  mobillubedrum,
  mobillube80w90,
  mobillube4l80w90,
  mobillube1404l,
  mobilhydraulic10w,
  mobil424drum,
  mobiltacpail,
  mobilpail,
  mobilshc626,
  mobilshc629,
  mobilshc632,
  mobilshc634,
  mobilshc639,
  mobilgear600,
  m_polyrex103,
  mobil_rarus,
  mobilrarus1026,
  mobilshc626pail,
  mobilesp1,
  mobil1esp1,
  mobil1fs,
  mobil14l,
  mobil3000,
  mobil30004l,
  mobil20001l,
  mobil20004l,
  mobil10001l,
  mobil10004l,
  mobilsupermoto4t,
  mobil20w501l,
  mobilspecial20w504l,
  mobilhd404l,
  mobilatf3201l,
  mobilatf3201l,
  mobilatf220,
  gtx20w501l,
  gtx20w504l,
  gtx25w504l,
  gtx15w40,
  castrolsae40,
  castroldrum,
  edge5w401l,
  edge5w405l,
  edge5w404l,
  edge10w605l,
  edgeec5,
  gtx5w40710,
  magnatec10w40,
  castrol5w301l,
  castrol5w305L,
  castrolmagnatecstopstart4l,
  magnateca51l,
  magnatec10wa3b4,
  magnateca3a44l,
  magnateca3a45l,
  superoutboard,
  castrolpower1racing,
  powerracing2t,
  actevo4t10w401l,
  radicoolnf1l,
  radicoolsfpremix,
  radicoolsfpremix1l,
  castrolatfdex5l,
  transmaxdex05l,
  transmaxdex1l,
  brakefluidcastrol,
  viscogenklspray,
  twotoil,
  powermaxoil,
  multimax4l,

  multimax1l20,
  multimax4lsuoper,
  optimax25l,
  optimax5l,
  supertc,
  castrolep140,
  castrolep90,
  ep25l,
  castrol25l,
  ep2grease,
  hydrexaw32,
  mouldingoil1l,
  atf1L,
  petronas20w50,
  hyundaixteerg50020w50,
  hyundaixteer15w40,
  hyundai1l,
  hyudaixteer_3000,
  totalq0w204l,
  totalhtcineo,
  total4x44l,
  total90005w,
  total7000s10w40,

  total700015w50,
  total500020w,
  total500044,
  total3000205l,
  total50005ww,
  rubia7400aaa,
  totalrubiafleet22,
  rubiafleethd200,
  rubiahd200sae,
  rubias40,
  rubiafleethd100,
};
