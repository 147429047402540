import React, { useState, useMemo, useEffect } from "react";
import Pagination from "../../constant/Pagination";
import ProductComponents from "../productComponents/ProductComponents";
import "./showTransportBosy.css";

import {
  petronasLubricants,
  transportationLubricant,
} from "../productsAssets/ProductData";
import { useLocation } from "react-router-dom";

let PageSize = 10;

const ShowTransportBody = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return petronasLubricants.slice(firstPageIndex, lastPageIndex);
  }, [currentPage]);

  return (
    <div className="transportspecial_lubGenFam">
      <div className="transportspecial_lubMax">
        <div className="transportspecial_lubHeading">Petronas lubricants</div>
        {currentTableData.map((data, index) => {
          return (
            <div>
              <ProductComponents
                productImg={data.productImg}
                productTitle={data.productTitle}
                productPara={data.productPara}
              />
            </div>
          );
        })}
      </div>
      <Pagination
        className="transportpagination-bar"
        currentPage={currentPage}
        totalCount={petronasLubricants.length}
        pageSize={PageSize}
        onPageChange={(page) => setCurrentPage(page)}
      />
    </div>
  );
};

export default ShowTransportBody;
