import React, { useEffect } from "react";
import "./firstBanner.css";
import firstBackground from "../../assets/images/banner1.jpg";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useState } from "react";

const squareVariantsY = {
  visible: { opacity: 1, y: 0, transition: { duration: 2 } },
  hidden: { opacity: 0, y: 100 },
};

const SearchTopBanner = ({ onChange }) => {
  const [searchValue, setSearchValue] = useState("");
  const controls = useAnimation();
  const [ref, inView] = useInView();
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  useEffect(() => {
    const items = localStorage.getItem("Search_Data");
    if (items) {
      setSearchValue(items);
    }

    onChange(searchValue);
  }, []);
  return (
    <div
      style={{
        background: `url(${firstBackground})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        overflow: "hidden",
        width: "100%",
        marginTop: "20px",
      }}
      className="banner_fMobile"
    >
      <div className="firstbannerCoverB">
        <div className="firstBanner-maxGen">
          <motion.div
            ref={ref}
            animate={controls}
            initial="hidden"
            variants={squareVariantsY}
          >
            <div className="firstBanner-maxGenF">
              ENGINE LUBRICATION SERVICE
            </div>
            <div className="firstBanner-maxGenSS">
              Industrial | Marine | PVL & CVL Lubricants.
            </div>
            <div className="firstBanner_searchGen">
              <div className="firstBanner_searchInput">
                <input
                  type="search"
                  placeholder="Search here..."
                  onChange={(e) => {
                    onChange(e.target.value);
                    setSearchValue(e.target.value);
                  }}
                  value={searchValue}
                />
              </div>
              {/* <div className="firstBanner_selectGen">
                <select>
                  <option>1</option>
                </select>
              </div>
              <div className="firstBanner_selectGen">
                <select>
                  <option>1</option>
                </select>
              </div> */}
            </div>
            <div
              className="firstBanner_searchBtnsearc"
              onClick={() => {
                onChange(searchValue);
              }}
            >
              Search
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default SearchTopBanner;
