import productsImg from "./productsImg";

export const lubricantSpecial = [
  {
    productImg: productsImg.krytox,
    productTitle: "Krytox™ Corrugator 226 FG",
    productPara:
      "Krytox™ Corrugator 226 FG is the standard for PFPE / PTFE (polytetrafluoroethylene) greases in the paper and corrugated industry, it is a NSF H-1 food grade lubricating grease classified for incidental food contact. Composition: Carbon, oxygen and fluorine, Krytox™ GPL 106 oil, additives to prevent oxidation and wear.",
  },

  {
    productImg: productsImg.krytox,
    productTitle: "Krytox™ Corrugator 227 FG",
    productPara:
      "Krytox™ Corrugator 227 FG is a grease for the corrugated industry with an oil viscosity of 440 Cst at 40ºC and an estimated useful temperature range of -30ºC to 288ºC. It is the standard in PFPE / PTFE (Polytetrafluoroethylene) greases recognized by both OEMs and operators as the best performing high temperature grease. This product is certified NSF H-1 Food Grade, providing safe, non-toxic, and inert performance in food-related operations.",
  },

  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon 4S Fluid",
    productPara:
      "Lubcon 4 S Fluid is a universally applicable synthetic special oil with PTFE additives and excellent adhesion.  ",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Grizzlygrease No. 1",
    productPara:
      "LUBCON GRIZZLY GREASE No. 1 MG is a lubricant for open gears and contains thermally stable solid lubricants, it is based on a lithium / calcium complex with high-pressure additives, which combine with the soap structure so that optimal adhesiveness is possible to the metal surface.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Grizzlygrease No. 1 NGLI 00",
    productPara:
      "Lubcon Grizzlygrease N.1 NGLI 00 is a fluid gear grease, it is based on a lithium/calcium complex with high-pressure additives, which combine with the soap structure so that optimum adhesion to the metal surface is possible.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Grizzlygrease No. 3",
    productPara:
      "Lubcon Grizzlygrease N.3 is an adhesive lubricating grease based on a highly viscous mineral oil and a special thickener called lithium carbonate complex soap. Contains additives to reduce friction (AW) and reduce wear due to shock loads (EP) and to prevent aging (AO) and corrosion (KS).",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Korroquick",
    productPara:
      "Lubcon Korroquick is a very thin, light-coloured protector that contains additives with synergistic effects. It contains a select package of additives that provide a high lubrication capacity and protection against corrosion, showing its effectiveness in different working conditions.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Neolube 1",
    productPara:
      "Lubcon Korroquick is a very thin, light-coloured protector that contains additives with synergistic effects. It contains a select package of additives that provide a high lubrication capacity and protection against corrosion, showing its effectiveness in different working conditions.",
  },

  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Tune GPE 702",
    productPara:
      "Lubcon Sintono GPE 702 is a fully synthetic, noise-tested special grease for a multitude of applications.",
  },

  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Tuning GPE 703",
    productPara:
      "Lubcon Sintono GPE 703 is a special grease, totally synthetic, tested against noise and with a multitude of applications.",
  },

  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Tune Terra HLK",
    productPara:
      "Lubcon Sintono Terra HLK is a special biodegradable paste for the lubrication of rails, with a combination of metallic additives that offer excellent EP characteristics.",
  },

  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Tuner Terra HLK 0",
    productPara:
      "Lubcon Sintono Terra HLK 0 is a special paste for conditioning rail heads based on synthetic oil.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Tune Terra HLK 1",
    productPara:
      "SINTONO TERRA HLK 1 is a special paste for the lubrication of rails based on biodegradable synthetic oil. A combination of selected metallic pigments offers excellent wear protection properties for SINTONO TERRA HLK 1.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Tune Terra HLK WINTER",
    productPara:
      "Lubcon Sintono Terra HLK Winter is a special biodegradable paste for the lubrication of rails, flanges and rail head. Contains a combination of metallic additives that offer excellent EP characteristics.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Tune Terra SK",
    productPara:
      "Lubcon Sintono Terra SK is a special grease based on lithium-calcium soap and a biodegradable synthetic oil, for the lubrication of wheel flanges, sides and rail curves.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Tuner Terra SK 1",
    productPara:
      "Lubcon Sintono Terra SK is a soapy lithium-calcium grease based on a biodegradable synthetic oil. It forms an excellent lubricating film, which is distributed over long distances.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Tuner Terra SK 2",
    productPara:
      "Lubcon Sintono Terra SK 2 is a soapy lithium-calcium grease based on a biodegradable synthetic oil. It forms an excellent lubricating film that is distributed over long distances. High coefficient of reduction and wear at high surface pressure. For the lubrication of rolling edges, rail flanks and curved tracks.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Tuner Terra SK RE",
    productPara:
      "SINTONO TERRA SK-RE is a special solid-free grease for wheel flange lubricators. This prevents sedimentation and clogging under pressure in automatic lubrication systems.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Tuner Terra SK RE 1",
    productPara:
      "SINTONO TERRA SK-RE 1 is a special solid-free grease based on an easily biodegradable synthetic oil and an inorganic thickener, it has been specially developed for stationary lubrication systems. This grease is free of solid particles to prevent sedimentation and clogging under pressure in automatic lubrication systems.  ",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Tuner Terra SK RE",
    productPara:
      "SINTONO TERRA SK-RE is a special solid-free grease for wheel flange lubricators. This prevents sedimentation and clogging under pressure in automatic lubrication systems.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Tune Terra SK RE DB",
    productPara:
      "Lubcon Sintono Terra SK-RE DB is a special biodegradable grease for lubricating wheel flanges, sides and rail curves.",
  },

  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Tune Terra SK-RE LT",
    productPara:
      "Lubcon Sintono Terra SK-RE LT is a special solid-free grease for wheel flange lubricators. Prevents sedimentation and clogging under pressure in automatic lubrication systems.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Tune Terra W DB",
    productPara:
      "Lubcon Sintono W DB is an easily biodegradable semi-fluid grease for the lubrication of switches, it has been developed in collaboration with DB (German Railways).",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Terrasol 100 WT",
    productPara:
      "Lubcon Terrasol100 WT is a rapidly biodegradable special oil that has been developed for the railway industry, especially for lubrication in the maneuvering area, it contains additives that prevent oxidation, reduce wear and at the same time provide very good lubricating properties and adhesive capabilities.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Tune Terra W DB",
    productPara:
      "Lubcon Sintono W DB is an easily biodegradable semi-fluid grease for the lubrication of switches, it has been developed in collaboration with DB (German Railways).",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Terrasol 40 CW",
    productPara:
      "Lubcon Terrasol 40 CW is a rapidly biodegradable synthetic oil that has been developed for coating electrical contact cables in rail traffic.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Terrasol SWA 150",
    productPara:
      "Lubcon Terrasol SWA 150 is a solid content, biodegradable ester oil based switch lubricant for the treatment of switch blades, rollers and control. It leaves no residue and provides optimal surface wetting, even after prolonged use.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Thermoplex 2 TML",
    productPara:
      "Lubcon Thermoplex 2 TML is a noise tested, fully synthetic grease for use in the presence of high speeds, high loads and high temperatures.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Thermoplex 2 TML SPECIAL",
    productPara:
      "Lubcon Thermoplex 2 TML Especial is a fully synthetic, asonic, high speed, low temperature grease.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Thermoplex ALN 1001/0",
    productPara:
      "Lubcon Thermoplex ALN 1001 / 0 is a synthetic and fluid gear grease, special for high temperatures with synthetic hydrocarbon base oil, and with a thickener of great metallic affinity.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Thermoplex ALN 1001/00",
    productPara:
      "Lubcon Thermoplex ALN 1001 / 00 is a synthetic, fluid gear grease, especially for high temperatures, with a synthetic hydrocarbon base oil and a thickener with high metal affinity.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Thermoplex ALN 1001/000",
    productPara:
      "Lubcon Thermoplex ALN 1001 / 000 is a synthetic, fluid gear grease. Special for high temperatures with synthetic hydrocarbon base oil and a thickener with high metallic affinity.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Thermoplex ALN 250 EP",
    productPara:
      "Lubcon Thermoplex ALN 250 EP is a synthetic gear grease. Lubcon's special complex soap – ALN confers high affinity for metal as well as excellent thermal and oxidation stability. The base oil, a combination of synthetic fluids with optimum viscosity/temperature behaviour, ensures a constant and sufficient lubricating film.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Thermoplex ALN 251 EP",
    productPara:
      "Lubcon Thermoplex ALN 251 EP is a synthetic gear grease. Lubcon's special complex soap – ALN confers high affinity for metal as well as excellent thermal and oxidation stability. The base oil, a combination of synthetic fluids with optimum viscosity/temperature behaviour, ensures a constant and sufficient lubricating film.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Thermoplex LI 302",
    productPara:
      "Lubcon Thermoplex Li 302 is a fully synthetic high speed special grease with excellent anti-wear properties. ",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmocleaner K 002",
    productPara: " ",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmofluid 150 HF PLUS",
    productPara:
      "Lubcon Turmofluid 150 HF Plus is a special chain oil with synthetic base oil and oxidation inhibitors and special EP additives, it creates a good adhesive film that protects against wear at temperatures above 250ºC.  ",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmofluid 300 OM",
    productPara:
      "Lubcon Turmofluid 300 OM is a fully synthetic sliding lubricating oil with sticky properties for the lubrication of open gears and chains.  ",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmofluid 40 B",
    productPara:
      "Lubcon Turmofluid 40 B is a synthetic chain oil with extreme adhesion and thermal stability. It is especially appropriate for high temperatures. The relubrication cycles must be adapted.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmofluid 40 BK",
    productPara:
      "Lubcon Turmofluid 40 CB is a high temperature chain oil for central lubrication systems. Synthetic, thermally stable and extremely adherent. It is particularly suitable for high temperatures. Forms a wear-resistant, transparent and extremely adherent lubrication film.  ",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmofluid 40 CB",
    productPara:
      "Lubcon Turmofluid 40 CB is a high temperature chain oil for central lubrication systems. Synthetic, thermally stable and extremely adherent. It is particularly suitable for high temperatures. Forms a wear-resistant, transparent and extremely adherent lubrication film.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmofluid 460",
    productPara:
      "Lubcon Turmofluid 460 is a multi-purpose oil with a high load capacity and excellent corrosion protection. ",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmofluid 4800",
    productPara:
      "Lubcon Turmofluid 4800 is a structural viscosity high temperature lubricant for long term lubrication.   ",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmofluid BIO 150 E",
    productPara:
      "Lubcon Turmofluid BIO-E are biodegradable hydraulic and gear oils based on synthetic esters.   ",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmofluid BIO 320 E ",
    productPara:
      "Lubcon Turmofluid Bio 320 E is a biodegradable hydraulic and gear oil based on synthetic esters.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmofluid 40 BK",
    productPara:
      "Lubcon Turmofluid 40 CB is a high temperature chain oil for central lubrication systems. Synthetic, thermally stable and extremely adherent. It is particularly suitable for high temperatures. Forms a wear-resistant, transparent and extremely adherent lubrication film.  ",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmofluid ED 13",
    productPara:
      "Lubcon Turmofluid 40 CB is a high temperature chain oil for central lubrication systems. Synthetic, thermally stable and extremely adherent. It is particularly suitable for high temperatures. Forms a wear-resistant, transparent and extremely adherent lubrication film.  ",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmofluid GV 150",
    productPara:
      "Lubcon Turmofluid GV 150 is a synthetic oil that offers a multitude of application possibilities in all industrial areas.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmofluid GV 220",
    productPara:
      "Lubcon Turmofluid GV 220 is a synthetic oil that offers a multitude of application possibilities in all industrial areas. ",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmofluid HSA 46",
    productPara:
      "Lubcon Turmofluid HSA 46 is a long life, low temperature oil. It is a synthetic oil that presents insignificant variations in viscosity with temperature variations, compared to mineral oils. Consequently less loss in performance and energy.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmofluid HSO",
    productPara:
      "Lubcon Turmofluid HSO is a long life, low temperature synthetic oil. Compared to mineral oils, this synthetic oil, which has been proven for decades, shows only slight differences in viscosity in the face of temperature variations. This reduces power loss and the amount of energy required.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmofluid SF 12",
    productPara:
      "Lubcon Turmofluid SF is an oil for low temperatures and long life, it presents insignificant variations in viscosity with temperature variations, in comparison with mineral oils. Consequently, less loss in performance and energy. They are ideal for any friction point with high requirements and for long duration.  ",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmofluid VAC 65-68",
    productPara:
      "Lubcon Turmofluid Vac 65-68 is a specially treated paraffinic-based lubricant for vacuum pumps.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmogeargrease L 0",
    productPara:
      "Lubcon Turmogeargrease L 0 is a high pressure grease with short fibers of smooth consistency with Extreme Pressure additives.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmogearoil PE 220",
    productPara:
      "Lubcon Turmogearoil PE 220 is a high-temperature, low-evaporation synthetic oil with excellent wear and corrosion protection.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmogrease BIO LC 1303",
    productPara: " ",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmogrease CAK 2501",
    productPara:
      "Lubcon Turmogrease CAK is a calcium complex grease with a mineral oil base for long lasting lubrication.",
  },

  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmogrease CAK 2502",
    productPara:
      "Lubcon Turmogrease Cak is a calcium complex grease with a mineral oil base for long lasting lubrication.",
  },

  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmogrease CAK 4002",
    productPara:
      "TURMOGREASE CAK 4002 is a mineral oil based calcium sulfonate complex grease. Especially under constant influences of water, it neither washes off nor soaks. ",
  },

  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmogrease CAN 1002",
    productPara:
      "Lubcon Turmogrease CAN 1002 is a special high-pressure grease with reinforced protection for the long-term and permanent lubrication of roller and plain bearings. It offers optimum safety even in case of extreme pressure, a long working life and reduces maintenance costs in the application of roller bearings and plain bearings.  ",
  },

  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmogrease CAN 2502",
    productPara:
      "Lubcon Turmogrease CAN 2502 is a special high-pressure grease with reinforced protection for the long-term and permanent lubrication of roller and plain bearings.  ",
  },

  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmogrease CAN 2502",
    productPara:
      "Lubcon Turmogrease CAN 2502 is a special high-pressure grease with reinforced protection for the long-term and permanent lubrication of roller and plain bearings.  ",
  },

  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmogrease Highspeed L 182",
    productPara:
      "Lubcon Turmogrease Highspeed L 182 has been developed to meet the requirements for high and low speeds in combination with high mechanical and thermal loads. Thanks to their variable temperature properties, these greases can be used in a range from -70ºC to 120ºC.  ",
  },

  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmogrease Highspeed L 250",
    productPara:
      "TURMOPGREASE HIGHSPEED L 250 is a special grease for spiral/helical gears with optimum adhesion to the surface.",
  },

  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmogrease Highspeed L 251",
    productPara:
      "Lubcon Turmogrease Highspeed L 251 has been developed to meet the requirements for high and low speeds in combination with high mechanical and thermal loads. Thanks to their variable temperature properties these greases can be used in a range from -40ºC to +120ºC, in a short period up to +130ºC, without a higher energy demand.",
  },

  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmogrease Highspeed L 252",
    productPara:
      "Lubcon Turmogrease Highspeed L 252 has been developed to meet the requirements for high and low speeds in combination with high mechanical and thermal loads. Thanks to their variable temperature properties these greases can be used in a range from -40ºC to +120ºC, in a short period up to +140ºC, without a higher energy demand.",
  },

  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmogrease HVM 1",
    productPara:
      "Lubcon Turmogrease HVM 1 is a lubricating grease based on a high viscosity index mineral oil and a polyurea thickener, containing additives to prevent oxidation and corrosion, as well as reducing anti-wear and loss of efficiency due to high loads (EP).",
  },

  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmogrease L 801 EP PLUS",
    productPara:
      "Lubcon Turmogrease HVM 1 is a lubricating grease based on a high viscosity index mineral oil and a polyurea thickener, containing additives to prevent oxidation and corrosion, as well as reducing anti-wear and loss of efficiency due to high loads (EP). ",
  },

  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmogrease L 802 EP PLUS",
    productPara:
      "TURMOGREASE L 802 EP plus is a special lithium soap-based lubricating grease with a blended partially synthetic base oil and additives. To optimize general lubricating properties. Due to the special soap complex, separation of the base oil from the thickener will be avoided.  ",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmogrease L 802 EP PLUS R",
    productPara:
      "TURMOGREASE L 802 EP PLUS R is a special lithium soap-based lubricating grease with a blended partially synthetic base oil and additives to optimize overall lubricating properties. Due to the special soap complex, separation of the base oil from the thickener will be avoided.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmogrease L 802 EP PLUS WHITE",
    productPara:
      "TURMOGREASE L 802 EP PLUS WHITE is a special lithium soap-based grease and partly synthetic base oil mixed with white solids. Due to the special soap complex, separation of the base oil from the thickener will be avoided. ",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmogrease LC 252",
    productPara:
      "Lubcon Turmogrease LC 252 is a semi-synthetic lubricating grease. It is waterproof and very suitable for use in bearings with high speeds.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmogrease LC 802 EP",
    productPara:
      "Lubcon Turmogrease LC 802 EP is a universal grease for highly loaded and long-term applications, containing a lithic/calcium thickener and based on a special oil. It has a select package of additives to improve anti-friction, anti-aging and corrosion protection capabilities.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmogrease LI 802 EP",
    productPara:
      "Lubcon Turmogrease Li 802 EP is a long-term multi-purpose lubricant for high loads, the special blend of base oil and additives help to optimize the properties of the lubricant. ",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmogrease No. 2",
    productPara:
      "Lubcon Turmogrease N 2 is a synthetic lubricating grease that offers a multitude of possible applications in industrial plants and in the automotive industry.  ",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmogrease N 2 MF",
    productPara:
      "Lubcon Turmogrease N2 MF is a special synthetic grease that has a multitude of possible applications in industrial plants and in the automotive industry.  ",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmogrease N 3",
    productPara:
      "Lubcon Turmogrease N 3 is a fully synthetic special grease that offers a multitude of possible applications in industrial plants and in the automotive industry.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmogrease N 4602",
    productPara:
      "Lubcon Turmogrease N 4602 is a multifunctional synthetic grease, with a polyurea thickener, soft and smooth that offers us a multitude of possible applications, within a temperature range of -30ºC to +180ºC.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmogrease N 502",
    productPara:
      "Lubcon Turmogrease N 502 is a totally synthetic special grease that offers a multitude of possible applications in industrial plants, and in the automotive industry.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmogrease NBI 2 SPECIAL D",
    productPara:
      "Lubcon Turmogrease NBI 2 special D is based on a synthetic oil supplemented with an inorganic thickener, to solve special lubrication problems.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmogrease NM 2",
    productPara:
      "Lubcon Turmogrease NM 2 is a multifunctional grease for various industrial applications, with a temperature range from -25ºC to 160ºC and peaks of 180ºC.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmogrease NM 4602",
    productPara:
      "Lubcon Turmogrease NM 4602 is a smooth and homogeneous multifunctional grease for multiple applications in a temperature range from -20ºC to +180ºC.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmogrease PHM 4602",
    productPara:
      "Lubcon Turmogrease PHM 4602 is a smooth and homogeneous multifunctional grease for multiple applications in a temperature range from -20ºC to +160ºC.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmogrease PHS 1002",
    productPara:
      "TURMOGREASE PHS 1002 is a partially synthetic special grease that offers a multitude of application possibilities in industrial plants, for machinery in the construction industry and in the automotive industry.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmogrease PP 300",
    productPara:
      "LUBCON TURMOGREASE PP 300 is a fully synthetic grease for bearings and accessories.",
  },

  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmogrease PU 702",
    productPara:
      "Lubcon Turmogrease PU 702 is a long-life, synthetic oil-based, noise-reducing grease for use in special ball bearings at low and high temperatures. It has a range of working temperatures that go from -40ºC to 180ºC and up to 200ºC in short periods of work.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmogrease PU 703",
    productPara:
      "Lubcon Turmogrease PU 703 is a long-life, synthetic oil-based, noise-reducing grease for use in special ball bearings at low and high temperatures. It has a range of working temperatures, ranging from -40ºC to 200ºC.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmogrease SKL 18 VTF",
    productPara:
      "Lubcon Turmogrease SKL 18 VTF is a special grease formulated with a synthetic oil and a lithium thickener, especially suitable for lubrication at low and high temperatures, as well as for high-speed roller bearings.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmoplex 1 EP",
    productPara:
      "Lubcon Turmoplex 1 EP is a universal grease produced with a selection of raw materials and special additives, with a wide temperature range and guarantees an excellent useful life due to its special oxidation stability.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmoplex 1585 WS",
    productPara:
      "Lubcon Turmoplex 1585 WS is a special grease with lithium complex soap thickener for very high protection against wear and corrosion. Thanks to the carefully balanced additive package, the high-performance grease reduces running noise/vibrations and enables smooth sliding of the slide rails.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmoplex 2 EP",
    productPara:
      "Lubcon Turmoplex 2 EP is a universal grease with selected raw materials and special additives, it allows lubrication in wet or dusty conditions without problems.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmoplex 3",
    productPara:
      "Lubcon Turmoplex 3 is a multifunctional grease made from selected raw materials and special additives. The consistency is medium-soft with a smooth texture.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmoplex ALN 1001/00",
    productPara:
      "Lubcon Turmoplex ALN 1001/00 is a fluid, high-temperature hydrocarbon-based gear grease of special consistency with high affinity for metals.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmoplex ALN 4602",
    productPara:
      "Lubcon Turmoplex ALN 4602 is a synthetic special grease for high specific loads in a wide operating temperature range.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmoplex EL 000 EP",
    productPara:
      "Lubcon Turmoplex EL 000 EP is a very smooth flowing grease with lithium thickener, EP additives and excellent wear protection.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmoplex L 360 HV",
    productPara:
      "Lubcon Turmoplex L 360 HV is a new mineral oil and lithium soap based grease with optimum surface adhesion and excellent lubricating film stability. Excellent corrosion protection for non-ferrous metals and aging stability.",
  },

  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmoplex LKF 1801 EP",
    productPara:
      "Lubcon Turmoplex LKF 1801 EP is a long life universal lubricant. It is a grease formulated with a mineral base oil and a lithium complex soap thickener. Due to this special lithium complex soap, the separation between the base oil and the thickener is avoided.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmopolgrease 00",
    productPara:
      "Lubcon Turmopolgrease 00 is a fluid grease for gears, with high requirements for a temperature range between -40ºC to +130ºC.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmopolgrease 000",
    productPara:
      "Lubcon Turmopolgrease 000 is a fluid synthetic grease for gears with high requirements for a temperature range between -40ºC to +130ºC.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmopoloil 100 EP",
    productPara:
      "Lubcon Turmopoloil 100 EP is a high performance synthetic oil especially for extreme mechanical and thermal demands.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmopoloil 150 EP",
    productPara:
      "Lubcon Turmopoloil 150 EP is a high performance synthetic oil especially for extreme mechanical and thermal demands.  ",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmopoloil 20 HD",
    productPara:
      "TURMOPOLOIL 20 HD is a high performance synthetic oil for extreme thermal and mechanical requirements. Used for the lubrication chain of conveyor plants.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmopoloil 220 G",
    productPara:
      "Lubcon Turmopoloil 220 G is a high temperature stable synthetic oil graphitized with pure natural graphite for extreme requirements.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmopoloil 32 EP",
    productPara:
      "Lubcon Turmopoloil 32 EP is a high-performance synthetic oil for particularly heavy-duty gears.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmopoloil 680 EP",
    productPara:
      "Lubcon Turmopoloil 680 EP is a high performance synthetic oil, especially for extreme mechanical and thermal requirements.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmosol K 52",
    productPara:
      "Lubcon Turmosol K 52 is a silicone-free lubricant and release agent for multiple manufacturing processes in the plastic and textile industry.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmosol TRAK 50",
    productPara: " ",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmosynthoil HTC 150",
    productPara:
      "TURMOSYNTHOIL HTC 150 is a synthetic oil for chains subjected to high temperatures and with special EP additives. TURMOSYNTHOIL HTC 150 has been tested at working temperatures up to 250ºC. Contains special additives to prevent the formation of residues TURMOSYNTHOIL HTC 150 has INS services H1 approval for lubricants that may come into occasional contact with food.",
  },

  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmotemp II 400",
    productPara:
      "Lubcon Turmotemp II 400 is a white special grease based on perfluorinated polyether oils. These oils are fully saturated and show optimum oxidation and thermal stability.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmotemp II 400 CL 1",
    productPara:
      "Lubcon Turmotemp II 400 CL 1 is a white special grease based on perfluorinated polyether oils.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmotemp II 400 CL 2",
    productPara:
      "Lubcon Turmotemp II 400 CL 2 is a white special grease based on perfluorinated polyether oils with H1 certification.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmotemp II 400 KL",
    productPara:
      "Lubcon Turmotemp II 400 KL is a universal grease based on perfluorinated polyether. These oils are fully saturated and show very high thermal and oxidation stability.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmotemp II 400 RS 00",
    productPara:
      "Lubcon Turmotemp II/400 RS 00 is a special grease for high temperatures, based on fully saturated perfluorinated polyesters and presenting oxidation resistance and optimal thermal stability up to +280ºC due to its extremely low vapor pressure.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmotemp II 400 RS 1",
    productPara: " ",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmotemp II 400 RS 2",
    productPara:
      "Lubcon Turmotemp II 400 RS 2 is a multipurpose grease based on perfluorinated polyether oils. These oils are fully saturated and show optimum oxidation and thermal stability.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmotemp II 400 VAC 3",
    productPara:
      " Lubcon Turmotemp II 400 VAC 3 is a grease based on alkoxy-fluoro-oils, being fully saturated and showing optimum oxidation stability and thermal stability above 280ºC.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmotemp LP 2502",
    productPara:
      "Lubcon Turmotemp LP 2502 is a special grease for the lubrication of corrugated rollers. Based on fluorinated oils (PFAE) and metallic soaps. The optimum temperature range is from >+120ºC to +250ºC. ",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmotemp LP 2550",
    productPara:
      "Lubcon Turmotemp LP 2550 is a special grease for the lubrication of highly loaded bearings, based on perfluorinated polyethers (PFPE), PTFE and a special lithium soap. ",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmotemp LP 5002",
    productPara:
      "Lubcon Turmotemp LP 5002 is a special grease for the lubrication of highly loaded rolling bearings based on perfluorinated polyethers (PFPE) and a special lithium soap. This means that, under thermal and mechanical load, the lubricant flows back to the contact surfaces and reduces wear to a minimum. ",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmotemp M 182",
    productPara:
      " Lubcon Turmotemp M 182 is a white special lubricant based on perfluorinated polyether oils. ",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmotemp M 2002",
    productPara:
      "Lubcon Turmotemp M 2002 is a high temperature grease based on perfluorinated polyethers. These oils are fully saturated and show optimum oxidation and thermal stability.   ",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmotemp Super 2 EP",
    productPara:
      "Lubcon Turmotemp Super 2 EP belongs to a series of unique greases on the lubricant market, in that the combination of perfluorinated base oils with special metallic soaps has been successfully achieved. Thanks to this new thickener we are able to combine the positive properties of chemical and thermal stability of perfluorinated base oils with the excellent properties of metallic soaps.   ",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmotempoil 400-270",
    productPara:
      "Lubcon Turmotempoil 400-270 is a special oil based on perfluorinated polyether. Therefore, TURMOTEMPOIL 400-270 belongs to the most thermally and chemically stable lubricants. It is used successfully, if the operating temperatures and chemical influences exclude the use of conventional lubricants. ",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmotexoil LP 32 W",
    productPara:
      "Lubcon Turmotexoil LP 32 W is a special oil for the lubrication of knitting machines, based on white oil, it keeps the needles clean and prolongs the useful life of the needles and sinkers. ",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmotexoil LP 46 T",
    productPara:
      "Lubcon Turmotexoil LP 46 T is a mineral oil-based, semi-synthetic special oil for the textile industry for the lubrication of knitting and sewing machines, including needles, sinkers, cams, needle cylinders and sinkers as well as spinning rings. ",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmoxygen LC 40",
    productPara:
      " Lubcon Turmoxygen LC 40 is a slip agent for oxygen installations. Use in accessories and other applications with gaseous oxygen.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmoxygen LC 40 FLUID",
    productPara:
      "Lubcon Turmoxygen LC 40 Fluid is a synthetic oil for use in oxygen installations, tested for use in accessories or other oxygen gas applications and as a vacuum fill fluid. ",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmoxygen LCO 36",
    productPara:
      "Lubcon Turmoxygen LCO 36 is a slip agent for oxygen installations with NSF-H1 approval. Use in accessories and other liquid oxygen applications. ",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmsilon K 100",
    productPara:
      "Lubcon Turmsilon K 100 is a liquid, crystalline polymethylsiloxane, has a temperature range of -50 °C up to +150 °C, in closed systems and under an inert gas atmosphere up to 200 °C ",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmsilon K 350",
    productPara:
      "Lubcon Tursilon K are liquid, crystalline polydimethylsiloxanes for temperatures up to +150 °C",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmsilon LMI 5000",
    productPara:
      "Lubcon Turmsilon LMI 500 is a special grease for the food and beverage industry, NSF / H1 approved. ",
  },
  {
    productImg: productsImg.petronas,
    productTitle: "Petronas Airlub 100",
    productPara:
      "Petronas Airlub 100 is a special “Long-Life” fluid strongly inhibited against prolonged oxidation in air compressors.",
  },
  {
    productImg: productsImg.petronasAlt,
    productTitle: "Petronas Ato 100",
    productPara:
      "Lubcon Turmsilon K 100 is a liquid, crystalline polymethylsiloxane, has a temperature range of -50 °C up to +150 °C, in closed systems and under an inert gas atmosphere up to 200 °C ",
  },
  {
    productImg: productsImg.petronas,
    productTitle: "Petronas Ato 150",
    productPara:
      "Petronas ATO are oils for drilling and pneumatic tools, they are available in two viscosity grades, which allows the selection of the appropriate viscosity according to the different surface temperatures registered in mining operations. They contain additives that effectively control emulsions, have preferential metal wetting properties, and exhibit excellent wear, rust and corrosion protection, autoignition resistance, mist control, and tack. ",
  },
  {
    productImg: productsImg.petronasAlt,
    productTitle: "Petronas Baku R 100 EP",
    productPara:
      "Petronas Baku R 100 EP are highly refined oils with special anti-wear and extreme pressure, antioxidant, anti-corrosion and anti-foam additives.",
  },
  {
    productImg: productsImg.petronasAlt,
    productTitle: "Petronas Baku TC 25",
    productPara:
      "Lubcon Turmsilon K 100 is a liquid, crystalline polymethylsiloxane, has a temperature range of -50 °C up to +150 °C, in closed systems and under an inert gas atmosphere up to 200 °C ",
  },
  {
    productImg: productsImg.petronas,
    productTitle: "Petronas Circulates 150",
    productPara:
      "Petronas Circula is a high performance anti-wear circulating oil, developed for a wide range of heavy duty circulating systems.",
  },
  {
    productImg: productsImg.petronas,
    productTitle: "Petronas Circle PM 220",
    productPara:
      "Petronas Circula PM is a superior performance anti-wear circulating oil, specifically developed for paper machine circulation systems operating under normal to extremely heavy duty conditions.",
  },
  {
    productImg: productsImg.petronas,
    productTitle: "Petronas Compressor A M2 100",
    productPara:
      "Petronas Compressor A M2 is a high-performance compressor oil, developed to cover the 2,000 hours of useful life of the oil, even when working at maximum discharge temperatures of up to 100ºC. Thanks to its formula with selected high-quality mineral base oils enhanced with advanced anti-oxidant, anti-wear (zinc-free), anti-rust and anti-foam additives, they minimize the formation of varnishes and deposits, providing very good thermal stability, oxidation resistance and high performance. durable.",
  },
  {
    productImg: productsImg.petronasAltBig,
    productTitle: "Petronas Compressor A M2 46",
    productPara:
      "Petronas Compressor A M2 is a high-performance compressor oil, developed to cover the 2,000 hours of useful life of the oil, even when working at maximum discharge temperatures of up to 100ºC. Thanks to its formula with selected high-quality mineral base oils enhanced with advanced anti-oxidant, anti-wear (zinc-free), anti-rust and anti-foam additives, they minimize the formation of varnishes and deposits, providing very good thermal stability, oxidation resistance and high performance. durable.",
  },
  {
    productImg: productsImg.petronas,
    productTitle: "Petronas Compressor A M2 68",
    productPara:
      "Petronas Compressor A M2 is a high-performance compressor oil, developed to cover the 2,000 hours of useful life of the oil, even when working at maximum discharge temperatures of up to 100ºC. Thanks to its formula with selected high-quality mineral base oils enhanced with advanced anti-oxidant, anti-wear (zinc-free), anti-rust and anti-foam additives, they minimize the formation of varnishes and deposits, providing very good thermal stability, oxidation resistance and high performance. durable.",
  },
  {
    productImg: productsImg.petronas,
    productTitle: "Petronas Compressor A M4 46",
    productPara:
      "Petronas Compressor A M4 is a premium performance compressor oil, developed to cover the 4,000 hour useful life of the oil, even when working at maximum discharge temperatures of up to 100ºC. Formulated with selected high-quality mineral base oils enhanced with advanced anti-oxidant, anti-wear (zinc-free), anti-rust and anti-fo",
  },
  {
    productImg: productsImg.petronasAlt,
    productTitle: "Petronas Extra Dexron III",
    productPara:
      "Petronas Extra Dexron III is a special fluid for automatic transmissions.",
  },
  {
    productImg: productsImg.petronas,
    productTitle: "Petronas Gear Mep 100",
    productPara:
      "Petronas Gear Mep is a high quality mineral oil for heavy industrial gears manufactured from high quality paraffinic mineral oils, incorporating new generation additives to provide extreme pressure and anti-wear characteristics, corrosion protection, improved oxidation resistance and resistance to foaming.",
  },
  {
    productImg: productsImg.petronas,
    productTitle: "Petronas Gear Mep 150",
    productPara:
      "Petronas Gear Mep is a high quality mineral oil for heavy industrial gears manufactured from high quality paraffinic mineral oils, incorporating new generation additives to provide extreme pressure and anti-wear characteristics, corrosion protection, improved oxidation resistance and resistance to foaming.",
  },
  {
    productImg: productsImg.petronas,
    productTitle: "Petronas Gear Mep 220",
    productPara:
      "Petronas Gear Mep is a high quality mineral oil for heavy industrial gears manufactured from high quality paraffinic mineral oils, incorporating new generation additives to provide extreme pressure and anti-wear characteristics, corrosion protection, improved oxidation resistance and resistance to foaming.",
  },
  {
    productImg: productsImg.petronas,
    productTitle: "Petronas Gear Mep 320",
    productPara:
      "Petronas Gear Mep is a high quality mineral oil for heavy industrial gears manufactured from high quality paraffinic mineral oils, incorporating new generation additives to provide extreme pressure and anti-wear characteristics, corrosion protection, improved oxidation resistance and resistance to foaming.",
  },
  {
    productImg: productsImg.petronas,
    productTitle: "Petronas Gear Mep 460",
    productPara:
      "Petronas Gear Mep is a high quality mineral oil for heavy industrial gears manufactured from high quality paraffinic mineral oils, incorporating new generation additives to provide extreme pressure and anti-wear characteristics, corrosion protection, improved oxidation resistance and resistance to foaming.",
  },
  {
    productImg: productsImg.petronas,
    productTitle: "Petronas Gear Mep 68",
    productPara:
      "Petronas Gear Mep is a high quality mineral oil for heavy industrial gears manufactured from high quality paraffinic mineral oils, incorporating new generation additives to provide extreme pressure and anti-wear characteristics, corrosion protection, improved oxidation resistance and resistance to foaming.",
  },
  {
    productImg: productsImg.petronas,
    productTitle: "Petronas Gear Mep 680",
    productPara:
      "Petronas Gear Mep is a high quality mineral oil for heavy industrial gears manufactured from high quality paraffinic mineral oils, incorporating new generation additives to provide extreme pressure and anti-wear characteristics, corrosion protection, improved oxidation resistance and resistance to foaming.",
  },
  {
    productImg: productsImg.petronas,
    productTitle: "Petronas Gear Syn Pag 150",
    productPara:
      "Petronas Gear Syn Pag are supreme performance industrial gear oils developed for various types of enclosed industrial gears operating under normal to extreme stress conditions. Formulated with high viscosity index (PAG) water soluble polyalkylene glycol synthetic base oils enhanced with extreme pressure, antioxidant, anti-rust and advanced anti-foam additives.",
  },
  {
    productImg: productsImg.petronas,
    productTitle: "Petronas Gear Syn Pag 220",
    productPara:
      "Petronas Gear Syn Pag are supreme performance industrial gear oils developed for various types of enclosed industrial gears operating under normal to extreme stress conditions. Formulated with high viscosity index (PAG) water soluble polyalkylene glycol synthetic base oils enhanced with extreme pressure, antioxidant, anti-rust and advanced anti-foam additives.",
  },
  {
    productImg: productsImg.petronas,
    productTitle: "Petronas Gear Syn Pag 320",
    productPara:
      "Petronas Gear Syn Pag are supreme performance industrial gear oils developed for various types of enclosed industrial gears operating under normal to extreme stress conditions. Formulated with high viscosity index (PAG) water soluble polyalkylene glycol synthetic base oils enhanced with extreme pressure, antioxidant, anti-rust and advanced anti-foam additives.",
  },
  {
    productImg: productsImg.petronas,
    productTitle: "Petronas Gear Syn Pag 460",
    productPara:
      "Petronas Gear Syn Pag are supreme performance industrial gear oils developed for various types of enclosed industrial gears operating under normal to extreme stress conditions. Formulated with high viscosity index (PAG) water soluble polyalkylene glycol synthetic base oils enhanced with extreme pressure, antioxidant, anti-rust and advanced anti-foam additives.",
  },
  {
    productImg: productsImg.petronas,
    productTitle: "Petronas Gear Syn Pao 220",
    productPara:
      "Petronas Gear Syn Pag are supreme performance industrial gear oils developed for various types of enclosed industrial gears operating under normal to extreme stress conditions. Formulated with high viscosity index (PAG) water soluble polyalkylene glycol synthetic base oils enhanced with extreme pressure, antioxidant, anti-rust and advanced anti-foam additives.",
  },
  {
    productImg: productsImg.petronas,
    productTitle: "Petronas Jenteram 46",
    productPara:
      "Petronas Jenteram is a high performance industrial turbine oil, specially developed for steam turbines, light duty gas turbines and combined cycle turbines with or without associated gear drives, which require zinc based turbine oils.",
  },
  {
    productImg: productsImg.petronasAlt,
    productTitle: "Petronas Jenteram G 32",
    productPara:
      "Petronas Jenteram G are premium performance industrial turbine oils developed for use in modern steam turbines, light and heavy duty gas turbines and combined cycle turbines with or without associated gearboxes.",
  },
  {
    productImg: productsImg.petronas,
    productTitle: "Petronas Oilsint 100",
    productPara:
      "Petronas Oleosint are synthetic fluids with special antioxidant additives for high temperatures, great chemical stability and resistance to mechanical work within a wide range of service temperatures.",
  },
  {
    productImg: productsImg.petronas,
    productTitle: "Petronas Oilsint 150",
    productPara:
      "Petronas Oleosint are synthetic fluids with special antioxidant additives for high temperatures, great chemical stability and resistance to mechanical work within a wide range of service temperatures.",
  },
  {
    productImg: productsImg.petronas,
    productTitle: "Petronas Oilsint 320",
    productPara:
      "Petronas Oleosint are synthetic fluids with special antioxidant additives for high temperatures, great chemical stability and resistance to mechanical work within a wide range of service temperatures.",
  },
  {
    productImg: productsImg.petronasAlt,
    productTitle: "Petronas Oilsint 68",
    productPara:
      "Petronas Oleosint are synthetic fluids with special antioxidant additives for high temperatures, great chemical stability and resistance to mechanical work within a wide range of service temperatures.",
  },
  {
    productImg: productsImg.petronas,
    productTitle: "Petronas Olicom PO 32",
    productPara:
      "Petronas Olicom PO are compressor lubricants for the food industry, specially formulated for use in compressors within the food, pharmaceutical and beverage industries. Manufactured from synthetic oils with special additives. They conform to the strictest standards of the food industry and comply with the Hazard Analysis of Critical Control Points (HACCP) system as well as the FDA 21 CFR 178.3570 'Lubricants in incidental contact with food'",
  },
  {
    productImg: productsImg.petronas,
    productTitle: "Petronas Olicom PO 46",
    productPara:
      "Petronas Olicom PO are compressor lubricants for the food industry, specially formulated for use in compressors within the food, pharmaceutical and beverage industries. Manufactured from synthetic oils with special additives. They conform to the strictest standards of the food industry and comply with the Hazard Analysis of Critical Control Points (HACCP) system as well as the FDA 21 CFR 178.3570 'Lubricants in incidental contact with food'",
  },
  {
    productImg: productsImg.petronas,
    productTitle: "Petronas Olicom PO 68",
    productPara:
      "Petronas Olicom PO are compressor lubricants for the food industry, specially formulated for use in compressors within the food, pharmaceutical and beverage industries. Manufactured from synthetic oils with special additives. They conform to the strictest standards of the food industry and comply with the Hazard Analysis of Critical Control Points (HACCP) system as well as the FDA 21 CFR 178.3570 'Lubricants in incidental contact with food'",
  },
  {
    productImg: productsImg.petronas,
    productTitle: "Petronas Slideway 220",
    productPara:
      "Petronas SlideWay are high-quality special lubricants for guides and sliding surfaces of machine tools where precision or low speeds make it necessary to eliminate jolts in the beds and sliding surfaces. Formulated with a high quality base and combined with additives that have good extreme pressure characteristics, adhesiveness, foam resistance, rust and corrosion protection, high lubricity and lubricity.",
  },
  {
    productImg: productsImg.petronas,
    productTitle: "Petronas Slideway 68",
    productPara:
      "Petronas SlideWay are high-quality special lubricants for guides and sliding surfaces of machine tools where precision or low speeds make it necessary to eliminate jolts in the beds and sliding surfaces. Formulated with a high quality base and combined with additives that have good extreme pressure characteristics, adhesiveness, foam resistance, rust and corrosion protection, high lubricity and lubricity.",
  },
  {
    productImg: productsImg.petronas,
    productTitle: "Petronas Slideway HG 68",
    productPara:
      "Petronas Slideway HG with top quality multifunction lubricants, developed for the lubrication of sliding guides, hydraulic systems and gearboxes of machine tools. Formulated with a selected mineral base with a high viscosity index and a selected additive package that guarantees excellent demulsibility, oxidation stability, rust protection, lubricity, extreme pressure and anti-wear properties.",
  },

  {
    productImg: productsImg.petronasAlt,
    productTitle: "Petronas Synthetic Polar 46",
    productPara:
      "Family of high quality fluids based on a mixture of select mineral and synthetic bases with which an excellent final product is obtained, specific for its application in refrigeration compressors of all types and models, obtaining excellent performance with its use.",
  },
  {
    productImg: productsImg.petronasAlt,
    productTitle: "Petronas Synthetic Polar 68",
    productPara:
      "Family of high quality fluids based on a mixture of select mineral and synthetic bases with which an excellent final product is obtained, specific for its application in refrigeration compressors of all types and models, obtaining excellent performance with its use.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Cosmolubric HF 122 E",
    productPara:
      "Cosmolubric HF 122 E is a biodegradable synthetic hydraulic fluid based on organic esters corresponding to a viscosity grade according to ISO VG46. Its high lubricating and anti-wear properties ensure reliable protection of hydraulic systems and components.  ",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Houghton Adriana D 208Quaker Houghton Cosmolubric HF 130 E",
    productPara:
      "Cosmolubric HF 130 E is a biodegradable synthetic hydraulic fluid based on organic esters corresponding to a viscosity grade according to ISO VG68. Its high lubricating and anti-wear properties ensure reliable protection of hydraulic systems and components. ",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Luber 1",
    productPara:
      "Family of high quality fluids based on a mixture of select mineral and synthetic bases with which an excellent final product is obtained, specific for its application in refrigeration compressors of all types and models, obtaining excellent performance with its use.",
  },
  {
    productImg: productsImg.shellGas,
    productTitle: "Shell Gas Compressor Oil S4 PV 190",
    productPara:
      "Shell Gas Compressor Oil S4 PV (formerly Shell Madrela T) is an advanced synthetic gas compressor lubricant, based on polyalkylene glycol fluid and fully approved by all major gas compressor manufacturers.",
  },
];

export const bioLubricants = [
  {
    productImg: productsImg.panolinDrum,
    productTitle: "Panolin Atlantis 15",
    productPara:
      "Panolin Atlantis is a biodegradable synthetic fluid for marine applications",
  },
  {
    productImg: productsImg.panolinDrum,
    productTitle: "Panolin Biofluid SBH-N 46",
    productPara:
      "Panolin Biofluid SBH-N 46 is a fully synthetic and readily biodegradable universal tractor transmission oil based on saturated synthetic esters for construction machinery, agriculture, forestry and railway construction.",
  },
  {
    productImg: productsImg.panolinKeg,
    productTitle: "Panolin Biofluid ZFH",
    productPara:
      "Panolin Biofluid ZFH is a fully synthetic biodegradable Universal Tractor Transmission Lubricant (UTTO). For combined use in reducers, shafts, hydraulic systems and retarders in agricultural, forestry and earthmoving machinery.",
  },
  {
    productImg: productsImg.panolinKeg,
    productTitle: "Panolin Biogear RS",
    productPara:
      "Panolin Biogear RS is a fully synthetic, environmentally friendly gear oil for gearboxes, differentials and final drives.",
  },
  {
    productImg: productsImg.panolinDrum,
    productTitle: "Panolin Biogrease EP 2",
    productPara:
      "Panolin Biogrease EP 2 is a fully synthetic, biodegradable extreme pressure biolubricating grease.",
  },
  {
    productImg: productsImg.panolinDrum,
    productTitle: "Panolin Biogrease LL-EP 2",
    productPara:
      "Panolin Biogrease LL-EP 2 is an easily biodegradable universal EP grease, synthetic esters, for exposed applications based on natural resources with European EU Ecolabel certification. PANOLIN BIOGREASE LL-EP 2 is now approved for use in Becker Marine Systems rudder systems. Founded by Willi Becker in 1946, Becker Marine Systems has become the market leader in energy saving devices and maneuvering systems for all types of ships. Becker Marine Systems has introduced multiple rudder systems to the market.  ",
  },
  {
    productImg: productsImg.panolinDrum,
    productTitle: "Panolin Biogrease W EP 1",
    productPara:
      "Panolin Biogrease W EP 1 is a readily biodegradable universal grease, synthetic esters, for exposed applications, Ecolabel certified",
  },
  {
    productImg: productsImg.panolinDrum,
    productTitle: "Panolin Biomot LD 10W 40",
    productPara:
      "Panolin Biomot LD 10W 40 is a fully synthetic and rapidly biodegradable diesel engine oil.",
  },
  {
    productImg: productsImg.panolinDrum,
    productTitle: "Panolin Biomot LE-X 5W-30",
    productPara:
      "Panolin Biomot LE-X 5W/30 is an environmentally friendly, fully synthetic diesel engine oil.",
  },
  {
    productImg: productsImg.panolinDrum,
    productTitle: "Panolin Biomot LX 10W-40",
    productPara:
      "Panolin Biomot LX 10w-40 is a fully synthetic, sulfated low ash motor oil for modern diesel engines.",
  },
  {
    productImg: productsImg.panolinDrum,
    productTitle: "Panolin Biotrack E 320",
    productPara:
      "Panolin Biotrack E is an ecological lubricant for loss lubrication.",
  },
  {
    productImg: productsImg.panolinDrum,
    productTitle: "Panolin Biotrack E 700",
    productPara:
      "Panolin Biotrack E is an ecological lubricant for loss lubrication.",
  },
  {
    productImg: productsImg.panolinKeg,
    productTitle: "Panolin Chainlube B 120",
    productPara:
      "Panolin Chainlube B 120 is a 100% synthetic and rapidly biodegradable saw chain oil.",
  },
  {
    productImg: productsImg.panolinKeg,
    productTitle: "Panolin Chainlube B 80",
    productPara:
      "Panolin Chainlube B 80 is a 100% synthetic, rapidly biodegradable saw chain oil.",
  },
  {
    productImg: productsImg.panolinDrum,
    productTitle: "Panolin Ecomot 5W-30",
    productPara:
      "Panolin Ecomot 5W-30 is a lowSAPS-FE, CO2-reducing, synthetic diesel engine oil.",
  },
  {
    productImg: productsImg.panolinDrum,
    productTitle: "Panolin EP Gear Synth 100",
    productPara:
      "Panolin EP Gear Synth is a 100% synthetic and rapidly biodegradable gear oil.",
  },
  {
    productImg: productsImg.panolinDrum,
    productTitle: "Panolin EP Gear Synth 150",
    productPara:
      "Panolin EP Gear Synth is a 100% synthetic and rapidly biodegradable gear oil.",
  },
  {
    productImg: productsImg.panolinDrum,
    productTitle: "Panolin EP Gear Synth 220",
    productPara:
      "Panolin EP Gear Synth is a 100% synthetic and rapidly biodegradable gear oil.",
  },
  {
    productImg: productsImg.panolinDrum,
    productTitle: "Panolin EP Gear Synth 320",
    productPara:
      "Panolin EP Gear Synth is a 100% synthetic and rapidly biodegradable gear oil.",
  },
  {
    productImg: productsImg.panolinDrum,
    productTitle: "DRUMPanolin EP Gear Synth 460",
    productPara:
      "Panolin EP Gear Synth is a 100% synthetic and rapidly biodegradable gear oil.",
  },
  {
    productImg: productsImg.panolinKeg,
    productTitle: "Panolin EP Gear Synth 68",
    productPara:
      "Panolin EP Gear Synth is a 100% synthetic and rapidly biodegradable gear oil.",
  },
  {
    productImg: productsImg.panolinDrum,
    productTitle: "Panolin HLP Synth 100",
    productPara:
      "Panolin HLP Synth is a hydraulic fluid, rapidly biodegradable with saturated synthetic esters.",
  },
  {
    productImg: productsImg.panolinDrum,
    productTitle: "Panolin HLP Synth 15",
    productPara:
      "Panolin HLP Synth is a hydraulic fluid, rapidly biodegradable with saturated synthetic esters.",
  },
  {
    productImg: productsImg.panolinDrum,
    productTitle: "Panolin HLP Synth 22",
    productPara:
      "Panolin HLP Synth is a hydraulic fluid, rapidly biodegradable with saturated synthetic esters.",
  },
  {
    productImg: productsImg.panolinKeg,
    productTitle: "Panolin HLP Synth 32",
    productPara:
      "Panolin HLP Synth is a hydraulic fluid, rapidly biodegradable with saturated synthetic esters.",
  },
  {
    productImg: productsImg.panolinDrum,
    productTitle: "Panolin HLP Synth 46",
    productPara:
      "Panolin HLP Synth is a hydraulic fluid, rapidly biodegradable with saturated synthetic esters.",
  },
  {
    productImg: productsImg.panolinDrum,
    productTitle: "Panolin HLP Synth 68",
    productPara:
      "Panolin HLP Synth is a hydraulic fluid, rapidly biodegradable with saturated synthetic esters.",
  },
  {
    productImg: productsImg.panolinKeg,
    productTitle: "Panolin HLP Synth E15",
    productPara:
      "Panolin HLP Synth E is a biodegradable hydraulic fluid with saturated synthetic esters.",
  },
  {
    productImg: productsImg.panolinKeg,
    productTitle: "Panolin HLP Synth E 22",
    productPara:
      "Panolin HLP Synth E is a biodegradable hydraulic fluid with saturated synthetic esters.",
  },
  {
    productImg: productsImg.panolinKeg,
    productTitle: "Panolin HLP Synth E 32",
    productPara:
      "Panolin HLP Synth E is a biodegradable hydraulic fluid with saturated synthetic esters.",
  },
  {
    productImg: productsImg.panolinKeg,
    productTitle: "Panolin HLP Synth E 46",
    productPara:
      "Panolin HLP Synth E is a biodegradable hydraulic fluid with saturated synthetic esters for hydraulic systems in the forestry, public works, industrial machinery, construction and hydroelectric engineering sectors, with European Ecolabel certificate.",
  },
  {
    productImg: productsImg.panolinKeg,
    productTitle: "Panolin HLP Synth E 68",
    productPara:
      "Panolin HLP Synth E is a biodegradable hydraulic fluid with saturated synthetic esters.",
  },
  {
    productImg: productsImg.panolinDrum,
    productTitle: "DRUMPanolin Margear 100",
    productPara:
      "Panolin Margear is an environmentally friendly, readily biodegradable gear oil that has obtained the European Ecolabel certification and therefore complies with the US EPA VIDA (Vessel Incidental Discharge Act) standard.",
  },
  {
    productImg: productsImg.panolinDrum,
    productTitle: "Panolin Margear 150",
    productPara:
      "Panolin Margear is an environmentally friendly, readily biodegradable gear oil that has obtained the European Ecolabel certification and therefore complies with the US EPA VIDA (Vessel Incidental Discharge Act) standard.",
  },
  {
    productImg: productsImg.panolinDrum,
    productTitle: "Panolin Margear 220",
    productPara:
      "Panolin Margear is an environmentally friendly, readily biodegradable gear oil.",
  },
  {
    productImg: productsImg.panolinDrum,
    productTitle: "Panolin Margear 320",
    productPara:
      "Panolin Margear is an environmentally friendly, readily biodegradable gear oil.",
  },
  {
    productImg: productsImg.panolinDrum,
    productTitle: "Panolin Margear 460",
    productPara:
      "Panolin Margear is an environmentally friendly, readily biodegradable gear oil.",
  },
  {
    productImg: productsImg.panolinDrum,
    productTitle: "Panolin Margear 68",
    productPara:
      "Panolin Margear is an environmentally friendly, readily biodegradable gear oil that has obtained the European Ecolabel certification and therefore complies with the US EPA VIDA (Vessel Incidental Discharge Act) standard.",
  },
  {
    productImg: productsImg.panolinDrum,
    productTitle: "Panolin Margrease EP 2",
    productPara:
      "Panolin Margrease EP 0 is an extreme pressure biolubricating grease, 100% synthetic and easily biodegradable.",
  },
  {
    productImg: productsImg.panolinDrum,
    productTitle: "Panolin Margrease W EP 0",
    productPara:
      "Panolin Margrease W EP 0 is a fully synthetic and rapidly biodegradable EP fluid grease for automatic greasing systems with EU Ecolabel European certification.",
  },
  {
    productImg: productsImg.panolinDrum,
    productTitle: "Panolin Marhyd 15",
    productPara:
      "Panolin Marhyd is an environmentally friendly biohydraulic fluid with saturated synthetic esters.",
  },
  {
    productImg: productsImg.panolinDrum,
    productTitle: "Panolin Marhyd 32",
    productPara:
      "Panolin Marhyd is an environmentally friendly biohydraulic fluid with saturated synthetic esters.",
  },
  {
    productImg: productsImg.panolinDrum,
    productTitle: "Panolin Marhyd 46",
    productPara:
      "Panolin Marhyd is an environmentally friendly biohydraulic fluid with saturated synthetic esters.",
  },
  {
    productImg: productsImg.panolinDrum,
    productTitle: "Panolin Marhyd 68",
    productPara:
      "Panolin Marhyd is an environmentally friendly biohydraulic fluid with saturated synthetic esters.",
  },
  {
    productImg: productsImg.panolinDrum,
    productTitle: "Panolin Polar Synth 30",
    productPara:
      "Panolin Polar Synth 30 is an environmentally friendly, zinc-free, fully synthetic hydraulic fluid based on saturated esters.",
  },
  {
    productImg: productsImg.panolinDrum,
    productTitle: "Panolin Sprint",
    productPara:
      "Panolin Sprint is a readily biodegradable, synthetic, hydraulic fluid for earthmoving and forestry hydraulic systems, for the machinery industry for industrial manufacturing, construction and hydroelectric engineering, with European Ecolabel certification.",
  },
  {
    productImg: productsImg.panolinDrum,
    productTitle: "Panolin Stella Maris 100",
    productPara:
      "Panolin Stella Maris is a biodegradable oil for the horn, totally synthetic and respectful with the environment, minimal toxicity and with European Ecolabel certification.",
  },
  {
    productImg: productsImg.panolinDrum,
    productTitle: "Panolin Stella Maris 150",
    productPara:
      "Panolin Stella Maris is a biodegradable oil for the horn, totally synthetic and respectful with the environment, minimal toxicity and with European Ecolabel certification.",
  },
  {
    productImg: productsImg.panolinDrum,
    productTitle: "Panolin Stella Maris 220",
    productPara:
      "Panolin Stella Maris is a biodegradable oil for the horn, totally synthetic and respectful with the environment, minimal toxicity and with European Ecolabel certification.",
  },
  {
    productImg: productsImg.panolinDrum,
    productTitle: "Panolin Stella Maris 320",
    productPara: "Panolin Stella Maris is a biodegradable oil for the horn",
  },
  {
    productImg: productsImg.panolinDrum,
    productTitle: "Panolin Stella Maris 68",
    productPara:
      "Panolin Stella Maris is a biodegradable oil for the horn, totally synthetic and respectful with the environment, minimal toxicity and with European Ecolabel certification.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Biovesta 22",
    productPara:
      "Quaker Houghton Biovesta 22 is a biodegradable hydraulic fluid, for applications in power transmission systems and in the lubrication of mechanisms with moderate service, in those points that must be respectful with the environment such as agricultural and forestry machinery, water treatment , power plants, etc.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Biovesta 46",
    productPara:
      "Quaker Houghton Biovesta 46 is a biodegradable hydraulic fluid, for applications in power transmission systems in those points that must be respectful with the environment such as agricultural and forestry machinery, public works, water treatment, power plants, etc. It is also recommended for general greasing applications where a biodegradable fluid at this viscosity is required.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Biovesta 60",
    productPara:
      "Quaker Houghton Biovesta 60 is a biodegradable hydraulic fluid, for applications in power transmission systems in those points that must be respectful with the environment such as agricultural and forestry machinery, public works, water treatment, power plants, etc. It is also recommended for general greasing applications where a biodegradable fluid at this viscosity is required.",
  },
];

export const energyLubricants = [
  {
    productImg: productsImg.shellGas,
    productTitle: "Shell Omala S4 GX 150",
    productPara:
      "Shell Omala S4 GX (formerly Shell Omala HD) is an advanced synthetic industrial gear lubricant with exceptional lubrication performance under demanding operating conditions, including reduced friction, long service life and high resistance to corrosion micro-pitting, for optimal protection of machinery.",
  },
  {
    productImg: productsImg.shellGas,
    productTitle: "Shell Omala S4 GX 220",
    productPara:
      "Shell Omala S4 GX (formerly Shell Omala HD) is an advanced synthetic industrial gear lubricant with exceptional lubrication performance under demanding operating conditions, including reduced friction, long service life and high resistance to corrosion micro-pitting, for o",
  },
  {
    productImg: productsImg.shellGas,
    productTitle: "Shell Omala S4 GX 460",
    productPara:
      "Shell Omala S4 GX (Shell Omala HD) is an advanced synthetic industrial gear lubricant with exceptional lubrication performance under demanding operating conditions, including reduced friction, long service life and high resistance to corrosion micro-pitting, for a optimal protection of machinery.",
  },
  {
    productImg: productsImg.shellOmala,
    productTitle: "Shell Omala S4 WE 150",
    productPara:
      "Shell Omala S4 WE (formerly Shell Tivela S) is an advanced synthetic gear lubricant for industrial worm drives, based on polyalkylene glycol fluids and specially selected additives. With exceptional lubrication performance under demanding operating conditions, including improved energy efficiency, long service life and high resistance to corrosion micro-pitting.",
  },
  {
    productImg: productsImg.shellOmala,
    productTitle: "Shell Omala S4 WE 320",
    productPara:
      "Shell Omala S4 WE (Formerly Shell Tivela S) is an advanced synthetic gear lubricant for industrial worm drives, based on polyalkylene glycol fluids and specially selected additives. With exceptional lubrication performance under demanding operating conditions, including improved energy efficiency, long service life and high resistance to corrosion micro-pitting.",
  },
  {
    productImg: productsImg.shellOmala,
    productTitle: "Shell Omala S4 WE 460",
    productPara:
      "Shell Omala S4 WE (formerly Shell Tivela S) is an advanced synthetic gear lubricant for industrial worm drives, based on polyalkylene glycol fluids and specially selected additives. With exceptional lubrication performance under demanding operating conditions, including improved energy efficiency, long service life and high resistance to corrosion micro-pitting.",
  },
  {
    productImg: productsImg.shellOmala,
    productTitle: "Shell Omala S4 WE 680",
    productPara:
      "Shell Omala S4 WE (formerly Shell Tivela S) is an advanced synthetic gear lubricant for industrial worm drives, based on polyalkylene glycol fluids and specially selected additives. With exceptional lubrication performance under demanding operating conditions, including improved energy efficiency, long service life and high resistance to corrosion micro-pitting.",
  },
];

export const industryLubricants = [
  {
    productLink: "/energy/efficient",
    productTitle: "CASTROL",
    productPara:
      "Castrol Limited is a British oil company that markets industrial and automotive lubricants, offering a wide range of oil, greases and similar products for most lubrication applications. ",
    btnTag: "View Products",
  },
  {
    productLink: "/food/industry",
    productTitle: "HYUNDAI",
    productPara:
      "Hyundai Oilbank has always worked for the stable supply of petroleum goods and the development of Korean Industry since its establishment in 1964",
    btnTag: "View Products",
  },
  {
    productLink: "/transportation/lubricant",
    productTitle: "PETRONAS",
    productPara:
      "PETRONAS Lubricants International, founded in 2008, produces and markets a full range of high-quality automotive and industrial lubricants on the markets of more than 80 countries. The main office of the company is located in Kuala Lumpur (Malaysia).",
    btnTag: "View Products",
  },

  {
    productLink: "/bio/lubricants",
    productTitle: "TOTAL",
    productPara:
      "TotalEnergies offers a wide range of lubricants (Engine Oils) developed in partnership with major manufacturers like Renault and Citroen and used in world-class competitions such as the FIA World Rallycross Championship and Dakar Rally. Our popular range of automotive lubricants include Quartz, Rubia, and Hi-Perf.",
    btnTag: "View Products",
  },
  // {
  //   productLink: "/metal/lubricants",
  //   productTitle: "Lubricants for metal",
  //   productPara:
  //     "Innovative, sustainable and that minimize occupational risks.",
  //   btnTag: "METAL",
  // },
  // {
  //   productLink: "/energy/efficient",
  //   productTitle: "energy efficient lubricants",
  //   productPara:
  //     "High performance synthetic lubricants in industrial applications aimed at reducing energy costs.",
  //   btnTag: "ENERGY EFFICIENCY",
  // },
  {
    productLink: "/lubricant/special",
    productTitle: "MOBIL",
    productPara:
      "Mobil is a petroleum brand owned and operated by American oil and gas corporation ExxonMobil. The brand was formerly owned and operated by an oil and gas corporation of the same name, which itself merged with Exxon to form ExxonMobil in 1999.",
    btnTag: "View Products",
  },
  // {
  //   productLink: "/marine/aviation_lubricant",
  //   productTitle: "marine lubricants",
  //   productPara:
  //     "Specific oils and greases for ships and yachts. Biodegradable lubricants that comply with international regulations.",
  //   btnTag: "MARINE",
  // },
];

export const foodLubricants = [
  {
    productImg: productsImg.cassidaOil,
    productTitle: "FC Cassida Chain Oil 150",
    productPara:
      "Cassida Chain Oil 150 is a synthetic lubricant for chains in food and beverage processing facilities.",
  },
  {
    productImg: productsImg.cassidaOil,
    productTitle: "FC Cassida Chain Oil 1500",
    productPara:
      "Cassida Chain Oil 1500 is a multifunctional, fully synthetic, very high performance anti-wear lubricant, specially designed for the lubrication of chains in hauling and transport lines.",
  },
  {
    productImg: productsImg.cassidaSpray,
    productTitle: "FC Cassida Chain Oil 1500 Spray",
    productPara:
      "Cassida Chain Oil 1500 Spray is a synthetic lubricant in spray form for chains in food and beverage processing facilities.",
  },
  {
    productImg: productsImg.cassidaSpray,
    productTitle: "FC Cassida Chain Oil 320 Spray",
    productPara:
      "Cassida Chain Oil 320 Spray is a synthetic lubricant in spray form for chains in food and beverage processing facilities.",
  },
  {
    productImg: productsImg.cassidaAlt,
    productTitle: "FC Cassida Chain Oil HT 220",
    productPara:
      "Cassida Chain Oil HT 220 is a high temperature synthetic chain lubricant for food and beverage processing equipment.",
  },
  {
    productImg: productsImg.cassidaAlt,
    productTitle: "FC Cassida Chain Oil HTE",
    productPara:
      "Cassida Chain Oil HTE is a synthetic lubricant for high temperature chains in food and beverage processing facilities.",
  },
  {
    productImg: productsImg.cassidaOil,
    productTitle: "FC Cassida Chain Oil HTX",
    productPara:
      "Cassida Chain Oil HTX is a synthetic lubricant for chains driven at extreme temperatures in the food industry.",
  },
  {
    productImg: productsImg.cassidaOil,
    productTitle: "FC Cassida Chain Oil LT",
    productPara:
      "Cassida Chain Oil LT is a synthetic lubricant for chains driven at low temperatures in food and beverage processing facilities.",
  },
  {
    productImg: productsImg.cassidaAlt,
    productTitle: "FC Cassida Chain Oil LT Spray",
    productPara:
      "Cassida Chain Oil LT Spray is a fully synthetic, high-performance lubricant, specially designed for the lubrication of transport mechanisms in food freezing tunnels.",
  },
  {
    productImg: productsImg.cassidaAlt,
    productTitle: "FC Cassida Chain Oil SP",
    productPara:
      "Cassida Chain Oil SP is a specialized synthetic lubricant for chains in food and beverage processing facilities. FC CASSIDA CHAIN ​​OIL SP is a fully synthetic anti-wear lubricant with very high performance, with excellent flow and sliding properties to facilitate penetration, specially designed for the lubrication of chains in drag and transport lines. It is based on a careful blend of synthetic fluids and additives selected for their ability to meet the stringent requirements of the food industry.",
  },
  {
    productImg: productsImg.cassidaAlt,
    productTitle: "FC Cassida Chain Oil XTE",
    productPara:
      "Cassida Chain Oil XTE is a high temperature synthetic chain lubricant with exceptional thermal stability, designed for the food industry to lubricate drive and conveying chains at elevated temperatures.",
  },
  {
    productImg: productsImg.cassidaSpray,
    productTitle: "FC Cassida Coolant",
    productPara:
      "Cassida Coolant is a propylene glycol based fluid containing corrosion inhibitor additives, for use in closed cooling or cooling systems in the food industry.",
  },
  {
    productImg: productsImg.cassidaOil,
    productTitle: "FC Cassida Fluid CR 100",
    productPara:
      "Cassida Fluid CR are synthetic lubricants for air compressors in food and beverage processing facilities.",
  },
  {
    productImg: productsImg.cassidaOil,
    productTitle: "FC Cassida Fluid CR 150",
    productPara:
      "Cassida Chain Oil LT is a synthetic lubricant for chains driven at low temperatures in food and beverage processing facilities.",
  },
  {
    productImg: productsImg.cassidaOil,
    productTitle: "FC Cassida Fluid CR 46",
    productPara:
      "Cassida Fluid CR are synthetic lubricants for air compressors in food and beverage processing facilities.",
  },
  {
    productImg: productsImg.cassidaOil,
    productTitle: "FC Cassida Fluid CR 68",
    productPara:
      "Cassida Fluid CR are synthetic lubricants for air compressors in food and beverage processing facilities.",
  },
  {
    productImg: productsImg.cassidaOil,
    productTitle: "FC Cassida Fluid DC 20",
    productPara:
      "Cassida Fluid DC 20 is a direct contact synthetic fluid for use in food and beverage processing facilities.",
  },
  {
    productImg: productsImg.cassidaOil,
    productTitle: "FC Cassida Fluid DC 32",
    productPara:
      "Cassida Fluid DC 32 is a multi-purpose fluid designed to meet a number of specialty applications in the food and pharmaceutical industries, including absorbent oil for edible oil solvent recovery systems, metal container molding, and loop heat transfer systems. closed not pressurized.",
  },
  {
    productImg: productsImg.cassidaAlt,
    productTitle: "FC Cassida Fluid DCE 15",
    productPara:
      "Cassida Fluid DCE 15 is a multifunctional synthetic direct contact fluid for applications in the production process in the food industry.",
  },
  {
    productImg: productsImg.cassidaOil,
    productTitle: "FC Cassida Fluid FL 5",
    productPara:
      "Cassida Fluid FL 5 is a synthetic penetrating and corrosion protecting fluid for use in food and beverage processing facilities.",
  },
  {
    productImg: productsImg.cassidaOil,
    productTitle: "FC Cassida Fluid GL 150",
    productPara:
      "Cassida Fluid GL are very high performance fluids, specially developed for the lubrication of reducers and other closed gear systems in the food industry.",
  },
  {
    productImg: productsImg.cassidaOil,
    productTitle: "FC Cassida Fluid GL 220",
    productPara:
      "Cassida Fluid GL are very high performance fluids, specially developed for the lubrication of reducers and other closed gear systems in the food industry.",
  },
  {
    productImg: productsImg.cassidaOil,
    productTitle: "FC Cassida Fluid GL 460",
    productPara:
      "Cassida Fluid GL are very high performance fluids, specially developed for the lubrication of reducers and other closed gear systems in the food industry.",
  },
  {
    productImg: productsImg.cassidaOil,
    productTitle: "FC Cassida Fluid GL 680",
    productPara:
      "Cassida Fluid FL 5 is a synthetic penetrating and corrosion protecting fluid for use in food and beverage processing facilities.",
  },
  {
    productImg: productsImg.cassidaOil,
    productTitle: "FC Cassida Fluid GLE 220",
    productPara:
      "Cassida Fluid GLE are synthetic lubricants specially designed for the lubrication of can seamers in food and beverage processing facilities.",
  },
  {
    productImg: productsImg.cassidaOil,
    productTitle: "FC Cassida Fluid HF 100",
    productPara:
      "Cassida Fluid HF are high-performance, multi-purpose lubricants with great anti-wear protection, developed for food and beverage processing facilities and the packaging industry.",
  },
  {
    productImg: productsImg.cassidaOil,
    productTitle: "FC Cassida Fluid HF 15",
    productPara:
      "Cassida Fluid HF are high-performance, multi-purpose lubricants with great anti-wear protection, developed for food and beverage processing facilities and the packaging industry.",
  },
  {
    productImg: productsImg.cassidaOil,
    productTitle: "FC Cassida Fluid HF 32",
    productPara:
      "Cassida Fluid HF are high-performance, multi-purpose lubricants with great anti-wear protection, developed for food and beverage processing facilities and the packaging industry.",
  },
  {
    productImg: productsImg.cassidaOil,
    productTitle: "FC Cassida Fluid HF 46",
    productPara:
      "Cassida Fluid HF are high-performance, multi-purpose lubricants with great anti-wear protection, developed for food and beverage processing facilities and the packaging industry.",
  },
  {
    productImg: productsImg.cassidaOil,
    productTitle: "FC Cassida Fluid HF 68",
    productPara:
      "Cassida Fluid HF are high-performance, multi-purpose lubricants with great anti-wear protection, developed for food and beverage processing facilities and the packaging industry.",
  },
  {
    productImg: productsImg.cassidaOil,
    productTitle: "FC Cassida Fluid RF 68",
    productPara:
      "Cassida Fluid FR are synthetic compressor lubricants used in ammonia refrigeration in the food industry.",
  },
  {
    productImg: productsImg.cassidaOil,
    productTitle: "FC Cassida Fluid VP 100",
    productPara:
      "Cassida Fluid VP is a fluid developed for use in vacuum pumps used in the processing and packaging of food and beverages.",
  },
  {
    productImg: productsImg.cassidaOil,
    productTitle: "FC Cassida Fluid VP 68",
    productPara:
      "Cassida Fluid VP is a fluid developed for use in vacuum pumps used in the processing and packaging of food and beverages.",
  },
  {
    productImg: productsImg.cassidaOil,
    productTitle: "FC Cassida Fluid WG 220",
    productPara:
      "Cassida Fluid WG are high performance synthetic lubricants for worm gears in food and beverage processing facilities.",
  },
  {
    productImg: productsImg.cassidaOil,
    productTitle: "FC Cassida Fluid WG 320",
    productPara:
      "Cassida Fluid WG are high performance synthetic lubricants for worm gears in food and beverage processing facilities.",
  },
  {
    productImg: productsImg.cassidaOil,
    productTitle: "FC Cassida Fluid WG 460",
    productPara:
      "Cassida Fluid WG are high performance synthetic lubricants for worm gears in food and beverage processing facilities.",
  },
  {
    productImg: productsImg.cassidaOil,
    productTitle: "FC Cassida FM Coolant",
    productPara:
      "Cassida FM Coolant is a concentrated coolant fluid and freeze point depressant, corrosion protector, for use in refrigeration systems of food and beverage processing facilities.",
  },
  {
    productImg: productsImg.cassidaOil,
    productTitle: "FC Cassida FM Fluid DC 20",
    productPara:
      "Cassida FM Fluid DC 20 is a direct contact fluid for use in food and beverage processing facilities.",
  },
  {
    productImg: productsImg.cassidaOil,
    productTitle: "FC Cassida FM Flushing Fluid 32",
    productPara:
      "Cassida FM Flushing Fluid 32 is a special concentrate for cleaning and flushing heat transfer systems in the food and beverage industry.",
  },
  {
    productImg: productsImg.cassidaAlt,
    productTitle: "FC Cassida FM Form Fluid DC 10",
    productPara:
      "Cassida FM Form Fluid DC 10 is a lubricating oil for forming, stamping, bending and drawing metal sheets for the manufacture of containers for food use. The fluid has been specially developed to be used as a forming oil for the manufacture of two and three-piece cans for food and beverages (stamping, drawing and bending). It can also be used to stamp and embed aluminum sheets for the manufacture of any type of packaging for food use.",
  },
  {
    productImg: productsImg.cassidaOil,
    productTitle: "FC Cassida FM Gear Oil 220",
    productPara:
      "Cassida FM Gear Oil are lubricants for gears in food and beverage processing facilities.",
  },
  {
    productImg: productsImg.cassidaOil,
    productTitle: "FC Cassida FM Grease DC 2",
    productPara:
      "Cassida FM Grease DC 2 is a multipurpose, direct contact grease for food and beverage processing facilities.",
  },
  {
    productImg: productsImg.cassidaOil,
    productTitle: "FC Cassida FM Grease EP 1.5",
    productPara:
      "Cassida FM Grease EP 1.5 is a specialized extreme pressure semi-synthetic grease for processing equipment in the food and beverage industry, developed for the lubrication and greasing of machinery in environments that require frequent changes and repeated regreasing.",
  },
  {
    productImg: productsImg.cassidaOil,
    productTitle: "FC Cassida FM Grease HD 1.5",
    productPara:
      "Cassida FM Grease HD 1.5 is a special semi-synthetic grease for extreme loads in food and beverage processing facilities.",
  },
  {
    productImg: productsImg.cassidaOil,
    productTitle: "FC Cassida FM Grease HD 2",
    productPara:
      "Cassida FM Grease HD 2 is a special semi-synthetic grease for extreme loads in food and beverage processing facilities.",
  },
  {
    productImg: productsImg.cassidaOil,
    productTitle: "FC Cassida FM Heat Transfer Fluid 32",
    productPara:
      "Cassida FM Heat Transfer Fluid 32 is a heat transfer fluid for use in the food industry.",
  },
  {
    productImg: productsImg.cassidaOil,
    productTitle: "FC Cassida FM Hydraulic Oil 32",
    productPara:
      "Cassida FM Hydraulic Oil are multifunctional anti-wear lubricants, specially developed for the lubrication of food and beverage processing and packaging facilities.",
  },
  {
    productImg: productsImg.cassidaOil,
    productTitle: "FC Cassida FM Hydraulic Oil 46",
    productPara:
      "Cassida FM Hydraulic Oil are multifunctional anti-wear lubricants, specially developed for the lubrication of food and beverage processing and packaging facilities.",
  },
  {
    productImg: productsImg.cassidaOil,
    productTitle: "FC Cassida FM Hydraulic Oil 68",
    productPara:
      "Cassida FM Hydraulic Oil are multifunctional anti-wear lubricants, specially developed for the lubrication of food and beverage processing and packaging facilities.",
  },
  {
    productImg: productsImg.cassidaOil,
    productTitle: "FC Cassida Grease Clear 2",
    productPara:
      "Cassida Grease Clear 2 is a specialty synthetic grease for food and beverage processing equipment.",
  },
  {
    productImg: productsImg.cassidaOil,
    productTitle: "FC Cassida Grease EPS 00",
    productPara:
      "Cassida Grease EPS are synthetic extreme pressure greases for food and beverage processing facilities.",
  },
  {
    productImg: productsImg.cassidaOil,
    productTitle: "FC Cassida Grease EPS 1",
    productPara:
      "Cassida Grease EPS are synthetic extreme pressure greases for food and beverage processing facilities.",
  },
  {
    productImg: productsImg.cassidaOil,
    productTitle: "FC Cassida Grease EPS 1 Flex 125",
    productPara:
      "Cassida Grease EPS 1 Flex 125 are extreme pressure synthetic greases for food and beverage processing facilities.",
  },
  {
    productImg: productsImg.cassidaOil,
    productTitle: "FC Cassida Grease EPS 2",
    productPara:
      "Cassida Grease EPS are synthetic extreme pressure greases for food and beverage processing facilities.",
  },
  {
    productImg: productsImg.cassidaOil,
    productTitle: "FC Cassida Grease EPS 2 Flex 125",
    productPara:
      "Cassida Grease EPS 2 Flex 125 are extreme pressure synthetic greases for food and beverage processing facilities.",
  },
  {
    productImg: productsImg.cassidaOil,
    productTitle: "FC Cassida Grease FC 2",
    productPara:
      "Cassida Grease FC 2 is a high temperature synthetic grease for extreme operating conditions.",
  },
  {
    productImg: productsImg.cassidaOil,
    productTitle: "FC Cassida Grease GTS 2",
    productPara:
      "Cassida Grease GTS 2 is a specialty synthetic extreme pressure grease for food and beverage processing facilities.",
  },
  {
    productImg: productsImg.cassidaOil,
    productTitle: "FC Cassida Grease GTS Spray",
    productPara:
      "Cassida Grease GTS Spray is a specialty synthetic extreme pressure grease in spray form for food and beverage processing facilities.",
  },

  {
    productImg: productsImg.cassidaOil,
    productTitle: "FC Cassida Grease GTX 2",
    productPara:
      "Cassida Grease GTX is a special grease for high loads in food and beverage processing facilities.",
  },
  {
    productImg: productsImg.cassidaOil,
    productTitle: "FC Cassida Grease HDS 2",
    productPara:
      "Cassida Grease HDS 2 are very high performance synthetic lubricants, specially designed for the lubrication of equipment subjected to extreme loads in the food industry.",
  },
  {
    productImg: productsImg.cassidaOil,
    productTitle: "FC Cassida Grease HTS 2",
    productPara:
      "Cassida Grease HTS 2 is a synthetic grease for high temperatures in food and beverage processing equipment.",
  },
  {
    productImg: productsImg.cassidaOil,
    productTitle: "FC Cassida Grease HTS 2 SR",
    productPara:
      "Cassida Grease HTS 2 SR is a synthetic grease for high temperatures in food and beverage processing equipment.",
  },
  {
    productImg: productsImg.cassidaAlt,
    productTitle: "FC Cassida Grease LTS 1",
    productPara:
      "Cassida Grease LTS 1 is a synthetic low temperature grease for food and beverage processing equipment.",
  },
  {
    productImg: productsImg.cassidaAlt,
    productTitle: "FC Cassida Grease MD 2",
    productPara:
      "Cassida Grease MD 2 is a silicone-based specialty grease for taps, valves and seals in food and beverage processing facilities.",
  },
  {
    productImg: productsImg.cassidaAlt,
    productTitle: "FC Cassida Grease MD 2 SR",
    productPara:
      "Cassida Grease MD 2 SR is a silicone-based specialty grease for taps, valves and seals in food and beverage processing facilities.",
  },
  {
    productImg: productsImg.cassidaOil,
    productTitle: "FC Cassida Grease GTS Spray",
    productPara:
      "Cassida Grease GTS Spray is a specialty synthetic extreme pressure grease in spray form for food and beverage processing facilities.",
  },
  {
    productImg: productsImg.cassidaOil,
    productTitle: "FC Cassida Grease RLS 00",
    productPara:
      "Cassida Grease RLS 00 are synthetic greases for regular loading in food and beverage processing facilities.",
  },
  {
    productImg: productsImg.cassidaOil,
    productTitle: "FC Cassida Grease RLS 000",
    productPara:
      "Cassida Grease RLS 000 is a semi-fluid synthetic grease for regular loading in food and beverage processing facilities.",
  },
  {
    productImg: productsImg.cassidaOil,
    productTitle: "FC Cassida Grease RLS 1",
    productPara:
      "Cassida Grease RLS 1 are synthetic greases for regular loading in food and beverage processing facilities.",
  },
  {
    productImg: productsImg.cassidaOil,
    productTitle: "FC Cassida Grease RLS 2",
    productPara:
      "Cassida Grease RLS 2 are synthetic greases for regular loading in food and beverage processing facilities.",
  },
  {
    productImg: productsImg.cassidaOil,
    productTitle: "FC Cassida Grease RLS 2 SR",
    productPara:
      "Cassida Grease RLS 2 SR are synthetic greases for regular loading in food and beverage processing facilities.",
  },
  {
    productImg: productsImg.cassidaAlt,
    productTitle: "FC Cassida Paste AP",
    productPara:
      "Cassida Paste AP is a synthetic assembly paste for food and beverage processing facilities.",
  },
  {
    productImg: productsImg.cassidaDry,
    productTitle: "FC Cassida PTFE DRY",
    productPara:
      "Cassida PTFE DRY Spray is a synthetic lubricant developed to cover multiple applications in food and beverage processing facilities.",
  },
  {
    productImg: productsImg.cassidaOil,
    productTitle: "FC Cassida Silicone Fluid",
    productPara:
      "Cassida Paste AP is a synthetic assembly paste for food and beverage processing facilities.",
  },
  {
    productImg: productsImg.cassidaAlt,
    productTitle: "FC Cassida Silicone Fluid Spray",
    productPara:
      "Cassida Silicone Fluid is a multifunctional silicone fluid, in spray format, for use in food and beverage processing facilities.",
  },
  {
    productImg: productsImg.ibiotecAirline,
    productTitle: "Ibiotec Airline AL 110",
    productPara:
      "Ibiotec Airline AL 110 is a lubricant for compressed air and pneumatic tools.",
  },
  {
    productImg: productsImg.ibiotecImpact,
    productTitle: "Ibiotec Impact",
    productPara:
      "Ibiotec Impact is a 100% vegetable penetrating fluid aerosol, unclog, decididant, lubricant, non-stick, release agent, renovator, polish.",
  },
  {
    productImg: productsImg.ibiotecNeolube,
    productTitle: "Ibiotec Neolube AL 150",
    productPara:
      "Ibiotec Neolube AL 150 is an adhesive codex grease, usable in mechanical components at low speed. NSF H1 certificate for agri-food industries, and codex quality for pharmaceutical industries.",
  },
  {
    productImg: productsImg.ibiotecNeolube160,
    productTitle: "Ibiotec Neolube AL 160",
    productPara:
      "Ibiotec Neolube AL 160 is a white grease with a high Teflon content, with NSF H1 certificate for agri-food industries.",
  },
  {
    productImg: productsImg.ibiotecNeolube500,
    productTitle: "Ibiotec Neolube AL 500",
    productPara:
      "Ibiotec Neolube AL 500 is a grease that has been developed to respond to extreme load situations in the food industry, such as freezing or deep-freezing.",
  },
  {
    productImg: productsImg.ibiotecNeolube220,
    productTitle: "Ibiotec Neolube ALSI 220",
    productPara:
      "Ibiotec Neolube Alsi 220 is a 100% silicone grease, with NSF H1 certificate for food contact, authorized for drinking water contact, especially for taps.",
  },
  {
    productImg: productsImg.ibiotecNeolube20,
    productTitle: "Ibiotec Neolube CT 20 AL",
    productPara:
      "Ibiotec Neolube CT 20 AL is a penetrating adhesive lubricant recommended for the lubrication of all types of chains, with anti-wear properties and total resistance to water and steam, with NSF –H1 certification.",
  },
  {
    productImg: productsImg.ibiotecNeolube350,
    productTitle: "Ibiotec Neolube F 350",
    productPara:
      "Ibiotec Neolube F 350 is a precision lubricating oil, with NSF H1 certificate for agri-food industries, and with codex quality for pharmaceutical industries.",
  },
  {
    productImg: productsImg.ibiotecNeolube2272,
    productTitle: "Ibiotec Neolube SIL 2272",
    productPara:
      "Ibiotec Neolube SIL 2272 is a multifunction liquid silicone fluid, it is a versatile product intended for difficult demoulding operations and lubrication problems in a clean environment.",
  },
  {
    productImg: productsImg.ibiotecNeolubeAlt,
    productTitle: "Ibiotec Neolube Sol 4 Plus",
    productPara:
      "Ibiotec Neolube Sol 4 Plus is a sugar dissolving, lubricating, cleaning, non-stick agent for preventive and curative treatment.",
  },
  {
    productImg: productsImg.ibiotecNeolubeAlt,
    productTitle: "Ibiotec Neutralene SL 30",
    productPara:
      "Ibiotec Neutralene SL 30 is a multifunction cellulosic diluent that replaces xylene-mec mixtures, it is an azeotropic technical fluid that advantageously replaces aromatic-ketone mixtures generally used as detoxifying agents in terms of toxicity, safety and respect for the environment. cleaning and dilution for cellulosic paints and inks.",
  },

  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Compguard FG 100",
    productPara:
      "Lubcon Compguard FG 100 is a universal compressor oil for high and low temperatures and extreme operating conditions. Compared to conventional mineral-based products, this oil is distinguished by its wide temperature range and high oxidation resistance.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Compguard FG 46",
    productPara:
      "Lubcon Compguard FG 46 is a universal compressor oil for high and low temperatures and extreme operating conditions. Compared to conventional mineral-based products, this oil is distinguished by its wide temperature range and high oxidation resistance.  ",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Compguard FG 68",
    productPara:
      "Lubcon Compguard FG 68 is a universal compressor oil for high and low temperatures and extreme operating conditions. Compared to conventional mineral-based products, this oil is distinguished by its wide temperature range and high oxidation resistance.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Elefanten Milch",
    productPara:
      "Lubcon Elefanten Milch is a special lubricant for the candy industry. It is a special lubricant, ready for use, that dissolves sugar and is preferably applied in the sweets, confectionery, ice cream, chocolate industry, etc. ",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmocomp HD 68",
    productPara:
      "Lubcon Turmocomp HD 68 is a lubricating compound consisting of an ultra-high molecular weight polymer and a synthetic base oil. The compound has a solid structure that completely fills the bearing and releases the oil contained in it as needed.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmocut LMI 18",
    productPara:
      "Lubcon Turmocut LMI 18 is a special oil for cutting and protection, developed for the lubrication of transversal and longitudinal cutting systems, in addition to feeding the Disc Cut system, in the corrugated cardboard industry.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmocut P 68",
    productPara:
      "Lubcon Compguard FG 46 is a universal compressor oil for high and low temperatures and extreme operating conditions. Compared to conventional mineral-based products, this oil is distinguished by its wide temperature range and high oxidation resistance.  ",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmocut SI 22",
    productPara: " ",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmocut SI 22",
    productPara:
      "LUBCON TURMOCUT SI 22 is a heat stabilized silicone oil for the lubrication of Disc-Cut systems in the paper industry.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmofluid LMI 300",
    productPara:
      "Lubcon Turmofluid LMI 300 is a synthetic special oil with excellent penetrating and lubricating properties. An effectively combined formulation of the base oil and additives provide optimum protection against wear and corrosion.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmogearoil PE 100",
    productPara: " ",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmopolgrease FDA 2 MD",
    productPara:
      "LUBCON TURMOPOLGREASE FDA 2 MD is a sealing and assembly grease with long-lasting protection against moisture.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmosynth 2000",
    productPara:
      "Lubcon Turmosynth 2000 is a physiologically safe, tasteless and odorless grease indicated for the food and beverage industry, approved by NSF-H1.",
  },

  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmosynth 2000 2",
    productPara:
      "Lubcon Turmosynth 2000 2 is a strong, good adhesive specialty lubricant for the food and beverage industry. NSF H1 Certified Registered as a lubricant for which incidental food contact technically cannot be avoided.",
  },

  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmosynth TAS WHITE",
    productPara:
      "Lubcon Turmosynth TAS White is a white assembly and lubrication paste containing a new type of solid lubricants against friction and fretting corrosion. It is INS H1 certified.",
  },

  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmosynth VG 220",
    productPara:
      "Lubcon Turmosynth VG 220 is a white oil-based lubricating fluid used in the food industry that is physiologically safe. NSF/H1 approved.",
  },

  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmosynthgrease AL 2500",
    productPara:
      "Lubcon Turmosynthgrease AL 2500 is a transparent amber special grease, it is universal for all applications in the bottling and food industry, USDA-H1 approved",
  },

  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmosynthgrease AL 2501",
    productPara:
      "Lubcon Turmosynthgrease AL 2501 is a light-coloured special grease for the food industry. NSF / H1 approved",
  },

  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmosynthgrease AL 2502",
    productPara:
      "Lubcon Turmosynthgrease Al 2502 is a light-coloured special grease for the food industry. NSF/H1 approved.",
  },

  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmosynthgrease AL 2503",
    productPara:
      "Lubcon Turmosynthgrease ALN 2503 is a NSF-H1 approved high temperature special grease based on synthetic hydrocarbons and with a high metal affinity thickener. It has the NSF-H1 registration.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmosynthgrease ALN 1001/00",
    productPara:
      "Lubcon Turmosynthgrease ALN 1001/00 is a synthetic, fluid grease, especially for high temperatures, with a synthetic hydrocarbon base oil and a thickener with high metal affinity. It has the NSF-H1 registration (num.130466)",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmosynthgrease ALN 1002",
    productPara:
      "Lubcon Turmosynthgrease ALN 1002 is a special high-temperature synthetic grease. It is composed of synthetic hydrocarbons and a special substance with a high metallic affinity. It has NSF-H1 approval for lubricants that may come into occasional contact with food.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmosynthgrease ALN 2502",
    productPara:
      "Lubcon Turmosynthgrease ALN 2502 is a special high-temperature synthetic grease, based on synthetic hydrocarbons with a special semi-synthetic thickener with high metal affinity. NSF-H1 approved.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmosynthgrease ALN 2502 PM",
    productPara:
      "Lubcon Turmosynthgrease ALN 2502 PM is a special synthetic grease for high temperatures, it is composed of synthetic hydrocarbons and a special thickener with high metal affinity. It is INS-H1 approved.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmosynthgrease ALN 502 WHITE",
    productPara:
      "Lubcon Turmosynthgrease ALN 502 White is a high temperature synthetic special grease based on synthetic hydrocarbons with a high affinity partial thickener for metals and white solids. It has the NSF-H1 registration.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmosynthgrease LMI 2",
    productPara:
      "Lubcon Turmosynthgrease LMI 2 is an extremely pure white Vaseline ointment-like grease. It meets USDA requirements and is NSF-H1 certified, has excellent lubricating properties, and is resistant to oxidation and steam.",
  },

  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmosynthgrease LT 300",
    productPara:
      "Lubcon Turmosynthgrease LT 300 is a low temperature lubricating grease based on synthetic hydrocarbons with a special thickener. It has the registration INS-H1.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmosynthoil 75 TF",
    productPara:
      "Lubcon Turmosynthoil 75 TF is a synthetic oil with solid lubricating and sticky additives, with excellent wetting properties and protects against oxidation and corrosion.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmosynthoil GV 150",
    productPara:
      "Lubcon Turmosynthoil GV is a fully synthetic oil with NSF-H1 certification, for universal lubrication in extreme working conditions at high and low temperatures, long service life, neutral towards elastomers and plastics.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmosynthoil GV 460 TF",
    productPara:
      "Lubcon Turmosynthoil GV 460 TF is a lightly colored synthetic base oil with thermally stable oxidation inhibitors, special EP additives and solid lubricants. Produces an excellent adhesive lubricating film that protects against wear at temperatures above 240ºC.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmosynthoil GV 68",
    productPara:
      "Lubcon Turmosynthoil GV is a fully synthetic oil with NSF-H1 certification, for universal lubrication in extreme working conditions at high and low temperatures, long service life, neutral towards elastomers and plastics.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmosynthoil GV 680",
    productPara:
      "Lubcon Turmosynthoil GV is a fully synthetic oil with NSF-H1 certification, for universal lubrication in extreme working conditions at high and low temperatures, long service life, neutral towards elastomers and plastics.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmosynthoil HTC 150",
    productPara:
      "Lubcon turmosynthoil HTC 150 is a synthetic chain oil subjected to high temperatures and with special EP additives. It has been verified at working temperatures up to 250ºC, it contains special additives to avoid the formation of residues.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmosynthoil HTC 220",
    productPara:
      "Lubcon turmosynthoil HTC 220 is a high-temperature synthetic chain oil with special EP additives for use in the food industry. It also contains special additives to prevent waste. It has INS/H1 approval for lubricants that may come into occasional contact with food.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmosynthoil HTC 270",
    productPara:
      "Lubcon turmosynthoil HTC 270 is a high-temperature synthetic chain oil with special EP additives for use in the food industry. It also contains special additives to prevent waste. It has INS/H1 approval for lubricants that may come into occasional contact with food.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmosynthoil HTC 320",
    productPara:
      "Lubcon Turmosynthoil HTC 320 is a high temperature synthetic chain oil with EP additive specifically for the food industry.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmosynthoil PG 150",
    productPara:
      "Lubcon Turmosynthoil PG 150 is a high temperature synthetic oil for chains and gears in the food industry. NSF/H1 approved.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmosynthoil PG 220",
    productPara:
      "Lubcon Turmosynthoil PG 220 is a high temperature synthetic oil for the lubrication of chains and gears in the food industry.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmsilon GL 320-G",
    productPara:
      "Lubcon Turmsilon GL 320-G is a silicone grease for the lubrication of hot and cold water fittings, especially potable water fittings.",
  },
  {
    productImg: productsImg.lubriscoFood,
    productTitle: "Lubriso Food Oil CC 100",
    productPara:
      "Lubriso Food Oil CC is a non-toxic, semi-synthetic multifunctional lubricant formulated with medicinal white oils, polyisobutenes and additives approved by the FDA, considered physiologically harmless and classified by NSF as H1.",
  },
  {
    productImg: productsImg.lubriscoFood,
    productTitle: "Lubriso Food Oil HM 32",
    productPara:
      "Lubriso Food Oil HM is a non-toxic semi-synthetic lubricant, formulated from FDA-approved medicinal white oils, polyisobutenes and additives, with NSF H1 certification.",
  },
  {
    productImg: productsImg.lubriscoFood,
    productTitle: "Lubriso Food Oil HM 46",
    productPara:
      "Lubriso Food Oil HM is a non-toxic semi-synthetic lubricant, formulated from FDA-approved medicinal white oils, polyisobutenes and additives, with NSF H1 certification.",
  },
  {
    productImg: productsImg.lubriscoFood,
    productTitle: "Lubriso Food Oil HM 68",
    productPara:
      "Lubriso Food Oil HM is a non-toxic semi-synthetic lubricant, formulated from FDA-approved medicinal white oils, polyisobutenes and additives, with NSF H1 certification.",
  },
  {
    productImg: productsImg.lubriscoFood,
    productTitle: "Lubriso Food VAP 30",
    productPara:
      "Lubriso Food VAP 30 is an evaporable stamping oil formulated from easily evaporable isoparaffinic hydrocarbons, added with lubricity, anti-wear and anti-welding agents, which comply with current FDA and European Pharmacopoeia regulations, making it a recommended product in the food industry.",
  },
  {
    productImg: productsImg.lubriscoFood,
    productTitle: "Lubriso Grease Food 2",
    productPara:
      "Lubriso Grease Food 2 is a non-toxic universal grease especially for food machinery, it provides a much higher performance than any conventional multipurpose grease.",
  },
  {
    productImg: productsImg.panolinKeg,
    productTitle: "Panolin Orcon Arctic 220",
    productPara:
      "Panolin Orcon Arctic 220 is a fully synthetic, NSF H1 certified compressor refrigeration oil with excellent cold start characteristics for the food, pharmaceutical and animal feed industries.",
  },
  {
    productImg: productsImg.panolinKeg,
    productTitle: "Panolin Orcon Arctic 68",
    productPara:
      "Panolin Orcon Arctic 68 is a fully synthetic, NSF H1 certified compressor refrigeration oil with excellent cold start characteristics for the food, pharmaceutical and animal feed industries.",
  },
  {
    productImg: productsImg.panolinKeg,
    productTitle: "Panolin Orcon Chain 100",
    productPara:
      "Panolin Orcon Chain 100 is a fully synthetic NSF chain lubricant for the food and pharmaceutical industries as well as the animal feed industry.    ",
  },
  {
    productImg: productsImg.panolinKeg,
    productTitle: "Panolin Orcon Chain 15",
    productPara:
      "Panolin Orcon Chain 15 is a fully synthetic NSF chain lubricant for the food and pharmaceutical industries as well as the animal feed industry.    ",
  },
  {
    productImg: productsImg.panolinKeg,
    productTitle: "Panolin Orcon Chain 150",
    productPara:
      "Panolin Orcon Chain 150 is a fully synthetic NSF chain lubricant for the food and pharmaceutical industries as well as the animal feed industry.    ",
  },
  {
    productImg: productsImg.panolinKeg,
    productTitle: "Panolin Orcon Chain 5",
    productPara:
      "Panolin Orcon Chain 5 is a fully synthetic NSF chain lubricant for the food and pharmaceutical industry as well as the animal feed industry.",
  },
  {
    productImg: productsImg.panolinKeg,
    productTitle: "Panolin Orcon Chain 68",
    productPara:
      "Panolin Orcon Chain 68 is a fully synthetic NSF chain lubricant for the food and pharmaceutical industries as well as the animal feed industry.",
  },
  {
    productImg: productsImg.panolinKeg,
    productTitle: "Panolin Orcon Comp LV 100",
    productPara:
      "Panolin Orcon Comp LV 100 is a fully synthetic NSF H1 certified compressor oil with very low evaporative loss, specially developed for the food, pharmaceutical and animal feed industries.",
  },
  {
    productImg: productsImg.panolinKeg,
    productTitle: "Panolin Orcon Comp LV 150",
    productPara:
      "Panolin Orcon Comp LV 150 is a fully synthetic NSF H1 certified compressor oil with very low evaporative loss, specially developed for the food, pharmaceutical and animal feed industries.",
  },
  {
    productImg: productsImg.panolinKeg,
    productTitle: "Panolin Orcon Comp LV 32",
    productPara:
      "Panolin Orcon Comp LV 32 is a fully synthetic NSF H1 certified compressor oil with very low evaporative loss, specially developed for the food, pharmaceutical and animal feed industries.",
  },
  {
    productImg: productsImg.panolinKeg,
    productTitle: "Panolin Orcon Comp LV 46",
    productPara:
      "Panolin Orcon Comp LV 46 is a fully synthetic NSF H1 certified compressor oil with very low evaporative loss, specially developed for the food, pharmaceutical and animal feed industries.",
  },
  {
    productImg: productsImg.panolinKeg,
    productTitle: "Panolin Orcon Comp LV 68",
    productPara:
      "Panolin Orcon Comp LV 68 is a fully synthetic NSF H1 certified compressor oil with very low evaporative loss, specially developed for the food, pharmaceutical and animal feed industries.",
  },
  {
    productImg: productsImg.panolinKeg,
    productTitle: "Panolin Orcon Gear 150",
    productPara:
      "Panolin Orcon Gear 150 is an extremely high-performance, long-term, fully synthetic NSF gear oil for the food and pharmaceutical industries as well as the animal feed industry.",
  },
  {
    productImg: productsImg.panolinKeg,
    productTitle: "Panolin Orcon Gear 220",
    productPara:
      "Panolin Orcon Gear 220 is an ultra-high performance, long-term, fully synthetic NSF gear oil for the food and pharmaceutical industries as well as the animal feed industry.",
  },
  {
    productImg: productsImg.panolinKeg,
    productTitle: "Panolin Orcon Gear 320",
    productPara:
      "Panolin Orcon Gear 320 is an ultra-high performance, long-term, fully synthetic NSF gear oil for the food and pharmaceutical industries as well as the animal feed industry.",
  },
  {
    productImg: productsImg.panolinKeg,
    productTitle: "Panolin Orcon Gear 460",
    productPara:
      "Panolin Orcon Gear 460 is an ultra-high performance, long-term, fully synthetic NSF gear oil for the food and pharmaceutical industries as well as the animal feed industry.",
  },
  {
    productImg: productsImg.panolinKeg,
    productTitle: "Panolin Orcon Gear 680",
    productPara:
      "Panolin Orcon Gear 680 is an ultra-high performance, long-term, fully synthetic NSF gear oil for the food and pharmaceutical industries as well as the animal feed industry. ",
  },
  {
    productImg: productsImg.panolinKeg,
    productTitle: "Panolin Orcon Grease 0",
    productPara:
      "Panolin Orcon Grease 0 is a white H1 free-flowing lubricating grease based on aluminum complex soap for the food and pharmaceutical industry as well as for the animal feed industry.  ",
  },
  {
    productImg: productsImg.panolinKeg,
    productTitle: "Panolin Orcon Grease 00",
    productPara:
      "Panolin Orcon Grease 00 is a white H1 free-flowing lubricating grease based on aluminum complex soap for the food and pharmaceutical industry as well as for the animal feed industry.",
  },
  {
    productImg: productsImg.panolinKeg,
    productTitle: "Panolin Orcon Grease 1",
    productPara:
      "Panolin Orcon Grease 1 is a white, fully synthetic, NSF H1 certified, fluid lubricating grease based on aluminum complex soap for the food and pharmaceutical industry as well as for the animal feed sector.",
  },
  {
    productImg: productsImg.panolinKeg,
    productTitle: "Panolin Orcon Grease 2",
    productPara:
      "Panolin Orcon Grease 2 is a white, fully synthetic, NSF H1 certified, fluid lubricating grease based on aluminum complex soap for the food and pharmaceutical industries as well as for the animal feed industry.  ",
  },
  {
    productImg: productsImg.panolinKeg,
    productTitle: "Panolin Orcon Grease Clear 2",
    productPara:
      "Panolin Orcon Grease Clear 2 is a fully synthetic, transparent, NSF H1 certified lubricating grease for the food and pharmaceutical industry as well as the animal feed industry.",
  },
  {
    productImg: productsImg.panolinKeg,
    productTitle: "Panolin Orcon Grease Teff 2",
    productPara:
      "Panolin Orcon Grease Tef 2 is a white, fully synthetic NSF H1 lubricating grease with inorganic thickeners and PTFE additive for the food and pharmaceutical industry as well as for the animal feed sector.",
  },
  {
    productImg: productsImg.panolinKeg,
    productTitle: "Panolin Orcon HYD 100",
    productPara:
      "Panolin Orcon HYD 100 is an ultra-high performance fully synthetic NSF certified industrial hydraulic oil for the food and pharmaceutical industries as well as the animal feed industry.",
  },
  {
    productImg: productsImg.panolinKeg,
    productTitle: "Panolin Orcon HYD 15",
    productPara:
      "Panolin Orcon HYD 15 is an ultra-high performance fully synthetic NSF certified industrial hydraulic oil for the food and pharmaceutical industries as well as the animal feed industry.",
  },
  {
    productImg: productsImg.panolinKeg,
    productTitle: "Panolin Orcon HYD 150",
    productPara:
      "Panolin Orcon HYD 150 is an ultra-high performance fully synthetic NSF certified industrial hydraulic oil for the food and pharmaceutical industries as well as the animal feed industry.",
  },
  {
    productImg: productsImg.panolinKeg,
    productTitle: "Panolin Orcon HYD 22",
    productPara:
      "Panolin Orcon HYD 22 is an ultra-high performance fully synthetic NSF certified industrial hydraulic oil for the food and pharmaceutical industries as well as the animal feed industry.",
  },
  {
    productImg: productsImg.panolinKeg,
    productTitle: "Panolin Orcon HYD 32",
    productPara:
      "Panolin Orcon HYD 32 is an ultra-high performance fully synthetic NSF certified industrial hydraulic oil for the food and pharmaceutical industries as well as the animal feed industry.",
  },
  {
    productImg: productsImg.panolinKeg,
    productTitle: "Panolin Orcon HYD 68",
    productPara:
      "Panolin Orcon HYD 68 is an ultra-high performance fully synthetic NSF certified industrial hydraulic oil for the food and pharmaceutical industries as well as the animal feed industry.",
  },
  {
    productImg: productsImg.panolinKeg,
    productTitle: "Panolin Orcon HYD SGM 46",
    productPara:
      "Panolin Orcon HYD SGM 46 is a fully synthetic, zinc-free, NSF H1 certified industrial hydraulic oil developed for the food, pharmaceutical and animal feed industries.",
  },
  {
    productImg: productsImg.panolinKeg,
    productTitle: "Panolin Orcon HYD SGM 68",
    productPara:
      "Panolin Orcon HYD SGM 68 is a fully synthetic, zinc-free, NSF H1 certified industrial hydraulic oil developed for the food, pharmaceutical and animal feed industries.",
  },
  {
    productImg: productsImg.panolinKeg,
    productTitle: "Panolin Orcon Synth E 46",
    productPara:
      "Panolin Orcon Synth E is a fully synthetic and rapidly biodegradable hydraulic fluid for the food and pharmaceutical industry, based on saturated esters that comes from renewable natural resources with European Ecolabel certification.",
  },
  {
    productImg: productsImg.panolinKeg,
    productTitle: "Panolin Orcon Temp 32",
    productPara:
      "Panolin Orcon Temp 32 is a fully synthetic, NSF H1 certified heat transfer oil for the food, pharmaceutical and feed industries.",
  },
  {
    productImg: productsImg.panolinKeg,
    productTitle: "Panolin Orcon Vacpump 100",
    productPara:
      "Panolin Orcon Vacpump 100 is a fully synthetic, NSF H1 certified vacuum pump oil with almost negligible evaporation loss for the food, pharmaceutical and animal feed industries.",
  },
  {
    productImg: productsImg.panolinKeg,
    productTitle: "Panolin Orcon Vacpump 32",
    productPara:
      "Panolin Orcon Vacpump 32 is a fully synthetic, NSF H1 certified vacuum pump oil with almost negligible evaporation loss for the food, pharmaceutical and animal feed industries.",
  },
  {
    productImg: productsImg.panolinKeg,
    productTitle: "Panolin Orcon Vacpump 46",
    productPara:
      "Panolin Orcon Vacpump 46 is a fully synthetic, NSF H1 certified vacuum pump oil with almost negligible evaporation loss for the food, pharmaceutical and animal feed industries.",
  },
  {
    productImg: productsImg.panolinKeg,
    productTitle: "Panolin Orcon Vacpump 68",
    productPara:
      "Panolin Orcon Vacpump 68 is a fully synthetic, NSF H1 certified vacuum pump oil with almost negligible evaporation loss for the food, pharmaceutical and animal feed industries.",
  },
  {
    productImg: productsImg.panolinKeg,
    productTitle: "Panolin Orcon Vitra 150",
    productPara: "Panolin Orcon Vitra 150 is a fully synthetic H1 outlet oil.",
  },
  {
    productImg: productsImg.panolinKeg,
    productTitle: "Panolin Orcon Vitra 220",
    productPara: "Panolin Orcon Vitra 220 is a fully synthetic H1 outlet oil.",
  },
  {
    productImg: productsImg.panolinKeg,
    productTitle: "Panolin Silicone H1 Spray",
    productPara:
      "Panolin Silicone H1 Spray is a preservative for rubber and plastic components.",
  },
  {
    productImg: productsImg.panolinKeg,
    productTitle: "Panolin VA Grease",
    productPara:
      "Panolin VA Grease is a highly refined white H1 vaseline for the food and beverage, animal feed and pharmaceutical industries.  ",
  },
  {
    productImg: productsImg.petronas,
    productTitle: "Petronas Gear NT 150",
    productPara:
      "Petronas Gear NT 150 is a medicinal paraffinic oil endowed with the appropriate additives in order to confer the technical properties required by the food industry.",
  },
  {
    productImg: productsImg.petronasAlt,
    productTitle: "Petronas Gear NT 220",
    productPara:
      "Petronas Gear NT 220 is a medicinal paraffinic oil endowed with the appropriate additives in order to confer the technical properties required by the food industry.",
  },
  {
    productImg: productsImg.petronas,
    productTitle: "Petronas Gear NT 320",
    productPara:
      "Petronas Gear NT 320 is a medicinal paraffinic oil endowed with the appropriate additives in order to confer the technical properties required by the food industry.",
  },
  {
    productImg: productsImg.petronasAlt,
    productTitle: "Petronas Grease FG",
    productPara:
      "Petronas Grease FG is an advanced food industry approved, high quality NLGI 2 grade aluminum complex polymer thickener grease. A multi-purpose grease specifically intended for the lubrication of machinery used in food processing and packaging.",
  },
  {
    productImg: productsImg.petronas,
    productTitle: "Petronas Hidrobak NT 32",
    productPara:
      "Petronas Hidrobak NT-32 is a paraffinic oil with non-toxic additives in order to provide the technical specifications required for lubricants intended for use in hydraulic systems and compressors within the food industry.",
  },
  {
    productImg: productsImg.petronas,
    productTitle: "Petronas Hidrobak NT 46",
    productPara:
      "Petronas Hidrobak NT-46 is a paraffinic oil with non-toxic additives in order to provide the technical specifications required for lubricants intended for use in hydraulic systems and compressors within the food industry.",
  },
  {
    productImg: productsImg.petronas,
    productTitle: "Petronas Hidrobak NT 68",
    productPara:
      "Petronas Hidrobak NT 68 are paraffinic oils with non-toxic additives in order to provide the technical specifications required for lubricants intended for use in hydraulic systems and compressors within the food industry.",
  },
  {
    productImg: productsImg.petronasAltBig,
    productTitle: "Petronas White Oil P15",
    productPara:
      "Petronas White Oil P series (formerly Atoxol L) are white oils of pharmaceutical quality of mineral origin obtained from selected and highly refined raw materials. Thanks to the manufacturing process, it confers the highest standards of purity and quality, since it eliminates aromatic hydrocarbons and polycyclic and mononuclear hydrocarbon rings. They are colorless, odorless, and comply with the most recent European pharmacopoeias.",
  },
  {
    productImg: productsImg.petronas,
    productTitle: "Petronas White Oil P 22",
    productPara:
      "Petronas White Oil P series (formerly Atoxol L) are white oils of pharmaceutical quality of mineral origin obtained from selected and highly refined raw materials. Thanks to the manufacturing process, it confers the highest standards of purity and quality, since it eliminates aromatic hydrocarbons and polycyclic and mononuclear hydrocarbon rings. They are colorless, odorless, and comply with the most recent European pharmacopoeias.",
  },
  {
    productImg: productsImg.petronasAlt,
    productTitle: "Petronas White Oil P68",
    productPara:
      "Petronas White Oil P series (formerly Atoxol L) are white oils of pharmaceutical quality of mineral origin obtained from selected and highly refined raw materials. Thanks to the manufacturing process, it confers the highest standards of purity and quality, since it eliminates aromatic hydrocarbons and polycyclic and mononuclear hydrocarbon rings. They are colorless, odorless, and comply with the most recent European pharmacopoeias.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Quakertek Verkofood FG-2",
    productPara:
      "QUAKER HOUGHTON QUAKERTEK VERKOFOOD FG-2 is a grease formulated based on highly purified compounds in accordance with the Guide established by CNERNA and responding to FDA requirements paragraph 178 35 70 for food contact.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Quakertek Verkofood SC-1",
    productPara:
      "QUAKER HOUGHTON QUAKERTEK VERKOFOOD SC-1 is a grease specifically designed for the lubrication of feed pelleting presses. It is recommended for use for the lubrication of rolling bearings, bearings, joints and slides where incidental contact between the lubricant and the food cannot be excluded.",
  },
  {
    productImg: null,
    productTitle: "SO CP-27 (P-002) PE",
    productPara:
      "CP-27 is a medicinal white oil free of unsaturated components and meets all the purity and ultraviolet absorption requirements of the DAB, USP, BP, F.Codex, EUR.PHAR pharmacopoeias, as well as CFR 172.878 and 178.3620 (a ) from the US Food and Drug Administration (FDA).",
  },
  {
    productImg: productsImg.totalAlt,
    productTitle: "Total Nevastane Antifreeze",
    productPara:
      "TOTAL NEVASTANE ANTIFREEZE is a multi-purpose coolant based on MPG and suitable for incidental food contact.",
  },
];

export const transportationLubricant = [
  {
    productImg: productsImg.cepsaAlt,
    productTitle: "Cepsa ATF 70",
    productPara:
      "Cepsa ATF 70 is a lubricating oil made with particularly selected base oils and a powerful package of additives for use in gearboxes that require the General Motors DII-D specification.",
  },
  {
    productImg: productsImg.cepsaAlt,
    productTitle: "Cepsa Auriga TE 85",
    productPara:
      "Cepsa Auriga TE 85 is an UTTO-type oil formulated to meet the requirements of the FORD M2C 86B specification for hydraulic systems and brakes immersed in an oil bath. Meets MASSEY FERGUSON MF 1135 specification.",
  },
  {
    productImg: productsImg.cepsaAlt,
    productTitle: "Cepsa Premium AX 40",
    productPara:
      "Cepsa Premium AX is a monograde mineral oil especially recommended for engines where detergent properties are not required.",
  },
  {
    productImg: productsImg.cepsaAlt,
    productTitle: "Cepsa Rolling and Protection SAE 30",
    productPara:
      "Cepsa Rodaje y Protección SAE 30 is a mineral oil with lubricating and protective properties recommended for industrial and marine applications.",
  },
  {
    productImg: productsImg.fuchAlt,
    productTitle: "FC Maintain Fricofin",
    productPara:
      "Maintain Fricofin is a premium performance coolant concentrate based on monoethylene glycol. Hybrid technology free of nitrites, amines and phosphates. Blue-green dye.",
  },
  {
    productImg: productsImg.fuchAlt,
    productTitle: "FC Neutrafilm H 55",
    productPara:
      "Neutrafilm H 55 is an anticorrosive waterproofing fluid that evaporates quickly, forming a fine oily film on the surface.",
  },
  {
    productImg: productsImg.fuchAlt,
    productTitle: "FC Pentosin CHF 11S",
    productPara:
      "Pentosin CHF 11S is a premium performance fluid for power steering and hydraulic power stations with a wide application and profile of approvals from various manufacturers. Product tint: green",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Terrasol 100 SWA",
    productPara:
      "Lubcon Terrasol 100 SWA is a rapidly biodegradable special oil that has been developed for the railway industry, especially for lubrication in the maneuvering area, it contains additives and solid lubricants that prevent oxidation, reduce wear and at the same time guarantee very good properties. lubricants and adhesive capabilities. ",
  },
  {
    productImg: productsImg.petronas,
    productTitle: "Petronas Akros Gear EP 90 LS",
    productPara:
      "Petronas Akros Gear EP 90 LS is a transmission oil with extreme pressure (EP) characteristics, for transmissions, final reducers, normal and self-locking differentials, also equipped with limited slip systems in tractors and agricultural machinery.",
  },
  {
    productImg: productsImg.petronasAkros10w30,
    productTitle: "Petronas Akros Multi 10W30",
    productPara:
      "Petronas Akros Multi 10W-30 is a multipurpose oil for the lubrication of transmissions of tractors and agricultural machinery: gearboxes and rear transmissions with oil bath brakes, wheel ends, differentials, hydraulic lifting, mechanical transmission pulley.",
  },
  {
    productImg: productsImg.petronasAkros20w30,
    productTitle: "Petronas Akros Multi 20W30",
    productPara:
      "Petronas Akros Multi 20W-30 is a multipurpose oil for the lubrication of transmissions of tractors and agricultural machinery: gearboxes and rear transmissions with oil bath brakes, wheel ends, differentials, hydraulic lifting, mechanical transmission pulley.",
  },
  {
    productImg: productsImg.petronas,
    productTitle: "Petronas Akros Multi VT",
    productPara:
      "Petronas Akros Multi VT is a multifunctional oil for tractors and agricultural machinery for the lubrication of transmissions with oil bath brakes, gearbox, hydraulic systems, services and continuously variable transmission operating in the most severe conditions.",
  },
  {
    productImg: productsImg.petronasMach15w40,
    productTitle: "Petronas Akros Synt Gold 10W40",
    productPara:
      "Petronas Akros Synt Gold is a multigrade, synthetic-based engine oil for high-performance agricultural machinery with turbocharged or aspirated engines of all makes and powers.",
  },
  {
    productImg: productsImg.petronasMach15w40,
    productTitle: "Petronas Akros Turbo 15W40",
    productPara:
      "Petronas Akros Turbo 15W 40 is a motor oil for high-performance agricultural machinery and tractors with turbocharged or aspirated engines of all types and power levels.",
  },
  {
    productImg: productsImg.petronas,
    productTitle: "Petronas Arbor Alfatech 15W-40",
    productPara:
      "Petronas Arbor Alfatech 15W-40 is a premium oil for agricultural and construction machinery engines, capable of maintaining its efficiency and offering a longer life cycle, protecting against oxidation and wear, keeping pistons clean and optimum viscosity.",
  },
  {
    productImg: productsImg.petronas,
    productTitle: "Petronas Arbor Alfatech Synth 10W-40",
    productPara:
      "Petronas Arbor Alfatech Synth 10W-40 is a special premium oil for agricultural and construction machinery engines capable of maintaining its efficiency and offering a longer life cycle by protecting against oxidation and wear and keeping pistons clean and optimal stable viscosity.",
  },
  {
    productImg: productsImg.petronas,
    productTitle: "Petronas Arbor MTF 10W-30",
    productPara:
      "Petronas Arbor MTF 10W-30 is a premium transmission oil (UTTO) specifically for agricultural and construction machinery, capable of maintaining its efficiency and offering a longer duration.",
  },
  {
    productImg: productsImg.petronas,
    productTitle: "Petronas Arbor Super 15W-40",
    productPara:
      "Petronas Arbor Super 15W-40 is a special premium oil for agricultural and construction machinery engines capable of maintaining its efficiency and offering a longer life cycle, protecting against oxidation and wear and maintaining clean pistons and optimum stable viscosity.",
  },
  {
    productImg: productsImg.petronas,
    productTitle: "Petronas Arbor TRW 90 LS",
    productPara:
      "Petronas Arbor TRW 90 LS 80W-90 is a high-quality axle fluid specifically for construction and agricultural machinery, capable of maintaining its efficiency and offering longer life. It is a perfect solution to obtain high and lasting performance in your daily work, protecting your tireless machinery from loss of performance and unscheduled maintenance stops.",
  },
  {
    productImg: productsImg.petronas,
    productTitle: "Petronas Catoil 10W",
    productPara:
      "Petronas Catoil 10 W is a lubricant specially developed for transmissions, wet brakes and final drives of off-road machines.",
  },
  {
    productImg: productsImg.petronas,
    productTitle: "Petronas Catoil 30",
    productPara:
      "Petronas Catoil 30 is a lubricant specially developed for transmissions, wet brakes and final drives of industrial and agricultural machinery.",
  },
  {
    productImg: productsImg.petronas,
    productTitle: "Petronas Catoil 50",
    productPara:
      "Petronas Catoil 50 is a lubricant specially developed for transmissions, wet brakes and final drives of industrial and agricultural machinery.",
  },
  {
    productImg: productsImg.petronas,
    productTitle: "Petronas GL4 75W-80",
    productPara:
      "Petronas gl 4 are multigrade oils suitable for the lubrication of mechanical transmissions and axles in automotive vehicles and industry. Provided with antioxidant, anticorrosive, antifoaming and chemical-type Express Pressure (EP) additives.",
  },
  {
    productImg: productsImg.petronas,
    productTitle: "Petronas GL4 80W-90",
    productPara:
      "Petronas gl 4 are multigrade oils suitable for the lubrication of mechanical transmissions and axles in automotive vehicles and industry. Provided with antioxidant, anticorrosive, antifoaming and chemical-type Express Pressure (EP) additives.",
  },
  {
    productImg: productsImg.petronas,
    productTitle: "Petronas GL4 85W-140",
    productPara:
      "Petronas gl 4 are multigrade oils suitable for the lubrication of mechanical transmissions and axles in automotive vehicles and industry. Provided with antioxidant, anticorrosive, antifoaming and chemical-type Express Pressure (EP) additives.",
  },
  {
    productImg: productsImg.petronasAlt,
    productTitle: "Petronas LHM",
    productPara: " ",
  },
  {
    productImg: productsImg.petronasMach15w40,
    productTitle: "Petronas Mach 5 15W40",
    productPara:
      "Petronas Arbor MTF 10W-30 is a premium transmission oil (UTTO) specifically for agricultural and construction machinery, capable of maintaining its efficiency and offering a longer duration.",
  },
  {
    productImg: productsImg.petronasMach20w50,
    productTitle: "Petronas Mach 5 20W50",
    productPara:
      "Petronas Mach 5 20w-50 is a multigrade lubricant for gasoline and diesel engines. It guarantees a cleanliness and total protection of the motors.",
  },
  {
    productImg: productsImg.petronasAlt,
    productTitle: "Petronas Oilgres L",
    productPara:
      "Petronas Oilgres L are mineral oils of extraordinary quality and specially formulated to be used as hydraulic fluids in all types of circuits exposed to environmental contamination.",
  },
  {
    productImg: productsImg.petronasAlt,
    productTitle: "Petronas Paraflu 11 Concentrate",
    productPara:
      "Petronas Paraflu 11 Concentrate is a concentrated ethylene glycol based fluid for the protection of radiators. Designed for professional use. It offers ideal protection for the cooling systems of cars, light and heavy commercial vehicles, tractors and machinery.",
  },
  {
    productImg: productsImg.petronasAlt,
    productTitle: "Petronas Paraflu UD Diluted to 33%",
    productPara:
      "Antifreeze fluid for direct use diluted to 33% in water with special additives for the anticorrosive protection of all the metal parts that make up the cooling system of automobile engines.",
  },
  {
    productImg: productsImg.petronasAlt,
    productTitle: "Petronas Paraflu UD Diluted to 50%",
    productPara:
      "Petronas Paraflu UD 50% is an antifreeze fluid for direct use diluted to 50% in water with special additives for the anticorrosive protection of all the metallic parts that make up the cooling system of automotive engines.",
  },
  {
    productImg: productsImg.petronasAlt,
    productTitle: "Petronas Paraflu UP Diluted to 50%",
    productPara:
      "Paraflu Up diluted to 50% is specially designed for the protection of radiators. It is a monoethylene glycol based fluid formulated with organic inhibitors based on organic acid technology. This protective fluid, which is also suitable for professional use, is specific for cooling systems containing the new light alloy materials.",
  },
  {
    productImg: productsImg.petronasSyntium5w40,
    productTitle: "Petronas Syntium 3000 AV 5W-40",
    productPara:
      "Petronas Syntium 3000 AV is a medium SAPS oil suitable for all types of passenger cars with gasoline and diesel engines (equipped with after-treatment exhaust system) and the latest high-performance vehicles equipped with fuel injection, multi-valve, turbochargers or superchargers that operate in the most severe conditions. It is also suitable for vehicles with biofuel.",
  },
  {
    productImg: productsImg.petronasSyntium5w40,
    productTitle: "Petronas Syntium 3000 E 5W-40",
    productPara:
      "Petronas Syntium 3000 E is suitable for all types of passenger cars, especially high-performance vehicle models such as Mercedes Benz and BMW, with gasoline and diesel engines, not equipped with a diesel particulate filter system, and high-performance vehicles. latest fuel-injected, multi-valve, turbocharged or supercharged models that operate in the most severe conditions.",
  },
  {
    productImg: productsImg.petronasSyntium5w40,
    productTitle: "Petronas Syntium 5000 AV 5W-30",
    productPara:
      "Petronas Syntium 5000 AV is an oil with a medium SAPS content, suitable for all types of passenger cars, such as Volkswagen and Mercedes Benz, with gasoline and diesel engines (equipped with after-treatment exhaust system) and the most high-performance vehicles. recent.",
  },
  {
    productImg: productsImg.petronasSyntium5w40,
    productTitle: "Petronas Syntium 3000 E 5W-40",
    productPara:
      "Petronas Syntium 3000 E is suitable for all types of passenger cars, especially high-performance vehicle models such as Mercedes Benz and BMW, with gasoline and diesel engines, not equipped with a diesel particulate filter system, and high-performance vehicles. latest fuel-injected, multi-valve, turbocharged or supercharged models that operate in the most severe conditions.",
  },
  {
    productImg: productsImg.petronasSyntium5w30,
    productTitle: "Petronas Syntium 5000 CP 5W-30",
    productPara:
      "Petronas Syntium 5000 CP is a medium SAPS oil suitable for all types of passenger cars with gasoline and diesel engines (equipped with after-treatment exhaust system) and the latest high performance vehicles.",
  },
  {
    productImg: productsImg.petronasSyntium5w20,
    productTitle: "Petronas Syntium 5000 FR 5W-20",
    productPara:
      "Petronas Syntium 5000 FR is a low viscosity High Temperature High Wear (HTHS) motor oil suitable for all types of passenger car gasoline engines.",
  },
  {
    productImg: productsImg.petronasSyntium5w20,
    productTitle: "Petronas Syntium 5000 RN 5W-30",
    productPara:
      "Petronas Syntium 5000 RN is an oil with a low SAPS content, suitable for all types of passenger cars, especially for Renault group engines, where Renault RN0720 or ACEA C4 is specified.",
  },
  {
    productImg: productsImg.petronasSyntium5w30,
    productTitle: "Petronas Syntium 5000 XS 5W-30",
    productPara:
      "Petronas Syntium 5000 RN is an oil with a low SAPS content, suitable for all types of passenger cars, especially for Renault group engines, where Renault RN0720 or ACEA C4 is specified.",
  },
  {
    productImg: productsImg.petronasSyntium5w30,
    productTitle: "Petronas Syntium 7000 0W-20",
    productPara:
      "Petronas Syntium 7000 is an oil suitable for all types of passenger cars and high performance vehicles.",
  },
  {
    productImg: productsImg.petronasSyntium5w30,
    productTitle: "Petronas Syntium 7000 0W-40",
    productPara:
      "Petronas Syntium 7000 is an oil suitable for all types of passenger cars, both for gasoline and diesel engines.",
  },
  {
    productImg: productsImg.petronasSyntium5w30,
    productTitle: "Petronas Syntium 7000 DM 0W-30",
    productPara:
      "Petronas Syntium 7000 is a medium SAPS oil, suitable for all types of passenger cars, such as Volkswagen and Mercedes-Benz, with gasoline and diesel engines (equipped with after-treatment exhaust system) and the most high-performance vehicles. recent.",
  },
  {
    productImg: productsImg.petronasSyntium5w30,
    productTitle: "Petronas Syntium Racer 10W60",
    productPara:
      "Petronas Syntium Racer is an oil suitable for all types of high-power competition engines.",
  },
  {
    productImg: productsImg.petronasAlt,
    productTitle: "Petronas Guardianship GI/M",
    productPara:
      "Fluid for automatic transmissions and hydraulic steering of industrial and commercial vehicles. Its characteristics provide it with a low fluidity point, correct viscosity for a wide range of working temperatures, oxidation stability, and detergent, dispersant, antiwear, anticorrosive, antirust, and antifoam properties. Recommended for semi-automatic transmissions, hydraulic couplings, torque converters, servomechanisms of cars, trucks, agricultural machines and roads. ",
  },
  {
    productImg: productsImg.petronasTutela,
    productTitle: "Petronas Tutela Car MATRYX",
    productPara:
      "Petronas Tutela Car Matryx is a synthetic-based multigrade lubricant for vehicle mechanical transmissions, especially for applications where lubricants with high thermal stability are required.",
  },
  {
    productImg: productsImg.petronasAlt,
    productTitle: "Petronas Guardianship GI/A",
    productPara:
      "Fluid for automatic transmissions and hydraulic steering. Its characteristics provide it with a low fluidity point, correct viscosity for a wide range of working temperatures, oxidation stability, and detergent, dispersant, antiwear, anticorrosive, antirust, and antifoam properties. Also contains EP (extreme pressure) additives",
  },
  {
    productImg: productsImg.petronasTutelaGie,
    productTitle: "Petronas Guardianship GI/E",
    productPara:
      " Fully synthetic lubricating oil for hydraulic automatic transmissions and power steering of automobiles. Ideal in harsh climates.",
  },
  {
    productImg: productsImg.petronasTutela,
    productTitle: "Petronas Guardianship SP",
    productPara:
      "Petronas Syntium 5000 RN is an oil with a low SAPS content, suitable for all types of passenger cars, especially for Renault group engines, where Renault RN0720 or ACEA C4 is specified.",
  },
  {
    productImg: productsImg.petronasTutelaGie,
    productTitle: "Petronas Guardianship Starfluid",
    productPara:
      "Petronas Transmission Starfluid is an automatic transmission fluid specifically formulated for the latest generation vehicle and truck transmission systems, with extended drain intervals.",
  },
  {
    productImg: productsImg.petronasAltBig,
    productTitle: "Petronas Tutela Starfluid 7S",
    productPara:
      "Petronas Tutela Transmission Starfluid 7S is an automatic transmission fluid for high performance transmissions, specially formulated for the 7-speed transmissions of the Mercedes-Benz Group (NAG2 Group Transmission).",
  },
  {
    productImg: productsImg.petronasTutela,
    productTitle: "Petronas Tutela Starfluid HD ED",
    productPara:
      "Petronas Tutela Starfluid HD-ED is a high performance automatic transmission fluid specifically formulated for heavy vehicles that require a Dexron IID product. The state-of-the-art formula allows for extended drain intervals.",
  },
  {
    productImg: productsImg.petronasTutelaPs,
    productTitle: "Petronas Tutela Starfluid PS",
    productPara:
      "Petronas Tutela Starfluid PS is a specifically formulated automatic transmission fluid dedicated to power steering systems and automatic transmission units where Dexron IID is required. Tutela Starfluid PS is a fluid suitable for the commercial vehicles of the Mercedes group and many other European manufacturers.",
  },
  {
    productImg: productsImg.petronasTutela,
    productTitle: "Petronas Guardianship Stargear",
    productPara:
      "Petronas Tutela Stargear is a synthetic based manual transmission fluid. Its modern formula allows its use in a wide range of manufacturers.",
  },
  {
    productImg: productsImg.petronasTutelaPs,
    productTitle: "Petronas Guardianship Stargear AX",
    productPara:
      "Petronas Tutela Stargear AX is a lubricant for automobile and commercial vehicle axles (especially Mercedes), which allows maximum protection of mechanical parts during use at high temperatures and severe operating conditions. The modern formulation guarantees protection against wear, oxidation and corrosion. The viscosity index allows for fast and safe lubrication during cold starts and during running at low temperatures.",
  },
  {
    productImg: productsImg.petronasAltBig,
    productTitle: "Petronas Guardianship Stargear AX ED",
    productPara:
      "Petronas Tutela Stargear AX-ED is a high performance axle fluid, specially formulated for heavily loaded gears and extended drain intervals.",
  },
  {
    productImg: productsImg.petronasTutelaPs,
    productTitle: "Petronas Guardianship Stargear F",
    productPara:
      "Petronas Tutela Transmission Stargear F is a fully synthetic manual transmission fluid, specially formulated for industrial/commercial vehicles. The technology used allows the drainage intervals to be extended.",
  },
  {
    productImg: productsImg.petronasAltBig,
    productTitle: "Petronas Guardianship Stargear FV",
    productPara:
      "Petronas Tutela Stargear FV is a lubricant for synchronized manual transmissions, suitable for gearboxes of VW group vehicles.",
  },
  {
    productImg: productsImg.petronasAltBig,
    productTitle: "Petronas Guardianship Stargear HD",
    productPara:
      "Petronas Tutela Transmission Stargear HD is a transmission lubricant for automobiles and commercial and industrial vehicles that allows spaced drain intervals.",
  },
  {
    productImg: productsImg.petronasAlt,
    productTitle: "Petronas Tutela T XT D 540",
    productPara:
      "Petronas Tutela Transmission XT-D 540 is a lubricant for manual and automated transmissions for commercial vehicles, to be used in transmissions and differentials (in the absence of hypoid torque), even in the presence of carbon synchronizers.",
  },
  {
    productImg: productsImg.petronasAlt,
    productTitle: "Petronas Guardianship TECHNYX",
    productPara:
      "Multigrade synthetic-based lubricant for vehicle mechanical transmissions, for use in gearbox-differential units, also equipped with hypoid gear pair and central differential (PTU).",
  },
  {
    productImg: productsImg.petronasAlt,
    productTitle: "Petronas Tutela Transmission W140 M-DA",
    productPara:
      "Petronas Tutela Transmission Starfluid 7S is an automatic transmission fluid for high performance transmissions, specially formulated for the 7-speed transmissions of the Mercedes-Benz Group (NAG2 Group Transmission).",
  },
  {
    productImg: productsImg.petronasAlt,
    productTitle: "Petronas Tutela Transmission W90 M-DA",
    productPara:
      "Petronas Tutela Transmission W90 M-DA is an axle lubricant for any vehicle application, it is suitable for differentials with hypoid gears and steering boxes, where extreme pressure (EP) lubrication is required.",
  },
  {
    productImg: productsImg.petronasTutelaGie,
    productTitle: "Petronas Tutela Truck ATF 6K",
    productPara:
      "Petronas Tutela Transmission ATF 6K is a synthetic lubricant and represents the highest technological answer to the demand of modern heavy automatic transmissions. It guarantees optimal lubrication and prevents excessive erosion of the transmission while maintaining its characteristics for the entire operating interval. It guarantees prolonged drainage and good maintenance, t",
  },
  {
    productImg: productsImg.petronasTutelaGie,
    productTitle: "Petronas Tutela Truck ATF 90",
    productPara:
      "Petronas Tutela Transmission ATF 90 is a fully synthetic lubricating oil for hydraulic automatic transmissions operating under severe conditions.",
  },
  {
    productImg: productsImg.petronasTutelaPs,
    productTitle: "Petronas Tutela Truck FE-GEAR",
    productPara:
      "Petronas Tutela Truck FE-GEAR is a fluid for mechanical and automatic transmissions of commercial and industrial vehicles and buses, which work in extreme conditions. The high quality of the raw materials used allows long oil change intervals to be achieved, as well as offering significant fuel savings (Fuel Economy)",
  },
  {
    productImg: productsImg.petronasTutelaGie,
    productTitle: "Petronas Tutela Truck Gearlite",
    productPara:
      "Petronas Tutela Truck Gearlite is a multigrade manual transmission lubricant for commercial vehicles.",
  },
  {
    productImg: productsImg.petronasTutelaPs,
    productTitle: "Petronas Tutela ZC 75 SYNT",
    productPara:
      "Petronas Tutela ZC 75 Synt is a fully synthetic lubricating oil for automotive mechanical transmissions.",
  },
  {
    productImg: productsImg.petronasTutelaZC90,
    productTitle: "Petronas Tutela ZC 90",
    productPara:
      "Petronas Tutela ZC 90 is an oil for mechanical transmissions in automobiles, commercial and industrial vehicles. Ideal for gears, non-hypoid differential gears, transmissions that require a lubricant with anti-wear characteristics. Oil for the rear axle of 2 wheeled vehicles.",
  },
  {
    productImg: productsImg.petronasUrania10w40,
    productTitle: "Petronas Urania 3000 E 10W-40",
    productPara:
      "Petronas Urania 3000 E 10W-40 is an engine oil suitable for most heavy-duty diesel applications, including modern, high-performance, low-emission diesel engines.",
  },
  {
    productImg: productsImg.petronasUrania15w40,
    productTitle: "Petronas Urania 3000 E 15W-40",
    productPara:
      "Petronas Urania 3000 E 15W-40 is an engine oil suitable for heavy-duty diesel applications, including modern, high-performance, low-emission diesel engines equipped with emission control systems with SCR and EGR technologies.",
  },
  {
    productImg: productsImg.petronasUrania15w40,
    productTitle: "Petronas Urania 3000 E 5W30",
    productPara:
      "Petronas Urania 3000 E is an engine oil suitable for all types of heavy-duty diesel applications, including modern high-performance engines.",
  },
  {
    productImg: productsImg.petronas,
    productTitle: "Petronas Urania 500 30",
    productPara:
      "Petronas Urania 500 30 is suitable for all types of diesel applications for older commercial vehicles and high revving turbo diesel engines both on and off highway.",
  },
  {
    productImg: productsImg.petronas,
    productTitle: "Petronas Urania 500 40",
    productPara:
      "Petronas Urania 500 40 suitable for all types of diesel applications for older commercial vehicles and high revving turbo diesel engines both on and off highway.",
  },
  {
    productImg: productsImg.petronasUranium500_10w40,
    productTitle: "Petronas Urania 5000 E 10W-40",
    productPara:
      "Petronas Urania 5000 E 10W-40 is a low SAPS motor oil compatible with applications in heavy-duty vehicles equipped with Euro VI and Euro V type diesel engines, and with emission control systems using DPF, SCR, DOC technologies. , and EGR.",
  },
  {
    productImg: productsImg.petronasAlt,
    productTitle: "Petronas Urania 5000 E 15W-40",
    productPara:
      "Petronas Urania 5000 E 15W-40 is an engine oil suitable for all types of heavy-duty diesel applications, including modern, high-performance, low-emission diesel engines equipped with emission control systems with DPF, SCR, DOC and EGR.",
  },
  {
    productImg: productsImg.petronas,
    productTitle: "Petronas Urania 5000 E 5W-30",
    productPara:
      "Petronas Urania 5000 E is a low SAPS engine oil compatible with applications in heavy-duty vehicles equipped with Euro VI and Euro V type diesel engines and with emission control systems using DPF, SCR, DOC, and EGR technologies.",
  },
  {
    productImg: productsImg.petronasUrania15w40,
    productTitle: "Petronas Urania 800 15W-50",
    productPara:
      "Petronas Urania 800 is a motor oil suitable for heavy-duty diesel applications, including naturally aspirated or turbocharged diesel engines, both on- and off-road, powered by high or extra low sulfur diesel.",
  },
  {
    productImg: productsImg.petronasUrania15w40,
    productTitle: "Petronas Urania 800 15W-50",
    productPara:
      "Petronas Tutela Transmission Starfluid 7S is an automatic transmission fluid for high performance transmissions, specially formulated for the 7-speed transmissions of the Mercedes-Benz Group (NAG2 Group Transmission).",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Maxidiesel 15w40 SHPD",
    productPara:
      "Quaker Houghton Maxidiesel 15w40 SHPD is a multigrade oil for diesel engines, naturally aspirated or turbocharged, in the most severe working conditions, applicable for a very wide range of ambient temperatures, in long-distance trucks and buses, in adverse weather conditions and with extended oil change intervals.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Maxidiesel 20w50 SHPD",
    productPara:
      "Quaker Houghton Maxidiesel 20w50 SHPD is a multigrade oil for diesel engines, naturally aspirated or turbocharged, in the most severe working conditions, applicable for a very wide range of ambient temperatures, in long-distance trucks and buses, in adverse weather conditions and with extended oil change intervals.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Salduba 80w90 – LS",
    productPara:
      "Quaker Houghton Salduba 80w90 – LS es un aceite lubricante especialmente desarrollado para su utilización en diferenciales del tipo hipoide con deslizamiento limitado (limited slip) donde el uso de aceites inadecuados produce vibraciones en el interior del diferencial, causando molestias y ruidos inaceptables para los operarios, debido a la fricción producida a baja velocidad y alto par.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Titanium NG 10w40 UHPD",
    productPara:
      "Quaker Houghton Titanium NG 10w40 UHPD es un lubricante multigrado de tecnología sintética y elevadas prestaciones, especialmente formulado para su utilización en los motores diesel pesado, flotas mixtas de largo recorrido, así como en autobuses urbanos y motores a gas que utilicen CNG y vehículos comerciales ligeros que cumplan las exigencias de la normativa EURO VI, V y anteriores.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Veratek 10w40",
    productPara:
      "Quaker Houghton Veratek 10w40 es un lubricante multigrado de tecnología sintética y elevadas prestaciones, especialmente formulado para su utilización en los motores diesel pesado, flotas mixtas y autobuses de largo recorrido, así como motores a gas que utilicen CNG y vehículos comerciales ligeros que cumplan las exigencias de la normativa EURO VI.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Veratek 5w30",
    productPara:
      "Quaker Houghton Veratek 5w30 is a high-performance synthetic technology multigrade lubricant, specially formulated for use in heavy diesel engines and long-distance mixed fleets, as well as gas engines that use CNG and light commercial vehicles that meet the requirements of the EURO VI regulations.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Verkol GT-8 / 80w90 EP",
    productPara:
      "Quaker Houghton Verkol GT-8 / 80w90 EP is a high thermal stability lubricant based on the combination of highly refined mineral oil with the most modern additives, for the lubrication of gears subjected to very severe conditions of use.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Verkoplus 10w30 MSA",
    productPara:
      "Quaker Houghton Verkoplus 10W30 MSA is a multigrade lubricant of semi-synthetic technology and high performance, specially formulated for use in heavy diesel engines, mixed fleets, light commercial vehicles, vans and vehicles with agricultural and construction applications that meet the requirements of EURO VI, V and earlier regulations.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Verkoplus 15w40 LD",
    productPara:
      "Quaker Houghton Verkoplus 15W40 LD is a multigrade product specially formulated for use in modern diesel engines with low polluting emissions (Euro 4 engine).",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Verkoplus 15w40 MSA",
    productPara:
      "Quaker Houghton Verkoplus 15W40 MSA is a multigrade lubricant made with high quality Group II bases, specially formulated for use in trucks, mixed fleets, light commercial vehicles, vans and vehicles with agricultural and construction applications that meet the requirements of the regulations. EURO VI, V and earlier.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Verkoplus 20w50 SHPD",
    productPara:
      "Quaker Houghton Verkoplus 20W50 SHPD is a multigrade oil for diesel engines, naturally aspirated or turbocharged, applicable for a very wide range of ambient temperatures, in trucks and coaches for long distances with long periods between oil changes and high mileage (in the order of up to 45,000 km).",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Vesta–JD",
    productPara:
      "Quaker Houghton Vesta - JD is a multifunctional fluid for agricultural machinery, of the UTTO (Universal Tractor Transmission Oil) type, specially prepared to meet the requirements of hydraulic systems, transmissions, gears (API GL-4), clutches and submerged brakes, with or without a power take-off.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Vesta TD-2",
    productPara:
      "Quaker Houghton Vesta TD – 2 is a lubricant specially recommended for use in automatic transmissions that require the GM DEXRON-IID specification.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Vesta TD – 3 Synth",
    productPara:
      "Quaker Houghton Vesta TD – 3 Synt is a synthetic lubricant specially recommended for use in automatic transmissions operating in severe conditions that require the GM DEXRON-III specification.",
  },
  {
    productImg: productsImg.shellAdvance15w50,
    productTitle: "Shell Advance 4T AX7 15W-50",
    productPara:
      "Shell Advance 4T AX 7 15W-50 is a synthetic technology lubricant for 4-stroke motorcycle engines. Formulated with high quality synthetic base oil and additives with active cleaning technology specific for motorcycles.",
  },
  {
    productImg: productsImg.shellAdvance10w40SmMa,
    productTitle: "Shell Advance 4T Ultra 10W-40 SN/MA2",
    productPara:
      "Shell Advance 4t Ultra is a 100% synthetic lubricant for 4-stroke motorcycle engines, it is a premium motor oil, designed to provide the latest protection from Shell to the engines of all types of modern 4-stroke motorcycles.",
  },
  {
    productImg: productsImg.shellAdvance15w50smMa,
    productTitle: "Shell Advance 4T Ultra 15W-50 SN/MA2",
    productPara:
      "Shell Advance 4T Ultra 15W-50 SN/MA2 is a 100% synthetic engine lubricant for 4-stroke motorcycles, with PurePlus technology it is a high-end lubricant, natural gas is transformed into a crystal clear lubricant base and virtually 100% free of the impurities contained in oils derived from crude oil and which are the basis of most conventional and synthetic lubricants.",
  },
  {
    productImg: productsImg.shellAdvanceRacingM30,
    productTitle: "Shell Advance Racing M 30",
    productPara:
      "Shell Advance Racing M is a castor based lubricant with synthetic components for highly revved 2-stroke engines of competition karts.",
  },
  {
    productImg: productsImg.shellAdvanceUltra2t,
    productTitle: "Shell Advance Ultra 2T",
    productPara:
      "Shell Advance Ultra 2T is a state-of-the-art two-stroke motorcycle lubricant, it is 100% synthetic, formulated with exclusive Shell technology that provides excellent protection and performance to 2-stroke motorcycle engines.",
  },
  {
    productImg: productsImg.shellAdvancevsx2,
    productTitle: "Shell Advance VSX 2",
    productPara:
      "Shell Advance VSX 2 is a synthetic technology 2-stroke motorcycle engine oil designed to provide excellent protection and performance in 2-stroke motorcycle engines. Guarantees control of deposits in the exhaust system and reduces the formation of smoke. It is suitable for engines with oil injection and premix system and exceeds the requirements of the main manufacturers.",
  },
  {
    productImg: productsImg.shellAtf134,
    productTitle: "Shell ATF 134",
    productPara:
      "Shell ATF 134 is an automatic transmission lubricant specially formulated for Mercedes Benz passenger cars, with high quality premium technology for Mercedes Benz 5 and 7 speed automatic transmissions and NAG2VSport transmissions.",
  },
  {
    productImg: productsImg.shellAtf134Fe,
    productTitle: "Shell ATF 134 FE",
    productPara:
      "Shell ATF 134 FE is an automatic transmission lubricant specially formulated for Mercedes Benz passenger cars, with high quality premium technology for Mercedes Benz 7-speed automatic transmissions.",
  },
  {
    productImg: productsImg.shellAlt,
    productTitle: "shell-lubricantsShell Coolant Longlife Plus Ready to Use",
    productPara:
      "Shell Coolant Longlife Plus Ready to Use is a pre-diluted premium coolant/antifreeze formulated with an optimal combination of organic and silicate additives.",
  },
  {
    productImg: productsImg.shellAlt,
    productTitle: "shell-lubricantsShell Coolant Longlife Ready to Use",
    productPara:
      "Shell Coolant Longlife Ready to Use is a pre-diluted coolant/antifreeze with organic technology.",
  },
  {
    productImg: productsImg.shellAlt,
    productTitle: "Shell Gadinia AL 30",
    productPara:
      "Shell Gadinia AL 30 is an advanced lubricant for high-quality medium-speed diesel engines that use distillate fuels, specially developed to lubricate trunk and medium-speed diesel engines that use distillate fuels.",
  },
  {
    productImg: productsImg.shellAlt,
    productTitle: "Shell Gadinia AL 40",
    productPara:
      "Shell Gadinia AL 40 are high-quality oils specially developed to lubricate trunk and medium-speed diesel engines that use distillate fuels.  ",
  },
  {
    productImg: productsImg.shellAlt,
    productTitle: "Shell Gadinia S3 30",
    productPara:
      "Shell Gadinia S3 30 is a high quality, multifunctional diesel engine lubricant that has been specially designed for main propulsion engines in the most severe service conditions and auxiliary engines burning distillate, hybrid and biofuels with a sulfur content of up to 1% ",
  },
  {
    productImg: productsImg.shellAlt,
    productTitle: "Shell Gadinia S3 40",
    productPara:
      "Shell Gadinia S3 40 is a high quality, multifunctional diesel engine lubricant that has been specially designed for main propulsion engines in the most severe service conditions and auxiliary engines burning distillate, hybrid and biofuels with a sulfur content of up to 1%  ",
  },
  {
    productImg: productsImg.shellAlt,
    productTitle: "Shell GadusRail S3 EUDB",
    productPara:
      "Shell GadusRail S3 EUDB is a premium extreme pressure grease for railway wheel hubs subjected to severe conditions, formulated with Lithium thickener that provides extended lubrication intervals.",
  },
  {
    productImg: productsImg.shellAlt,
    productTitle: "Shell GadusRail S3 EUFR",
    productPara:
      "Shell GadusRail S3 EUFR is a premium extreme pressure grease for wheel hubs on railway equipment.",
  },
  {
    productImg: productsImg.shellAtf134Fe,
    productTitle: "Shell ATF 134 FE",
    productPara:
      "Shell ATF 134 FE is an automatic transmission lubricant specially formulated for Mercedes Benz passenger cars, with high quality premium technology for Mercedes Benz 7-speed automatic transmissions.",
  },
  {
    productImg: productsImg.shellGas,
    productTitle: "Shell Helix Diesel HX7 10W-40",
    productPara:
      "Shell Helix Diesel HX 7 has been formulated with active cleaning technology. Protects the engine more effectively than standard engine oils, preventing the formation of soot deposits. Provides greater responsiveness until the next oil change.",
  },
  {
    productImg: productsImg.shellHelix20w50,
    productTitle: "Shell Helix HX3 20W-50",
    productPara:
      "Shell Helix HX3 formulated with a cleaning technology that prevents the formation of dirt and sludge deposits, thus helping to protect and prolong the life of your engine.",
  },
  {
    productImg: productsImg.shellGas,
    productTitle: "Shell Helix HX5 15W-40",
    productPara:
      "Shell Helix HX5 designed to maintain the performance of your engine. Protects the engine more effectively than standard oils, preventing the formation of deposits and wear.",
  },
  {
    productImg: productsImg.shellHelix10w40,
    productTitle: "Shell Helix HX6 10W-40",
    productPara:
      "Shell Helix HX6 is a synthetic motor oil, which protects against sludge formation and reduces wear, its formulation improves the performance of your engine and is suitable for a wide variety of vehicles in day-to-day driving.",
  },
  {
    productImg: productsImg.shellHelixHx710w40,
    productTitle: "Shell Helix HX7 10W-40",
    productPara:
      "Shell Helix HX7 is a synthetic technology motor oil that helps keep the engine clean and running efficiently, prevents the formation of deposits and sludge, and is suitable for a wide variety of modern vehicles, even in the most demanding driving conditions.",
  },
  {
    productImg: productsImg.shellGas,
    productTitle: "Shell Helix HX7 5W-40",
    productPara:
      "Shell Helix HX7 5W-40 is a motor oil with synthetic technology that helps maintain engine cleanliness and efficiency, prevents the formation of deposits and sludge. Suitable for a wide variety of vehicles, even in the most demanding driving conditions.",
  },
  {
    productImg: productsImg.shellGas,
    productTitle: "Shell Helix HX7 Professional AF 5W-30",
    productPara:
      "Shell Helix HX7 Professional AF 5W-30 is a synthetic technology motor oil, designed to meet the special requirements of manufacturers.",
  },
  {
    productImg: productsImg.shellGas,
    productTitle: "Shell Helix HX7 Professional AV 5W-30",
    productPara:
      "Shell Helix HX7 Professional AV 5W-30 is a synthetic technology motor oil designed to meet the demanding requirements of high performance engines, including Audi and VW and those with ACEA C3 specification.",
  },
  {
    productImg: productsImg.shellGas,
    productTitle: "Shell Helix HX8 ECT 5W-30",
    productPara:
      "Shell Helix HX8 ECT 5W-30 is a 100% synthetic motor oil formulated with a low ash content, making it suitable for vehicles equipped with a diesel particulate filter, DPF, in compliance with the latest safety regulations. emissions. Helps reduce friction and provides fuel economy.",
  },
  {
    productImg: productsImg.shellGas,
    productTitle: "Shell Helix Ultra 0W-40",
    productPara:
      "Shell Helix Ultra 0W-40 is a 100% synthetic motor oil, it is Shell's most advanced formulation for high performance engines. Formulated with Shell's exclusive active cleaning technology, which helps keep your engine running at peak efficiency and protects against wear and deposit formation.",
  },
  {
    productImg: productsImg.shellGas,
    productTitle: "Shell Helix Ultra 5W-40",
    productPara:
      "Shell Helix Ultra is a 100% synthetic motor oil, formulated with Shell's exclusive active cleaning technology, which helps keep your engine running at peak efficiency and protects against wear and deposit formation. Suitable for extended drain intervals from all major manufacturers.",
  },
  {
    productImg: productsImg.shellGas,
    productTitle: "Shell Helix Ultra A5/B5 0W-30",
    productPara:
      "Shell Helix Ultra A5/B5 0W-30 is a 100% synthetic motor oil, it helps high performance engines to operate at maximum efficiency, protecting against the formation of deposits. Reduces engine friction to help save fuel.",
  },
  {
    productImg: productsImg.shellGas,
    productTitle: "Shell Helix Ultra AV-L 5W-30",
    productPara:
      "Shell Helix Ultra AV-L 5W-30 is a latest generation 100% synthetic motor oil, specifically designed to provide maximum protection for most gasoline and diesel engines, including those equipped with a particulate filter, from Volkswagen, Audi, Skoda and Seat.",
  },
  {
    productImg: productsImg.shellGas,
    productTitle: "Shell Helix Ultra ECT 5W-30",
    productPara:
      "Shell Helix Ultra ECT 5W-30 is a 100% synthetic motor oil, formulated with the most advanced technology compatible with emission control systems, keeping particulate filters clean so that the engine works at full performance.",
  },
  {
    productImg: productsImg.shellGas,
    productTitle: "Shell Helix Ultra ECT C2 C3 0W-30",
    productPara:
      "Shell Helix Ultra ECT C2/C3 is a 100% synthetic engine oil, formulated with the latest Shell technology compatible with the most advanced emission control systems, which helps keep particulate filters clean and the engine running at peak performance. . Reduces engine friction, contributing to fuel savings.",
  },
  {
    productImg: productsImg.shellGas,
    productTitle: "Shell Helix Ultra ECT C3 5W-30",
    productPara:
      "Shell Helix Ultra ECT C3 has been formulated with the most advanced technology, compatible with emission control systems, which helps keep particulate filters clean and provides the best engine performance. Reduces friction and offers greater fuel economy.",
  },
  {
    productImg: productsImg.shellGas,
    productTitle: "Shell Helix Ultra Professional AF 5W-20",
    productPara:
      "Shell Helix Ultra Professional AF 5W-20 is a 100% synthetic motor oil designed to meet the most demanding requirements of high performance gasoline engines, including Ford 'Ecoboost' engines.",
  },
  {
    productImg: productsImg.shellGas,
    productTitle: "Shell Helix Ultra Professional AFL 5W-30",
    productPara:
      "Shell Helix Ultra Professional AFL 5W-30 is a 100% synthetic motor oil designed to meet the most demanding requirements of high performance engines, including Ford, Jaguar, Mazda and those requiring ACEA C1.",
  },
  {
    productImg: productsImg.shellGas,
    productTitle: "Shell Helix Ultra Professional AG 5W-30",
    productPara:
      "Shell Helix Ultra Professional AG 5w-30 is a 100% synthetic motor oil designed to meet the most demanding requirements of high performance engines, including General Motors and those requiring API SN or ACEA C3 specifications.",
  },
  {
    productImg: productsImg.shellGas,
    productTitle: "Shell Helix Ultra Professional AJ-L 0W-30",
    productPara:
      "Shell Helix Ultra Professional AJL 0W-30 is a 100% synthetic motor oil designed to meet the most demanding requirements of high performance Jaguar-Land Rover and Ford engines and those requiring ACEA C2.",
  },
  {
    productImg: productsImg.shellGas,
    productTitle: "Shell Helix Ultra Professional AJ-L 5W-30",
    productPara:
      "Shell Helix Ultra Professional AJL 5W-30 is a 100% synthetic motor oil designed to meet the most demanding requirements of Jaguar-Land Rover high performance engines and those requiring ACEA C1.",
  },
  {
    productImg: productsImg.shellGas,
    productTitle: "Shell Helix Ultra Professional AP-L 0W-30",
    productPara:
      "Shell Helix Ultra Professional AP-L 0W-30 is a 100% synthetic motor oil designed to meet the most demanding requirements of high performance engines including Peugeot, Citroen and those requiring the ACEA C2 specification.",
  },
  {
    productImg: productsImg.shellGas,
    productTitle: "Shell Helix Ultra Professional AP-L 5W-30",
    productPara:
      "Shell Helix Ultra Professional AP-L 5W-30 is a 100% synthetic motor oil designed to meet the most demanding requirements of high performance engines including Peugeot, Citroen and those requiring the ACEA C2 specification.",
  },
  {
    productImg: productsImg.shellGas,
    productTitle: "Shell Helix Ultra Professional AR-L 5W-30",
    productPara:
      "Shell Helix Ultra Professional AR-L 5W-30 is a 100% synthetic motor oil designed to meet the most demanding requirements of high performance engines including Renault and those requiring the ACEA C4 specification.",
  },
  {
    productImg: productsImg.shellGas,
    productTitle: "Shell Helix Ultra Professional AS-L 0W-20",
    productPara:
      "Shell Helix Ultra Professional AS-L 0W-20 is a 100% synthetic motor oil designed to meet the most demanding requirements of high performance engines including Volvo and those requiring ACEA C5 compliance.",
  },
  {
    productImg: productsImg.shellGas,
    productTitle: "Shell Helix Ultra Professional AV-L 0W-20",
    productPara:
      "Shell Helix Ultra Professional AV-L 0W-20 is a 100% synthetic motor oil designed to meet the particular requirements of high performance engines from Audi, VW and Porsche.",
  },
  {
    productImg: productsImg.shellHelix10w60,
    productTitle: "Shell Helix Ultra Racing 10W-60",
    productPara:
      "Shell Helix Ultra Racing is a 100% synthetic motor oil, it meets the most demanding requirements of the highest performance engines, including those that need an engine oil with API SN or ACEA A3/B4 specification.",
  },
  {
    productImg: productsImg.shellRimula10w,
    productTitle: "Shell Rimula R3 10W",
    productPara:
      "Shell Rimula R3 is a single-grade heavy-duty diesel engine oil that adapts to your driving needs and provides the right protection for your engine. It has detergent properties that keep pistons and other engine parts clean, protecting against wear and deposit formation, to prolong the life of your engine and optimize its performance.",
  },
  {
    productImg: productsImg.shellRimula10w,
    productTitle: "Shell Rimula R3 NG 15W-40",
    productPara:
      "Shell Rimula R3 NG 15W-40 is a motor oil that is specially formulated to adapt and protect under the full range of pressures and temperatures in natural gas engines suitable for mobile vehicles that run on 100% LNG/CNG fuel, approved for use in Cummins LNG/CNG engines.",
  },
  {
    productImg: productsImg.shellGas,
    productTitle: "Shell Rimula R3 Turbo 15W-40",
    productPara:
      "Shell Rimula R3 Turbo's high oxidation resistance and thermal oil stability provide a high level of protection against piston deposits. Thanks to the use of high-performance dispersants to control sludge and deposits in other parts of the engine, there is an improvement in engine cleanliness.",
  },
  {
    productImg: productsImg.shellRimula10w,
    productTitle: "Shell Rimula R3+ 30",
    productPara:
      "Shell Rimula R3 adapts to driving conditions and provides extra protection and keeps pistons and other piston parts clean. Provides wear protection and extended engine life, offering protection against deposit formation and improved engine performance.",
  },
  {
    productImg: productsImg.shellRimula10w,
    productTitle: "Shell Rimula R3+ 40",
    productPara:
      "Shell Rimula R3 adapts to driving conditions and provides extra protection and keeps pistons and other piston parts clean. Provides wear protection and extended engine life, offering protection against deposit formation and improved engine performance.",
  },
  {
    productImg: productsImg.shellRimula15w40,
    productTitle: "Shell Rimula R4 15W-40",
    productPara:
      "Shell Rimula R4 15W40 is a multigrade motor oil for heavy vehicles, it combines the technology of its base oils and high performance additives to guarantee the protection of your engine in a wide range of temperatures and pressures, from the high temperatures reached in pistons to the extreme pressures of the valve train.",
  },
  {
    productImg: productsImg.shellRimula15w40,
    productTitle: "Shell Rimula R4 L 15W40",
    productPara:
      "Shell Rimula R4 L 15W40 is a multi-grade heavy-duty diesel engine oil, featuring Low-Ash (Low-SAPS) additive technology, protecting modern low-emission engines even in the most severe driving conditions.",
  },
  {
    productImg: productsImg.shellRimula15w40,
    productTitle: "Shell Rimula R4 X 15W-40",
    productPara:
      "Shell Rimula R4 X is a heavy-duty motor oil containing selected additives designed to provide triple protection and improve engine and oil durability in three critical areas: acid and corrosion control, engine wear reduction and deposit control. Helps reduce maintenance costs and increase vehicle reliability. Suitable for most naturally aspirated or turbocharged heavy duty diesel engines for both on and off-highway applications.",
  },
  {
    productImg: productsImg.shellRimulaR5,
    productTitle: "Shell Rimula R5 E 10W-40",
    productPara:
      "Shell Rimula R5 LE 10w-30 includes “Low – Saps” low ash additive technology to increase compatibility with the latest exhaust gas treatment systems while offering fuel economy.",
  },
  {
    productImg: productsImg.shellGas,
    productTitle: "Shell Rimula R5 LE 10W-30",
    productPara:
      "Shell Rimula R5 LE 10w-30 includes “Low – Saps” low ash additive technology to increase compatibility with the latest exhaust gas treatment systems while offering fuel economy.",
  },
  {
    productImg: productsImg.shellRimulaR5Le10,
    productTitle: "Shell Rimula R5 LE 10W-40",
    productPara:
      "Shell Rimula R5 LE 10w-40 is an oil with synthetic technology for heavy-duty diesel engines.",
  },
  {
    productImg: productsImg.shellRimulaR5Lm10,
    productTitle: "Shell Rimula R5 LM 10W-40",
    productPara:
      "Shell Rimula R5 LM 10w-40 is a “Low-SAPS” low ash content oil that is formulated using synthetic technology which increases engine protection and performance, extends drain intervals and saves on maintenance costs.",
  },
  {
    productImg: productsImg.shellGas,
    productTitle: "Shell Rimula R6 LM 10W-40",
    productPara:
      "Shell Rimula R6 LM is a 100% synthetic motor oil for heavy duty diesel engines, it is a “Low-SAPS” oil with unique additive technology to prevent engine wear. Its engine protection capacity has been increased with the new synthetic technology. The result is an oil indicated for long maintenance intervals, with exceptional cleaning capacity and protection against engine wear.",
  },
  {
    productImg: productsImg.shellGas,
    productTitle: "Shell Rimula R6 M 10W-40",
    productPara:
      "Shell Rimula R6 M is a 100% synthetic motor oil for heavy vehicles, it contains advanced technology of multifunctional additives, which provide adequate protection for your engine, thanks to its improved formula it allows less maintenance and at the same time protects effectively against the formation of deposits and sludge, providing excellent piston cleanliness and contributing to fuel economy.",
  },
  {
    productImg: productsImg.shellRimula5W_30B,
    productTitle: "Shell Rimula R6 MS 10W-40",
    productPara:
      "Shell Rimula R6 MS 10W-40 is a 100% synthetic heavy-duty motor oil.",
  },
  {
    productImg: productsImg.shellAlt,
    productTitle: "Shell Rimula R7 Plus AI 0W-20",
    productPara:
      "Shell Rimula R7 Plus AI 0W-20 is a 100% synthetic HTHS low viscosity motor oil for heavy vehicles, with high performance “Low-SAPS” additive technology, it has been formulated to provide better fuel economy and favor emissions reduction. Offers enhanced protection with synthetic technology that significantly reduces friction in the engine to enable extended drain intervals. Suitable for latest technology engines.",
  },
  {
    productImg: productsImg.shellAlt,
    productTitle: "Shell Rimula R7 Plus AM 5W-20",
    productPara:
      "Shell Rimula R7 Plus AM 5W-20 is a fully synthetic HTHVS low strength diesel engine oil with efficient, high performance “Low-Saps” additive technology that is designed to deliver increased fuel economy and reduced emissions benefits. . Protection is enhanced with synthetic technology that significantly reduces engine friction and offers long maintenance intervals. Suitable for the latest engine technologies.",
  },
  {
    productImg: productsImg.shellRimula5W_30B,
    productTitle: "Shell Rimula Ultra 5W-30",
    productPara:
      "Shell Rimula Ultra 5W-30 is a high performance 100% synthetic diesel engine oil.",
  },
  {
    productImg: productsImg.shellAlt,
    productTitle: "shell-lubricantsShell Spirax MB 90",
    productPara:
      "Shell Spirax MB 90 is a high quality, extreme pressure, heavy duty axle oil containing multifunctional additives that provide excellent anti-wear, oxidation stability and anti-corrosion properties.",
  },
  {
    productImg: productsImg.shellSpiraxS1,
    productTitle: "Shell Spirax S1 ATF RATE",
    productPara:
      "Shell Spirax S1 ATF TASA (formerly Shell Spirax S1 ATF TASA) is a high-quality transmission, steering and hydraulic oil for use in heavy-duty vehicles operating in off-road or off-road environments.",
  },
  {
    productImg: productsImg.shellSpiraxS2,
    productTitle: "Shell Spirax S2 A 80W-90",
    productPara:
      "Shell Spirax S2 A 80W-90 (formerly Shell Spirax A 80W-90) has been formulated for use in a wide range of automotive differentials subject to severe working conditions.",
  },
  {
    productImg: productsImg.shellSpiraxS2,
    productTitle: "Shell Spirax S2 A 85W-140",
    productPara:
      "Shell Spirax S2 A 85W-140 (formerly Shell Spirax A 85w140) has been formulated for use in a wide range of automotive differentials subject to severe working conditions.",
  },
  {
    productImg: productsImg.shellAlt,
    productTitle: "Shell Spirax S2 ALS 90",
    productPara:
      "Shell Spirax S2 ALS 90 is a high quality GL-5 lubricant for limited slip differentials. It has been formulated for use in a wide range of limited slip limited slip differentials.",
  },
  {
    productImg: productsImg.shellSpiraxS2,
    productTitle: "Shell Spirax S2 ATF AX",
    productPara:
      "Shell Spirax S2 ATF AX (formerly Shell Donax TA (D-21666) for high quality transmissions, suitable for passenger cars and heavy duty automatic transmissions, as well as power steering and hydraulic systems.",
  },
  {
    productImg: productsImg.shellGas,
    productTitle: "Shell Spirax S2 G 80W-90",
    productPara:
      "Shell Spirax S2 G 80W-90 (formerly Shell Spirax G 80W-90) is an automotive gear lubricant containing the multifunctional additives required for medium pressure conditions.",
  },
  {
    productImg: productsImg.shellAlt,
    productTitle: "Shell Spirax S3 AS 80W-140",
    productPara:
      "Shell Spirax S3 AS 80W-140 is a high performance axle oil, GL-5, specially designed for Scania. For use in a wide variety of automotive axle applications subjected to severe working conditions.",
  },
  {
    productImg: productsImg.shellSpiraxS3,
    productTitle: "Shell Spirax S3 AX 80W-90",
    productPara:
      "Shell Spirax S3 AX 80W-90 is a high performance API GL-5 oil for moderately to heavily loaded gears and axles and in off-road applications where an SAE 80W-90 viscosity is required.",
  },
  {
    productImg: productsImg.shellAlt,
    productTitle: "Shell Spirax S3 AX 85W-140",
    productPara:
      "Shell Spirax S3 AX 85W-140 is a High Performance oil for general GL-5 transmission applications.",
  },
  {
    productImg: productsImg.shellSpiraxg80w,
    productTitle: "Shell Spirax S3 G 80W",
    productPara:
      "Shell Spirax S3 G 80W GL-4 (Shell Spirax GX 80W) is a long life gearbox fluid that provides benefits based on improved levels of performance to meet gearbox requirements. Formulated with mineral base oils and a new technology of specially optimized additives, they improve the lubrication of the power transmission train and extend the interval between oil changes. High performance manual gearbox oil for Mercedes and other Original Equipment Manufacturers (OEMs).",
  },
  {
    productImg: productsImg.shellGas,
    productTitle: "Shell Spirax S3T",
    productPara:
      "Shell Spirax S3 T (formerly Shell Harvella T 15W-40) is a Super Tractor Oil Universal (STOU) type oil designed for use in a wide range of modern agricultural equipment. Its formulation with high viscosity index base oils and advanced additives provide high, reliable performance in a wide variety of agricultural applications.",
  },
  {
    productImg: productsImg.shellSpiraxS3,
    productTitle: "Shell Spirax S3 TLV",
    productPara:
      "Shell Spirax S3 TLV is a premium transmission and hydraulic oil for tractors. Low viscosity SAE 5W-20, specially designed with friction modifiers, for use in oil-immersed transmissions, differentials, hydraulic systems and brakes, as well as power take-off systems on farm tractors and other off-highway equipment. Provides high noise-free performance in oil-immersed (wet) brakes combined with reliable, trouble-free operation in all other applications.",
  },
  {
    productImg: productsImg.shellAlt,
    productTitle: "Shell Spirax S4 AT 75W-90",
    productPara:
      "Shell Spirax S4 AT 75W-90 is a premium quality semi-synthetic automotive gear lubricant specially designed for gearboxes and axles.",
  },
  {
    productImg: productsImg.shellSpiraxS2,
    productTitle: "Shell Spirax S4 ATF HDX",
    productPara:
      "Shell Spirax S4 ATF HDX (formerly Shell Donax TX) is an advanced technology synthetic automatic transmission oil, suitable for a wide range of passenger car and heavy-duty applications, high performance that allows long drain intervals even in the most severe conditions. .",
  },
  {
    productImg: productsImg.shellSpiraxS2,
    productTitle: "Shell Spirax S4 CX 10W",
    productPara:
      "Shell Spirax S4 CX 10W (formerly Shell Donax TC 10W) ​​is a high performance off-highway hydraulic and transmission oil to provide trouble-free operation and maximum reliability over the life of the equipment. Spirax S4 CX 10W meets the demanding requirements of modern transmissions, oil-immersed brakes as well as hydraulic systems of heavy-duty civil construction vehicles.",
  },
  {
    productImg: productsImg.shellAlt,
    productTitle: "Shell Spirax S4 CX 30",
    productPara:
      "Shell Spirax S4 CX 30 is a high performance transmission and hydraulic oil for a multitude of civil engineering vehicle applications.",
  },
  {
    productImg: productsImg.shellSpiraxS2,
    productTitle: "Shell Spirax S4 TXM",
    productPara:
      "Shell Spirax S4 TXM (formerly Shell Donax td) is a Premium Universal Tractor Transmission Oil (UTTO) designed for use in transmissions, hydraulics, oil brakes and other stationary systems found in agricultural tractors and equipment with off-road applications. highway. Shell Spirax S4 TXM is approved by major agricultural equipment manufacturers and is suitable for use in most modern equipment.",
  },
  {
    productImg: productsImg.shellAlt,
    productTitle: "Shell Spirax S4 TXM-I",
    productPara:
      "Shell Spirax S4 TXM-I is a premium multifunctional transmission and hydraulic oil for tractors.",
  },
  {
    productImg: productsImg.shellSpiraxS5Atf,
    productTitle: "Shell Spirax S5 ATF X",
    productPara:
      "Shell Spirax S5 ATF X is a premium, high-performance oil made from a synthetic technology base oil that meets the requirements of most manufacturers for in-service filling of automatic transmissions.",
  },
  {
    productImg: productsImg.shellSpiraxS5Atf,
    productTitle: "Shell Spirax S5 CVT X",
    productPara:
      "Shell Spirax S5 CVT X is a premium lubricant, formulated with exclusive synthetic technology for optimal performance. Ideal for use in CVT type chain and belt transmissions, from multiple manufacturers (OEM's)",
  },
  {
    productImg: productsImg.shellSpiraxS5dct,
    productTitle: "Shell Spirax S5 DCT X",
    productPara:
      "Shell Spirax S5 DCT X is a high performance, synthetic technology oil suitable for use in multi-manufacturer (OEM) dual-clutch transmissions.",
  },
  {
    productImg: productsImg.shellAlt,
    productTitle: "Shell Spirax S6 ATF 134M",
    productPara:
      "Shell Spirax S6 ATF 134M is a premium lubricant for Mercedes Benz 5 and 7 speed automatic transmissions and NAG2VSport transmissions.",
  },
  {
    productImg: productsImg.shellAlt,
    productTitle: "Shell Spirax S6 ATF 134ME",
    productPara:
      "Shell Spirax S6 ATF 134ME is a premium lubricant for the latest generation of Mercedes Benz 7-speed automatic transmissions.",
  },
  {
    productImg: productsImg.shellSpiraxS6AtfA,
    productTitle: "Shell Spirax S6 ATF A295",
    productPara:
      "Shell Spirax S6 ATF A295 is a 100% synthetic commercial vehicle automatic transmission oil that has been specially designed and approved for use in transmissions that require an Allison TES-295 specification fluid.",
  },
  {
    productImg: productsImg.shellAlt,
    productTitle: "Shell Spirax S6 ATF A668",
    productPara:
      "Shell Spirax S6 ATF A668 is a synthetic heavy duty automatic transmission lubricant suitable for extended drain intervals.",
  },
  {
    productImg: productsImg.shellAlt,
    productTitle: "Shell Spirax S6 ATF D971",
    productPara:
      "Shell Spirax S6 ATF D971 is a premium lubricant with synthetic technology for the latest generation of Mercedes-Benz 9-speed automatic transmissions (9G-Tronic).",
  },
  {
    productImg: productsImg.shellAlt,
    productTitle: "Shell Spirax S6 ATF VM Plus",
    productPara:
      "Shell Spirax S6 ATF VM Plus is a premium technology oil for Voith automatic transmissions for extended drain intervals.",
  },
  {
    productImg: productsImg.shellSpiraxS6AtfX,
    productTitle: "Shell Spirax S6 ATF X",
    productPara:
      "Shell Spirax S6 ATF X is a premium synthetic technology oil for multi-ATF type automatic transmissions with low viscosity requirements in General Motors, Ford and many other cars and trucks.  ",
  },
  {
    productImg: productsImg.shellGas,
    productTitle: "Shell Spirax S6 ATF ZM",
    productPara:
      "Shell Spirax S6 ATF ZM (formerly Shell Donax TZ) is a high-quality, 100% synthetic heavy-duty transmission oil, specially designed in collaboration with ZF as an exclusive lubricant for the latest generation of ZF Ecomat transmissions. Spirax S6 ATF ZM is the ultimate high performance automatic transmission fluid, extending drain intervals even under the toughest and most demanding conditions.",
  },
  {
    productImg: productsImg.shellSpiraxS6AXME,
    productTitle: "Shell Spirax S6 AXME 75W-140",
    productPara:
      "Shell Spirax S6 AXME 75w-140 is a 100% synthetic premium performance extended drain axle oil for Meritor, Scania and others, designed to provide ultimate protection for the latest heavy-duty axles and transmissions.",
  },
  {
    productImg: productsImg.shellSpiraxS6GXME,
    productTitle: "Shell Spirax S6 GXME 75W-80",
    productPara:
      "Shell Spirax S6 GXME 75W-80 (formerly Shell Spirax S6 GXME 75W-80) is a long life oil designed to provide maximum performance and protection, meeting the requirements of industrial vehicle gearboxes. Synthetic base oils specially formulated with unique new additive technology that provides improved lubrication and extended equipment life.",
  },
  {
    productImg: productsImg.shellAlt,
    productTitle: "Shell Spirax S6 TXME",
    productPara:
      "Shell Spirax S6 TXME is a SAE 10W-30 viscosity grade synthetic oil for multi-purpose tractors (UTTO).",
  },
];

export const metalSpecial = [
  {
    productImg: productsImg.fuchAnticorit,
    productTitle: "FC Anticorit DFW 8101",
    productPara:
      "Anticorit DFW 8101 is a waterproof protective anticorrosive, based on solvents free of aromatic compounds, so it has a very low, almost imperceptible odor.",
  },

  {
    productImg: productsImg.fuchAnticorit,
    productTitle: "FC Anticorit VCI UNI O-40",
    productPara:
      "Anticorit VCI UNI O-40 is a vapor phase anticorrosion fluid, based on mineral oil, amber in color, vapor phase anticorrosive fluid with unique protective properties.",
  },
  {
    productImg: productsImg.fuchAlt,
    productTitle: "FC Compound S",
    productPara:
      "Compound S is a paste for extrusion and deformation of metals, anti-adherent for lacquers and paints, mainly used as a pressing and forming lubricant in the manufacture of cans and containers in the food and beverage industries.",
  },
  {
    productImg: productsImg.fuchAlt,
    productTitle: "FC Ecocool MG 540",
    productPara:
      "Ecocool MG 540 is a semi-synthetic soluble fluid for machining all types of metals.",
  },
  {
    productImg: productsImg.fuchAlt,
    productTitle: "FC Gleitmo 165",
    productPara:
      "Gleitmo 165 is a metallurgical paste for hot screws with separating and lubricating properties in a very wide temperature range, which makes it maintain a neutral behavior towards screw materials.",
  },
  {
    productImg: productsImg.motulStagAlt,
    productTitle: "Motul Stag 15/PC",
    productPara:
      "Motul Stag 15/PC is a special lubricant for cutting extruded aluminum profiles, it is used to lubricate the blades during the cutting of extruded aluminum profiles.",
  },
  {
    productImg: productsImg.motulStagAlt,
    productTitle: "Motul Stag 16/PC",
    productPara:
      "Motul Stag 16/PC is a special lubricant for cutting extruded aluminum profiles, it is used to lubricate blades/disk saws used to cut extruded aluminum profiles.",
  },
  {
    productImg: productsImg.motulStagAlt,
    productTitle: "Motul Stag 40 BC/2",
    productPara:
      "Motul Stag 40 BC/2 is a biodegradable lubricant for cutting aluminum, copper and ferrous alloys, it is a pure lubricant obtained from raw materials of natural origin, extremely refined. Its high purity and high performance additives guarantee a very high efficiency.",
  },
  {
    productImg: productsImg.motulStagAlt,
    productTitle: "Motul Stag 98/AL",
    productPara:
      "Motul Stag 98/AL is an anhydrous lubricant for the extrusion of aluminum alloys. Used to lubricate the stop shears in extrusion presses for aluminum alloys.",
  },
  {
    productImg: productsImg.motulStagAlt,
    productTitle: "Motul Stag RP/1",
    productPara:
      "Motul Stag RP 1 is an aqueous emulsion based on a semi-synthetic active ingredient that is used for the lubrication of butt shears and billet or saw shears in extrusion of metals and alloys.",
  },
  {
    productImg: productsImg.petronas,
    productTitle: "Petronas Drawol HNS",
    productPara:
      "Petronas Drawol HNS is an aqueous fluid for complete use and very low dry extract, slow but total drying, designed for deformation, cutting and cold stamping of metals, it protects the parts after use and the parts processed with the product do not require degreasing. in most cases, it minimizes tool and fixture wear.",
  },
  {
    productImg: productsImg.petronas,
    productTitle: "Petronas Drawol HNS ESP",
    productPara:
      "Petronas Drawol HNS ESP is an aqueous fluid for complete use and very low dry extract of slow but total drying, designed for deformation, cutting and cold stamping of metals, it protects the parts after use and the parts processed with the product do not require degreasing in most cases, minimizes wear on tools and fixtures.",
  },
  {
    productImg: productsImg.petronas,
    productTitle: "Petronas Mecafluid D 128",
    productPara:
      "Petronas Mecafluid D 128 is a whole oil for severe deformation of metals, specially reinforced with extreme pressure additives, friction reducing agents, natural and synthetic adhesion enhancers.",
  },
  {
    productImg: productsImg.petronas,
    productTitle: "Petronas Mecafluid D 3210",
    productPara:
      "Petronas Mecafluid D 3210 is a mineral-based, evanescent metalworking fluid with excellent evaporation properties and minimal dry residue.",
  },
  {
    productImg: productsImg.petronas,
    productTitle: "Petronas Mecafluid N 10 Syn",
    productPara:
      "Petronas Mecafluid N 10 SYN is a chlorine-free synthetic pure cutting oil, with strong extreme pressure additives, natural and synthetic additives that provide excellent lubricity and prevent the formation of mist and foam.",
  },
  {
    productImg: productsImg.petronas,
    productTitle: "Petronas Mecafluid N 32",
    productPara:
      "Petronas Mecafluid N 32 is a mineral-based, chlorine-free, multifunctional neat cutting oil containing corrosion inhibitors, anti-wear agents, chlorine-free extreme pressure, lubricity and friction-reducing additives.",
  },
  {
    productImg: productsImg.petronas,
    productTitle: "Petronas Mecafluid No. 5",
    productPara:
      "Petronas Mecafluid N 5 is a pure cutting oil for high cutting speeds, low viscosity and chlorine-free, it contains natural and synthetic additives that improve lubricity and reduce part/tool ​​friction, extreme pressure additives and foam inhibitors. and oil mist.",
  },
  {
    productImg: productsImg.petronas,
    productTitle: "Petronas Mecafluid NE 8 SYN",
    productPara:
      "Petronas Mecafluid NE 8 Syn is a supreme performance neat metalworking oil, developed for use undiluted in cutting and grinding ferrous metals and aluminum under normal and high performance conditions.",
  },
  {
    productImg: productsImg.petronas,
    productTitle: "Petronas Mecafluid NO 23 EP",
    productPara:
      "Petronas Mecafluid NO 23 EP is a high performance neat metalworking oil (chlorine free), specially developed to be used undiluted. For automatic work on aluminum and steel materials of automatic machining.",
  },
  {
    productImg: productsImg.petronas,
    productTitle: "Petronas Mecafluid P5",
    productPara:
      "Petronas Mecafluid P 5 is a water-repellent anti-corrosion protective oil, mineral-based to prevent corrosion, contains demulsifying additives that displace water, prevents rust produced after pickling on ferrous metals.",
  },
  {
    productImg: productsImg.petronas,
    productTitle: "Petronas Mecafluid S 10 FF",
    productPara:
      "Petronas Mecafluid S 10 FF is a soluble semi-synthetic cutting fluid formulated with the latest technological advances related to the most advanced machining processes.",
  },
  {
    productImg: productsImg.petronas,
    productTitle: "Petronas Mecafluid S 11 ECO",
    productPara:
      "Petronas Mecafluid S 11 ECO is a water soluble synthetic machining fluid with low environmental impact, soluble in water that forms transparent and limpid solutions. Contains no boron, chlorine, or formaldehyde-based biocides. Contains rust inhibitors, antioxidants and detergent and wetting additives.",
  },
  {
    productImg: productsImg.petronas,
    productTitle: "Petronas Mecafluid S 2 FF",
    productPara:
      "Petronas Mecafluid S 2 FF is a soluble semi-synthetic cutting fluid formulated following the latest technological advances related to the most advanced machining processes.",
  },
  {
    productImg: productsImg.petronas,
    productTitle: "Petronas Mecafluid S 3 FFB",
    productPara:
      "Petronas Mecafluid S 3 FFB is a semi-synthetic fluid for metal machining, it is coolant and emulsifiable, for cutting and grinding free of chlorine, secondary amines or other derivatives and formaldehyde donor bactericity.",
  },
  {
    productImg: productsImg.petronas,
    productTitle: "Petronas Mecafluid SM 4001 ECO",
    productPara:
      "Petronas Mecafluid SM 4001 ECO is a boron- and formaldehyde-free, supreme performance, water-miscible metalworking fluid specially developed for grinding and machining of aluminium, yellow metals, steel and aerospace alloys operating under harsh conditions. normal to high performance.",
  },
  {
    productImg: productsImg.petronas,
    productTitle: "Petronas Mecafluid SM 5001 ECO",
    productPara:
      "Petronas Mecafluid SM 5001 ECO is a boron and formaldehyde free supreme performance water miscible metal machining fluid specially developed for all chip forming processes, cast iron, aluminium, copper alloys, hard alloys and alloys for the aerospace sector that operate under normal conditions at high performance.",
  },
  {
    productImg: productsImg.petronas,
    productTitle: "Petronas Mecafluid SM 59",
    productPara:
      "Petronas Mecafluid SM 59 is a boron and chlorine free emulsifiable machining fluid based on highly refined mineral oil containing wetting agents, biocides and corrosion inhibitors. Mixed with water, it forms a milky emulsion with high lubrication properties.",
  },
  {
    productImg: productsImg.petronas,
    productTitle: "Petronas Mecafluid SM 69 NF",
    productPara:
      "Petronas Mecafluid SM 69 NF is an emulsifiable machining fluid for metalworking in high-strength operations, with or without chipping. It is free of chlorine, boron and formaldehyde.",
  },
  {
    productImg: productsImg.petronas,
    productTitle: "Petronas Mecafluid SS 1001 ECO",
    productPara:
      "Petronas Mecafluid SS 1001 ECO is a boron- and formaldehyde-free supreme performance water-miscible metalworking fluid specially developed for grinding cast iron, steel alloys as well as carbon steel and aluminum operating under normal conditions.",
  },
  {
    productImg: productsImg.petronas,
    productTitle: "Petronas Mecafluid SS 3001",
    productPara:
      "Petronas Mecafluid SS 3001 is a high performance water miscible metalworking fluid, specially developed for general grinding and machining of cast iron and alloys as well as carbon steel and aluminum operating under normal conditions at high performance.",
  },
  {
    productImg: productsImg.petronas,
    productTitle: "Petronas Mecafluid SS 3001 ECO",
    productPara:
      "Petronas Mecafluid SS 3001 ECO is a boron- and formaldehyde-free, supreme performance, water-miscible metalworking fluid developed for general machining, turning, milling, drilling and sawing operations on cast iron, steel, high-alloy steel and aluminum operating under normal conditions at high performance.",
  },
  {
    productImg: productsImg.petronas,
    productTitle: "Petronas Mecafluid SS 4001 ECO",
    productPara:
      "Petronas Mecafluid SS 4001 ECO is a boron and formaldehyde free supreme performance water miscible metal machining fluid, specially developed for machining aluminum alloys and operating under normal conditions at high performance.",
  },
  {
    productImg: productsImg.petronas,
    productTitle: "Petronas Mecafluid Syn 1001 ECO",
    productPara:
      "Petronas Mecafluid Syn 1001 ECO is a boron- and formadehyde-free supreme performance water-miscible metalworking fluid specially designed for grinding alloys, carbon steel and cast iron operating under normal conditions at high performance.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Adrana D 208",
    productPara:
      "classic metalworking fluid, soluble cutting fluid for machining, coolant, semi-synthetic, which forms stable microemulsions based on high quality mineral oil and a package of sophisticated surfactants. It is recommended for general machining and grinding operations on various materials; In addition, it is suitable for use in both individual machines and centralized systems. This versatile microemulsion can be used in a wide range of water hardnesses; Furthermore, it is designed with excellent detergency and high wetting capacity to create a clean working environment. HOUGHTON ADRANA D 208 is a classic semi-synthetic boro-amine metalworking fluid that forms a stable microemulsion. It is based on high-quality mineral oil and a sophisticated package of surfactants. Soluble cutting fluid for machining, coolant. ADRANA D 208 is a combination of mineral oil with emulsifiers and lubricating and anticorrosive additives. Properties: Free of chlorine and DEA (diethanolamine) High detergent capacity Extremely high antifoam performance Free of formaldehyde Free of Hexahydrotriazine The product ADRANA D 208 is a high quality and very versatile refrigerant that easily disperses in water and that it provides a stable and odorless microemulsion, with optimal detergent properties and a high refrigeration capacity without loss of foam formation control. These characteristics together with a good resistance to microbial contamination that prevents its consequent degradation, makes the ADRANA D 208 product useful in applications of medium to high severity,    ",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Antifreeze F-30",
    productPara:
      "QUAKER HOUGHTON ANTIFREEZE F-30 is a 100% organic fluid composed of monoethylene glycol and anticorrosive additives. Free of borates, phosphates, nitrites and silicates. ANTIFREEZE F-30 is used undiluted as supplied. To compensate for water evaporation, demineralized water or water with a low salt content should be used. It is suitable for closed cooling circuits.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Antifreeze F-50",
    productPara:
      "ANTIFREEZE F-50 is a 100% organic fluid composed of monoethylene glycol and anticorrosive additives. Free of borates, phosphates, nitrites and silicates. ANTIFREEZE F-50 is used undiluted as supplied. To compensate for water evaporation, demineralized water or water with a low salt content should be used. It is suitable for closed cooling circuits.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Cindolube 201",
    productPara:
      "HOUGHTON CINDOLUBE 201 is a lubricating fluid with polar additives. Free of sulfur and chlorine and biodegradability of 90%. CINDOLUBE 201 is suitable for forming all types of metals, especially indicated for aluminum cold deformation applications. Especially indicated for microlubrication processes.",
  },

  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Cindolube 3030 E",
    productPara:
      "QUAKER HOUGHTON CINDOLUBE 3030 E is a pure oil for metal working, it is a combination of paraffinic mineral oil, with a high degree of refining, and specific anti-corrosion, wetting and polar lubricant additives.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Cindolube 3102",
    productPara:
      "QUAKER HOUGHTON CINDOLUBE 3102 is a pure oil for metal forming, it is a combination of paraffinic mineral oil, highly refined, and lubricating and anticorrosive additives. Its composition is free of sulfur and chlorine.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Cindolube 4683 E",
    productPara:
      "QUAKER HOUGHTON CINDOLUBE 4683 E is a pure oil for metalworking, it is a combination of paraffinic mineral oil, highly refined, and lubricating and anticorrosive additives. Contains the ND additive, specific for aluminium, which prevents surface oxidation and allows a particularly shiny finish to be obtained.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Cindolube 6012",
    productPara:
      "CINDOLUBE 6012 is a combination of paraffinic linear hydrocarbons with specific polar additives. Free of sulfur and chlorine. It is a pure oil. CINDOLUBE 6012 is suitable as a lubricant in forming processes of all types of metals, by plastic deformation, in which a minimum residual film and rapid evaporation of the fluid are required.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Cut Max 1-03 FS",
    productPara:
      "QUAKER HOUGHTON CUT-MAX 1-03 FS is a combination of paraffinic mineral oil with antioxidant additives, moisturizers and Extreme Pressure lubricants, to which a component with high detergent power has been incorporated, it is free of chlorine.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Cut Max 225",
    productPara:
      "CUT-MAX 225 is a combination of paraffinic mineral oil with anti-fog, anti-corrosion additives and special EXTREME PRESSURE lubricants. The product is EXEMPT from CHLORINE.  ",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Cut Max 3-95 A",
    productPara:
      "QUAKER HOUGHTON CUT-MAX 3-93 A is a mixture of paraffinic mineral oil with antioxidant additives and Extreme Pressure lubricants. Product without chlorine.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Cut Max 600",
    productPara:
      "Pure cutting oil for severe metal machining. CUT-MAX 600 is a combination of paraffinic mineral oil with anti-fog additives and chlorinated EXTREME PRESSURE lubricants. CUT-MAX 600 is suitable for severe machining of all types of metals (including stainless steel and inconel) and is especially indicated for broaching and gear carving and shaving.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Cut Max AL E",
    productPara:
      "CUT-MAX AL-E is a blend of paraffinic mineral oil with lubricating and wetting additives. Exempt from CHLORINE and SULFUR. CUT-MAX AL-E is especially indicated as a coolant in polishing operations on all types of metals. It is also suitable for general machining, of low severity, when a fluid oil with a high refrigerant capacity is required.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Cindolube 4683 E",
    productPara:
      "QUAKER HOUGHTON CINDOLUBE 4683 E is a pure oil for metalworking, it is a combination of paraffinic mineral oil, highly refined, and lubricating and anticorrosive additives. Contains the ND additive, specific for aluminium, which prevents surface oxidation and allows a particularly shiny finish to be obtained.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Cut Max BR 212",
    productPara:
      "CUT-MAX BR 212 is a combination of parffinic mineral oil with antioxidant, anticorrosive, anti-fog additives and special EXTREME PRESSURE lubricants, which prevent metal welding on the cutting tool and prolong its useful life. CUT-MAX BR 212 is free of CHLORINE.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Cut Max BR 500",
    productPara:
      "CUT-MAX BR 500 is a combination of refined paraffinic mineral oil with anti-corrosion additives and EXTREME PRESSURE lubricants. Pure cutting oil for severe metal machining",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Cut Max DC 20",
    productPara:
      "CUT-MAX DC 20 is a pure cutting oil for machining. It is a mixture of paraffinic mineral oil with antioxidant additives. Exempt from CHLORINE and ACTIVE SULFUR.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Cut Max GM 38",
    productPara:
      "CUT MAX GM 38 is a pure cutting oil for machining and gear hobbing. Cut Max GM38 is a formulated product based on paraffinic mineral oil, with antioxidant, antiwear, anticorrosive and extreme pressure additives. The product is free of CHLORINE and active Sulfur.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Cut Max IX",
    productPara:
      "Cut-Max IX is a combination of refined paraffinic mineral oil, with antioxidant and anticorrosive additives, and Extreme Pressure and unctuous lubricants.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Cut Max LT 101",
    productPara:
      "Cut-Max LT-101 is a paraffinic mineral oil with lubricating and moisturizing additives, free of CHLORINE and active SULFUR.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Cut Max LT 22",
    productPara:
      "CUT-MAX LT-22 is a refined paraffinic mineral oil, with anti-corrosion, anti-fog and anti-wear additives, free of CHLORINE.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Cut Max LT 23",
    productPara:
      "Cut-Max LT-23 is a mixture of paraffinic mineral oil with lubricant additives free of CHLORINE and active SULFUR.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Cut Max ML 20",
    productPara:
      "Cut-Max ML 20 is a high performance lubricant designed for micro-mist lubrication in machining operations. It is a synthetic fluid, free of mineral oil, sulfur and chlorine.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Cut Max ML 40",
    productPara:
      "Cut-Max ML 40 is a high performance lubricant designed for micro-mist lubrication in machining operations. Cut-Max ML 40 is a synthetic fluid, free of mineral oil, sulfur and chlorine.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Cut Max SE2",
    productPara:
      "Cut-Max SE2 is a low viscosity dielectric fluid for superfine finishing operations. Dielectric cutting fluid for EDM.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Cut Max SH 10",
    productPara:
      "Cut-Max SH10 is a pure cutting oil for high speed grinding operations.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Cut Max STAR",
    productPara:
      "Cut-Max STAR is a combination of refined paraffinic mineral oil, with antioxidant, anticorrosive, Medium and Extreme Pressure additives, free of CHLORINE.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Cut Max TK 055 BR",
    productPara:
      "QUAKER HOUGHTON CUT-MAX TK 055 BR is a pure cutting oil for grinding and shaving gears, it is a mixture of highly refined paraffinic mineral oil with anti-wear additives and Extreme Pressure lubricants.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Dromus B",
    productPara:
      "QUAKER HOUGHTON DROMUS B is an emulsifiable cutting oil, with a high mineral oil content, developed to prepare an emulsion for metalworking. Free of boron, nitrites, phenols, chlorine and biocides.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Ensis DW 1262",
    productPara:
      "ENSIS DW 1262 is a fluid based on a mixture of dearomatized solvent and carefully selected high-performance additives that ensure protection in the interior for a maximum of 12 months. ENSIS DW 1262 is a premium quality water repellent anti-corrosion agent that produces a very thin, crack resistant waxy film, neutralizes fingerprints and can be easily removed if necessary. ENSIS DW 1262 is effective on ferrous and non-ferrous metals. Contains a selection of low odor, high performance additives to ensure worker comfort and is barium free.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Ensis DW 662",
    productPara:
      "ENSIS DW 662 is a fluid based on a mixture of dearomatized solvent and carefully selected high-performance additives that ensure protection in the interior for a maximum of 6 months. ENSIS DW 662 is a premium quality water repellent anti-corrosion agent that produces a very thin waxy film that resists cracking, neutralizes fingerprints and can be easily removed if necessary. ENSIS DW 662 is effective on ferrous and non-ferrous metals. Contains a selection of low odor, high performance additives to ensure worker comfort and is barium free.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Fenella Fluid D 2628",
    productPara:
      "QUAKER HOUGHTON FENELLA FLUID D 2628 is a soluble oil for cold forming. It contains in its formulation special extreme pressure lubrication additives and corrosion inhibitors. Free of bactericides and fungicides, does not contain chlorine.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Fenella Oil BSD 410 N",
    productPara:
      "QUAKER HOUGHTON FENELLA OIL BSD 410 N is a pure oil for cold extrusion, based on highly refined mineral oil with a reduced aromatic content. A combination of polar, extreme pressure and anti-wear additives give the oil excellent extrusion and drawing properties. It is chlorine free.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Fenella VD 201 N",
    productPara:
      "Fenella VD 201 N is based on a high purity solvent with a reduced content of aromatics. Its additive has been selected to provide a high lubricating power together with a minimum amount of dry residue after evaporation. Fenella VD 201 N is free of chlorine, sulfur, phosphorus and heavy metals (zinc).  ",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Ferrocote 131",
    productPara:
      "QUAKER HOUGHTON FERROCOTE 131 is a medium to long-term anti-corrosion-protection oil. It is a concentrate that forms an emulsion with anticorrosive properties especially for steel. It deposits a very active thin film with a dry appearance.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Garia 601 M-22",
    productPara:
      " GARIA 601 M 22 is a pure oil for cutting and grinding. It is based on hydrotreated mineral oil with reduced aromatics content. A combination of polar, extreme pressure and anti-wear additives give the oil high load carrying capacity. The oil achieves a good surface finish on machined parts. GARIA 601 M 22 contains active sulfur and may discolor brass. The oil does not contain zinc or chlorine.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Garia Oil 405 M 32",
    productPara:
      "Garia 405 M 32 is a pure cutting oil for general machining in automatic lathes, milling by generation and in machines for grinding threads and tooth flanks. Garia 405 M 32 is particularly suitable for working on medium to high strength low carbon and alloy steels as well as aluminum or magnesium alloys.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Garia SL 201",
    productPara:
      "GARIA SL 201 is a lubricant for lubrication by minimum quantities. It is based on natural esters with a high content of saturated fatty acids. The selected antioxidants give the oil a high resistance to oxidation. A combination of extreme pressure and anti-wear additives give the oil high load carrying capacity. GARIA SL 201 contains active sulfur and may discolor brass. The oil does not contain zinc or chlorine.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Garia SL 601",
    productPara:
      "Lubricant for lubrication by minimum quantities MQL. Garia SL 601 is based on synthetic esters from renewable sources. Due to the low amount of unsaturated fatty acids, the product shows high oxidation and hydrolytic stability. It is compatible with 2-component paints and sealant materials, such as those used for HEES hydraulic oils. Garia SL 601 does not contain chlorine or mineral oil and has a high EP characteristic.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Hocut 4450",
    productPara:
      "HOCUT 4450, water soluble machining fluid, is a semi-synthetic fluid of advanced technology, free of boron and formaldehyde, which provides a long service life as well as increases cutting performance compared to a conventional product due to the synergy of additives. HOCUT 4450 is recommended for medium machining and grinding of ferrous metals. It forms highly detergent microemulsions especially recommended for severe machining of cast iron, keeping the baths in very clean conditions. HOCUT 4450 can also be used for general machining of aluminum alloys. It can be used both in individual baths and in centralized systems without the need for regular use of biocide treatments. It can be used in water hardness ranges between 5-20 ºHF.",
  },

  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Hocut B 217",
    productPara:
      "Hocut B 217 is a semi-synthetic cutting oil for grinding and machining of metals. It is a combination of mineral oil with emulsifiers and anticorrosive additives, preservatives and lubricants.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Hocut BIO 3300",
    productPara:
      "Hocut Bio 3300 is an emulsifiable cutting oil for metal machining, composed of a high content of mineral oil and esters, with fatty and polar, emulsifying and anticorrosive additives.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Hocut BIO 5500",
    productPara:
      "Hocut Bio 5500 is an emulsifiable cutting oil for metal machining, composed of a high content of mineral oil and esters, with fatty and polar, emulsifying and anticorrosive additives.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Hocut SB 590",
    productPara:
      "Hocut SB 590 is a water soluble machining and grinding fluid, it is a combination of mineral oil with emulsifiers and lubricating and anticorrosive additives. It is free in its formulation of formaldehyde derivatives, secondary amines, boron, chlorine, phenols, heavy metals, nitrites and silicones.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Hocut 3130",
    productPara:
      "Hocut 3130 Water soluble machining fluid is recommended for medium machining of a wide range of materials, including automotive aluminum alloys and high alloy steels. Product specifically designed for high pressure systems without the formation of foam. Thanks to its long service life, the product is suitable for both centralized systems and individual machines without the need for regular biocide treatments. The product is primarily designed for soft water.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Hocut 3270",
    productPara:
      "Hocut HOCUT 3270, water soluble machining fluid, is an advanced technology with high lubricating capacity, free of boron and formaldehyde, which ensures extra long bath life and improved machining performance compared to conventional products.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Hocut 3330",
    productPara:
      "Hocut HOCUT 3330, water soluble machining fluid, is an advanced technology product with high lubricating capacity, free of boron and formaldehyde, which ensures extra long bath life and improved machining performance compared to conventional products. Due to its high lubrication characteristics, HOCUT 3330 is recommended for machining difficult materials such as titanium, inconel and alloy steels. The reduced foaming characteristics are specifically designed for modern high pressure refrigerant supply systems. The product is primarily designed for soft water. Thanks to its extra long service life, the product is suitable both for centralized systems and for machines with a single bath, ",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Hocut 3350",
    productPara:
      "HOCUT 3350 is an advanced technology with high lubricating capacity, free of boron and formaldehyde-derived biocides, which ensures extra-long bath life and improved machining performance compared to conventional products. HOCUT 3350 is recommended for medium machining of a wide range of materials, including aluminum alloys for the automotive industry. Thanks to its long service life, the product is suitable for both centralized systems and individual machines without the need for regular biocide treatments. The product is primarily designed for soft water.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Hocut 3550",
    productPara:
      "HOCUT 3550, water soluble machining fluid, is an advanced technology product with high lubricating capacity, free of boron, chlorine and formaldehyde, which ensures extra long bath life and improved machining performance compared to conventional products. Its emulsions with high lubricating power HOCUT 3550 are recommended for medium to severe machining of all materials for the aerospace sector, including sensitive aluminum alloys for the aerospace industry, titanium, inconel and alloy steels. Thanks to its extra-long pot life, the product is suitable for both centralized systems and individual machines, while the advanced boron-free technology extends the pot life of the emulsion without the need for regular biocide treatments.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Hocut 4730",
    productPara:
      "HOCUT 4730 is a cutting solution that belongs to advanced technology, with high lubricity, which forms emulsions free of boron and formaldehyde derivatives with a long life in use. A special package of additives provides great performance during the machining process compared to other conventional products, being especially recommended for machining aluminum alloys in the automotive sector as well as titanium alloys. HOCUT 4730 is recommended for severe machining of a wide range of materials, including aluminum alloys used in the automotive industry, alloy steels, and difficult-to-machine materials used in the aerospace industry such as titanium and nickel alloys. The product can be used both in centralized systems and in individual machines, while the technology to which it belongs ensures a long useful life of its emulsions without the need for regular biocide treatments. Specially designed to work with soft water with low foam formation.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Hocut 5230",
    productPara:
      "Houghton Hocut 5230 is a water soluble machining and grinding fluid. It is a totally synthetic product, with an advanced lubricant, wetting and anticorrosive package, designed for roughing and finishing operations in steel and cast iron.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Hocut 795 CV",
    productPara:
      "Hocut 795 CV is a water soluble machining fluid, it is a semi-synthetic product of advanced boron-free technology, it ensures an extra-long useful life of the bath and an improved machining performance compared to conventional products.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Houghton Draw 756",
    productPara:
      "Houghton Houghto Draw 756 is a cream colored emulsifiable paste, specially developed for the most severe deformation operations. This product has been formulated based on a mixture of highly lubricating and emulsifying additives that allow maximum performance to be obtained.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Houghton Draw B-75 PER",
    productPara:
      "Houghto Draw B-75 PER is a semi-synthetic tube-forming lubricating fluid. Emulsifiable, biostatic in nature, composed of highly refined paraffinic mineral oil, with emulsifiers and anticorrosive and lubricating additives.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Houghton Draw CB 02/26",
    productPara: " ",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Houghton Draw MP 600",
    productPara:
      "Houghto Draw MP 600 is a soluble lubricant for drawing ferrous metals. Emulsifiable with anticorrosive additives and chlorine-free polar and Extreme Pressure lubricants.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Houghton Draw R 200",
    productPara: " ",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Houghton Draw SHO 68",
    productPara:
      "Houghto Draw SHO 68 is a pure oil for cold drawing, it has a combination of highly refined paraffinic mineral oil, with anti-corrosion additives and polar and extreme pressure lubricants.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Houghton Draw SJ 180",
    productPara:
      "Houghto Draw SJ 180 is a lubricant for plastic deformation of steels, it has a combination of highly refined paraffinic mineral oil, with anticorrosive additives and polar and extreme pressure lubricants.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Houghton Draw TD 51",
    productPara:
      "Houghto Draw TD 51 is a pure oil for severe deformation of metals, they are paraffinic mineral oils with chlorinated lubricant and Extreme Pressure additives.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Houghton Draw TD 52",
    productPara:
      "Houghto Draw TD 52 is a pure oil for severe deformation of metals, they are paraffinic mineral oils with chlorinated lubricant and Extreme Pressure additives.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Houghto Grind 01 RSF",
    productPara:
      "Houghto-Grind 01 RSF is a synthetic cutting fluid for metal machining and grinding. It is a water-soluble refrigeration concentrate, composed of anticorrosive additives, preservatives, humectants and polar and Extreme Pressure lubricants.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Houghto Grind Md",
    productPara:
      "Houghton Hocut 5230 is a water soluble machining and grinding fluid. It is a totally synthetic product, with an advanced lubricant, wetting and anticorrosive package, designed for roughing and finishing operations in steel and cast iron.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Houghto Quench C 125",
    productPara:
      "Houghto Quench C 125 is a medium speed quenching oil suitable for temperatures up to 80ºC. Based on a specially selected base oil and solvent refined to minimize oxidation and ensure consistent performance in use.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Houghto-Grind 780",
    productPara:
      "Houghton Houghto-Grind 780 is a synthetic fluid, totally free of mineral oil, chlorine, nitrites and phenols. Solutions with water are transparent, characterized by their great bacteriostatic, coolant, detergent and anticorrosive properties.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Macron 205 M5",
    productPara: "sulfur.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Macron 2425 S8",
    productPara: " ",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Macron 2830 S 32",
    productPara: "Macron 2830 S-32 is a pure cutting oil for grinding tools.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Macron SL 501",
    productPara:
      "Macron SL 501 is a lubricant based on high purity fatty alcohols. In this way, the product is free of aromatic components. The raw material is specially selected to achieve a low pour point for use in cold environments. Does not contain zinc, chlorine, phosphorus or sulfur.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Metalina BY 2211",
    productPara: " ",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Prevent Rust CP4 NN",
    productPara:
      "Houghton Prevent Rust CP 4 NN is a rust preventative based on a formulation of plastic components that offers multi-year rust protection indoors, as well as resistance to shock and friction.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Rust Veto 211 D",
    productPara:
      "Houghton Rust Veto 211 D is a fluid based on a mixture of dearomatized solvent and carefully selected high performance additives that ensure interior protection for up to 12 months.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Rust Veto 232",
    productPara:
      "Houghton Rust Veto 232 is a fluid based on a mixture of dearomatized solvent and carefully selected high performance additives that ensure protection inside for up to 36 months and outside for several months.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Rust Veto 377 C/2",
    productPara:
      "Houghton Rust Veto 377 C/2 is a water-repellent anticorrosive based on volatile solvents that, when evaporated, leaves an oily film with great anticorrosive power.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Rust Veto 377 S",
    productPara:
      "Houghton Rust Veto 377 S is a waterproofing anticorrosive based on volatile solvents that, when evaporated, leaves a greasy film with great anticorrosive power.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Rust Veto 90 HF",
    productPara:
      "Houghton Rust Veto 90 HF is a low viscosity aliphatic solvent",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Rust Veto C-15",
    productPara: " ",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Tube Lubricant CR 51 H",
    productPara: " ",
  },
];

export const aviationSpecial = [
  {
    productImg: productsImg.shellAlt,
    productTitle: "Aeroshell Fluid 31",
    productPara:
      "Aeroshell Fluid 31 is a synthetic hydrocarbon based hydraulic oil with excellent flame retardant characteristics compared to conventional petroleum products. It has a specially designed base that imposes a high flash point, excellent",
  },
  {
    productImg: productsImg.aeroshellFluid,
    productTitle: "Aeroshell Fluid 41",
    productPara:
      "Aeroshell Fluid 41 is a mineral hydraulic oil made to a high level of cleanliness that has very good flow characteristics. Contains additives that provide excellent fluidity at low temperatures as well as exceptional anti-wear stability, oxidation and corrosion inhibition.",
  },
  {
    productImg: productsImg.aeroshellFluid71,
    productTitle: "Aeroshell Fluid 71",
    productPara:
      "Aeroshell Fluid 71 is a mineral hydraulic fluid consisting of a mineral base oil with an additive package with anti-corrosion properties, excellent oxidation stability and anti-wear properties.",
  },
  {
    productImg: productsImg.aeroshellGrease22,
    productTitle: "Aeroshell Grease 22",
    productPara:
      "Aeroshell Grease 22 is an advanced versatile general purpose grease comprised of a Microgel thickened synthetic hydrocarbon oil with outstanding performance characteristics. Appropriate additives are included to achieve oxidation and corrosion resistance, and have antiwear and load-bearing properties.",
  },
  {
    productImg: productsImg.aeroshellGrease33,
    productTitle: "Aeroshell Grease 33",
    productPara:
      "Aeroshell Grease 33 is a synthetic aeronautical grease composed of a complex lithium thickener and a synthetic oil base with oxidation and corrosion inhibitors. It has excellent water resistance.",
  },
  {
    productImg: productsImg.aeroshellGrease581,
    productTitle: "Aeroshell Grease 58",
    productPara:
      "Aeroshell Grease 58 is an advanced general purpose and wheel bearing grease composed of a synthetic base fluid and a lithium complex soap thickener. AeroShell Grease 58 possesses an excellent combination of high performance characteristics including high load capacity, corrosion protection, mechanical stability, oxidation resistance, and wear resistance.",
  },
  {
    productImg: productsImg.aeroshellGrease64,
    productTitle: "Aeroshell Grease 64",
    productPara:
      "Aeroshell Grease 64 comprises Aeroshell Grease 33 fortified with 5% molybdenum disulfide. It has the improved anti-wear and anti-corrosion properties of Aeroshell Grease 33 with the additional EP (Extreme Pressure) properties provided by the addition of a solid lubricant.",
  },
  {
    productImg: productsImg.aeroshellGrease22,
    productTitle: "Aeroshell Turbine Oil 500",
    productPara:
      "Aeroshell Turbine Oil 500 is a carefully selected 5 cSt high performance synthetic ester oil delicately balanced with a combination of additives to provide thermal and oxidation stability as well as metal passivation.",
  },
  {
    productImg: productsImg.aeroshellGrease22,
    productTitle: "Aeroshell Turbine Oil 560",
    productPara:
      "Aeroshell Turbine Oil 560 is a high performance, low coking, 5 mm2/S synthetic oil that incorporates a combination of carefully selected additives to improve thermal and oxidation stability.",
  },
  {
    productImg: productsImg.aeroshellFluid71,
    productTitle: "Aeroshell Turbine Oil 750",
    productPara:
      "Aeroshell Turbine Oil 750 was developed to meet the requirements of DERD 2487 and to provide a high level of lubrication in British civil gas turbines, particularly turboprop engines where a good oil transport head is required for the gearbox. helix reduction.",
  },
  {
    productImg: productsImg.aeroshellW100plus,
    productTitle: "Aeroshell W 100 PLUS",
    productPara:
      "Aeroshell Oil W 100 Plus is a new single grade oil that combines the ashless dispersant performance of a grade found in Aeroshell W100 oil and the anti-corrosion and anti-corrosion additives of multigrade Aero Shell W15W-50 oil. It's the oil for riders who prefer a single grade but also want the extra protection and performance.",
  },

  {
    productImg: productsImg.aeroshellFluid71,
    productTitle: "Cepsa Arga Biogrease 2",
    productPara:
      "Cepsa Arga Biogrease 2 is a Premium EAL (environmentally acceptable lubricant) quality grease specially developed for applications where a biodegradable product is needed that complies with VGP 2013 legislation.",
  },
  {
    productImg: productsImg.cepsaAlt,
    productTitle: "Cepsa Arga Casux",
    productPara:
      "Cepsa Arga Casux is a state-of-the-art, high-performance complex calcium sulphonate grease for industrial, marine and off-road applications. Formulated from a complex calcium sulfonate soap thickener with anti-wear properties and built-in Extreme Pressure characteristics, as well as excellent anti-corrosion characteristics.",
  },
  {
    productImg: productsImg.cepsaAlt,
    productTitle: "Cepsa Arga Complex Lithium EP 00",
    productPara:
      "Cepsa Arga Complex Litio EP 00 is a multipurpose grease for marine applications, with high EP properties and great resistance to water. For use in marine applications where a water resistant grease is required.",
  },
  {
    productImg: productsImg.cepsaAlt,
    productTitle: "Cepsa Arga Force",
    productPara:
      "Cepsa Arga Force is a special adherent synthetic grease for cables and open gears. It incorporates solid lubricants and other specially studied additives to provide total stability against the highest pressures and loads.",
  },
  {
    productImg: productsImg.cepsaAlt,
    productTitle: "Cepsa Arga Synt",
    productPara:
      "Cepsa Arga Synt is a synthetic marine grease that combines the exclusive characteristics of a high-viscosity PAO-type synthetic base with those of a high-quality lithium complex thickener.",
  },
  {
    productImg: productsImg.cepsaAlt,
    productTitle: "Cepsa Arga WR EP",
    productPara:
      "Cepsa Arga WR EP is a calcium-lithium grease with high film tenacity and reinforced lubricity, with excellent water repellent properties and EP properties.",
  },
  {
    productImg: productsImg.cepsaAlt,
    productTitle: "Cepsa Bio Oil HM-S 32",
    productPara:
      "Cepsa Bio Oil HM-S is a biodegradable synthetic fluid formulated with ester-type biodegradable synthetic bases and specially selected additives, for use as hydraulic fluid.",
  },
  {
    productImg: productsImg.cepsaAlt,
    productTitle: "Cepsa Bio Oil HM-S 46",
    productPara:
      "Cepsa Bio Oil HM-S is a biodegradable synthetic fluid formulated with ester-type biodegradable synthetic bases and specially selected additives, for use as hydraulic fluid.",
  },
  {
    productImg: productsImg.cepsaAlt,
    productTitle: "Cepsa Bio Oil HM-S 68",
    productPara:
      "Cepsa Bio Oil HM-S is a biodegradable synthetic fluid formulated with ester-type biodegradable synthetic bases and specially selected additives, for use as hydraulic fluid.",
  },
  {
    productImg: productsImg.cepsaAlt,
    productTitle: "Cepsa Boreal 68",
    productPara:
      "Cepsa Boreal 68 is a highly refined, wax-free naphthenic mineral oil, designed for use in refrigeration compressors.",
  },
  {
    productImg: productsImg.cepsaAlt,
    productTitle: "Cepsa Ciconia 30",
    productPara:
      "Cepsa Ciconia 30 is a marine oil developed for the lubrication of the crankcase of slow-moving 2T diesel marine engines. Formulated with highly refined base oils and has good anti-corrosion and anti-foam properties.",
  },
  {
    productImg: productsImg.cepsaAlt,
    productTitle: "Circulating Cepsa 100",
    productPara:
      "Cepsa Circulante 100 is a high-quality “universal use” lubricating oil formulated with paraffinic bases and selected additives to provide the product with the necessary properties for its multiple applications.",
  },
  {
    productImg: productsImg.cepsaAlt,
    productTitle: "Circulating Cepsa 150",
    productPara:
      "Cepsa Circulante 150 is a high-quality “universal use” lubricating oil formulated with paraffinic bases and selected additives to provide the product with the necessary properties for its multiple applications.",
  },
  {
    productImg: productsImg.cepsaAlt,
    productTitle: "Cepsa Circulating 220",
    productPara:
      "Cepsa Circulante 220 is a high-quality, “universal use” lubricating oil formulated with paraffinic bases and selected additives to provide the product with the necessary properties for its multiple applications.",
  },
  {
    productImg: productsImg.cepsaAlt,
    productTitle: "Cepsa Circulating 320",
    productPara:
      "Cepsa Circulante 320 is a high-quality, “universal use” lubricating oil formulated with paraffinic bases and selected additives to provide the product with the necessary properties for its multiple applications.",
  },
  {
    productImg: productsImg.cepsaAlt,
    productTitle: "Cepsa Circulating 460",
    productPara:
      "Cepsa Circulante 460 is a high-quality “universal use” lubricating oil formulated with paraffinic bases and selected additives to provide the product with the necessary properties for its multiple applications.",
  },
  {
    productImg: productsImg.cepsaAlt,
    productTitle: "Cepsa Compressors AR 100",
    productPara:
      "Cepsa Compressors AR 100 is a lubricating oil formulated with highly refined paraffinic bases and a selected additive that gives it excellent properties for use in compressors.  ",
  },
  {
    productImg: productsImg.cepsaAlt,
    productTitle: "Cepsa Compressors AR 46",
    productPara:
      "Cepsa Compressors AR 46 is a lubricating oil formulated with highly refined paraffinic bases and a selected additive that gives it excellent properties for use in compressors.  ",
  },
  {
    productImg: productsImg.cepsaAlt,
    productTitle: "Cepsa Compressors AR 68",
    productPara:
      "Cepsa Compressors AR 68 is a lubricating oil formulated with highly refined paraffinic bases and a selected additive that gives it excellent properties for use in compressors.  ",
  },
  {
    productImg: productsImg.cepsaAlt,
    productTitle: "Cepsa Compressors ARS 46",
    productPara:
      "Cepsa Compressors ARS 46 is a fully synthetic lubricating oil, formulated based on polyalphaolefins and state-of-the-art additives, for use in air compressors.",
  },
  {
    productImg: productsImg.cepsaAlt,
    productTitle: "Cepsa Compressors ARS 68",
    productPara:
      "Cepsa Compressors ARS 68 is a fully synthetic lubricating oil, formulated based on polyalphaolefins and latest generation additives, for use in air compressors.",
  },
  {
    productImg: productsImg.cepsaAlt,
    productTitle: "Cepsa Compressors ES 100",
    productPara:
      "Cepsa Compressores ES 100 is a Di-Ester-based synthetic oil, specifically recommended for reciprocating air compressors, used in industrial, marine and mobile applications. It has excellent protection against wear, resistance to oxidation and thermal degradation, surpassing mineral oil in performance.  ",
  },
  {
    productImg: productsImg.cepsaAlt,
    productTitle: "Cepsa Diatherm 32",
    productPara:
      "Cepsa Diatermo 32 is a thermal oil formulated with highly refined paraffinic bases and a special additive that allows obtaining a product with a high viscosity index and very stable. Therefore, the viscosity of the product is maintained without undergoing considerable variations during the heat transfer process.",
  },
  {
    productImg: productsImg.cepsaAlt,
    productTitle: "Cepsa Gears HPX 150",
    productPara:
      "Cepsa Engranajes HPX 150 is a high-performance synthetic lubricant, formulated with synthetic base oils (PAO) and extreme pressure additives, which give it excellent properties for multiple applications in severe work conditions, operating under heavy loads and low speeds.",
  },
  {
    productImg: productsImg.cepsaAlt,
    productTitle: "Cepsa Gears HPX 220",
    productPara:
      "Cepsa Engranajes HPX 220 is a high-performance synthetic lubricant, formulated with synthetic base oils (PAO) and extreme pressure additives, which give it excellent properties for multiple applications in severe work conditions, operating under heavy loads and low speeds.",
  },
  {
    productImg: productsImg.cepsaAlt,
    productTitle: "Cepsa Gears HPX 320",
    productPara:
      "Cepsa Engranajes HPX 320 is a high-performance synthetic lubricant, formulated with synthetic base oils (PAO) and extreme pressure additives, which give it excellent properties for multiple applications in severe work conditions, operating under heavy loads and low speeds.",
  },
  {
    productImg: productsImg.cepsaAlt,
    productTitle: "Cepsa Gears HPX 460",
    productPara:
      "Cepsa Engranajes HPX 460 is a high-performance synthetic lubricant, formulated with synthetic base oils (PAO) and extreme pressure additives, which give it excellent properties for multiple applications in severe work conditions, operating under heavy loads and low speeds.",
  },
  {
    productImg: productsImg.cepsaAlt,
    productTitle: "Cepsa Gavia 2550",
    productPara:
      "Cepsa Gavia 2550 is a marine lubricant developed for the lubrication of cylinders in slow-moving 2T diesel marine engines that use fuels with a low percentage of sulfur (up to 0.1%). Cepsa Gavia 2550 has been designed to ensure that ship operators can meet the new requirements for navigation in ECA zones.",
  },
  {
    productImg: productsImg.cepsaAlt,
    productTitle: "Cepsa Gavia 7050",
    productPara:
      "Cepsa Gavia 7050 is a marine lubricant, developed for the lubrication of cylinders in slow-moving 2T diesel marine engines that use fuel with a high sulfur content (between 1.0 and 3.5%), working at high specific powers and high loads. thermal.",
  },
  {
    productImg: productsImg.cepsaAlt,
    productTitle: "Cepsa HD Turbines 32",
    productPara:
      "Cepsa HD Turbinas 32 is a lubricating oil formulated with highly refined and hydrotreated paraffin base oils and selected additives that give it excellent properties for multiple applications.",
  },
  {
    productImg: productsImg.cepsaAlt,
    productTitle: "Cepsa HD Turbines 46",
    productPara:
      "Cepsa HD Turbinas 46 is a lubricating oil formulated with highly refined and hydrotreated paraffin base oils and selected additives that give it excellent properties for multiple applications.",
  },
  {
    productImg: productsImg.cepsaAlt,
    productTitle: "Cepsa HD Turbines 68",
    productPara:
      "Cepsa HD Turbinas 68 is a lubricating oil formulated with highly refined and hydrotreated paraffin base oils and selected additives that give it excellent properties for multiple applications.",
  },
  {
    productImg: productsImg.cepsaAlt,
    productTitle: "Cepsa Larus 3030",
    productPara:
      "Cepsa Larus 3030 is a marine lubricant that offers a perfect combination of first-refined paraffin bases and additives designed for application in 4-stroke marine diesel engines, eliminating black sludge formation problems, and with excellent oil/fuel compatibility. (IFO).",
  },
  {
    productImg: productsImg.cepsaAlt,
    productTitle: "Cepsa Larus 3040",
    productPara:
      "Cepsa Larus 3040 is a marine lubricant that offers a perfect combination of first-refined paraffin bases and additives designed for application in 4-stroke marine diesel engines, eliminating black sludge formation problems, and with excellent oil/fuel compatibility. (IFO).",
  },
  {
    productImg: productsImg.cepsaAlt,
    productTitle: "Cepsa Larus 4040",
    productPara:
      "Cepsa Larus 4040 is a marine lubricant that offers a perfect combination of first-refined paraffin bases and additives designed for application in 4-stroke marine diesel engines, eliminating black sludge formation problems and with excellent oil/fuel compatibility ( IFO).",
  },
  {
    productImg: productsImg.cepsaAlt,
    productTitle: "Cepsa Larus 5040",
    productPara:
      "Cepsa Larus 5040 is a marine lubricant formulated with a perfect combination of first-refined paraffin bases and additives designed for application in 4-stroke marine diesel engines, eliminating black sludge formation problems and with excellent oil/fuel compatibility ( IFO).",
  },
  {
    productImg: productsImg.cepsaAlt,
    productTitle: "Cepsa Mar SHPD 15W40",
    productPara:
      "Cepsa Mar SHPD is an oil for high-performance diesel engines, with optimal protection and performance in diesel engines, both naturally aspirated and turbocharged, that operate under severe high-speed conditions.",
  },
  {
    productImg: productsImg.cepsaAlt,
    productTitle: "Cepsa Mistral 32",
    productPara:
      "Cepsa Mistral 32 is a marine lubricating oil formulated with highly refined paraffinic bases and selected additives that give the required properties to anti-wear HM type hydraulic fluids recommended in ship hydraulic systems.",
  },
  {
    productImg: productsImg.cepsaAlt,
    productTitle: "Cepsa Mistral 46",
    productPara:
      "Cepsa Mistral 46 is a marine lubricating oil formulated with highly refined paraffinic bases and selected additives that give the required properties to anti-wear HM type hydraulic fluids recommended in ship hydraulic systems.",
  },
  {
    productImg: productsImg.cepsaAlt,
    productTitle: "Cepsa Mistral 68",
    productPara:
      "Cepsa Mistral 46 is a marine lubricating oil formulated with highly refined paraffinic bases and selected additives that give the required properties to anti-wear HM type hydraulic fluids recommended in ship hydraulic systems.",
  },
  {
    productImg: productsImg.cepsaAlt,
    productTitle: "Cepsa Mistral HX 100",
    productPara:
      "Cepsa Mistral HX 100 is a high viscosity index marine lubricant formulated with special highly refined paraffinic bases and selected latest-generation additives, which give it excellent anti-wear properties, as well as special characteristics against thermo-decomposition and oxidation.",
  },
  {
    productImg: productsImg.cepsaAlt,
    productTitle: "Cepsa Mistral HX 150",
    productPara:
      "Cepsa Mistral HX 150 is a high viscosity index marine lubricant formulated with special highly refined paraffinic bases and selected latest-generation additives, which give it excellent anti-wear properties, as well as special characteristics against thermo-decomposition and oxidation.",
  },
  {
    productImg: productsImg.cepsaAlt,
    productTitle: "Cepsa Mistral HX 32",
    productPara:
      "Cepsa Mistral HX 32 is a high viscosity index marine lubricant formulated with special highly refined paraffinic bases and selected latest-generation additives, which give it excellent anti-wear properties, as well as special characteristics against thermo-decomposition and oxidation.",
  },
  {
    productImg: productsImg.cepsaAlt,
    productTitle: "Cepsa Mistral HX 46",
    productPara:
      "Cepsa Mistral HX 46 is a high viscosity index marine lubricant formulated with special highly refined paraffinic bases and selected latest-generation additives, which give it excellent anti-wear properties, as well as special characteristics against thermo-decomposition and oxidation.",
  },
  {
    productImg: productsImg.cepsaAlt,
    productTitle: "Cepsa Mistral HX 68",
    productPara:
      "Cepsa Mistral HX 68 is a high viscosity index marine lubricant formulated with special highly refined paraffinic bases and selected latest-generation additives, which give it excellent anti-wear properties, as well as special characteristics against thermo-decomposition and oxidation.",
  },
  {
    productImg: productsImg.cepsaAlt,
    productTitle: "Cepsa Norther AB 100",
    productPara:
      "Cepsa Norther AB 100 is a high-quality synthetic lubricating oil for refrigeration compressors that use HFC and HCFC-type refrigerants. Based on Alkylbenzene, it has superior miscibility with refrigerants such as R12, R22 and R502, especially in the event that these refrigerant fluids present miscibility problems with mineral oils.",
  },
  {
    productImg: productsImg.cepsaAlt,
    productTitle: "Cepsa Norther HFC 32",
    productPara:
      "Cepsa Norther HFC 32 are synthetic lubricating oils specially designed for the lubrication of compressors and refrigeration systems that use ozone-friendly HFC refrigerants. They are oils formulated from synthesized polyol esters and an exclusive additive package to guarantee good protection against wear, thermal, chemical and hydrolytic stability.",
  },
  {
    productImg: productsImg.cepsaAlt,
    productTitle: "Cepsa Norther HFC 68",
    productPara:
      "Cepsa Norther HFC 68 are synthetic lubricating oils specially designed for the lubrication of compressors and refrigeration systems that use ozone-friendly HFC refrigerants. They are oils formulated from synthesized polyol esters and an exclusive additive package to guarantee good protection against wear, thermal, chemical and hydrolytic stability.",
  },
  {
    productImg: productsImg.cepsaAlt,
    productTitle: "Cepsa Outboard 2T",
    productPara:
      "Cepsa Outboard 2T is a semi-synthetic lubricating oil specially formulated for boats with 2-stroke outboard engines.",
  },
  {
    productImg: productsImg.cepsaAlt,
    productTitle: "Cepsa Petrel 1340 NZ",
    productPara:
      "Cepsa Petrel 1340 NZ is a marine lubricant for marine and stationary diesel engines that operate at medium and high speeds, using marine distillate fuels (ISO 8217 DMX. DMA) that require a zinc-free lubricant.",
  },
  {
    productImg: productsImg.cepsaAlt,
    productTitle: "Cepsa Petrel 1530",
    productPara:
      "Cepsa Petrel 1530 is a marine lubricant for marine and stationary diesel engines that operate at medium and high speeds, using marine distillate fuels (ISO 8217 DMX, DMA).",
  },
  {
    productImg: productsImg.cepsaAlt,
    productTitle: "Cepsa Petrel 1540",
    productPara:
      "Cepsa Petrel 1530 is a marine lubricant for marine and stationary diesel engines that operate at medium and high speeds, using marine distillate fuels (ISO 8217 DMX, DMA).",
  },
  {
    productImg: productsImg.cepsaAlt,
    productTitle: "Cepsa Petrel HDL 40",
    productPara:
      "Cepsa Petrel HDL 40 is a lubricant for new generation marine and stationary diesel engines that operate under extreme conditions of high speed and high mean effective pressure, using marine distillate fuels.",
  },
  {
    productImg: productsImg.cepsaAlt,
    productTitle: "Cepsa Rada XMP 100",
    productPara:
      "Cepsa Rada XMP are very high-quality mineral-based marine lubricants formulated with Sulfur-Phosphorus Extreme Pressure (EP) additives, developed for all types of steel gears, both industrial and marine. They also contain anti-rust and anti-corrosion additives as well as special agents to increase oxidation stability and resistance to foaming.",
  },
  {
    productImg: productsImg.cepsaAlt,
    productTitle: "Cepsa Rada XMP 150",
    productPara:
      "Cepsa Rada XMP are very high-quality mineral-based marine lubricants formulated with Sulfur-Phosphorus Extreme Pressure (EP) additives, developed for all types of steel gears, both industrial and marine. They also contain anti-rust and anti-corrosion additives as well as special agents to increase oxidation stability and resistance to foaming.",
  },
  {
    productImg: productsImg.cepsaAlt,
    productTitle: "Cepsa Rada XMP 220",
    productPara:
      "Cepsa Rada XMP are very high-quality mineral-based marine lubricants formulated with Sulfur-Phosphorus Extreme Pressure (EP) additives, developed for all types of steel gears, both industrial and marine. They also contain anti-rust and anti-corrosion additives as well as special agents to increase oxidation stability and resistance to foaming.",
  },
  {
    productImg: productsImg.cepsaAlt,
    productTitle: "Cepsa Rada XMP 320",
    productPara:
      "Cepsa Rada XMP are very high-quality mineral-based marine lubricants formulated with Sulfur-Phosphorus Extreme Pressure (EP) additives, developed for all types of steel gears, both industrial and marine. They also contain anti-rust and anti-corrosion additives as well as special agents to increase oxidation stability and resistance to foaming.",
  },
  {
    productImg: productsImg.cepsaAlt,
    productTitle: "Cepsa Rada XMP 460",
    productPara:
      "Cepsa Rada XMP are very high-quality mineral-based marine lubricants formulated with Sulfur-Phosphorus Extreme Pressure (EP) additives, developed for all types of steel gears, both industrial and marine. They also contain anti-rust and anti-corrosion additives as well as special agents to increase oxidation stability and resistance to foaming.",
  },
  {
    productImg: productsImg.cepsaAlt,
    productTitle: "Cepsa Rada XMP 68",
    productPara:
      "Cepsa Rada XMP are very high-quality mineral-based marine lubricants formulated with Sulfur-Phosphorus Extreme Pressure (EP) additives, developed for all types of steel gears, both industrial and marine. They also contain anti-rust and anti-corrosion additives as well as special agents to increase oxidation stability and resistance to foaming.",
  },
  {
    productImg: productsImg.cepsaAlt,
    productTitle: "Cepsa Sterna",
    productPara:
      "Cepsa Sterna is a high-viscosity, high-performance lubricating oil specially developed for the lubrication of Cedervall-type horns installed on ships and for certain fin stabilizer bearings installed on passenger ships. Formulated from high viscosity index (VI) base oils combined with emulsifiers, structure modifiers, active surface agents and corrosion preventatives.",
  },
  {
    productImg: productsImg.cepsaAlt,
    productTitle: "Cepsa Sterna Bio",
    productPara:
      "Cepsa Sterna Bio is a synthetic and biodegradable hydraulic fluid especially for horns.",
  },
  {
    productImg: productsImg.cepsaAlt,
    productTitle: "Cepsa Ultramar SHPD 15W40",
    productPara:
      "Cepsa Ultramar SHPD 15W40 is a premium type multigrade marine lubricant specially formulated to meet the current requirements of the latest generation 4T diesel engines used in the marine sector, operating with current low sulfur diesels (less than 50 ppm) and provides special protection for engines running on diesel with up to 1% S.",
  },
  {
    productImg: productsImg.ibiotecNeolube,
    productTitle: "Ibiotec Neolube GRV 190",
    productPara:
      "Ibiotec Neolube GRV 190 is a super tech marine green grease, it is a complex lithium/polymerized grease for the lubrication of all elements that work in extremely severe conditions.",
  },
  {
    productImg: productsImg.exxonMobil,
    productTitle: "Mobil Delvac 1 SHC 5W40",
    productPara:
      "Mobil Delvac 1 SHC 5W-40 is a very high performance diesel engine oil, offering excellent lubrication including the ability to extend drain intervals, helping to extend engine life for diesel engines running in severe applications",
  },
  {
    productImg: productsImg.exxonMobil,
    productTitle: "Mobil Delvac MX 15W40",
    productPara:
      "Mobil Delvac MX 15W 40 is a very high performance oil for diesel engines, it offers excellent lubrication of current diesel engines, which helps to increase engine life.",
  },
  {
    productImg: productsImg.exxonMobil,
    productTitle: "Mobile DTE 10 Excel 100",
    productPara:
      "Mobil DTE 10 Excel 100 is a premium quality hydraulic oil, with high performance anti-wear properties specifically designed to meet the needs of high pressure hydraulic systems of modern equipment, both industrial and mobile.",
  },
  {
    productImg: productsImg.exxonMobil,
    productTitle: "Mobile DTE 10 Excel 15",
    productPara:
      "Mobil DTE 10 Excel 15 is a premium quality hydraulic oil, with high performance anti-wear properties specifically designed to meet the needs of high pressure hydraulic systems of modern equipment, both industrial and mobile.",
  },
  {
    productImg: productsImg.exxonMobil,
    productTitle: "Mobile DTE 10 Excel 32",
    productPara:
      "Mobil DTE 10 Excel 32 is a premium quality hydraulic oil, with high performance anti-wear properties specifically designed to meet the needs of high pressure hydraulic systems of modern equipment, both industrial and mobile.",
  },
  {
    productImg: productsImg.exxonMobil,
    productTitle: "Mobile DTE 10 Excel 46",
    productPara:
      "Mobil DTE 10 Excel 46 is a premium quality hydraulic oil, with high performance anti-wear properties specifically designed to meet the needs of high pressure hydraulic systems of modern equipment, both industrial and mobile.",
  },
  {
    productImg: productsImg.exxonMobil,
    productTitle: "Mobile DTE 10 Excel 68",
    productPara:
      "Mobil DTE 10 Excel 68 is a premium quality hydraulic oil, with high performance anti-wear properties specifically designed to meet the needs of high pressure hydraulic systems of modern equipment, both industrial and mobile.",
  },
  {
    productImg: productsImg.exxonMobil,
    productTitle: "Mobil DTE Oil Heavy Medium",
    productPara:
      "Mobil DTE Oil Heavy Medium are a line of superior performance circulating system lubricants designed for applications including steam turbine and hydroturbine equipment and for other systems where long life lubricants are required.",
  },
  {
    productImg: productsImg.exxonMobil,
    productTitle: "Mobil EAL Arctic 100",
    productPara:
      "Mobil EAL Arctic 100 is an environmentally friendly high performance refrigeration oil. Fully synthetic specifically designed for the lubrication of compressors and refrigeration systems using non-ozone depleting HFC refrigerants.",
  },
  {
    productImg: productsImg.exxonMobil,
    productTitle: "Mobile EAL Arctic 68",
    productPara:
      "Mobil EAL Arctic 68 is an environmentally friendly high performance refrigeration oil. Fully synthetic specifically designed for the lubrication of compressors and refrigeration systems using non-ozone depleting HFC refrigerants.",
  },
  {
    productImg: productsImg.exxonMobil,
    productTitle: "Mobil Gargoyle Arctic Oil 300",
    productPara:
      "Mobil Gargoyle Arctic Oil 300 is a refrigeration oil made from high performance naphthenic mineral oils designed primarily for refrigeration compressors. They have low pour points and excellent fluidity at very low temperatures thanks to their almost absence of waxes.",
  },
  {
    productImg: productsImg.exxonMobil,
    productTitle: "Mobil Mobilgard 300 C",
    productPara:
      "Mobil Mobilgard 300 C is a premium quality two-stroke diesel engine systems oil specially formulated to provide superior engine cleanliness, better wear protection and longer service life compared to conventional system oils.",
  },
  {
    productImg: productsImg.exxonMobil,
    productTitle: "Mobil Mobilgard 312",
    productPara:
      "Mobil Mobilgard 312 are high performance diesel engine oils developed for use in trunk piston engines operating on low sulfur distillate fuels in industrial and marine applications. Formulated to have excellent oxidation resistance and viscosity throughout the service period.",
  },
  {
    productImg: productsImg.exxonMobil,
    productTitle: "Mobil Mobilgard 412",
    productPara:
      "Mobil Mobilgard 412 are high performance diesel engine oils developed for use in trunk piston engines operating on low sulfur distillate fuels in industrial and marine applications. Formulated to have excellent oxidation resistance and viscosity throughout the service period.",
  },
  {
    productImg: productsImg.exxonMobil,
    productTitle: "Mobilgard 5100",
    productPara:
      "Mobil Mobilgard 5100 is a premium quality, extra high performance marine diesel engine cylinder oil. The excellent performance of Mobilgard 5100 has been demonstrated at the elevated jacket temperatures and peak firing pressures of modern two-stroke marine engines.",
  },
  {
    productImg: productsImg.exxonMobil,
    productTitle: "Mobilgard 525",
    productPara:
      "Mobil Mobilgard 525 is a marine diesel engine cylinder oil for crosshead engines operating on low and extra low sulfur distillate fuels and meets the requirements for MAN and Wartsila engines.",
  },
  {
    productImg: productsImg.exxonMobil,
    productTitle: "Mobil Mobilgard 560 VS",
    productPara:
      "Mobil Mobilgard 560 VS is a high performance marine diesel engine cylinder oil designed for crosshead engines burning heavy fuel with sulfur levels of 0.5-4%.",
  },
  {
    productImg: productsImg.exxonMobil,
    productTitle: "Mobil Mobilgard 570",
    productPara:
      "Mobil Mobilgard 570 is a premium quality, extra high performance cylinder oil for marine diesel engines. Its excellent performance has been demonstrated in the elevated jacket temperatures and peak firing pressures of modern two-stroke marine engines.",
  },
  {
    productImg: productsImg.exxonMobil,
    productTitle: "Mobil Mobilgard ADL 30",
    productPara:
      "Mobil Mobilgard ADL 30 is a high performance, enhanced dispersancy diesel engine oil for BMEP medium and high speed diesel engines operating on distillate and MDO fuels. These advanced diesel engine lubricants are available in both SAE 30 and 40 grades to meet most marine engine viscosity requirements.",
  },
  {
    productImg: productsImg.exxonMobil,
    productTitle: "Mobil Mobilgard ADL 40",
    productPara:
      "Mobil Mobilgard ADL 40 is a high performance, enhanced dispersancy diesel engine oil for BMEP medium and high speed diesel engines operating on distillate and MDO fuels. These advanced diesel engine lubricants are available in both SAE 30 and 40 grades to meet most marine engine viscosity requirements.",
  },
  {
    productImg: productsImg.exxonMobil,
    productTitle: "Mobile Mobilgard Delvac 1640",
    productPara:
      "Mobil Mobilgard Delvac 1640 is a premium performance, heavy duty diesel engine oil formulated from advanced base oils and a balanced additive system to meet or exceed the demanding specifications of the world's leading manufacturers of modern diesel engines.",
  },
  {
    productImg: productsImg.exxonMobil,
    productTitle: "Mobil Mobilgard M 330",
    productPara:
      "Mobil Mobilgard M 330 is a diesel engine oil made up of high quality, extra high performance oils for TBN 30 engines. They have been designed for most applications of medium speed diesel engines fueled with residual fuels, operating in severe conditions and which can be found in the marine and stationary power generation industries.",
  },
  {
    productImg: productsImg.exxonMobil,
    productTitle: "Mobil Mobilgard M 430",
    productPara:
      "Mobil Mobilgard M 430 is a diesel engine oil made up of high quality, extra high performance oils for TBN 30 engines. They have been designed for most applications of medium speed diesel engines fueled with residual fuels, operating in severe conditions and which can be found in the marine and stationary power generation industries.",
  },
  {
    productImg: productsImg.exxonMobil,
    productTitle: "Mobil Mobilgard M 440",
    productPara:
      "Mobil Mobilgard M 440 is a high quality, extra high performance diesel engine oil for TBN 40 engines. It has been designed for most medium speed diesel engine applications that are fueled by residual fuels, operating in severe conditions and can be found in the marine and stationary power generation industries.",
  },
  {
    productImg: productsImg.exxonMobil,
    productTitle: "Mobil Mobilgard M 50",
    productPara:
      "Mobil Mobilgard M 50 is a diesel engine oil made up of high quality, extra high performance TBN 50 engine oils. They have been designed for the majority of medium speed diesel engine applications running on residual fuels, operating in severe conditions and which can be found in the marine and stationary power generation industries.",
  },
  {
    productImg: productsImg.exxonMobil,
    productTitle: "Mobil Gear 600 XP 100",
    productPara:
      "Mobil Mobilgear 600 XP 100 is an extra high performance gear oil possessing excellent extreme pressure characteristics and load carrying properties, designed for all types of enclosed gear transmissions with circulation or splash lubrication systems.",
  },
  {
    productImg: productsImg.exxonMobil,
    productTitle: "Mobil Gear 600 XP 150",
    productPara:
      "Mobil Mobilgear 600 XP 150 is an extra high performance gear oil possessing excellent extreme pressure characteristics and load carrying properties, designed for all types of enclosed gear transmissions with circulation or splash lubrication systems.",
  },
  {
    productImg: productsImg.exxonMobil,
    productTitle: "Mobil Gear 600 XP 220",
    productPara:
      "Mobil Mobilgear 600 XP 220 is an extra high performance gear oil possessing excellent extreme pressure characteristics and load carrying properties, designed for all types of enclosed gear transmissions with circulation or splash lubrication systems.",
  },
  {
    productImg: productsImg.exxonMobil,
    productTitle: "Mobil Mobilgear 600 XP 460",
    productPara:
      "Mobil Mobilgear 600 XP 460 is an extra high performance gear oil possessing excellent extreme pressure characteristics and load carrying properties, designed for all types of enclosed gear transmissions with circulation or splash lubrication systems.",
  },
  {
    productImg: productsImg.exxonMobil,
    productTitle: "Mobil Gear 600 XP 68",
    productPara:
      "Mobil Mobilgear 600 XP 68 is an extra high performance gear oil possessing excellent extreme pressure characteristics and load carrying properties, designed for all types of enclosed gear transmissions with circulation or splash lubrication systems.",
  },
  {
    productImg: productsImg.exxonMobil,
    productTitle: "Mobil Mobiltherm 605",
    productPara:
      "Mobil Mobiltherm 605 are high performance heat transfer oils designed for enclosed, indirectly heated installations. Formulated from extremely refined base oils resistant to thermal cracking and chemical oxidation.",
  },
  {
    productImg: productsImg.exxonMobil,
    productTitle: "Mobil Rarus 427",
    productPara:
      "Mobil Rarus 427 is a premium performance ashless air compressor lubricant designed to meet the most stringent requirements of major compressor manufacturers. Formulated with high quality mineral base oil and a high performance additive system designed to provide exceptional reliability and equipment protection for compressors operating in medium to severe conditions.",
  },
  {
    productImg: productsImg.exxonMobil,
    productTitle: "Mobil Rarus 827",
    productPara:
      "Mobil Rarus 827 is a premium performance air compressor lubricant intended primarily for the lubrication of reciprocating air compressors subjected to severe conditions, although it is not recommended for air compressors used in breathing air applications.",
  },
  {
    productImg: productsImg.exxonMobil,
    productTitle: "Mobil Rarus 829",
    productPara:
      "Mobil Rarus 829 is a premium performance air compressor lubricant intended primarily for the lubrication of reciprocating air compressors subjected to severe conditions, although it is not recommended for air compressors used in breathing air applications.",
  },
  {
    productImg: productsImg.exxonMobil,
    productTitle: "Mobil Rarus SHC 1025",
    productPara:
      "Mobil Rarus SHC 1025 is a premium performance synthetic air compressor lubricant intended primarily for the lubrication of rotary screw and vane air compressors subjected to severe conditions. They are formulated with design-specific wax-free synthetic hydrocarbon fluids and a high-tech additive system that ensures exceptional resistance to oxidation and thermal degradation far superior to mineral oil-based air compressor oils.",
  },
  {
    productImg: productsImg.exxonMobil,
    productTitle: "Mobil Rarus SHC 1026",
    productPara:
      "Mobil Rarus SHC 1026 is a premium performance, synthetic air compressor lubricant intended primarily for the lubrication of rotary screw and vane air compressors subjected to severe conditions. They are formulated with design-specific wax-free synthetic hydrocarbon fluids and a high-tech additive system that ensures exceptional resistance to oxidation and thermal degradation far superior to mineral oil-based air compressor oils.",
  },
  {
    productImg: productsImg.exxonMobil,
    productTitle: "Mobile SHC 626",
    productPara:
      "Mobil SHC 626 are exceptional performance bearing and gear oils designed to provide excellent service in terms of equipment protection, oil life and trouble free operation to help improve customer productivity.",
  },
  {
    productImg: productsImg.exxonMobil,
    productTitle: "Mobile SHC 629",
    productPara:
      "Mobil SHC 629 are exceptional performance bearing and gear oils designed to provide excellent service in terms of equipment protection, oil life and trouble free operation to help improve customer productivity.",
  },
  {
    productImg: productsImg.exxonMobil,
    productTitle: "Mobile SHC 630",
    productPara:
      "Mobil SHC 630 are exceptional performance bearing and gear oils designed to provide excellent service in terms of equipment protection, oil life and trouble free operation to help improve customer productivity.",
  },
  {
    productImg: productsImg.exxonMobil,
    productTitle: "Mobile SHC 632",
    productPara:
      "Mobil SHC 632 are exceptional performance bearing and gear oils designed to provide excellent service in terms of equipment protection, oil life and trouble free operation to help improve customer productivity.",
  },
  {
    productImg: productsImg.exxonMobil,
    productTitle: "Mobil SHC Aware Grease EP 2",
    productPara:
      "Mobil SHC Aware Grease EP 2 environmentally acceptable grease for use in a wide range of marine applications.",
  },
  {
    productImg: productsImg.exxonMobil,
    productTitle: "Mobil SHC Aware ST 100",
    productPara:
      "Mobil SHC Aware ST 100 are high performance stern tube emulsifying oils for marine stern tube systems that meet the US Environmental Protection Agency (EPA) 2013 Vessel General Permit (VGP) guidelines for environmentally acceptable lubricants .",
  },
  {
    productImg: productsImg.exxonMobil,
    productTitle: "Mobil SHC Aware ST 220",
    productPara:
      "Mobil SHC Aware ST 220 are high performance stern tube emulsifying oils for marine stern tube systems that meet the US Environmental Protection Agency (EPA) 2013 Vessel General Permit (VGP) guidelines for environmentally acceptable lubricants .",
  },
  {
    productImg: productsImg.exxonMobil,
    productTitle: "Mobil Vacuoline 528",
    productPara:
      "Mobil Vacuoline 528 is a heavy duty, high performance circulating oil designed to meet the demands of non-twist rolling mills, however their outstanding performance makes them an excellent choice for circulating systems that lubricate gears and bearings.",
  },
  {
    productImg: productsImg.exxonMobil,
    productTitle: "Mobile Zerice S 100",
    productPara:
      "Mobil Zerice S 100 are synthetic lubricants for refrigeration compressors made from alkylbenzenes which, due to their nature, have superior miscibility with hydrochlorofluorocarbons (R22), this allows them to be used in very low temperature applications, down to -60ºC.",
  },
  {
    productImg: productsImg.exxonMobil,
    productTitle: "Mobile Zerice S 68",
    productPara:
      "Mobil Zerice S 68 are synthetic lubricants for refrigeration compressors made from alkylbenzenes which, due to their nature, have superior miscibility with hydrochlorofluorocarbons (R22), which allows them to be used in very low temperature applications, down to -60ºC.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Oil 9156",
    productPara:
      "Houghton Oil 9156 is a readily dispersible fluid that forms stable, odorless, semi-transparent emulsions. It is characterized by its high lubricity, good detergency and refrigeration.",
  },
  {
    productImg: productsImg.shellAlt,
    productTitle: "Shell Argina S2 30",
    productPara:
      "Shell Argina S2 30 is a lubricant for high performance medium speed marine diesel engines operating on residual, blended or distillate fuels or fuels. Shell Argina S2 30 has a TBN of 20 and is designed for low oil stress working conditions.",
  },
  {
    productImg: productsImg.shellAlt,
    productTitle: "Shell Argina S2 40",
    productPara:
      "Shell Argina S2 40 is a lubricant for high performance medium speed marine diesel engines operating on residual, blended or distillate fuels or fuels. Shell Argina S2 40 has a TBN of 20 and is designed for low oil stress working conditions.",
  },
  {
    productImg: productsImg.shellAlt,
    productTitle: "Shell Argina S3 30",
    productPara:
      "Shell Argina S3 30 is a multifunctional lubricant for high performance medium speed diesel engines operating on residual, blended or distillate fuels or fuels. Shell Argina S3 30 has a TBN of 30 and is designed for working conditions with moderate oil stress.",
  },
  {
    productImg: productsImg.shellAlt,
    productTitle: "Shell Argina S3 40",
    productPara:
      "Shell Argina S3 40 is a multifunctional lubricant for high performance medium speed diesel engines operating on residual, blended or distillate fuels or fuels. Shell Argina S3 40 has a TBN of 30 and is designed for working conditions with moderate oil stress.",
  },
  {
    productImg: productsImg.shellAlt,
    productTitle: "Shell Argina S4 40",
    productPara:
      "Shell Argina S4 40 is a multifunctional lubricant for high performance medium speed diesel engines running on residual fuels or fuels. Shell Argina S4 40 has a TBN of 40 and is designed for high oil stress working conditions. Its composition has been optimized to offer excellent control in the formation of deposits.",
  },
  {
    productImg: productsImg.shellAlt,
    productTitle: "Shell Argina S5 40",
    productPara:
      "Shell Argina S5 40 is a multifunctional lubricant for high performance medium speed diesel engines running on residual fuels or fuels. Shell Argina S5 40 has a TBN of 55 and is specially designed for modern high power, low oil consumption engines.",
  },
  {
    productImg: productsImg.shellAlt,
    productTitle: "Shell Naturelle S2 Grease A600P 1.5",
    productPara:
      "Shell Naturelle S2 Grease A600P 1.5 is a fully biodegradable high performance grease for use on open gears, cables and wires. Formulated with renewable base fluids and contains a select blend of additives to provide corrosion resistance and high charging performance.",
  },
  {
    productImg: productsImg.shellAlt,
    productTitle: "Shell Naturelle S2 Wire Rope Lubricant A",
    productPara:
      "Shell Naturelle S2 Wire Rope Lubricant A is a high performance, fully biodegradable grease for use on open gears, wire ropes and ropes. This fluid is formulated with renewable base fluids and contains a select blend of additives to provide corrosion resistance and high charging performance.",
  },
  {
    productImg: productsImg.shellAlt,
    productTitle: "Shell Naturelle S4 Gear Fluid 100",
    productPara:
      "Shell Naturelle S4 Gear Fluid 100 is an advanced synthetic fluid for use in gear applications such as propellers and propulsion units. Designed to offer superior load carrying performance while being readily biodegradable with low ecotoxicity.",
  },
  {
    productImg: productsImg.shellAlt,
    productTitle: "Shell Naturelle S4 Gear Fluid 150",
    productPara:
      "Shell Naturelle S4 Gear Fluid 150 is an advanced synthetic fluid for use in gear applications such as propellers and propulsion units. Designed to offer superior load carrying performance while being readily biodegradable with low ecotoxicity.",
  },
  {
    productImg: productsImg.shellAlt,
    productTitle: "Shell Naturelle S4 Gear Fluid 68",
    productPara:
      "Shell Naturelle S4 Gear Fluid 68 is an advanced synthetic fluid for use in gear applications such as propellers and propulsion units. Designed to offer superior load carrying performance while being readily biodegradable with low ecotoxicity.",
  },
  {
    productImg: productsImg.shellAlt,
    productTitle: "Shell Naturelle S4 Stern Tube Fluid",
    productPara:
      "Shell Naturelle S4 Stern Tube Fluid is a non-emulsifying synthetic fluid developed specifically for use in stern tubes. Manufactured from fully saturated esters, Shell Naturelle S4 stern tube fluid is designed to offer superior lubrication performance, while being readily biodegradable with low ecotoxicity.",
  },
  {
    productImg: productsImg.shellAlt,
    productTitle: "Shell Nautilus Premium Outboard",
    productPara:
      "Shell Nautilus Premium Outboard is a high performance lubricant for the superior protection of all two-stroke gasoline outboard engines. Its advanced formulation, which exceeds the requirements of the main outboard motor manufacturers, is a guarantee of durability and reliability.",
  },
  {
    productImg: productsImg.shellNautilus,
    productTitle: "Mobil Mobilgear 600 XP 460",
    productPara:
      "Mobil Mobilgear 600 XP 460 is an extra high performance gear oil possessing excellent extreme pressure characteristics and load carrying properties, designed for all types of enclosed gear transmissions with circulation or splash lubrication systems.",
  },
  {
    productImg: productsImg.shellGas,
    productTitle: "Shell Rotella DD+ 40",
    productPara:
      "Shell Rotella DD+ is a high quality two-stroke diesel engine oil designed for heavy-duty two-stroke diesel engines manufactured by Detroit Diesel Corporation.",
  },
  {
    productImg: productsImg.shellGas,
    productTitle: "Shell SiriusX40",
    productPara:
      "Shell Sirius X Oils is a high performance lubricant for high speed engines. They are high quality diesel engine oils, designed for high speed diesel engines using distillate fuels and formulated to offer better engine protection and longer drain periods than normal diesel engine oils.  ",
  },
];
export const aerosolLubricants = [
  {
    productImg: productsImg.ibiotecAero10,
    productTitle: "Ibiotec Aero DP 10",
    productPara:
      "Box Penetrating fluid aerosol 10 functions biodegradable super seizing agent Food contact. Content: 1 Box consisting of 12 sprays.",
  },
  {
    productImg: productsImg.ibiotecDrum,
    productTitle: "Ibiotec Aero OPEN GEAR",
    productPara:
      "Ibiotec Aero Open Gear is a special lubricant, strongly adhesive and penetrating, excellent for lubricating power transmissions subjected to vibration phenomena.",
  },
  {
    productImg: productsImg.ibiotecDrum,
    productTitle: "Ibiotec AS 10",
    productPara:
      "Ibiotec AS 10 is a water-based non-stick welding spray for the protection of all parts or sheets before welding.",
  },
  {
    productImg: productsImg.ibiotecDrum,
    productTitle: "Ibiotec AS 50",
    productPara:
      "Ibiotec Aerosol AS 50 is a non-flammable welding anti-adherent for tubes, suitable for all welding methods and for all cable diameters, even automatically, it does not generate cracks, it allows the recovery of defects and aspects.",
  },
  {
    productImg: productsImg.ibiotecDecap1000,
    productTitle: "Ibiotec Decap 1000",
    productPara:
      "Ibiotec Decap 1000 is a stripper without chlorinated solvents for cleaning and stripping mold marks.",
  },
  {
    productImg: productsImg.ibiotecDecap5000,
    productTitle: "Ibiotec Decap 5000",
    productPara:
      "Ibiotec Decap 5000 is a particularly reactive stripper for removing highly cross-linked coatings such as grouting and graffiti.",
  },
  {
    productImg: productsImg.ibiotecDemo750,
    productTitle: "Ibiotec Demoplast 750",
    productPara:
      "Ibiotec Demoplast 750 is a release agent for plastics. It allows any subsequent coating operation by painting, screen printing, gluing or all decoration.",
  },
  {
    productImg: productsImg.ibiotecDemo780,
    productTitle: "Ibiotec Demoplast 780 HT",
    productPara:
      "Ibiotec Demoplast 780 HT is a silicone-free non-stick release agent. Versatile product intended for difficult demolding operations to replace silicones.",
  },
  {
    productImg: productsImg.ibiotecDP2,
    productTitle: "Ibiotec DP 3 MOS2",
    productPara:
      "Ibiotec DP 3 MoS2 is an immediate action unblocking aerosol, a product loaded with Mos2 and low in fat.",
  },
  {
    productImg: productsImg.ibiotecDemo850,
    productTitle: "Ibiotec Demoplast SIL 850",
    productPara:
      "Ibiotec Demoplast Sil 850 is a non-greasy multifunctional silicone aerosol, it is a slightly greasy multipurpose product intended for difficult demoulding operations and to solve lubrication problems in clean environments.",
  },
  {
    productImg: productsImg.ibiotecDP6,
    productTitle: "Ibiotec DP 6",
    productPara:
      "Ibiotec DP 6 is a super unclogging aerosol with 6 functions: seize remover, rust remover, lubricant, anti-corrosion, cleaner, tar remover.",
  },
  {
    productImg: productsImg.ibiotecNeolube20,
    productTitle: "Ibiotec Neolube T 20",
    productPara:
      "Ibiotec Neolube T 20 is a penetrating adhesive lubricant, especially for transmission chains, with a very high viscosity index, it has remarkable adhesion and penetration properties.",
  },
  {
    productImg: productsImg.ibiotecProtect100,
    productTitle: "Ibiotec Protect FL 100",
    productPara:
      "Ibiotec Protect FL 100 is a colorless anti-corrosion fluid for steel and aluminium.",
  },
  {
    productImg: productsImg.ibiotecDrum,
    productTitle: "Ibiotec Protect FL 100 Blue",
    productPara:
      "Ibiotec Protect FL 100 Blue is a non-greasy anticorrosive fluid, recommended for the protection of steel or aluminum moulds. Long-lasting anti-corrosion protection.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Rapid GT",
    productPara: " ",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmotempoil 400-05",
    productPara:
      "Lubcon Turmotempoil 400/05 is a special oil based on perfluorinated polyether, which is why it belongs to the most thermally and chemically stable lubricants.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmsilon M 100",
    productPara: " ",
  },
  {
    productImg: productsImg.petronas6_1,
    productTitle: "Petronas 6 in 1 Svitol",
    productPara:
      "Petronas 6 IN 1 By Svitol is a multipurpose spray product, designed to instantly and simultaneously solve problems and emergency situations in the home, garden, hobbies, automobile, go-kart, nautical, cycling, sports and DIY areas in general, but also for the industrial maintenance, mechanics and crafts sector.",
  },
];

export const degreasersLubricants = [
  {
    productImg: productsImg.cepsaAlt,
    productTitle: "Cepsa Marine Degreaser",
    productPara:
      "Cepsa Marine Degreaser is a product specifically formulated for use in cleaning metal surfaces of tanks, plates, machinery, etc. of boats. Formulated with high purity aliphatic solvents and selected surfactants",
  },
  {
    productImg: productsImg.fuchAlt,
    productTitle: "FC Renoclean MS 214 F",
    productPara:
      "Renoclean MS 214 F is a multi-metal synthetic degreasing product",
  },
  {
    productImg: productsImg.rivoltaBFC,
    productTitle: "FC Rivolta BFC Spray",
    productPara:
      "Rivolta BFC Spray is a biodegradable active foam for cleaning and degreasing waterproof surfaces for oily, greasy, soot and dust contamination, nicotine, fingerprints and much more. It is odorless, slightly alkaline and free of silicone and silicates.",
  },
  {
    productImg: productsImg.rivoltaMTX,
    productTitle: "FC Rivolta MTX 100",
    productPara:
      "Rivolta MTX 100 is an intensive degreaser with a slow evaporation rate 100",
  },
  {
    productImg: productsImg.rivoltaMTX,
    productTitle: "FC Rivolta MTX 60",
    productPara:
      "Rivolta MTX 60 is an intensive degreaser with a slow evaporation rate 60",
  },
  {
    productImg: productsImg.rivoltaSBC,
    productTitle: "FC Rivolta SBC Spray",
    productPara:
      "Rivolta SBC Spray is a solvent-based degreaser for metal surfaces.",
  },
  {
    productImg: productsImg.rivoltaslx,
    productTitle: "FC Rivolta SLX 1000",
    productPara:
      "Rivolta SLX 1000 is a low dielectric strength degreaser for equipment connected to load/current.",
  },
  {
    productImg: productsImg.ibiotecDrum,
    productTitle: "Ibiotec Neutralene 2005",
    productPara:
      "Ibiotec Neutralene 2005 is a solvent with a very fast evaporation rate, which is used to clean and wash all kinds of plastic materials, without alterations, before surface treatment, decoration or metal plating.",
  },
  {
    productImg: productsImg.ibiotecDrum,
    productTitle: "Ibiotec Neutralene 2012",
    productPara:
      "Ibiotec Neutralene 2012 is a degreasing solvent with a fast evaporation rate, specifically designed for the removal of oils and greases and waxes.",
  },
  {
    productImg: productsImg.ibiotecDrum,
    productTitle: "Ibiotec Neutralene AL 30",
    productPara:
      "Ibiotec Neutralene AL 30 is a totally deodorized fluid, particularly suitable for any classic degreasing and cleaning operation, in metallurgy and plastic transformation.",
  },
  {
    productImg: productsImg.ibiotecDrum,
    productTitle: "Ibiotec Neutralene AL 50",
    productPara:
      "Ibiotec Neutralene AL 50 is a totally deodorized fluid, particularly suitable for any degreasing and classic cleaning operation, in metallurgy and plastic transformation.",
  },
  {
    productImg: productsImg.ibiotecDrum,
    productTitle: "Ibiotec Neutralene AL 66",
    productPara:
      "Ibiotec Neutralene AL 66 is a totally deodorized fluid, particularly suitable for any degreasing and classic cleaning operation, in metallurgy and plastic transformation.",
  },
  {
    productImg: productsImg.ibiotecDrum,
    productTitle: "Ibiotec Neutralene Bio 1000",
    productPara:
      "Ibiotec Neutralene Bio 1000 is a non-flammable, biodegradable, VOC-free, safety degreasing solvent with high solvent power specifically developed for use in cleaning sources with solvents.",
  },
  {
    productImg: productsImg.ibiotecDrum,
    productTitle: "Ibiotec Neutralene Eco 60",
    productPara:
      "Ibiotec Neutralene Eco 60 is an industrial degreasing solvent, with a mixture of narrow-cut hydrocarbons that allows a high evaporation speed to be obtained.",
  },
  {
    productImg: productsImg.ibiotecHV1,
    productTitle: "Ibiotec Neutralene HV 1",
    productPara:
      "Ibiotec Neutralene HV 1 is an instant drying degreaser, recommended for degreasing, cleaning, and decontamination of all metals, composites, glass, ceramics, as well as most plastics and elastomers.",
  },
  {
    productImg: productsImg.ibiotecDrum,
    productTitle: "Ibiotec Neutralene RG 30",
    productPara:
      "Ibiotec Neutralene RG 30 is an immediate substitute for acetone.",
  },
  {
    productImg: productsImg.ibiotecNeutra200,
    productTitle: "Ibiotec Neutralene V 200",
    productPara:
      "Ibiotec Neutralene V 200 is a cleaning and surface preparation solvent.",
  },
  {
    productImg: productsImg.ibiotecNeutraVGAL,
    productTitle: "Ibiotec Neutralene VG AL 1",
    productPara:
      "Ibiotec Neutralene VG AL 1 is a degreasing solvent for food industries with NSF K1 certification.",
  },
  {
    productImg: productsImg.lubriscoFood,
    productTitle: "Lubriso Desgras",
    productPara:
      "Lubriso Desgras is a solvent-based compound free of chlorinated compounds, which removes grease and oil residues accumulated on any surface.",
  },
  {
    productImg: productsImg.mmccAlt,
    productTitle: "MMCC Biosane BIO 2000 E",
    productPara:
      "MMCC Biosane Bio 2000 E is an emulsifiable cleaner and degreaser. Specifically developed to replace hydrocarbon solvents or any other petroleum solvent.",
  },
  {
    productImg: productsImg.ibiotecDrum,
    productTitle: "MMCC Biosane BIO 2050 ED",
    productPara:
      "MMCC Biosane Bio 2050 ED is a degreasing fluid formulated with vegetable oil derivatives.",
  },
  {
    productImg: productsImg.mmccAlt,
    productTitle: "MMCC Biosane DW 60",
    productPara:
      "MMCC Biosane DW 60 is a product that guarantees degreasing, dry hydrophobic, anticorrosive functions.",
  },
  {
    productImg: productsImg.mmccAlt,
    productTitle: "MMCC Biosane ECO 35",
    productPara:
      "MMCC Biosane ECO 35 is a degreaser with an optimized evaporation rate.  ",
  },
  {
    productImg: productsImg.mmccAlt,
    productTitle: "MMCC Biosane ECO 60 R",
    productPara:
      "MMCC Biosane ECO 60 R is a degreaser with an optimized evaporation speed.",
  },
  {
    productImg: productsImg.mmccAlt,
    productTitle: "MMCC Biosane N 100",
    productPara: "MMCC Biosane N 100 is a VOC-free degreasing fluid.",
  },
  {
    productImg: productsImg.mmccAlt,
    productTitle: "MMCC Biosane SR 84",
    productPara: "MMCC Biosane SR 84 is a high-tech degreaser.",
  },
  {
    productImg: productsImg.mmccAlt,
    productTitle: "MMCC Biosane T 212",
    productPara:
      "MMCC Biosane T 212 is a high-tech degreaser, which has been developed to respond to extreme cases: non-measurable flash point, ultra-fast scanning speed, solvent power for any requirement, compatibility with most substrates.",
  },
  {
    productImg: productsImg.mmccAlt,
    productTitle: "MMCC Biosane T 3000",
    productPara:
      "MMCC Biosane T 3000 is a technical degreasing fluid with volatile organic compounds.",
  },
];

export const releaseLubricants = [
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmsilon MV 100",
    productPara: " ",
  },
  {
    productImg: productsImg.motulStagAlt,
    productTitle: "Motul Stag 10",
    productPara:
      "Motul Stag 10 is a water-based mold release agent for the extrusion of metals and alloys, specially developed for aluminum extrusion.",
  },
  {
    productImg: productsImg.motulStagAlt,
    productTitle: "Motul Stag 11",
    productPara:
      "Motul Stag 11 is a water-based mold release agent for the extrusion of metals and alloys, specially developed for aluminum extrusion.",
  },
  {
    productImg: productsImg.motulStagAlt,
    productTitle: "Motul Stag 14",
    productPara:
      "Motul Stag 14 is a powdered anti-seize agent for the extrusion of metals and alloys, composed of innovative separating agents, formulated for the treatment of the extrusion head and the billet in extrusion of metals and alloys. It has been specifically developed for aluminum extrusion.",
  },
  {
    productImg: productsImg.motulStagAlt,
    productTitle: "Quaker Houghton Die Kote 4231",
    productPara:
      "DIE KOTE 4231 is a solvent-based mold release agent formulated with specific synthetic raw materials for the casting of Zinc and its alloys in hot chamber machines. The natural chemistry of its active phase ensures an excellent level of cleanliness, significantly reducing the problems of carbonaceous residues in the molds. For this reason DIE KOTE 4231 is particularly recommended for high-speed production processes.",
  },
];

export const detergentsLubricants = [
  {
    productImg: productsImg.rivoltabrx,
    productTitle: "FC Rivolta BRX 501",
    productPara:
      "Rivolta BRX 501 certified as NSF-A4 and has been developed as a cleaning concentrate to be used instead of many different products with very specific application sectors without exception.",
  },
  {
    productImg: productsImg.rivoltabrx611,
    productTitle: "FC Rivolta BRX 611",
    productPara:
      "Rivolta BRX 611 is a concentrated biodegradable water-based degreaser",
  },
  {
    productImg: productsImg.rivoltabwk,
    productTitle: "FC Rivolta BWK",
    productPara:
      "Rivolta BWK is a ready-to-use, easy-to-apply and biodegradable cleaning product. It is used to remove persistent waterborne dirt on surfaces. It does not contain solvents and is based on a combination of highly effective sequestering agents.",
  },
  {
    productImg: productsImg.rivoltabwr,
    productTitle: "FC Rivolta BWR 210",
    productPara:
      "Rivolta BWR 210 is a concentrated cleaner with high cleaning power for universal use. Eliminates all kinds of grease, glass, plastics, lacquered objects, and much more.",
  },
  {
    productImg: productsImg.rivoltaslx500,
    productTitle: "FC Rivolta SLX 500 Spray",
    productPara:
      "Rivolta SLX 500 Spray is a special safety cleaner for difficult cleaning jobs on electronic and mechanical elements, for cleaning highly sensitive electronic installations, components and devices during operation and current transport.",
  },
  {
    productImg: productsImg.rivoltabrx,
    productTitle: "FC Rivolta SLX Rapid Spray",
    productPara:
      "Rivolta SLX Rapid Spray is NSF-K1 certified and meets strict food hygiene requirements, designed for fast and effective cleaning of machinery parts in the food, pharmaceutical and animal feed industries and is excellent for its rapid and complete evaporation without residues and its excellent compatibility with materials.",
  },
  {
    productImg: productsImg.rivoltaslxSuper,
    productTitle: "FC Rivolta SLX Super",
    productPara:
      "Rivolta SLX SUPER is a high security electronic equipment cleaner.",
  },
  {
    productImg: productsImg.rivoltaslxTop,
    productTitle: "FC Rivolta SLX Top",
    productPara:
      "Rivolta SLX TOP is a high dielectric strength degreaser for equipment NOT connected to load/current",
  },
  {
    productImg: productsImg.ibiotecAlt,
    productTitle: "Ibiotec Bio Protect 450",
    productPara:
      "Ibiotec Bio Protect 450 is a microcrystalline wax emulsion for the preventive treatment of logs and boards, anti-cracks and anti-fissures.",
  },
  {
    productImg: productsImg.ibiotecDrum,
    productTitle: "Ibiotec Bioclean AL HP",
    productPara:
      "Ibiotec Bioclean AL HP is a degreasing detergent for difficult cleaning, particularly recommended for the elimination of organic products such as grease, light or heavy hydrocarbons, during periodic cleaning of workshop floors, structures, drilling platforms, storage areas and during emergency cleaning. in industrial processes or during accidental spreading.",
  },
  {
    productImg: productsImg.ibiotecDrum,
    productTitle: "Ibiotec Bioclean C 350",
    productPara:
      "Ibiotec Bioclean C 350 is a special super-concentrated detergent for bodywork, autoactive, specifically formulated for washing and cleaning vehicles that circulate in highly polluted environments.",
  },
  {
    productImg: productsImg.ibiotecDrum,
    productTitle: "Ibiotec Bioclean Inox AL",
    productPara:
      "Ibiotec Bioclean Inox AL is a non-greasy polishing cleaner, especially for stainless steel products, it can be used for all types of stainless steel.",
  },
  {
    productImg: productsImg.ibiotecDrum,
    productTitle: "Ibiotec Bioclean Safe",
    productPara:
      "Ibiotec Bioclean Safe is a degreasing cleaning detergent for industrial environments. Non-flammable, it does not contain any toxic substances or traces of hydrocarbons.",
  },
  {
    productImg: productsImg.ibiotecDrum,
    productTitle: "Ibiotec Bioclean SL 200",
    productPara:
      "Ibiotec Bioclean SL 200 is a specific degreasing detergent for floors and areas, it can be used manually, with HP materials or on brushing machines.",
  },
  {
    productImg: productsImg.ibiotecDrum,
    productTitle: "Ibiotec Bioclean VG 55",
    productPara:
      "Ibiotec Bioclean VG 55, the first super-concentrated industrial detergent with 55% active ingredients, based on surfactants of biological vegetable and marine origin, easily and quickly biodegradable.",
  },
  {
    productImg: productsImg.ibiotecDrum,
    productTitle: "Ibiotec Fast Clean PU 110",
    productPara:
      "Ibiotec Fast Clean Pu 110 (formerly Biosane Lotis Pu Cleaner) is a polyurethane cleaning solvent, an alternative solvent to methylene chloride without any modification of machine settings and cycle times.",
  },
  {
    productImg: productsImg.ibiotecDrum,
    productTitle: "Ibiotec Fast Clean Stripper",
    productPara:
      "Ibiotec Fast Clean Stripper is a mold cleaning and stripping solvent for crosslinked resins.",
  },
  {
    productImg: productsImg.ibiotecDrum,
    productTitle: "Ibiotec Neutralene 1079",
    productPara:
      "Ibiotec Neutralene 1079 is a special cleaning and washing solvent for resins, inks, adhesives, glues, fresh paints and the like.",
  },
  {
    productImg: productsImg.ibiotecDrum,
    productTitle: "Ibiotec Neutralene D 312",
    productPara:
      "Ibiotec Neutralene D 312 is a solvent for dissolving bitumen, asphalt, guaranteed without chlorinated solvents, optimized ratio of evaporation rate/flash point.",
  },
  {
    productImg: productsImg.ibiotecAlt,
    productTitle: "Ibiotec Neutralene Infinity",
    productPara:
      "Ibiotec Neutralene Infinity is a cleaning solvent, PE, PU, ​​Epoxies, BIS GMA resins",
  },
  {
    productImg: productsImg.ibiotecStripper,
    productTitle: "Ibiotec Neutralene Label Stripper",
    productPara:
      "Ibiotec Neutralene Label Stripper is a product for removing adhesive and self-adhesive labels, by impregnation, infiltration and dissolution.",
  },
  {
    productImg: productsImg.ibiotecDrum,
    productTitle: "Ibiotec Neutralene VG 2020",
    productPara:
      "Ibiotec Neutralene VG 2020 is an alternative agrosolvent for difficult degreasing, without any danger pictogram, guaranteed without dangerous, harmful, irritant or sensitizing substance, high performance that replaces hydrocarbon-based solvents and caustic water-based degreasers. It provides cleaning and emulsifiable degreasing, low toxicity and meets the strictest environmental and safety criteria.",
  },
  {
    productImg: productsImg.ibiotecDrum,
    productTitle: "Ibiotec Solvetal AC 100",
    productPara:
      "Ibiotec Solvetal AC 100 is a 100% vegetable ready-to-use anti-adherent, specially developed to offer maximum anti-adherence in all stages of hot asphalt agglomerate application, loading from 160ºC, delivery to site at 130ºC, compaction at 120ºC, also for tempered agglomerates.",
  },
  {
    productImg: productsImg.ibiotecDrum,
    productTitle: "Ibiotec Solvetal DBE 250",
    productPara:
      "Ibiotec Solvetal DBE 250 is an emulsifiable non-stick agent for chipboard floors, equipped with automatic spray bars, made from new generation vegetable esters, fully biodegradable. It is a concentrated anti-adherent and deasphalting treatment fluid for the manufacture and transport of road coatings.",
  },
  {
    productImg: productsImg.ibiotecDrum,
    productTitle: "MMCC Deternet 2005",
    productPara:
      "MMCC Deternet 2005 is a cleaner for mechanical components, it responds to most cleaning operations in the industrial environment.",
  },
  {
    productImg: productsImg.mmccAlt,
    productTitle: "MMCC Deternet A 300",
    productPara:
      "MMCC Deternet A 300 is a degreaser, mechanical detergent, anticorrosive, designed to meet the most extreme cases of cleaning in industrial environments.",
  },
  {
    productImg: productsImg.mmccAlt,
    productTitle: "MMCC Deternet HP",
    productPara:
      "MMCC Deternet HP is a multipurpose detergent, responds to the most extreme cases of cleaning in industrial settings.",
  },
  {
    productImg: productsImg.mmccAlt,
    productTitle: "MMCC Deternet SL 200",
    productPara:
      "MMCC Deternet SL 200 is a detergent for difficult cleaning, responds to the most extreme cases of cleaning in industrial settings.",
  },
  {
    productImg: productsImg.mmccAlt,
    productTitle: "MMCC Dexcel D 255",
    productPara:
      "MMCC Dexcel D 255 is a high evaporation cleaning fluid for cleaning operations in paint dispensing machines by circulation, robot and manual guns.",
  },
  {
    productImg: productsImg.mmccAlt,
    productTitle: "MMCC Dexcel D 285",
    productPara:
      "MMCC Dexcel D 285 is a technical fluid for general use for the dilution or cleaning of organic material of mineral or vegetable origin.",
  },
  {
    productImg: productsImg.mmccAlt,
    productTitle: "MMCC Dexcel D 312",
    productPara:
      "MMCC Dexcel D 312 is a cleaning fluid specially developed for the removal of asphalt and tar, it allows the cleaning of any material used for the application of heavy hydrocarbons, it is equally effective on combustion residues.",
  },
  {
    productImg: productsImg.mmccAlt,
    productTitle: "Dexcel Serimax R MMCC",
    productPara:
      "MMCC Dexcel Serimax R is a cleaning fluid for screen printing, a substitute for ketones, acetates and aromatics.",
  },
  {
    productImg: productsImg.mmccAlt,
    productTitle: "MMCC Dexcel SL 30",
    productPara: " ",
  },
  {
    productImg: productsImg.mmccAlt,
    productTitle: "MMCC Dexcel SL 50",
    productPara:
      "Dexcel SL 50 is a safety solvent for cleaning paints, inks, varnishes, resins and glues.",
  },
  {
    productImg: productsImg.mmccAlt,
    productTitle: "MMCC Dexcel SL 70",
    productPara:
      "Dexcel SL 70 is a universal cleaning fluid for inks, paints, varnishes, resins and glues, a substitute for ketones, acetates and aromatics.",
  },
  {
    productImg: productsImg.mmccAlt,
    productTitle: "MMCC Dexcel SL 80",
    productPara:
      "MMCC Deternet A 300 is a degreaser, mechanical detergent, anticorrosive, designed to meet the most extreme cases of cleaning in industrial environments.",
  },
  {
    productImg: productsImg.mmccAlt,
    productTitle: "MMCC Deternet A 300",
    productPara: " ",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "QH Grotanol FF 1N",
    productPara:
      "QH Grotanol FF 1 N is a microbicidal cleaning product for sanitizing circulation systems and production facilities.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Disolgum",
    productPara:
      "Disolgum is a fluid for cleaning hydraulic circuits. It is completely miscible with mineral oils used for hydraulic controls. It has a very high degree of detergency, which dissolves and keeps in suspension the sludge, lacquers and gummies that have been deposited in the different mechanisms and pipes. Its use in an appropriate way, allows to obtain a cleaning of the circuit, without the need for long and costly disassembly operations. not dry Leave a light lubricating layer on the points where it has been in contact. It does not attack the rubber of the pipes or the joints.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Houghto Clean 121",
    productPara:
      "Houghto Clean 121 is a universal neutral detergent for hard surfaces.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Houghto Clean 122",
    productPara:
      "Houghto Clean 122 is a low alkaline degreaser for spray washing light metal alloys and brass.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Houghto Clean 142",
    productPara:
      "Houghto Clean 142 is a medium alkalinity spray wash detergent formulated primarily for cleaning ferrous metals and alkali resistant substrates.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Houghto Clean 291",
    productPara: " ",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Houghto Clean 503",
    productPara:
      "Houghto Clean 503 is a relatively slow evaporating, high purity cleaning solvent.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Houghto Clean 90",
    productPara:
      "Houghto Clean 90 is a low viscosity anticorrosive thinner and degreaser.",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Houghto Clean Scrubber Machine",
    productPara: " ",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Micromag Standard MM10",
    productPara: " ",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Micromag Standard Cleaning Post",
    productPara: " ",
  },
  {
    productImg: productsImg.quaker,
    productTitle: "Quaker Houghton Oil Separator 1600 ( PF 82225 )",
    productPara: " ",
  },
  {
    productImg: null,
    productTitle: "Royal Flush",
    productPara: "Royal Flush is an oil circulation system cleaner.",
  },
  {
    productImg: productsImg.thermorensinox,
    productTitle: "Thermorens G200 INOX",
    productPara:
      "Termorens G200 INOX is a biodegradable gel for the passivation of stainless steels. Eliminates corrosion in all types of stainless steel, restores their original shine and protects them to delay the formation of rust on treated surfaces. It is respectful with the environment and does not react or damage any type of gasket or seal. Does not remove paint.",
  },
  {
    productImg: productsImg.thermorensnaval,
    productTitle: "Thermorens G200 Naval",
    productPara:
      "Termorens G200 Naval is a biodegradable gel for the removal of rust, lime and saltpeter on all types of surfaces above the waterline of boats (abovework). In stainless steels, in addition, it passives them, delaying the formation of rust while restoring their original shine.",
  },
  {
    productImg: productsImg.thermorensoxide,
    productTitle: "Termorens G200 Oxide",
    productPara:
      "Thermorens G200 Rust is a biodegradable gel for the removal of rust on metals, painted surfaces, plastics, fiberglass and untreated natural stones. It is also indicated for removing lime scale and for cleaning saltpeter and urine stains. It is respectful with the environment and does not react or damage any type of gasket or seal. Does not remove paint.",
  },
  {
    productImg: productsImg.dieselPowerUnit,
    productTitle: "Diesel Power Biocontrol Unit",
    productPara:
      "Unitor Diesel Power Biocontrol, it is essential to have adequate storage of marine fuel. But microorganisms can corrode fuel storage tanks and systems, leading to clogging of filters and nozzles and fuel degradation. DieselPower Biocontrol Unitor is a specially designed fluid that protects fuel against microorganisms. More advice on how to detect and manage microbial contamination can be found in ASTM D6469-14 Standard Guide for Microbial Contamination in Fuels and Fuel Systems.",
  },
];

export const assemblyPaste = [
  {
    productImg: productsImg.ibiotecNeolube2000,
    productTitle: "Ibiotec Neolube HC 2000",
    productPara:
      "Ibiotec Neolube HC 2000 is a cutting oil for all metals that are difficult to machine. It is a powerful friction reducer, lubricant, anti-weld, it has an exceptional film resistance.",
  },
  {
    productImg: productsImg.ibiotecMetal1100,
    productTitle: "Ibiotec Neolube Metal 1200",
    productPara:
      "Ibiotec Neolube Metal 1200 is a nickel anti-seize paste for very high performance. Product guaranteed without metalloids, without nitrites.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmolyb MDC",
    productPara:
      "Lubcon Turmolyb MDC is an extremely pure molybdenum disulfide powder bound by a small portion of organic agents. After spraying and a short drying time, an excellent adhesive film is created that allows easy sliding.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmolyb PSP",
    productPara:
      "Lubcon Turmolyb PSP is a high temperature molybdenum disulfide mounting paste.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmopast HTP 550 KEP",
    productPara:
      "LUBCON TURMOPAST HTP 550 KEP is a high temperature grease consisting of a combination of solid lubricant and showing high stability, thermal and chemical properties. Therefore, abrasion and wear will be reduced.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmopast MA 1",
    productPara:
      "Lubcon Turmopast MA 1 is a special synthetic grease for extreme conditions.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmopast MA 2",
    productPara:
      "Lubcon Turmopast MA 2 is a lubricating and assembly paste containing white solid lubricants.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmopast NBI 2 WHITE",
    productPara:
      "Lubcon Turmopast NBI 2 White is a lightly colored paste for lubrication and assembly, containing a new type of solid lubricants against fretting corrosion and tribocorrosion.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmopast SFP",
    productPara:
      "Lubcon Turmopast SFP is a partially synthetic lubricating and assembly paste for metallic friction combinations.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmopast TAS",
    productPara:
      "Lubcon Turmopast TAS is a non-stick product with a temperature range of up to 900 ° C. At 1100 ° C, pasty lubrication passes dry lubrication.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmopast TAS LMI",
    productPara:
      "Lubcon Turmopast TAS-LMI is a non-metallic paste for temperatures over 1200ºC. It is a combination of solid lubricants in a synthetic oil that evaporates without leaving any type of residue at high temperatures.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmopast TAS WT",
    productPara:
      "Lubcon Turmopast TAS-WT is a silver-grey anti-seize lubricant with release agents for temperatures above 1400ºC. It is composed of paste and fluid, it is a combination of solid lubricants in a synthetic oil that evaporates without leaving any residue at high temperatures.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmopast TAS WT 000 CRY",
    productPara:
      "LUBCON TURMOPAST TAS WT 000 CRY is a sprayable adhesive gear grease containing graphite. It does not drip at high temperatures and it does not slip at high peripheral speed.",
  },
  {
    productImg: productsImg.lubcon,
    productTitle: "Lubcon Turmopast WG",
    productPara:
      "LUBCON TURMOPAST WG is a white, silicone-containing paste with a high proportion of heat-conducting metal oxides. This special formulation is for high temperature resistance combined with a high thermal conductivity of 0.67 W/m K.",
  },
];

export const absorbentsLub = [
  {
    productImg: productsImg.absorbent30_80,
    productTitle: "Absorbent Instazorb 30/80 Bag 30 liters",
    productPara:
      "Absorbent Instazorb 30/80 is an industrial absorbent for greasy and liquid materials for outdoor use. It is an expanded perlite of a patented chemical product that allows it to break the surface tension of the products to be absorbed.",
  },

  {
    productImg: productsImg.absorbent30_80,
    productTitle: "Absorbent Instazorb 5/20 Bag 30 liters",
    productPara:
      "Absorbent Instazorb 5/20 is an industrial absorbent for greasy and liquid materials for interior and exterior use. It is an expanded perlite of a patented chemical product that allows it to break the surface tension of the products to be absorbed.",
  },
  {
    productImg: productsImg.deurexPure,
    productTitle: "Deurex Pure",
    productPara:
      "Deurex Pure is the perfect eco-friendly absorbent for hydrophobic liquid spills: petroleum and petroleum derivatives, chemical products, as well as alcohols and surfactants, in aquatic environments. It absorbs up to 13 times its own weight of liquid. Deurex Pure fiber is light, very easy to store and apply.",
  },
];

export const mobilLubricants = [
  {
    productImg: productsImg.mobilJetOil254,
    productTitle: "M-MOBILJET OIL 254",
    productPara:
      "Mobil Jet Oil 254 is a third-generation, extra high performance, synthetic aircraft-type gas turbine lubricant engineered to meet the performance requirements for gas turbine engines used in commercial and military aircraft.  This product is formulated from a specially prepared, hindered-ester base stock and fortified with a unique chemical additive package.",
  },

  {
    productImg: productsImg.delvacshc_5w40,
    productTitle: "M-DELVAC 1 SHC 5W40 PAIL 20L",
    productPara:
      "Mobil Delvac 1 SHC 5W-40 is a very high performance, synthetic diesel engine oil that provides excellent lubrication including long drain capability, helping towards long engine life for diesel engines operating in severe applications.",
  },

  {
    productImg: productsImg.delvac15w40drum,
    productTitle: "M-DELVAC MX 15W40 DRUM 208L",
    productPara:
      "Mobil Delvac MX 15W-40 is an extra high performance diesel engine oil that provides excellent lubrication of today’s diesel engines promoting extended engine life. ",
  },
  {
    productImg: productsImg.mobildelvacpail,
    productTitle: "M-DELVAC MX 15W40 PAIL 25L (L28)",
    productPara:
      "Mobil Delvac MX 15W-40 is a high performance diesel engine oil that helps extend engine life in the most severe on and off highway applications.",
  },
  {
    productImg: productsImg.delvac4x4,
    productTitle: "Mobil Delvac MX 15W-40, Diesel, 1 Gal",
    productPara:
      "Mobil DelvacTM MX 15W-40 is an extra high performance diesel engine oil that provides excellent lubrication of today's diesel engines promoting long engine life. As a result, this product meets or exceeds the requirements of virtually all major European and American engine manufacturers. ",
  },
  {
    productImg: productsImg.delvacsuper,
    productTitle: "Mobil Delvac 1300 Super 15W-40 Motor Oil - 55 Gal. Drum",
    productPara:
      "Mobil Delvac 1300 Super 15W-40 is extra-high performance, diesel engine oil that helps extend engine life in the most severe on- and -off highway applications while delivering outstanding performance. They meet or exceed the requirements of the API CJ-4, CI-4 PLUS and CH-4 service categories as well as key original equipment manufacturer requirements.",
  },
  {
    productImg: productsImg.delvac_15w40pail,
    productTitle: "Mobil Delvac Modern 15W-40 Super Defense 20L Pail",
    productPara:
      "Mobil Delvac Modern 15W-40 Super Defense is an extra high performance diesel engine oil that provides excellent lubrication of today's diesel engines promoting extended engine life.",
  },
  {
    productImg: productsImg.delvac1340,
    productTitle: "Mobil Delvac™ 1340",
    productPara:
      "Mobil Delvac™ 1340, and 1350 are high performance, heavy duty diesel engine oils formulated from advanced technology base oils and a balanced additive system.  They are specifically engineered for performance in intercooled, turbo-charged engines operating under severe on and off highway conditions.",
  },
  {
    productImg: productsImg.mobiltrans30,
    productTitle: "M-MOBILTRANS HD 30 DRUM-M 208L (L28)",
    productPara:
      "Mobiltrans HD 10W, 30, 50, and 60 are extra high performance, heavy duty transmission and drive-train lubricants engineered to meet or exceed the requirements of the rigorous Caterpillar TO-4 specification.  This product line is uniquely designed to optimise the performance of powershift transmissions, gearboxes, and final drives. ",
  },
  {
    productImg: productsImg.mobillube80w90,
    productTitle: "M-LUBE HD 80W90 DRUM-M 208L",
    productPara:
      "Mobilube HD 80W-90 and 85W-140 are high performance, heavy duty gear lubricants formulated from high performance base oils and an advanced additive system. These lubricants are engineered for automotive applications including heavy duty axles and final drives where extreme pressures and shock loading are expected. ",
  },
  {
    productImg: productsImg.mobillube4l80w90,
    productTitle: "M-LUBE HD 80W90 GSP 4X4L AME-1",
    productPara:
      "Mobilube HD 85W-140 are high performance, heavy duty gear lubricants formulated from high performance base oils and an advanced additive system. These lubricants are engineered for automotive applications including heavy duty axles and final drives where extreme pressures and shock loading are expected.  ",
  },
  {
    productImg: productsImg.mobillube1404l,
    productTitle: "M-LUBE HD 85W140 GSP 4X4L AME-1",
    productPara:
      "Mobilube HD 85W-140 are high performance, heavy duty gear lubricants formulated from high performance base oils and an advanced additive system. These lubricants are engineered for automotive applications including heavy duty axles and final drives where extreme pressures and shock loading are expected.  ",
  },
  {
    productImg: productsImg.mobilhydraulic10w,
    productTitle: "MOBIL HYDRAULIC 10W DRUM-M 208L (L28)",
    productPara:
      "Mobil Hydraulic 10W is a high performance hydraulic oil formulated from advanced base oils and a balanced additive system designed to satisfy a wide range of heavy-duty hydraulic equipment requirements. This product is specifically engineered using an effective balance of ashless dispersants and metallic detergents combined with inhibitors to control oxidation, wear, corrosion and rust.  ",
  },
  {
    productImg: productsImg.delvac15w40drum,
    productTitle: "M-MOBILTRANS HD 50 DRUM-M 208L (L28)",
    productPara:
      "Mobiltrans HD 10W, 30, 50, and 60 are extra high performance, heavy duty transmission and drive-train lubricants engineered to meet or exceed the requirements of the rigorous Caterpillar TO-4 specification. ",
  },
  {
    productImg: productsImg.delvac15w40drum,
    productTitle: "M-DELVAC 1330 DRUM-M 208L (L28)",
    productPara:
      "Mobil Delvac 1330, 1340, and 1350 are high performance monograde diesel engine oils formulated from advanced technology base oils and a balanced additive system. They are specifically engineered for performance in intercooled, turbo-charged engines operating under severe on and off-highway conditions.  ",
  },
  {
    productImg: productsImg.mobil424drum,
    productTitle: "M-MOBILFLUID 424 Drum-M 208L",
    productPara:
      "Mobilfluid 424 is an extra high performance multipurpose tractor lubricant engineered to meet or exceed transmission and hydraulic fluid requirements.  ",
  },
  {
    productImg: productsImg.mobiltacpail,
    productTitle: "M-MOBILTAC 375 NC Pail 38LB",
    productPara:
      "Mobiltac 325 NC and 375 NC are extra high performance non-leaded, diluent-type, heavy-bodied open gear lubricants designed for a wide variety of open gear and mining applications. They contain a non-chlorinated, volatile solvent that ensures fluidity during application, even at low temperatures.  ",
  },
  {
    productImg: productsImg.delvac15w40drum,
    productTitle: "M-DTE 10 EXCEL 100 DRUM-M 208L",
    productPara:
      "The Mobil DTE 10 Excel Series is constructed from selected base oils and a proprietary additive package to provide well balanced performance in a range of applications.  The products demonstrate outstanding oxidation and thermal stability allowing for long oil life and minimized deposit formation in severe hydraulic systems using high pressure, high output pumps.  ",
  },
  {
    productImg: productsImg.delvac15w40drum,
    productTitle: "M-DTE 10 EXCEL 15 DRUM-M 208L",
    productPara:
      "The Mobil DTE 10 Excel Series is constructed from selected base oils and a proprietary additive package to provide well balanced performance in a range of applications.  The products demonstrate outstanding oxidation and thermal stability allowing for long oil life and minimized deposit formation in severe hydraulic systems using high pressure, high output pumps. ",
  },
  {
    productImg: productsImg.delvac15w40drum,
    productTitle: "M-DTE 10 EXCEL 32 DRUM-M 208L",
    productPara:
      "The Mobil DTE 10 Excel Series is constructed from selected base oils and a proprietary additive package to provide well balanced performance in a range of applications.  The products demonstrate outstanding oxidation and thermal stability allowing for long oil life and minimized deposit formation in severe hydraulic systems using high pressure, high output pumps.  ",
  },
  {
    productImg: productsImg.delvac15w40drum,
    productTitle: "M-DTE 10 EXCEL 46 DRUM-M 208L",
    productPara:
      "The Mobil DTE 10 Excel Series is constructed from selected base oils and a proprietary additive package to provide well balanced performance in a range of applications.  The products demonstrate outstanding oxidation and thermal stability allowing for long oil life and minimized deposit formation in severe hydraulic systems using high pressure, high output pumps. ",
  },
  {
    productImg: productsImg.delvac15w40drum,
    productTitle: "M-DTE 10 EXCEL 68 DRUM-M 208L",
    productPara:
      "The Mobil DTE 10 Excel Series is constructed from selected base oils and a proprietary additive package to provide well balanced performance in a range of applications.  The products demonstrate outstanding oxidation and thermal stability allowing for long oil life and minimized deposit formation in severe hydraulic systems using high pressure, high output pumps.",
  },
  {
    productImg: productsImg.delvac15w40drum,
    productTitle: "MOBIL DTE 24 ULTRA DRUM-M 208L",
    productPara:
      "Mobil DTE 20 Ultra series oils are high performance anti-wear hydraulic oils with extended oil life capabilities and have demonstrated up to 2 times longer oil drain intervals versus similar competitive oils (*). ",
  },
  {
    productImg: productsImg.delvac15w40drum,
    productTitle: "MOBIL DTE 25 ULTRA DRUM-M 208L",
    productPara:
      "Mobil DTE 20 Ultra series oils are high performance anti-wear hydraulic oils with extended oil life capabilities and have demonstrated up to 2 times longer oil drain intervals versus similar competitive oils (*). ",
  },
  {
    productImg: productsImg.delvac15w40drum,
    productTitle: "MOBIL  DTE 26 ULTRA DRUM-M 208L",
    productPara:
      "Mobil DTE 20 Ultra series oils are high performance anti-wear hydraulic oils with extended oil life capabilities and have demonstrated up to 2 times longer oil drain intervals versus similar competitive oils (*).",
  },
  {
    productImg: productsImg.delvac15w40drum,
    productTitle: "M-DTE 832 DRUM-M 208L (L28)",
    productPara:
      "Mobil DTE™ 832 and 846 are superior performance turbine oils designed for use in steam turbines, gas turbines and combined cycle gas turbine (CCGT) applications under the most severe operating conditions. These progressive products are based on high quality hydrotreated basestocks for exceptional thermal/oxidation resistance along with specially chosen additives engineered to provide the deposit control and keep-clean performance required by severe duty gas turbines as well as excellent water separability needed for steam turbine operation. ",
  },
  {
    productImg: productsImg.delvac15w40drum,
    productTitle: "M-DTE 846 Drum-M 208L",
    productPara:
      "Mobil DTE™ 832 and 846 are superior performance turbine oils designed for use in steam turbines, gas turbines and combined cycle gas turbine (CCGT) applications under the most severe operating conditions. These progressive products are based on high quality hydrotreated basestocks for exceptional thermal/oxidation resistance along with specially chosen additives engineered to provide the deposit control and keep-clean performance required by severe duty gas turbines as well as excellent water separability needed for steam turbine operation. ",
  },
  {
    productImg: productsImg.delvac15w40drum,
    productTitle: "M-DTE OIL LIGHT DRUM-M 208L (L28)",
    productPara:
      "The Mobil DTE™  Oil Named Series of lubricants are premium performance circulating lubricants designed for applications including steam and hydro turbine sets and other systems where long lubricant service life is required. Mobil DTE Oil Named Series lubricants are formulated from highly refined base stocks and an additive system which provide an extremely high level of chemical and thermal stability, rapid and complete separation from water and a high resistance to emulsification. ",
  },
  {
    productImg: productsImg.delvac15w40drum,
    productTitle: "M-EAL ARC 68 PAIL 20L",
    productPara:
      "Mobil EAL Arctic Series are high performance  fully synthetic lubricants specifically designed for the lubrication of refrigeration compressors and systems using ozone-friendly synthetic HFC refrigerants as well as recently developed HFO refrigerants and HFO/HFC blends with lower Global Warming Potential than HFC's, including A1 and A2L refrigerants as per ASHRAE 34/ISO 817 safety classification.",
  },
  {
    productImg: productsImg.mobilpail,
    productTitle: "M-EAL ARC 68 PAIL 20L",
    productPara:
      "Mobil EAL Arctic Series are high performance  fully synthetic lubricants specifically designed for the lubrication of refrigeration compressors and systems using ozone-friendly synthetic HFC refrigerants as well as recently developed HFO refrigerants and HFO/HFC blends with lower Global Warming Potential than HFC's, including A1 and A2L refrigerants as per ASHRAE 34/ISO 817 safety classification.",
  },
  {
    productImg: productsImg.delvac15w40drum,
    productTitle: "M-GARGOYLE ARC 300 Drum-M 208L",
    productPara:
      "Mobil Gargoyle™ Arctic Oil 155, 300, and Mobil Gargoyle™ Arctic C Heavy products are high performance naphthenic mineral oils primarily intended for use in refrigeration compressors. They have low pour points and excellent fluidity at very low temperatures by virtue of being almost wax-free. Consequently, use of these Mobil Gargoyle Arctic oils helps to ensure that evaporator tubes are kept clean to improve heat transfer and to reduce downtime for maintenance.  ",
  },
  {
    productImg: productsImg.mobilpail,
    productTitle: "M-GEAR OGL 009 PAIL 18KG",
    productPara:
      "Mobilgear OGL 007, 009, 2800 and 461 are high performance advanced technology lubricants which incorporate both extreme pressure additives and finely dispersed graphite for load carrying. They are intended primarily for the lubrication of large, slow to medium speed, heavily loaded gears. In addition to providing outstanding load carrying, they are formulated to have excellent adhesion and resistance to 'fling-off' under extreme conditions. ",
  },
  {
    productImg: productsImg.mobilpail,
    productTitle: "M-GLYGOYLE 30 Pail 20L",
    productPara:
      "Mobil Glygoyle™ 11, 22, and 30 oils are polyalkyleneglycol-based (PAG) high performance lubricants that provide outstanding lubrication in extreme-temperature gear, bearing and circulation system applications in conditions well beyond the capabilities of mineral oils.  ",
  },
  {
    productImg: productsImg.mobilpail,
    productTitle: "M-GREASE XHP 222 PAIL 18KG",
    productPara:
      "Mobilgrease XHP 222 is an extended service lithium complex grease intended for a wide variety of applications and severe operating conditions. This grease was designed to outperform conventional products by applying cutting-edge, proprietary, lithium complex manufacturing technology. ",
  },
  {
    productImg: productsImg.mobilpail,
    productTitle: "M-MOBILITH SHC 100 Pail 16KG",
    productPara:
      "Mobilith SHC™ Series greases are superior performance products designed for a wide variety of applications at extremes of temperature. They combine the unique features of synthetic base fluids with those of a high quality lithium complex thickener. The wax-free nature of synthetic fluids and the low coefficient of traction (compared with mineral oils), provide excellent low temperature pumpability and very low starting and running torque.  ",
  },
  {
    productImg: productsImg.mobilpail,
    productTitle: "M-MOBILITH SHC 1500 PAIL 16KG",
    productPara:
      "Mobilith SHC™ Series greases are superior performance products designed for a wide variety of applications at extremes of temperature. They combine the unique features of synthetic base fluids with those of a high quality lithium complex thickener. The wax-free nature of synthetic fluids and the low coefficient of traction (compared with mineral oils), provide excellent low temperature pumpability and very low starting and running torque.  ",
  },
  {
    productImg: productsImg.mobilpail,
    productTitle: "M-MOBILITH SHC 460 PAIL 16KG",
    productPara:
      "Mobilith SHC™ Series greases are superior performance products designed for a wide variety of applications at extremes of temperature. They combine the unique features of synthetic base fluids with those of a high quality lithium complex thickener.",
  },
  {
    productImg: productsImg.delvac15w40drum,
    productTitle: "M-MOBILTHERM 605 DRUM-M 208L (L28)",
    productPara:
      "Mobiltherm heat transfer oils are high performance products intended for use in closed indirect heating installations. They are recommended for use in cold-oil sealed, indirect heating and cooling systems in all kinds of industrial processes.",
  },
  {
    productImg: productsImg.mobilpail,
    productTitle: "M-MOBILUX EP 2 PAIL 18KG",
    productPara:
      "Mobilux™ EP 0, 1, 2, 3, 004 and 023 products are a high performance family of four general-purpose industrial greases and two special-duty semi-fluid greases. These lithium hydroxystearate greases are formulated to provide extra protection against wear, rusting and water washout. They are available in NLGI grades ranging from 00 to 3, with base oil viscosities ISO VG 150 and 320. ",
  },
  {
    productImg: productsImg.delvac15w40drum,
    productTitle: "M-NUTO H 32 DRUM-M 208L (L28)",
    productPara:
      "Nuto™ H Series oils are good quality anti-wear hydraulic oils intended for industrial and mobile service applications, subjected to moderate operating conditions and requiring anti-wear lubricants.",
  },
  {
    productImg: productsImg.delvac15w40drum,
    productTitle: "M-NUTO H 46 DRUM-M 208L (L28)",
    productPara:
      "Nuto™ H Series oils are good quality anti-wear hydraulic oils intended for industrial and mobile service applications, subjected to moderate operating conditions and requiring anti-wear lubricants. Their effective oxidation resistance and chemical stability support good oil life in moderate to severe applications. ",
  },
  {
    productImg: productsImg.delvac15w40drum,
    productTitle: "M-NUTO H 68 DRUM-M 208L (L28)",
    productPara:
      "Nuto™ H Series oils are good quality anti-wear hydraulic oils intended for industrial and mobile service applications, subjected to moderate operating conditions and requiring anti-wear lubricants. Their effective oxidation resistance and chemical stability support good oil life in moderate to severe applications. ",
  },
  {
    productImg: productsImg.delvac15w40drum,
    productTitle: "M-NUTO H 68 PAIL 25L (L28)",
    productPara:
      "Mobil Nuto H 68 is an ISO Viscosity Grade (VG) 68 premium antiwear hydraulic oil. It is designed for use in industrial and mobile equipment service where antiwear hydraulic oils are required. It meets requirements of piston, vane and gear pumps, as well as the requirements of other hydraulic system components such as servo-valves. ",
  },
  {
    productImg: productsImg.mobilshc626,
    productTitle: "MOBIL SHC 626 PAIL 20L",
    productPara:
      "Mobil SHC™ 600 Series lubricants are exceptional performance gear and bearing oils designed to provide outstanding service in terms of equipment protection, oil life and problem-free operation helping to enable increased customer productivity.",
  },
  {
    productImg: productsImg.mobilshc629,
    productTitle: "MOBIL SHC 629 PAIL 20L",
    productPara:
      "Mobil SHC™ 600 Series lubricants are exceptional performance gear and bearing oils designed to provide outstanding service in terms of equipment protection, oil life and problem-free operation helping to enable increased customer productivity.",
  },
  {
    productImg: productsImg.mobilshc632,
    productTitle: "MOBIL SHC 632 PAIL 20L",
    productPara:
      "Mobil SHC™ 600 Series lubricants are exceptional performance gear and bearing oils designed to provide outstanding service in terms of equipment protection, oil life and problem-free operation helping to enable increased customer productivity. ",
  },
  {
    productImg: productsImg.mobilshc634,
    productTitle: "MOBIL SHC 634 PAIL 20L",
    productPara:
      "Mobil SHC™ 600 Series lubricants are exceptional performance gear and bearing oils designed to provide outstanding service in terms of equipment protection, oil life and problem-free operation helping to enable increased customer productivity. ",
  },
  {
    productImg: productsImg.mobilshc639,
    productTitle: "MOBIL SHC 639 PAIL 20L",
    productPara:
      "Mobil SHC™ 600 Series lubricants are exceptional performance gear and bearing oils designed to provide outstanding service in terms of equipment protection, oil life and problem-free operation helping to enable increased customer productivity. ",
  },
  {
    productImg: productsImg.delvac15w40drum,
    productTitle: "MOBILECT 39 DRUM-M 208L",
    productPara:
      "Mobilect 39 Drum-M 208L is a high-quality mineral uninhibited insulating oil with very good dielectric properties and oxidation stability. It is intended for use in transformers, switchgears, and other electrical equipment.",
  },
  {
    productImg: productsImg.mobilgear600,
    productTitle: "MOBILGEAR 600 XP 68 DRUM-M 208L",
    productPara:
      "Mobilgear 600 XP Series are extra high-performance gear oils having outstanding extreme pressure characteristics and load-carrying properties, intended for use in all types of enclosed gear drives with circulation or splash lubrication systems. ",
  },
  {
    productImg: productsImg.mobilgear600,
    productTitle: "MOBILGEAR 600 XP 100 DRUM-M 208L",
    productPara:
      "Mobilgear 600 XP Series are extra high performance gear oils having outstanding extreme pressure characteristics and load-carrying properties, intended for use in all types of enclosed gear drives with circulation or splash lubrication systems. ",
  },
  {
    productImg: productsImg.delvac15w40drum,
    productTitle: "MOBILGEAR 600 XP 150 DRUM-M 208L",
    productPara:
      "Mobilgear 600 XP Series are extra high performance gear oils having outstanding extreme pressure characteristics and load-carrying properties, intended for use in all types of enclosed gear drives with circulation or splash lubrication systems. ",
  },
  {
    productImg: productsImg.delvac15w40drum,
    productTitle: "MOBILGEAR 600 XP 220 DRUM-M 208L",
    productPara:
      "Mobilgear 600 XP Series are extra high performance gear oils having outstanding extreme pressure characteristics and load-carrying properties, intended for use in all types of enclosed gear drives with circulation or splash lubrication systems. ",
  },
  {
    productImg: productsImg.delvac15w40drum,
    productTitle: "MOBILGEAR 600 XP 320 DRUM-M 208L",
    productPara:
      "Mobilgear 600 XP Series are extra high performance gear oils having outstanding extreme pressure characteristics and load-carrying properties, intended for use in all types of enclosed gear drives with circulation or splash lubrication systems. ",
  },
  {
    productImg: productsImg.delvac15w40drum,
    productTitle: "MOBILGEAR 600 XP 460 DRUM-M 208L",
    productPara:
      "Mobilgear 600 XP Series are extra high performance gear oils having outstanding extreme pressure characteristics and load-carrying properties, intended for use in all types of enclosed gear drives with circulation or splash lubrication systems.  ",
  },
  {
    productImg: productsImg.delvac15w40drum,
    productTitle: "M-PEGASUS 1005 DRUM-M 208L",
    productPara:
      "Mobil Pegasus™ 1005 is a high performance gas engine oil designed to provide today's high output, low-emission four-cycle gas engines with the highest levels of protection while maintaining superior performance in earlier model engines. Mobil Pegasus 1005 is the latest addition to the Mobil Pegasus pedigree of proven natural gas engine oils with a balanced, durable formulation. ",
  },
  {
    productImg: productsImg.delvac15w40drum,
    productTitle: "M-PEGASUS 805 DRUM-M 208L (L28)",
    productPara:
      "Mobil Pegasus™ 805 is premium performance gas engine oil engineered to meet the rigorous demands of today's high output four-cycle engines designed to reduce emissions and improve fuel-efficiency.  ",
  },
  {
    productImg: productsImg.m_polyrex103,
    productTitle: "M-POLYREX EM 103 16KG",
    productPara:
      "Super-premium Mobil Polyrex™ EM Series greases are specially formulated for electric-motor bearings. The advanced thickener formulation and proprietary manufacturing techniques provide improved bearing performance and protection for long electric motor life.",
  },
  {
    productImg: productsImg.delvac15w40drum,
    productTitle: "M-RARUS 427 Drum-M 208L",
    productPara:
      "The Mobil Rarus™ 400 Series is a line of premium performance ashless air compressor lubricants designed to meet the stringent requirements of the major compressor manufacturers. They are formulated with high quality mineral base-oils and a high performance additive system designed to provide exceptional equipment protection and reliability for compressors operating under mild to severe conditions. ",
  },
  {
    productImg: productsImg.delvac15w40drum,
    productTitle: "M-RARUS 429 Drum-M 208L",
    productPara:
      "The Mobil Rarus™ 400 Series is a line of premium performance ashless air compressor lubricants designed to meet the stringent requirements of the major compressor manufacturers. They are formulated with high quality mineral base-oils and a high performance additive system designed to provide exceptional equipment protection and reliability for compressors operating under mild to severe conditions.",
  },
  {
    productImg: productsImg.mobil_rarus,
    productTitle: "M-RARUS 827 PAIL 5USG",
    productPara:
      "Mobil Rarus 827 is a supreme performance synthetic air compressor lubricant engineered for the lubrication of severe duty reciprocating air compressors*. ",
  },
  {
    productImg: productsImg.mobilrarus1026,
    productTitle: "M-RARUS SHC 1026 Pail 20L",
    productPara:
      "The Mobil Rarus SHC™ 1020 Series is a line of supreme performance oils primarily intended for the lubrication of severe duty rotary screw and vane air compressors. They are particularly suited for severe service where mineral oil-based products are not meeting expectations such as in severe applications subjected to high final compression temperatures or where extended oil drain intervals are desired.",
  },
  {
    productImg: productsImg.mobilshc626pail,
    productTitle: "M-SHC 626 PAIL 20L",
    productPara:
      "Mobil SHC™ 600 Series lubricants are exceptional performance gear and bearing oils designed to provide outstanding service in terms of equipment protection, oil life and problem-free operation helping to enable increased customer productivity.",
  },
  {
    productImg: productsImg.delvac15w40drum,
    productTitle: "M-VACUOLINE 525 DRUM-M 208L (L28)",
    productPara:
      "The Mobil Vacuoline 500 Series family of products provides a versatile lubricant source for a wide range of industrial equipment.  The Mobil Vacuoline 500 Series of lubricants are high performance heavy duty circulating oils designed for the demands of No-Twist Rod mills, however their all round performance makes them a excellent choice for circulation systems lubricating gears and bearings. ",
  },
  {
    productImg: productsImg.delvac15w40drum,
    productTitle: "M-VACUOLINE 528 DRUM-M 208L (L28)",
    productPara:
      "The Mobil Vacuoline 500 Series family of products provides a versatile lubricant source for a wide range of industrial equipment.  The Mobil Vacuoline 500 Series of lubricants are high performance heavy duty circulating oils designed for the demands of No-Twist Rod mills, however their all round performance makes them a excellent choice for circulation systems lubricating gears and bearings. ",
  },
  {
    productImg: productsImg.delvac15w40drum,
    productTitle: "M-MOBILGARD 300C DRUM 208L (L28)",
    productPara:
      "Mobilgard™ 300 C is a next-generation system oil specially engineered to help improve cleanliness in two-stroke crosshead diesel engines. Its balanced formulation combats deposits and sludge buildup, especially in the most susceptible engine components – such as the piston undercrown and crankcase. ",
  },
  {
    productImg: productsImg.delvac15w40drum,
    productTitle: "M-GARD 450NC DRUM-M 208L (L28)",
    productPara:
      "Mobilgard™ 450 NC (No Chlorine) engine oil by ExxonMobil is a non-zinc and non-chlorine lubricant formulated with high-quality basestocks which provide low consumption characteristics, high-temperature oxidation resistance, and thermal stability. ",
  },
  {
    productImg: productsImg.delvac15w40drum,
    productTitle: "M-MOBILGARD 412 DRUM-M 208L (L28)",
    productPara:
      "Mobilgard™ 12 Series by ExxonMobil are high performance diesel engine oils developed for use in trunk piston engines operating on low sulphur distillate fuels in marine and industrial applications. ",
  },
  {
    productImg: productsImg.delvac15w40drum,
    productTitle: "M-MOBILGARD 570 DRUM-M 208L (L28)",
    productPara:
      "TMobilgard™ 570 cylinder oil is designed to optimise the performance of two-stroke marine diesel engines operating on heavy fuel oil with a sulphur content of up to 3.5 per cent. It can help improve operation of engines suffering from low levels of corrosion.",
  },
  {
    productImg: productsImg.delvac15w40drum,
    productTitle: "M-MOBILGARD M330 DRUM-M 208L (L28)",
    productPara:
      "Mobilgard™ M30 Series (M330 and M430) by ExxonMobil are premium, extra high performance 30 TBN engine oils designed for use in the most severe residual-fuelled medium-speed diesel applications found in marine and stationary power industries.  ",
  },
  {
    productImg: productsImg.delvac15w40drum,
    productTitle: "M-MOBILGARD M430 DRUM-M 208L (L28)",
    productPara:
      "Mobilgard 430 M-MOBILGARD is a premium quality, heavy-bodied lubricant made from thermally stable base stocks. It is designed to reduce the build-up of harmful deposits caused by the high operating temperatures of steam cylinders. ",
  },
  {
    productImg: productsImg.delvac15w40drum,
    productTitle: "M-MOBILGARD M440 DRUM-M 208L (L28)",
    productPara:
      "Mobilgard™ M440 is a high performance 40 BN engine oil recommended for use on deep-sea vessels, coastal and river ships operating on heavy fuel oil (HFO).",
  },
  {
    productImg: productsImg.mobilesp1,
    productTitle: "M-1 ESP 5W30 GSP 4X5L EU-AME1",
    productPara:
      "Mobil 1™ ESP 5W-30 full synthetic motor oil has been expertly engineered to help prolong the life and maintain the efficiency of emission systems in both diesel and gasoline-powered automobiles. ",
  },
  {
    productImg: productsImg.mobil1esp1,
    productTitle: "M-1 ESP FORM5W30 GSP 12X1L EU-SE",
    productPara:
      "Mobil 1™ ESP 5W-30 full synthetic motor oil has been expertly engineered to help prolong the life and maintain the efficiency of emission systems in both diesel and gasoline-powered automobiles.  ",
  },
  {
    productImg: productsImg.mobil1fs,
    productTitle: "M-1 FS 0W40 GSP 12X1L :AME2",
    productPara:
      "Mobil 1™ FS 0W-40 advanced full synthetic engine oil is engineered for the latest gasoline and diesel  engine technology (without Gasoline or Diesel Particulate Filters) delivering excellent all-round performance. It provides exceptional cleaning power, wear protection. Mobil 1™ FS 0W-40 keeps your engine running like new in all driving conditions.",
  },
  {
    productImg: productsImg.mobil14l,
    productTitle: "M-1 FS 0W40 GSP 4X4L :AME2",
    productPara:
      "Mobil 1™ FS 0W-40 advanced full synthetic engine oil is engineered for the latest gasoline and diesel  engine technology (without Gasoline or Diesel Particulate Filters) delivering excellent all-round performance. It provides exceptional cleaning power, wear protection. Mobil 1™ FS 0W-40 keeps your engine running like new in all driving conditions.",
  },
  {
    productImg: productsImg.mobil3000,
    productTitle: "MOBIL SUPER 3000 X1 5W-40  12X1L",
    productPara:
      "Mobil Super™ 3000 x1 5W-40 is a fully synthetic engine oil that provides long engine life for many types and ages of vehicles with enhanced protection over a wide temperature range. ",
  },
  {
    productImg: productsImg.mobil30004l,
    productTitle: "MOBIL SUPER 3000 X1 5W-40  4X4L",
    productPara:
      "Mobil Super™ 3000 x1 5W-40 is a fully synthetic engine oil that provides long engine life for many types and ages of vehicles with enhanced protection over a wide temperature range. ",
  },
  {
    productImg: productsImg.mobil30004l,
    productTitle: "MOBIL SUPER 3000 X1 5W-40 4X5L",
    productPara:
      "Mobil Super™ 3000 x1 5W-40 is a fully synthetic engine oil that provides long engine life for many types and ages of vehicles with enhanced protection over a wide temperature range.  ",
  },
  {
    productImg: productsImg.mobil20001l,
    productTitle: "M SUPER 2000 5W-30 12X1L :NG",
    productPara:
      "Mobil Super 2000 5W-30 is an enhanced-premium semi-synthetic motor oil meeting the latest industry engine oil specifications. It is designed to provide an excellent level of protection and performance under the most demanding conditions. ",
  },
  {
    productImg: productsImg.mobil20004l,
    productTitle: "M SUPER 2000 5W-30 4X4L :NG",
    productPara:
      "Mobil Super 2000 5W-30 is an enhanced-premium semi-synthetic motor oil meeting the latest industry engine oil specifications. It is designed to provide an excellent level of protection and performance under the most demanding conditions. ",
  },
  {
    productImg: productsImg.mobil20004l,
    productTitle: "M SUPER 2000 5W-30 4X5L :NG",
    productPara:
      "Mobil Super 2000 5W-30 is an enhanced-premium semi-synthetic motor oil meeting the latest industry engine oil specifications. It is designed to provide an excellent level of protection and performance under the most demanding conditions.",
  },
  {
    productImg: productsImg.mobil10004l,
    productTitle: "M-SUP1000 X1 20W50 GSP 4X4L :NG",
    productPara:
      "The Mobil Super™ 1000 range of lubricants are premium mineral engine oils, designed to provide a high level of protection and performance. ",
  },
  {
    productImg: productsImg.mobil10004l,
    productTitle: "M-SUP1000 X1 20W50 GSP 4X5L :NG",
    productPara:
      "The Mobil Super™ 1000 range of lubricants are premium mineral engine oils, designed to provide a high level of protection and performance. ",
  },
  {
    productImg: productsImg.mobilsupermoto4t,
    productTitle: "MOBIL SUPER MOTO 4T 20W-50 12X1L :NG",
    productPara:
      "Mobil Super Moto 4T 20W-50 is a high performance conventional four-stroke motorcycle engine oil primarily intended for general use in many types of four-stroke motorcycles and engines.",
  },
  {
    productImg: productsImg.delvac15w40drum,
    productTitle: "MOBIL SPL 20W50 GSP 12X1L AME-1",
    productPara:
      "Mobil Special 20W-50 is a high performance motor oil for all types of vehicles. Mobil Special 20W-50 delivers engine protection and cleanliness. Mobil Special 20W-50 is recommended for use in a wide range of applications and operating conditions. ",
  },
  {
    productImg: productsImg.mobil20w501l,
    productTitle: "MOBIL SPECIAL 20W50 GSP 12X1L AME-1",
    productPara:
      "Mobil Special 20W-50 is a high performance motor oil for all types of vehicles. Mobil Special 20W-50 delivers engine protection and cleanliness. Mobil Special 20W-50 is recommended for use in a wide range of applications and operating conditions.",
  },
  {
    productImg: productsImg.mobilspecial20w504l,
    productTitle: "MOBIL SPL 20W50 GSP 4X4L AME-1",
    productPara:
      "Mobil Special 20W-50 is a high performance motor oil for all types of vehicles. Mobil Special 20W-50 delivers engine protection and cleanliness. Mobil Special 20W-50 is recommended for use in a wide range of applications and operating conditions. ",
  },
  {
    productImg: productsImg.delvac15w40drum,
    productTitle: "M-HD 40 12X1L AME-1",
    productPara:
      "Mobil HD 40 is a heavy-duty engine oil primarily intended for use in gasoline automotive engines of passenger cars, commercial vehicles and farm equipment. It may also be used in diesel engines of commercial vehicles and contractor equipment. It is formulated from high quality mineral base oils and an advanced additive system to provide the excellent performance required for modern combustion engines. ",
  },
  {
    productImg: productsImg.mobilhd404l,
    productTitle: "M-HD 40 4X4L AME-1",
    productPara:
      "Mobil HD 40 is a heavy-duty engine oil primarily intended for use in gasoline automotive engines of passenger cars, commercial vehicles and farm equipment. It may also be used in diesel engines of commercial vehicles and contractor equipment. It is formulated from high quality mineral base oils and an advanced additive system to provide the excellent performance required for modern combustion engines.",
  },
  {
    productImg: productsImg.delvac15w40drum,
    productTitle: "M-HD 40 DRUM-M 208L (L28)",
    productPara:
      "Mobil HD 40 is a heavy-duty engine oil primarily intended for use in gasoline automotive engines of passenger cars, commercial vehicles and farm equipment. It may also be used in diesel engines of commercial vehicles and contractor equipment. It is formulated from high quality mineral base oils and an advanced additive system to provide the excellent performance required for modern combustion engines. ",
  },
  {
    productImg: productsImg.delvac15w40drum,
    productTitle: "M-SPL 2T DRUM-M 208L (L28)",
    productPara:
      "Mobil Special 2T is a high performance, two-stroke engine oil for use in motorcycles, snowmobiles and lean oil/fuel ratio chain saws. Mobil Special 2T is pre-diluted to ensure rapid mixing when added to fuel. ",
  },
  {
    productImg: productsImg.delvac15w40drum,
    productTitle: "M-SPL 2T GSP 12X1L AME-1",
    productPara:
      "Mobil Special 2T is a high performance, two-stroke engine oil for use in motorcycles, snowmobiles and lean oil/fuel ratio chain saws. Mobil Special 2T is pre-diluted to ensure rapid mixing when added to fuel. ",
  },
  {
    productImg: productsImg.delvac15w40drum,
    productTitle: "MOBIL ATF 320 DRUM-M 208 L28",
    productPara:
      "Mobil ATF 320 is recommended for most passenger car and commercial automatic transmissions. It is also suitable for power steering systems, hydraulic applications and some manual transmissions where an automatic transmission fluid is specified. ",
  },
  {
    productImg: productsImg.mobilatf3201l,
    productTitle: "M-ATF 320 GSP 12X1L AME-1",
    productPara:
      "Mobil ATF 320 is recommended for most passenger car and commercial automatic transmissions. It is also suitable for power steering systems, hydraulic applications and some manual transmissions where an automatic transmission fluid is specified.",
  },
  {
    productImg: productsImg.mobilatf220,
    productTitle: "M-ATF 220 DRUM-M 208L (L28)",
    productPara:
      "Mobil ATF 220 is a high performance, fluid for automatic transmissions in older vehicles specifying Dexron IID. It is also used as a hydraulic fluid in unique applications. ",
  },
];

export const totalLubricants = [
  {
    productImg: productsImg.totalq0w204l,
    productTitle: "QUARTZ 9000 FUTURE GF6 0W-20",
    productPara:
      "TOTAL QUARTZ 9000 FUTURE GF6 0W-20 is an advanced synthetic technology engine oil that exceeds the latest API SP and ILSAC GF-6 sequences. TOTAL QUARTZ 9000 FUTURE GF6 0W-20 provides the best protection against wear and deposits. The posttreatment systems for petrol engines, which are sensitive to the lubricants used and expensive to maintain, are also protected over the long-term thanks to the product’s low phosphorus content.",
  },
  {
    productImg: productsImg.totalq0w204l,
    productTitle: "QUARTZ 9000 FUTURE GF5 OW -20",
    productPara:
      "TOTAL QUARTZ 9000 FUTURE GF6 0W-20 is an advanced synthetic technology engine oil that exceeds the latest API SP and ILSAC GF-6 sequences. TOTAL QUARTZ 9000 FUTURE GF6 0W-20 provides the best protection against wear and deposits. The posttreatment systems for petrol engines, which are sensitive to the lubricants used and expensive to maintain, are also protected over the long-term thanks to the product’s low phosphorus content.",
  },
  {
    productImg: productsImg.totalq0w204l,
    productTitle: "QUARTZ 9000 ENERGY 0W-30",
    productPara:
      "TTOTAL QUARTZ 9000 ENERGY 0W-30 is an advanced synthetic technology engine oil that has been developed to cover the most stringent requirements of both gasoline and diesel passengers cars engines. TOTAL QUARTZ 9000 ENERGY 0W-30 is particularly suited to the most recent engines. ",
  },
  {
    productImg: productsImg.totalhtcineo,
    productTitle: "QUARTZ INEO HTC 5W30",
    productPara:
      "Quartz Ineo HTC 5W-30 is an advanced synthetic engine oil specially designed for PEUGEOT, CITROËN and DS engines and the use in high-temperature countries. Its highly advanced formulation increases its robustness to a very high level and makes it an excellent choice in regards to the prevention of low speed premature ignition (LSPI), sludges and oil consumption issues.",
  },
  {
    productImg: productsImg.total90005w,
    productTitle: "QUARTZ 9000 5W40",
    productPara:
      "Synthetic technology engine oil for gasoline and Diesel passenger’s cars engines, particularly suitable for turbo compressed, multi-valve and direct injection diesel engines.",
  },
  {
    productImg: productsImg.total4x44l,
    productTitle: "QUARTZ 4X4 15W50 (SN)",
    productPara:
      "Multigrade engine oil for both diesel and gasoline engines developed for 4X4, pick up and SUV of last generation vehicles. Can be used in most severe driving conditions (motorways, dense city traffic, and cross country driving).",
  },
  {
    productImg: productsImg.total7000s10w40,
    productTitle: "QUARTZ 7000 S 10W-40",
    productPara:
      "TOTAL QUARTZ 7000 10W-40 is a synthetic based engine oil that has been developed to cover the most stringent requirements of both gasoline and diesel engines. TOTAL QUARTZ 7000 10W-40 is particularly suited to turbocharged and multi-valve engines.",
  },
  {
    productImg: productsImg.total700015w50,
    productTitle: "QUARTZ 7000 15W-50",
    productPara:
      "Quartz 7000 15W50 satisfies the most demanding manufacturers standards, while guaranteeing perfect and constant quality. Its high viscosity helps the product to combat wear in high-temperature conditions. Ensures good engine cleanliness by draining engine particles and dust to the oil filter.",
  },
  {
    productImg: productsImg.total500020w,
    productTitle: "QUARTZ 5000-20W-50 SN",
    productPara:
      "Engine oil suitable for use in gasoline and Diesel engines (passenger cars and light industrial vehicles). This lubricant is particularly suited to turbocharged engines. Perfectly adapted to vehicles equipped with catalysts and using unleaded fuel or liquefied petroleum gas. It can be used in all operating conditions (city traffic, road, motorways), whatever the season.",
  },
  {
    productImg: productsImg.total50005ww,
    productTitle: "QUARTZ 5000 20W50 SL",
    productPara:
      "Engine oil suitable for use in gasoline and Diesel engines (passenger cars and light industrial vehicles). This lubricant is particularly suited to turbocharged engines. Perfectly adapted to vehicles equipped with catalysts and using unleaded fuel or liquefied petroleum gas. It can be used in all operating conditions (city traffic, road, motorways), whatever the season.",
  },
  {
    productImg: productsImg.total3000205l,
    productTitle: "QUARTZ 3000 SG 20W50",
    productPara:
      "QUARTZ 3000 20W50 is a multi-grade car engine oil specially designed to be used in all car engine types – Petrol, LPG, CNG, etc. Its unique UNI PRO FORMULA provides uniform protection to all vital engine parts from wear and tear.",
  },
  {
    productImg: productsImg.rubia7400aaa,
    productTitle: "RUBIA TIR 7400 15W-40",
    productPara:
      "This is a mineral lubricant suitable for on-road diesel engines recommended to Euro 3, and previous engines of most European and American manufacturers. It is also adapted to Euro 5 and previous engines of some manufacturers with appropriate oil drain intervals.",
  },
  {
    productImg: productsImg.totalrubiafleet22,
    productTitle: "RUBIA FLEET HD 300 15W40",
    productPara:
      "RUBIA FLEET HD 300 15W-40 diesel engine oil with its XTRA BOOSTER FORMULA is a premium multi-grade oil recommended for all turbocharged (TC) engines – on-road and off-road.",
  },
  {
    productImg: productsImg.rubiafleethd200,
    productTitle: "RUBIA FLEET HD 200 SAE 30.",
    productPara:
      "TOTAL RUBIA FLEET HD 200 10W / 30 / 40 / 50 is recommended for all turbocharged or naturally-aspirated diesel engines of commercial vehicles (trucks, buses, vans, pick-ups, taxis...), and for all off-road applications (public works, agriculture, navigation, rail road) requiring API CF performance.",
  },
  {
    productImg: productsImg.rubiahd200sae,
    productTitle: "RUBIA FLEET HD 200 SAE 40",
    productPara:
      "Oxidation resistance. Rubia Fleet HD 200 helps protecting engines against oil deposit caused by oxidation formed under moderate and high temperature Detergent properties keep engines clean",
  },
  {
    productImg: productsImg.rubias40,
    productTitle: "RUBIA S 40",
    productPara:
      "Monograde lubricant for Diesel engines, suitable for all turbocharged or normally aspirated Diesel engines of civil works machines, trucks, locomotives.",
  },
  {
    productImg: productsImg.rubiafleethd100,
    productTitle: "RUBIA FLEET HD 100 SAE 40",
    productPara:
      "TOTAL RUBIA FLEET HD 100 SAE 10W/30/4050 is recommended for turbocharged or naturally-aspirated diesel engines of commercial vehicles (trucks, buses, vans, pick-ups, taxis...), and for off-road applications (public works, agriculture, navigation, rail road) requiring API CD performance.",
  },
];

export const castrolLubricants = [
  {
    productImg: productsImg.gtx20w501l,
    productTitle: "GTX Essential 20W-50 1L",
    productPara:
      "A 20W-50 motor oil is typically used in older vehicles or in particularly warm climates. Castrol GTX 20W-50 has been specially formulated to help extend the life of your engine by keeping it free from sludge, even when operating in extremely high temperatures.",
  },

  {
    productImg: productsImg.gtx20w504l,
    productTitle: "GTX Essential 20W-50 4L",
    productPara:
      "A 20W-50 motor oil is typically used in older vehicles or in particularly warm climates. Castrol GTX 20W-50 has been specially formulated to help extend the life of your engine by keeping it free from sludge, even when operating in extremely high temperatures.",
  },
  {
    productImg: productsImg.gtx25w504l,
    productTitle: "GTX Essential 25W-50 4L",
    productPara:
      "It protects against problems like sludge caused by severe driving conditions and it’s an excellent solution to the problem of fuel dilution usually associated with old engines. It is suitable for use in older generation normally aspirated and turbocharged petrol and diesel engines where the manufacturer recommends an API SG/CF 25W-50 lubricant.",
  },
  {
    productImg: productsImg.gtx15w40,
    productTitle: "GTX Essential 15W-40",
    productPara:
      "15W-40 is a very common viscosity grade for diesel engine oil. GTX Diesel is a 15W-40 diesel engine oil formulated to help extend engine life.",
  },
  {
    productImg: productsImg.castrolsae40,
    productTitle: "Motor Oil SAE 40",
    productPara:
      "Castrol Motor Oil is recommended for use in all makes of petrol and naturally aspirated diesel engines fitted to passenger cars and light to medium commercial vehicles where SAE 40 monograde oil is specified.",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "GTX Essential 20W-50 205L",
    productPara:
      "A 20W-50 motor oil is typically used in older vehicles or in particularly warm climates. Castrol GTX 20W-50 has been specially formulated to help extend the life of your engine by keeping it free from sludge, even when operating in extremely high temperatures.",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "GTX Essential 15W-40 205L",
    productPara:
      "15W-40 is a very common viscosity grade for diesel engine oil. GTX Diesel is a 15W-40 diesel engine oil formulated to help extend engine life.",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "GTX Essential 25W-50 205L",
    productPara:
      "t protects against problems like sludge caused by severe driving conditions and it’s an excellent solution to the problem of fuel dilution usually associated with old engines.It is suitable for use in older generation normally aspirated and turbocharged petrol and diesel engines where the manufacturer recommends an API SG/CF 25W-50 lubricant.",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "Vecton 15W-40 CI4",
    productPara:
      "Castrol VECTON® 15W-40 CI-4/E7 is an advanced mineral heavy duty diesel engine oil that is specifically engineered to deliver longer useful oil life. It is suitable for use in high speed 4-stroke diesel engines that use a broad range of fuel qualities. It is also compatible with diesel engines fitted with exhaust gas recirculation (EGR) systems",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "Motor Oil SAE 40",
    productPara:
      "Castrol Motor Oil is recommended for use in all makes of petrol and naturally aspirated diesel engines fitted to passenger cars and light to medium commercial vehicles where SAE 40 monograde oil is specified.",
  },
  {
    productImg: productsImg.edge5w401l,
    productTitle: "Edge 5W-40 1L",
    productPara:
      "If you're looking for a full synthetic 5W-40 motor oil, Castrol® EDGE® is formulated with liquid titanium providing the strength to withstand the pressure of today's high engine pressures.",
  },
  {
    productImg: productsImg.edge5w405l,
    productTitle: "Edge 5W-40 5L",
    productPara:
      "If you're looking for a full synthetic 5W-40 motor oil, Castrol® EDGE® is formulated with liquid titanium providing the strength to withstand the pressure of today's high engine pressures.",
  },
  {
    productImg: productsImg.edge5w404l,
    productTitle: "Edge 5W-40 4L",
    productPara:
      "If you're looking for a full synthetic 5W-40 motor oil, Castrol® EDGE® is formulated with liquid titanium providing the strength to withstand the pressure of today's high engine pressures.",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "Edge 5W-40 208L",
    productPara:
      "If you're looking for a full synthetic 5W-40 motor oil, Castrol® EDGE® is formulated with liquid titanium providing the strength to withstand the pressure of today's high engine pressures.",
  },
  {
    productImg: productsImg.edge10w605l,
    productTitle: "Edge 10W-60 SN",
    productPara:
      "Castrol EDGE 10W-60 SN is suitable for use in automotive gasoline and diesel engines where the manufacturer recommends an ACEA A3/B4, A3/B3, API SN/CF or earlier specification 10W-60 lubricant.",
  },
  {
    productImg: productsImg.edgeec5,
    productTitle: "Edge Professional E C5 0W-20",
    productPara:
      "Castrol EDGE Professional E C5 0W-20 is suitable for use in automotive gasoline and diesel engines where the manufacturer recommends an ACEA C5 0W-20 lubricant. Castrol EDGE Professional E C5 0W-20 is approved for use in vehicles that require a Jaguar Land Rover engine oil specification STJLR.03.5006 0W-20 lubriicant.",
  },
  {
    productImg: productsImg.gtx5w40710,
    productTitle: "GTX RN 5W-40 RN 710",
    productPara:
      "Renault - Castrol GTX RN-SPEC 5W-40 RN 710 is a fully synthetic oil is designed for all gasoline engines and diesel engines with no aftertreatment devices, suitable for long drain interval. Renault - Castrol GTX RN-SPEC 5W-40 RN 710 is suitable for use in vehicles where an API SN, Renault RN 0710 Specification 5W-40 lubricant is required.",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "GTX RN 5W-40 RN 710",
    productPara:
      "Renault - Castrol GTX RN-SPEC 5W-40 RN 710 is a fully synthetic oil is designed for all gasoline engines and diesel engines with no aftertreatment devices, suitable for long drain interval. Renault - Castrol GTX RN-SPEC 5W-40 RN 710 is suitable for use in vehicles where an API SN, Renault RN 0710 Specification 5W-40 lubricant is required.",
  },
  {
    productImg: productsImg.magnatec10w40,
    productTitle: "Magnatec 10W-40",
    productPara:
      "Castrol EDGE, an advanced full synthetic 10W-40 motor oil, is Castrol’s strongest oil and a great option for those who want the very best for their car. Castrol GTX MAGNATEC is a premium full synthetic 10W-40 that offers superior wear protection. If your car has over 75,000 miles on the odometer",
  },
  {
    productImg: productsImg.castrol5w301l,
    productTitle: "Magnatec 5W-30 1L",
    productPara:
      "Castrol EDGE, an advanced full synthetic 5W-30 motor oil, is Castrol’s strongest oil and a great option for those who want the very best for their car. Castrol GTX MAGNATEC is a premium full synthetic 5W-30 that offers superior wear protection. If your car has over 75,000 miles on the odometer.",
  },
  {
    productImg: productsImg.castrol5w305L,
    productTitle: "Magnatec 5W-30 5L",
    productPara:
      "Castrol EDGE, an advanced full synthetic 5W-30 motor oil, is Castrol’s strongest oil and a great option for those who want the very best for their car. Castrol GTX MAGNATEC is a premium full synthetic 5W-30 that offers superior wear protection. If your car has over 75,000 miles on the odometer.",
  },
  {
    productImg: productsImg.castrolmagnatecstopstart4l,
    productTitle: "Magnatec Stopstart 5W-30 A5",
    productPara:
      "Castrol MAGNATEC STOP-START 5W-30 A5 is suitable for use in automotive petrol and diesel engines where the manufacturer recommends an ACEA A1/B1, A5/B5, API SN, ILSAC GF-5 or earlier specification 5W-30 lubricant.",
  },
  {
    productImg: productsImg.magnateca51l,
    productTitle: "Magnatec 5W-30 A5",
    productPara:
      "Castrol Magnatec Professional MP 5W-30 is a full synthetic engine oil with intelligent molecules that cling to the engine like a magnet providing an extra layer of protection, dramatically reducing engine wear even from the start. It is suitable for use in automotive petrol and diesel engines where the manufacturer recommends an ACEA C3, API SN/CF or earlier specification 5W-30 lubricant.",
  },
  {
    productImg: productsImg.castrolmagnatecstopstart4l,
    productTitle: "Magnatec 5W-30 A5 4L",
    productPara:
      "Castrol Magnatec Professional MP 5W-30 is a full synthetic engine oil with intelligent molecules that cling to the engine like a magnet providing an extra layer of protection, dramatically reducing engine wear even from the start. It is suitable for use in automotive petrol and diesel engines where the manufacturer recommends an ACEA C3, API SN/CF or earlier specification 5W-30 lubricant.",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "Magnatec 5W-30 A5",
    productPara:
      "Castrol Magnatec Professional MP 5W-30 is a full synthetic engine oil with intelligent molecules that cling to the engine like a magnet providing an extra layer of protection, dramatically reducing engine wear even from the start. It is suitable for use in automotive petrol and diesel engines where the manufacturer recommends an ACEA C3, API SN/CF or earlier specification 5W-30 lubricant.",
  },
  {
    productImg: productsImg.magnatec10wa3b4,
    productTitle: "Magnatec 10W-40 A3/B4 1L",
    productPara:
      "Castrol MAGNATEC 10W-40 A3/B4 is suitable for use in Automotive gasoline and diesel engines where the manufacturer recommends anAPI SL/CF, ACEA A3/B4, A3/B3 or earlier specification 10W-40 lubricant. Castrol MAGNATEC 10W-40 A3/B4 is also approved for use in VW group vehicles that require a VW 501 01 / 505 00 specification 10W-40 lubricant and Mercedes vehicles that require an MB-Approval 226.5/229.1 10W-40 lubricant.",
  },
  {
    productImg: productsImg.magnateca3a44l,
    productTitle: "Magnatec 10W-40 A3/B4 4L",
    productPara:
      "Castrol MAGNATEC 10W-40 A3/B4 is suitable for use in Automotive gasoline and diesel engines where the manufacturer recommends anAPI SL/CF, ACEA A3/B4, A3/B3 or earlier specification 10W-40 lubricant. Castrol MAGNATEC 10W-40 A3/B4 is also approved for use in VW group vehicles that require a VW 501 01 / 505 00 specification 10W-40 lubricant and Mercedes vehicles that require an MB-Approval 226.5/229.1 10W-40 lubricant.",
  },
  {
    productImg: productsImg.magnateca3a45l,
    productTitle: "Magnatec 10W-40 A3/B4 5L",
    productPara:
      "Castrol MAGNATEC 10W-40 A3/B4 is suitable for use in Automotive gasoline and diesel engines where the manufacturer recommends anAPI SL/CF, ACEA A3/B4, A3/B3 or earlier specification 10W-40 lubricant. Castrol MAGNATEC 10W-40 A3/B4 is also approved for use in VW group vehicles that require a VW 501 01 / 505 00 specification 10W-40 lubricant and Mercedes vehicles that require an MB-Approval 226.5/229.1 10W-40 lubricant.",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "Magnatec 10W-40 A3/B4 210L",
    productPara:
      "Castrol MAGNATEC 10W-40 A3/B4 is suitable for use in Automotive gasoline and diesel engines where the manufacturer recommends anAPI SL/CF, ACEA A3/B4, A3/B3 or earlier specification 10W-40 lubricant. Castrol MAGNATEC 10W-40 A3/B4 is also approved for use in VW group vehicles that require a VW 501 01 / 505 00 specification 10W-40 lubricant and Mercedes vehicles that require an MB-Approval 226.5/229.1 10W-40 lubricant.",
  },
  {
    productImg: productsImg.superoutboard,
    productTitle: "Super Outboard Plus",
    productPara:
      "Superior performance Engine Oil for Outboards Castrol Super Outboard Plus is a mineral based, superior performance engine oil designed for water-cooled 2-stroke outboard engines. It is suitable for use in premix and oil injection systems with both leaded and unleaded petrol for fuel/oil mixture ratios, as recommended by the engine manufacturer, up to 100:1.",
  },
  {
    productImg: productsImg.castrolpower1racing,
    productTitle: "Power 1 Racing 4T 10W-50",
    productPara:
      "Castrol Power 1 Racing 4T 10W-50 is a fully synthetic 4-stroke engine oil for modern, high performance sports bikes that increases engine acceleration and power right up to maximum rpm. It is suitable for all makes of 4-strokes bikes, both carburetor and fuel injected where API SL (and below) and JASO MA or MA2 specifications are recommended. ",
  },
  {
    productImg: productsImg.powerracing2t,
    productTitle: "Power 1 Racing 2T",
    productPara:
      "Castrol Power 1 Racing is suitable for all modern, high performance 2-stroke motorcycle engines from European and Japanese manufacturers. It is designed for both oil injection and pre-mix lubrication, as per manufacturers' instructions, up to a fuel/oil ratio of 50:1.",
  },
  {
    productImg: productsImg.actevo4t10w401l,
    productTitle: "Actevo 4T 10W-40",
    productPara:
      " Castrol® Actevo® with Actibond Molecules® is a semi synthetic, 4-stroke motorcycle oil that is uniquely formulated to cling to critical parts even when the engine is off, providing a protective layer from the moment you start your engine. ",
  },
  {
    productImg: productsImg.radicoolnf1l,
    productTitle: "Radicool NF",
    productPara:
      " Castrol Radicool NF has been developed to meet the growing demand from engine and vehicle manufacturers for a higher performance coolant that minimises environmental impact. It provides excellent protection against corrosion and because it contains no phosphate, the problems of deposits in some modern performance engines are eliminated.",
  },
  {
    productImg: productsImg.radicoolsfpremix,
    productTitle: "Radicool SF Premix 5L",
    productPara:
      " Castrol Radicool SF Premix is a long-life ready to use 50:50 pre-mixed coolant based on monoethyleneglycol with advanced organic acid inhibitor technology. It is especially suitable for use in Engines employing cast iron, aluminium, copper or combinations of these metals used in modern engine designs. Standard rubber hoses, gaskets and seals used within the cooling system.",
  },
  {
    productImg: productsImg.radicoolsfpremix1l,
    productTitle: "Radicool SF Premix 1L",
    productPara:
      " Castrol Radicool SF Premix is a long-life ready to use 50:50 pre-mixed coolant based on monoethyleneglycol with advanced organic acid inhibitor technology. It is especially suitable for use in Engines employing cast iron, aluminium, copper or combinations of these metals used in modern engine designs. Standard rubber hoses, gaskets and seals used within the cooling system.",
  },
  {
    productImg: productsImg.castrolatfdex5l,
    productTitle: "ATF Dex II Multi Vehicle",
    productPara:
      " Castrol ATF Dex II Multivehicle is an Automatic Transmission Fluid which may be used in automatic transmissions andpower steering units of many types of vehicle where Dexron IID or Mercon performance is required. It is also approved for use in many european heavy duty automatic transmissions, Mercedes Benz manual transmissions and for the torque converters and gearboxes of many items of Industrial equipment where an ATF-type fluid is specified.",
  },
  {
    productImg: productsImg.transmaxdex05l,
    productTitle: "Transmax Dex III Multi Vehicle",
    productPara:
      " Transmax Dex III Multivehicle, formulated with Smooth Drive Technology is designed for use in GM automatic transmissions pre 2005 and Ford automatic transmissions built between 1983 and 1996 where Dexron(II or III) or Mercon are required. Power steering units. It has a wide range of European heavy duty automatic transmissions approvals as well has Allison heavy duty transmissions against TES 389.",
  },
  {
    productImg: productsImg.transmaxdex1l,
    productTitle: "Transmax Dex III Multi Vehicle",
    productPara:
      "  Transmax Dex III Multivehicle, formulated with Smooth Drive Technology is designed for use in GM automatic transmissions pre 2005 and Ford automatic transmissions built between 1983 and 1996 where Dexron(II or III) or Mercon are required. Power steering units. It has a wide range of European heavy duty automatic transmissions approvals as well has Allison heavy duty transmissions against TES 389.",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "Transmax Dex III Multi Vehicle",
    productPara:
      " Transmax Dex III Multivehicle, formulated with Smooth Drive Technology is designed for use in GM automatic transmissions pre 2005 and Ford automatic transmissions built between 1983 and 1996 where Dexron(II or III) or Mercon are required. Power steering units. It has a wide range of European heavy duty automatic transmissions approvals as well has Allison heavy duty transmissions against TES 389.",
  },
  {
    productImg: productsImg.brakefluidcastrol,
    productTitle: "Brake Fluid Dot 4 0.2L",
    productPara:
      " Dot 4 Brake fluid is an essential part of your car's brake system. Castrol Dot 4 brake fluid is liquid engineered to prevent vapor lock and protect your brake system.",
  },
  {
    productImg: productsImg.brakefluidcastrol,
    productTitle: "Brake Fluid Dot 4 0.5L",
    productPara:
      " Dot 4 Brake fluid is an essential part of your car's brake system. Castrol Dot 4 brake fluid is liquid engineered to prevent vapor lock and protect your brake system.",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "Brake Fluid Dot 4 210L",
    productPara:
      "  Dot 4 Brake fluid is an essential part of your car's brake system. Castrol Dot 4 brake fluid is liquid engineered to prevent vapor lock and protect your brake system.",
  },
  {
    productImg: productsImg.viscogenklspray,
    productTitle: "viscogen kl 300",
    productPara:
      " Castrol Viscogen KL range are thermally stable synthetic lubricants designed for high temperature lubrication in severe environments, where the use of mineral oils or conventional synthetic oils would result in excessive wear, carbonisation and residue formation.",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "Tection Monograde 10W",
    productPara:
      " The primary application of Castrol Tection Monograde 10W is in diesel engines, hydraulic systems and transmissions that specify the use of a monograde diesel engine oil.",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "Vecton L/Drain 10W-40 E7",
    productPara:
      " Castrol VECTON® Long Drain 10W-40 E7 is an advanced synthetic heavy duty diesel engine oil that is specifically engineered to deliver longer useful oil life. It is suitable for use in highly rated diesel engines meeting Euro I to Euro V emission requirements. It is suitable for engines without particulate filters, for some EGR engines and some engines fitted with SCR NOx reduction systems.",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "Vecton L/Drain 10W-40 E6/E9",
    productPara:
      " Castrol VECTON® Long Drain 10W-40 E6/E9 is an advanced synthetic heavy duty diesel engine oil that is specifically engineered to deliver longer useful oil life. It is suitable for highly rated diesel engines meeting Euro I to Euro VI emission requirements. It is suitable for engines with or without particulate filters, and for most EGR engines and for most engines fitted with SCR NOx reduction systems. It is strongly recommended for engines fitted with particulate filters and is designed for use in combination with low sulphur diesel fuel",
  },
  {
    productImg: productsImg.twotoil,
    productTitle: "2T Oil",
    productPara:
      " 2-Stroke Oil Eterna Powercycle 2T Oil is a premium high performance motorcycle engine oil blended for use in both oil injection and pre-mix systems.It is formulated to prevent spark plug fouling and carbon deposits by reducing smoke thereby providing exceptional protection under the most severe conditions in motorcycles, go-karts, scooters, lawn equipment and all other two-stroke engines.",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "2T Oil",
    productPara:
      "  2-Stroke Oil Eterna Powercycle 2T Oil is a premium high performance motorcycle engine oil blended for use in both oil injection and pre-mix systems.It is formulated to prevent spark plug fouling and carbon deposits by reducing smoke thereby providing exceptional protection under the most severe conditions in motorcycles, go-karts, scooters, lawn equipment and all other two-stroke engines.",
  },
  {
    productImg: productsImg.powermaxoil,
    productTitle: "Powermax HD 40",
    productPara:
      " Powermax Diesel Oils are good quality oils blended for the lubrication of small, medium powered and certain heavy-duty, naturally aspirated diesel engines.",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "Powermax HD 40",
    productPara:
      " Powermax Diesel Oils are good quality oils blended for the lubrication of small, medium powered and certain heavy-duty, naturally aspirated diesel engines.",
  },
  {
    productImg: productsImg.multimax4l,
    productTitle: "Multimax 20W-50 SF",
    productPara:
      "Multimax  SJ  is  a  guaranteed  quality  lubricant  blended  to  provide dependable all-year-round engine  protection in naturally aspirated passenger cars & SUV engines fueled by gasoline, diesel or LPG.",
  },
  {
    productImg: productsImg.multimax1l20,
    productTitle: "Multimax 20W-50 SF",
    productPara:
      " Multimax  SJ  is  a  guaranteed  quality  lubricant  blended  to  provide dependable all-year-round engine  protection in naturally aspirated passenger cars & SUV engines fueled by gasoline, diesel or LPG.",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "Multimax 20W-50 SJ (Metal Drum)",
    productPara:
      " Multimax  SJ  is  a  guaranteed  quality  lubricant  blended  to  provide dependable all-year-round engine  protection in naturally aspirated passenger cars & SUV engines fueled by gasoline, diesel or LPG.",
  },
  {
    productImg: productsImg.multimax4lsuoper,
    productTitle: "Multimax SL Super",
    productPara:
      " Multimax SL is specially formulated to give ultimate all-year-round engine protection, performance and superior fuel economy. It is suitable for all naturally aspirated, fuel injected, turbocharged and multi valve passenger car engines fueled by gasoline, diesel or LPG.",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "Multimax SL Super (Metal Drum)",
    productPara:
      " Multimax SL is specially formulated to give ultimate all-year-round engine protection, performance and superior fuel economy. It is suitable for all naturally aspirated, fuel injected, turbocharged and multi valve passenger car engines fueled by gasoline, diesel or LPG.",
  },
  {
    productImg: productsImg.optimax25l,
    productTitle: "Optimax 15W-40 CF-4",
    productPara:
      " Super High Performance Diesel Engine Oil (SHPD) Optimax SAE 15W-40, is a High Performance Heavy Duty Engine Lubricant designed for use in all diesel engines.",
  },
  {
    productImg: productsImg.optimax5l,
    productTitle: "Optimax 15W-40 CF-4",
    productPara:
      " Super High Performance Diesel Engine Oil (SHPD) Optimax SAE 15W-40, is a High Performance Heavy Duty Engine Lubricant designed for use in all diesel engines.",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "Optimax 15W-40 CF-4 (Metal Drum)",
    productPara:
      " Super High Performance Diesel Engine Oil (SHPD) Optimax SAE 15W-40, is a High Performance Heavy Duty Engine Lubricant designed for use in all diesel engines.",
  },
  {
    productImg: productsImg.optimax25l,
    productTitle: "Optimax Plus 15W-40 CH-4",
    productPara:
      " Super High Performance Diesel Engine Oil (SHPD) It is a top quality Super High Performance Diesel Engine Oil (SHPD) specially formulated to provide the ultimate all round performance for automotive diesel engines. It meets the requirements of all automotive high-speed four-stroke diesel engines.",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "Optimax Plus 15W-40 CH-4 (Metal Drum)",
    productPara:
      " Super High Performance Diesel Engine Oil (SHPD) It is a top quality Super High Performance Diesel Engine Oil (SHPD) specially formulated to provide the ultimate all round performance for automotive diesel engines. It meets the requirements of all automotive high-speed four-stroke diesel engines.",
  },
  {
    productImg: productsImg.optimax25l,
    productTitle: "Optimax Super Plus 15W-40 CI-4",
    productPara:
      "Super High Performance Diesel Engine Oil (SHPD) It is a top quality multigrade Super High Performance Diesel Engine Oil (SHPD) specially formulated to provide ultimate all round performance for all high-speed four-stroke diesel engines.",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "Optimax Super Plus 15W-40 CI-4 (Metal Drum)",
    productPara:
      "Super High Performance Diesel Engine Oil (SHPD) It is a top quality multigrade Super High Performance Diesel Engine Oil (SHPD) specially formulated to provide ultimate all round performance for all high-speed four-stroke diesel engines.",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "Optimax 15W-40 CF-4 (Plastic Drum)",
    productPara:
      "Super High Performance Diesel Engine Oil (SHPD) It is a top quality multigrade Super High Performance Diesel Engine Oil (SHPD) specially formulated to provide ultimate all round performance for all high-speed four-stroke diesel engines.",
  },
  {
    productImg: productsImg.supertc,
    productTitle: "Super TC 40",
    productPara:
      "High Performance Diesel Engine Oil Eterna Super TC Oils are high performance heavy duty engine lubricants designed for use in all diesel engines. It provides outstanding lubrication in all 4-Stroke diesel engines.  It is the prime recommendation for GM Diesel Two –Stroke Engines. It is suitable for Road Transport (CRT) & Construction Industry units, high speed/stop-start duty.",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "Super TC 40",
    productPara:
      " High Performance Diesel Engine Oil Eterna Super TC Oils are high performance heavy duty engine lubricants designed for use in all diesel engines. It provides outstanding lubrication in all 4-Stroke diesel engines.  It is the prime recommendation for GM Diesel Two –Stroke Engines. It is suitable for Road Transport (CRT) & Construction Industry units, high speed/stop-start duty.",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "Super TC 50 (Metal Drum)",
    productPara:
      " CASTROL HYSOL MB 50 55 GAL DRUM METALWORKING FLUID SOLUBLE 03117-BEDR.",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "EP 140",
    productPara:
      " Classic EP 140 is a high viscosity, mineral oil based gear oil containing anti-oxidant, anti-wear and extreme pressure additives to protect against problems due to oil oxidation, gear wear and corrosion.",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "EP 140",
    productPara:
      "Classic EP 140 is a high viscosity, mineral oil based gear oil containing anti-oxidant, anti-wear and extreme pressure additives to protect against problems due to oil oxidation, gear wear and corrosion.",
  },
  {
    productImg: productsImg.castrolep90,
    productTitle: "EP 90",
    productPara:
      " Castrol EP 90 may be used in applications where API GL-4 performance is required",
  },
  {
    productImg: productsImg.ep25l,
    productTitle: "EP 90",
    productPara:
      "Castrol EP 90 may be used in applications where API GL-4 performance is required",
  },
  {
    productImg: productsImg.castrol25l,
    productTitle: "EPX 80W-90",
    productPara:
      "Castrol Transmax Axle EPX 80W-90 is a multipurpose axle oil which may be used in differentials, final drives and other applications in passenger cars and commercial vehicles where API GL-5 performance is required. It is approved by MAN and ZF for use in a range of commercial applications including axles in off-road and agricultural equipment.",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "EPX 80W-90",
    productPara:
      "Castrol Transmax Axle EPX 80W-90 is a multipurpose axle oil which may be used in differentials, final drives and other applications in passenger cars and commercial vehicles where API GL-5 performance is required. It is approved by MAN and ZF for use in a range of commercial applications including axles in off-road and agricultural equipment.",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "EPX 85W-140 (Metal Drum)",
    productPara:
      "Castrol Axle EPX 85W-140 is a multipurpose axle oil which may be used in differentials, final drives and other applications in passenger cars and commercial vehicles where API GL-5 performance is required. It is approved by ZF for use in a range of commercial applications including axles in off-road and agricultural equipment.",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "XP - SP 100",
    productPara:
      "  XP-SP  Gear  Oils  are  premium  quality,  lead-free gear  oils  formulated to  provide  an  extreme pressure  performance  significantly  better  than  that  provided  by  leaded  gear  oils.",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "XP - SP 150",
    productPara:
      " XP-SP  Gear  Oils  are  premium  quality,  lead-free gear  oils  formulated to  provide  an  extreme pressure  performance  significantly  better  than  that  provided  by  leaded  gear  oils.",
  },
  {
    productImg: productsImg.castrol25l,
    productTitle: "XP - SP 150",
    productPara:
      "  XP-SP  Gear  Oils  are  premium  quality,  lead-free gear  oils  formulated to  provide  an  extreme pressure  performance  significantly  better  than  that  provided  by  leaded  gear  oils.",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "XP - SP 220",
    productPara:
      "  XP-SP  Gear  Oils  are  premium  quality,  lead-free gear  oils  formulated to  provide  an  extreme pressure  performance  significantly  better  than  that  provided  by  leaded  gear  oils.",
  },
  {
    productImg: productsImg.castrol25l,
    productTitle: "XP - SP 220",
    productPara:
      "  XP-SP  Gear  Oils  are  premium  quality,  lead-free gear  oils  formulated to  provide  an  extreme pressure  performance  significantly  better  than  that  provided  by  leaded  gear  oils.",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "XP - SP 320",
    productPara:
      "  XP-SP  Gear  Oils  are  premium  quality,  lead-free gear  oils  formulated to  provide  an  extreme pressure  performance  significantly  better  than  that  provided  by  leaded  gear  oils.",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "XP - SP 460",
    productPara:
      "  XP-SP  Gear  Oils  are  premium  quality,  lead-free gear  oils  formulated to  provide  an  extreme pressure  performance  significantly  better  than  that  provided  by  leaded  gear  oils.",
  },

  {
    productImg: productsImg.ep2grease,
    productTitle: "EP 2 Grease",
    productPara:
      " Castrol Spheerol EP2 is an extreme pressure, lithium-based, general-purpose industrial grease of an NLGI No. 2 consistency.",
  },
  {
    productImg: productsImg.hydrexaw32,
    productTitle: "Hydrex AW 32",
    productPara:
      "High Performance Hydraulic Oils They are specially formulated to give excellent performance in standard industrial hydraulic and fluid power transmission system lubrication.Suitable in machine tools, centrifugal pumps, injection moulding machines, gear units and centralized bearing lubricating system.",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "Hydrex AW 32",
    productPara:
      "High Performance Hydraulic Oils They are specially formulated to give excellent performance in standard industrial hydraulic and fluid power transmission system lubrication.Suitable in machine tools, centrifugal pumps, injection moulding machines, gear units and centralized bearing lubricating system",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "Hydrex AW 46",
    productPara:
      "High Performance Hydraulic Oils They are specially formulated to give excellent performance in standard industrial hydraulic and fluid power transmission system lubrication.Suitable in machine tools, centrifugal pumps, injection moulding machines, gear units and centralized bearing lubricating system.",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "Hydrex AW 68",
    productPara:
      "High Performance Hydraulic Oils They are specially formulated to give excellent performance in standard industrial hydraulic and fluid power transmission system lubrication.Suitable in machine tools, centrifugal pumps, injection moulding machines, gear units and centralized bearing lubricating system.",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "Hydrex AW 100",
    productPara:
      "High Performance Hydraulic Oils They are specially formulated to give excellent performance in standard industrial hydraulic and fluid power transmission system lubrication.Suitable in machine tools, centrifugal pumps, injection moulding machines, gear units and centralized bearing lubricating system.",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "Hydrex AWH-M 46",
    productPara:
      "Castrol Hyspin AWH-M 46 is a high viscosity index anti-wear hydraulic oil. It exhibits very good corrosion and wear protection as well as good thermal and oxidative stability.",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "Hydrex AWH-M 46",
    productPara:
      "Castrol AWH-M 100 is a high viscosity index anti-wear hydraulic oil. It exhibits very good corrosion and wear protection as well as good thermal and oxidative stability.",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "Hydrex AWH-M 100",
    productPara:
      "Castrol AWH-M 100 is a high viscosity index anti-wear hydraulic oil. It exhibits very good corrosion and wear protection as well as good thermal and oxidative stability.",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "Cyl 40",
    productPara:
      "Castrol Cyltech 40SX is a marine diesel engine cylinder lubricant developed with a bespoke detergency system for operations at higher temperatures and pressures in modern engines,which operate on fuels with sulphur content of between 0.0-1.5%*, and LNG.",
  },
  {
    productImg: productsImg.mouldingoil1l,
    productTitle: "Demoulding Oil",
    productPara:
      "Castrol form oil ML-70 form oil is a mineral based form oil that contains a release agent and is recommended for high quality architectural surface finishes on pre-cast concrete.",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "Enertherm HT 5",
    productPara:
      "Enertherm Oil is based on a blend of carefully selected, solvent refined, high viscosity index mineral oils chosen for their ability to provide high thermal and oxidation stability, superior performance in indirect closed fluid heat transfer systems operating at bulk temperatures up to 320C.",
  },
  {
    productImg: productsImg.atf1L,
    productTitle: "ATF Dexron II",
    productPara:
      "Castrol ATF Dex II may be used in GM automatic transmissions pre 1993 and power steering units of many types of vehicle where Dexron IID or Mercon performance is required.",
  },
  {
    productImg: productsImg.castrol25l,
    productTitle: "ATF Dexron II",
    productPara:
      "Castrol ATF Dex II may be used in GM automatic transmissions pre 1993 and power steering units of many types of vehicle where Dexron IID or Mercon performance is required.",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "ATF Dexron II",
    productPara:
      "Castrol ATF Dex II may be used in GM automatic transmissions pre 1993 and power steering units of many types of vehicle where Dexron IID or Mercon performance is required.",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "ATF Dexron III",
    productPara:
      "Castrol ATF Dex III is designed for use in GM automatic transmissions pre 2005 and in Ford automatic transmission built between 1983 and 1996 where Dexron® (II or III) or Mercon performance is required. Also suitable as Power Steering Fluid. NB:Should not be used where Dexron® VI, Mercon® V or Mercon® SP are required",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "Turbine 32",
    productPara:
      "Castrol Perfecto X 32 is a turbine oil based upon premium quality mineral oils enhanced with rust and oxidation inhibitors to give maximum protection at high temperatures.",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "Turbine 46",
    productPara:
      "Castrol Perfecto X 46 is a turbine oil based upon premium quality mineral oils enhanced with rust and oxidation inhibitors to give maximum protection at high temperatures.",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "Turbine 68",
    productPara:
      "Castrol Perfecto X 46 is a turbine oil based upon premium quality mineral oils enhanced with rust and oxidation inhibitors to give maximum protection at high temperatures.",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "CDX 30",
    productPara:
      "Castrol CDX 30 is a premium alkaline system lubricant suitable for use in the crankcase of low speed marine crosshead engines.",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "MHP 153",
    productPara:
      "Castrol MHP 153 is a high performance lubricant specifically developed for use in modern highly rated marine and power generation four-stroke engines operating on distillate fuels.",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "MHP 154",
    productPara:
      "Castrol MHP 154 is a high performance lubricant specifically developed for use in modern highly rated marine and power generation four-stroke engines operating on distillate fuels.",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "TLX Plus 204",
    productPara:
      "Castrol TLX Plus 204 is a high performance lubricant incorporating an advanced additive technology formulated to overcome the adverse conditions seen in engines with low oil consumption and operating with varying heavy fuel qualities.",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "TLX Plus 303",
    productPara:
      " The Castrol TLX Plus range is formulated using high quality base oils and supersedes the previous TLX series. It incorporates a unique formulation of additive technology systems designed to overcome the adverse conditions seen in low oil consumption engines operating with varying quality heavy fuel oils.",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "TLX Plus 404",
    productPara:
      " The Castrol TLX Plus range is formulated using high quality base oils and supersedes the previous TLX series. It incorporates a unique formulation of additive technology systems designed to overcome the adverse conditions seen in low oil consumption engines operating with varying quality heavy fuel oils.",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "Alpha SP 68",
    productPara:
      "The Castrol Alpha™ SP gear oil range of high quality lubricants are based upon highly refined mineral oil, enhanced with an extreme pressure additive technology providing good thermal stability and high load carrying capacity.",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "Alpha SP 100",
    productPara:
      "Castrol Alpha SP 100 is a high quality gear oil which are based upon highly refined mineral oil, enhanced with an extreme pressure additive technology providing good thermal stability and high load carrying capacity. ",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "Alpha SP 150",
    productPara:
      "Castrol Alpha™ SP 150 P (previously called Energol™ GR-XP 150 Plus) is based upon highly refined mineral oil, enhanced with extreme pressure additive technology. This provides good thermal stability, high load carrying capacity and resistance to microscopic wear, known as micropitting protection. The high load carrying capacity has been boosted over conventional gear oils of this type for specific applications.",
  },

  {
    productImg: productsImg.castroldrum,
    productTitle: "Alpha SP 320",
    productPara:
      "The Castrol Alpha SP range are high quality mineral extreme pressure gear lubricants. They are formulated using high quality mineral base oils which are fortified with a sulphur-phosphorous additive system to prevent scuffing in heavily loaded applications. The balanced formulation separates water rapidly and resists foaming ensuring optimum film strength at the point of contact.",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "Alpha SP 460",
    productPara:
      "The Castrol Alpha SP range are high quality mineral extreme pressure gear lubricants. They are formulated using high quality mineral base oils which are fortified with a sulphur-phosphorous additive system to prevent scuffing in heavily loaded applications. The balanced formulation separates water rapidly and resists foaming ensuring optimum film strength at the point of contact.",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "Alpha EP 150",
    productPara:
      "The Castrol Alphasyn EP gear oil range of high quality synthetic lubricants are based on polyalpha-olefin (PAO) fluids and sulpur / phosphorus Extreme Pressure (EP) additive technology providing outstanding thermal stability and high load carrying capacity. The Alphasyn EP range has been formulated for use in all types of enclosed gears including heavy loaded / shock-loaded gears and bearings where EP properties are required.",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "Alpha EP 220",
    productPara:
      "The Castrol Alphasyn EP gear oil range of high quality synthetic lubricants are based on polyalpha-olefin (PAO) fluids and sulpur / phosphorus Extreme Pressure (EP) additive technology providing outstanding thermal stability and high load carrying capacity. The Alphasyn EP range has been formulated for use in all types of enclosed gears including heavy loaded / shock-loaded gears and bearings where EP properties are required.",
  },
  {
    productImg: productsImg.castrol25l,
    productTitle: "Axle EPX 80W-90",
    productPara:
      "Axle EPX 80W-90 is a Multigrade gear oil fortified to meet the requirements of API GL-5. Its primary use is for hypoid gears featured in automotive differentials but is often specified in highly loaded spiral bevel or planetary gearboxes on offshore installations and onshore drilling sites.",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "Axle EPX 80W-90",
    productPara:
      "Axle EPX 80W-90 is a Multigrade gear oil fortified to meet the requirements of API GL-5. Its primary use is for hypoid gears featured in automotive differentials but is often specified in highly loaded spiral bevel or planetary gearboxes on offshore installations and onshore drilling sites.",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "Axle EPX 85W-140",
    productPara:
      "Castrol Axle EPX 85W-140 is a multipurpose axle oil which may be used in differentials, final drives and other applications in passenger cars and commercial vehicles where API GL-5 performance is required. It is approved by ZF for use in a range of commercial applications including axles in off-road and agricultural equipment.",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "Hyspin AWH-M 46",
    productPara:
      " The Castrol Hyspin™ AWH-M hydraulic oil range of shear stable high viscosity index lubricants are based on the latest stabilised zinc additive technology.",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "Hyspin AWH-M 68",
    productPara:
      " Castrol Hyspin AWH-M is a range of high quality mineral hydraulic oils with a high viscosity index (VI), designed for operation over a wide temperature range with minimum variations in viscosity. The Castrol Hyspin AWH-M range contains antiwear, anti-oxidation, anti-foam and anti-corrosion additives. ",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "Hyspin AWH-M 100",
    productPara:
      " Castrol Hyspin AWH-M is a range of high quality mineral hydraulic oils with a high viscosity index (VI), designed for operation over a wide temperature range with minimum variations in viscosity. The Castrol Hyspin AWH-M range contains antiwear, anti-oxidation, anti-foam and anti-corrosion additives. ",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "Hyspin AWH-M 150",
    productPara:
      " Castrol Hyspin AWH-M is a range of high quality mineral hydraulic oils with a high viscosity index (VI), designed for operation over a wide temperature range with minimum variations in viscosity. The Castrol Hyspin AWH-M range contains antiwear, anti-oxidation, anti-foam and anti-corrosion additives. ",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "Hyspin AWS 32",
    productPara:
      " The Castrol Hyspin AWS hydraulic oil range is based upon highly refined mineral oil with a low zinc containing anti -wear system. ",
  },

  {
    productImg: productsImg.castroldrum,
    productTitle: "Hyspin AWS 46",
    productPara:
      " The Castrol Hyspin AWS hydraulic oil range is based upon highly refined mineral oil with a low zinc containing anti -wear system. ",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "Hyspin AWS 68",
    productPara:
      " The Castrol Hyspin AWS hydraulic oil range is based upon highly refined mineral oil with a low zinc containing anti -wear system. ",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "Hyspin AWS 100",
    productPara:
      " The Castrol Hyspin AWS hydraulic oil range is based upon highly refined mineral oil with a low zinc containing anti -wear system. ",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "Cyltech 70",
    productPara:
      "CyltechTM 70 supersedes and replaces Castrol S/DZ 70. Developed to provide superior performance at the higher temperatures and pressures in modern engines, it is suitable for the lubrication of the latest super long-stroke crosshead engines, and indeed for all slow-speed marine diesel engines burning residual fuels. ",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "Cyltech 40SX",
    productPara:
      "Castrol Cyltech 40SX is a marine diesel engine cylinder lubricant developed with a bespoke detergency system for operations at higher temperatures and pressures in modern engines,which operate on fuels with sulphur content of between 0.0-1.5%*, and LNG.",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "Alpha EP 150",
    productPara:
      "Alphasyn EP 150 is a high-quality synthetic lubricant which has been formulated for use in all types of enclosed gears including heavy loaded / shock-loaded gears and bearings where EP properties are required.",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "Alphasyn EP 220",
    productPara:
      "Castrol Alphasyn™ EP 220 gear oil is a high quality synthetic lubricant based on poly- alphaolefin (PAO) fluids and sulphur/phosphorus Extreme Pressure (EP) additive technology providing good thermal stability and high load carrying capacity",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "Alphasyn HG 220",
    productPara:
      "Alphasyn HG synthetic fluids are polyalphaolefin based, high performance lubricants formulated with specially selected additives to offer a range of fluids for use in both gear and bearing applications. The range has been designed to offer specific performance benefits over its conventional mineral oil and synthetic fluid counterparts.",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "Alphasyn PG 220",
    productPara:
      "The Castrol Alphasyn™ PG gear oil range of synthetic lubricants are based on polyalkyleneglycol (PAG) fluids enhanced with antioxidants, rust inhibitors and Extreme Pressure (EP) additives of high thermal stability.",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "Alphasyn PG 320",
    productPara:
      " The Castrol Alphasyn™ PG gear oil range of synthetic lubricants are based on polyalkyleneglycol (PAG) fluids enhanced with antioxidants, rust inhibitors and Extreme Pressure (EP) additives of high thermal stability.",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "Alphasyn PG 460",
    productPara:
      " The Castrol Alphasyn™ PG gear oil range of synthetic lubricants are based on polyalkyleneglycol (PAG) fluids enhanced with antioxidants, rust inhibitors and Extreme Pressure (EP) additives of high thermal stability.",
  },
  {
    productImg: productsImg.castroldrum,
    productTitle: "Alphasyn T 220",
    productPara:
      " The Castrol Alphasyn™ T gear oil range of synthetic lubricants is based on polyalphaolefin (PAO) fluids and sulphur/ phosphorus anti-wear additive technology providing outstanding thermal stability and good load carrying capacity.",
  },
  // {
  //   productImg: productsImg.castroldrum,
  //   productTitle: "Powermax HD 40",
  //   productPara:
  //     " Powermax Diesel Oils are good quality oils blended for the lubrication of small, medium powered and certain heavy-duty, naturally aspirated diesel engines.",
  // },
];

export const hyundaiLubricants = [
  {
    productImg: productsImg.hyundaixteerg50020w50,
    productTitle: "XTEER G500 20w-50",
    productPara:
      "XTeer Gasoline is the most advanced gasoline engine oil designed for premium cars with the latest technology. It meets the requirements of current advanced engine oil grade of API SL. It is optimized to provide outstanding protection and lubricity in all passenger gasoline car engines.",
  },
  {
    productImg: productsImg.hyundaixteer15w40,
    productTitle: "Hyundai XTEER G500 15w-40",
    productPara:
      "This Hyundai XTeer Gasoline Engine Oil G500 15w40 4L is a gasoline engine oil product made of high-quality base oil and premium additives. It is compliant with API SL Grade and applicable to gasoline engines.",
  },

  {
    productImg: productsImg.hyudaixteer_3000,
    productTitle: "HYUNDAI XTEER hd 3000 sae 50",
    productPara:
      "XTeer Heavy Duty is the most advanced heavy duty diesel engine oil designed for the latest premium diesel engines as well as conventional engines. It is engineered to provide outstanding lubrication to modern, high performance diesel engines used in severe on-and-off highway applications. The advanced technology in XTeer Heavy Duty delivers exceptional performance in both modern diesel engines as well as old models.",
  },
];

export const petronasLubricants = [
  {
    productImg: productsImg.petronas20w50,
    productTitle: "Petronas Mach 5Plus 20w-50",
    productPara:
      "PETRONAS M-PLUS is a premium quality SAE 20W-50 multi-grade engine oil specially formulated for the lubrications of passenger car and light duty truck especially those with mileage above 120,000 km.",
  },
];
