import React, { useEffect } from "react";
import SingleDistributor from "../singleDistributor/SingleDistributor";
import "./distributorsList.css";
import skf from "../../assets/images/skf.webp";
import cassida from "../../assets/images/cassida.webp";
import cepsa from "../../assets/images/cepsa.webp";
import ibiotec from "../../assets/images/ibiotec.webp";
import lubcon from "../../assets/images/lubcon.webp";
import lubriso from "../../assets/images/lubriso.webp";
import mobil from "../../assets/images/mobil.webp";
import motul from "../../assets/images/motul.png";
import panolin from "../../assets/images/panolin.webp";
import petronas from "../../assets/images/petronas.webp";
import quaker from "../../assets/images/quaker.webp";
import royal_purple from "../../assets/images/royal_purple.webp";
import shell from "../../assets/images/shell.webp";
import total_logo from "../../assets/icons/total_logo.png";
import amasco_logo from "../../assets/icons/amasco_logo.jpg";
import texaco_logo from "../../assets/icons/texaco_logo.png";
import ardova_logo from "../../assets/icons/ardova_logo.png";
import nnpc_logo from "../../assets/icons/nnpc_logo.png";
import mrs_logo from "../../assets/icons/mrs_logo.jpg";
import oplug_logo from "../../assets/icons/oplug_logo.png";
import eterna_logo from "../../assets/icons/eterna_logo.jpg";
import castrol_logo from "../../assets/icons/castrol_logo.png";
import fuch_logo from "../../assets/images/fuch_logo.png";
import { useAnimation, motion } from "framer-motion";

import { useInView } from "react-intersection-observer";
import { Location } from "react-router-dom";

import { useLocation } from "react-router-dom";

const squareVariantsY = {
  visible: { opacity: 1, y: 0, transition: { duration: 3 } },
  hidden: { opacity: 0, y: 100 },
};

const DistributorsList = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView();
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);
  return (
    <div className="distributorsList_gen">
      <div className="distributorsList_genMax">
        <div className="distributorsList_header">Lubricant Brands</div>

        <motion.div
          ref={ref}
          animate={controls}
          initial="hidden"
          variants={squareVariantsY}
          className="distributor_listFlex"
        >
          <SingleDistributor
            distLogo={skf}
            distName="SKF"
            distLogoAlt="skf_logo"
          />
          <SingleDistributor
            distLogo={shell}
            distName="SHELL"
            distLogoAlt="shell_logo"
          />
          <SingleDistributor
            distLogo={cepsa}
            distName="CEPSA"
            distLogoAlt="cepsa_logo"
          />

          <SingleDistributor
            distLogo={total_logo}
            distName="TOTAL"
            distLogoAlt="total_logo"
          />
          <SingleDistributor
            distLogo={amasco_logo}
            distName="AMASCO"
            distLogoAlt="amasco_logo"
          />
          <SingleDistributor
            distLogo={texaco_logo}
            distName="TEXACO"
            distLogoAlt="texaco_logo"
          />
          <SingleDistributor
            distLogo={ardova_logo}
            distName="ARDOVA"
            distLogoAlt="ardova_logo"
          />
          <SingleDistributor
            distLogo={mobil}
            distName="MOBIL"
            distLogoAlt="mobil_logo"
          />
          <SingleDistributor
            distLogo={motul}
            distName="MOTUL"
            distLogoAlt="motul_logo"
          />
          <SingleDistributor
            distLogo={nnpc_logo}
            distName="NNPC LOGO"
            distLogoAlt="nnpc_logo"
          />
          <SingleDistributor
            distLogo={mrs_logo}
            distName="MRS"
            distLogoAlt="mrs_logo"
          />
          <SingleDistributor
            distLogo={oplug_logo}
            distName="OPLUG"
            distLogoAlt="oplug_logo"
          />
          <SingleDistributor
            distLogo={eterna_logo}
            distName="ETERNA"
            distLogoAlt="eterna_logo"
          />
          <SingleDistributor
            distLogo={castrol_logo}
            distName="CASTROL"
            distLogoAlt="castrol_logo"
          />
          <SingleDistributor
            distLogo={fuch_logo}
            distName="FUCH"
            distLogoAlt="fuch_logo"
          />
          <SingleDistributor
            distLogo={petronas}
            distName="PETRONAS"
            distLogoAlt="petronas_logo"
          />
        </motion.div>
      </div>
    </div>
  );
};

export default DistributorsList;
